// @ts-nocheck
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../../runtime/json/scalar";
import { default as serialize, } from "../../../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, unpackFns, } from "../../../../runtime/wire/scalar";
import { default as deserialize, } from "../../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        description: "",
        boardCode: "",
        productIds: [],
        recommendedProductIds: [],
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.description !== undefined)
        result.description = tsValueToJsonValueFns.string(value.description);
    if (value.boardCode !== undefined)
        result.boardCode = tsValueToJsonValueFns.string(value.boardCode);
    result.productIds = value.productIds.map(value => tsValueToJsonValueFns.int64(value));
    result.recommendedProductIds = value.recommendedProductIds.map(value => tsValueToJsonValueFns.int64(value));
    return result;
}
export function decodeJson(value) {
    var _a, _b, _c, _d;
    const result = getDefaultValue();
    if (value.description !== undefined)
        result.description = jsonValueToTsValueFns.string(value.description);
    if (value.boardCode !== undefined)
        result.boardCode = jsonValueToTsValueFns.string(value.boardCode);
    result.productIds = (_b = (_a = value.productIds) === null || _a === void 0 ? void 0 : _a.map((value) => jsonValueToTsValueFns.int64(value))) !== null && _b !== void 0 ? _b : [];
    result.recommendedProductIds = (_d = (_c = value.recommendedProductIds) === null || _c === void 0 ? void 0 : _c.map((value) => jsonValueToTsValueFns.int64(value))) !== null && _d !== void 0 ? _d : [];
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.description !== undefined) {
        const tsValue = value.description;
        result.push([1, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.boardCode !== undefined) {
        const tsValue = value.boardCode;
        result.push([2, tsValueToWireValueFns.string(tsValue)]);
    }
    for (const tsValue of value.productIds) {
        result.push([3, tsValueToWireValueFns.int64(tsValue)]);
    }
    for (const tsValue of value.recommendedProductIds) {
        result.push([4, tsValueToWireValueFns.int64(tsValue)]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.description = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.boardCode = value;
    }
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 3).map(([, wireValue]) => wireValue);
        const value = Array.from(unpackFns.int64(wireValues));
        if (!value.length)
            break collection;
        result.productIds = value;
    }
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 4).map(([, wireValue]) => wireValue);
        const value = Array.from(unpackFns.int64(wireValues));
        if (!value.length)
            break collection;
        result.recommendedProductIds = value;
    }
    return result;
}
