// @ts-nocheck
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "../DisplayNameDeclaration";
import { encodeJson as encodeJson_2, decodeJson as decodeJson_2, encodeBinary as encodeBinary_2, decodeBinary as decodeBinary_2, } from "../../../../../../toeic/ToeicTag";
import { encodeJson as encodeJson_3, decodeJson as decodeJson_3, encodeBinary as encodeBinary_4, decodeBinary as decodeBinary_4, } from "../ProficiencyDeclaration";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../../../../../runtime/json/scalar";
import { WireType, } from "../../../../../../../runtime/wire/index";
import { default as serialize, } from "../../../../../../../runtime/wire/serialize";
import { encodeBinary as encodeBinary_3, decodeBinary as decodeBinary_3, } from "../../../../../../google/protobuf/StringValue";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../../../../../../runtime/wire/scalar";
import { default as deserialize, } from "../../../../../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        titleDeclarations: [],
        tagsForTagProficiency: [],
        skillTagValue: undefined,
        superordinateCategoryNameDeclarations: [],
        imageSetId: "0",
        proficiencyDeclaration: undefined,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    result.titleDeclarations = value.titleDeclarations.map(value => encodeJson_1(value));
    result.tagsForTagProficiency = value.tagsForTagProficiency.map(value => encodeJson_2(value));
    if (value.skillTagValue !== undefined)
        result.skillTagValue = value.skillTagValue;
    result.superordinateCategoryNameDeclarations = value.superordinateCategoryNameDeclarations.map(value => encodeJson_1(value));
    if (value.imageSetId !== undefined)
        result.imageSetId = tsValueToJsonValueFns.int64(value.imageSetId);
    if (value.proficiencyDeclaration !== undefined)
        result.proficiencyDeclaration = encodeJson_3(value.proficiencyDeclaration);
    return result;
}
export function decodeJson(value) {
    var _a, _b, _c, _d, _e, _f;
    const result = getDefaultValue();
    result.titleDeclarations = (_b = (_a = value.titleDeclarations) === null || _a === void 0 ? void 0 : _a.map((value) => decodeJson_1(value))) !== null && _b !== void 0 ? _b : [];
    result.tagsForTagProficiency = (_d = (_c = value.tagsForTagProficiency) === null || _c === void 0 ? void 0 : _c.map((value) => decodeJson_2(value))) !== null && _d !== void 0 ? _d : [];
    if (value.skillTagValue !== undefined)
        result.skillTagValue = value.skillTagValue;
    result.superordinateCategoryNameDeclarations = (_f = (_e = value.superordinateCategoryNameDeclarations) === null || _e === void 0 ? void 0 : _e.map((value) => decodeJson_1(value))) !== null && _f !== void 0 ? _f : [];
    if (value.imageSetId !== undefined)
        result.imageSetId = jsonValueToTsValueFns.int64(value.imageSetId);
    if (value.proficiencyDeclaration !== undefined)
        result.proficiencyDeclaration = decodeJson_3(value.proficiencyDeclaration);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    for (const tsValue of value.titleDeclarations) {
        result.push([1, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    for (const tsValue of value.tagsForTagProficiency) {
        result.push([2, { type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }]);
    }
    if (value.skillTagValue !== undefined) {
        const tsValue = value.skillTagValue;
        result.push([3, ((tsValue) => ({ type: WireType.LengthDelimited, value: encodeBinary_3(tsValue) }))({ value: tsValue })]);
    }
    for (const tsValue of value.superordinateCategoryNameDeclarations) {
        result.push([4, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    if (value.imageSetId !== undefined) {
        const tsValue = value.imageSetId;
        result.push([5, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.proficiencyDeclaration !== undefined) {
        const tsValue = value.proficiencyDeclaration;
        result.push([6, { type: WireType.LengthDelimited, value: encodeBinary_4(tsValue) }]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    var _a;
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 1).map(([, wireValue]) => wireValue);
        const value = wireValues.map((wireValue) => wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined).filter(x => x !== undefined);
        if (!value.length)
            break collection;
        result.titleDeclarations = value;
    }
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 2).map(([, wireValue]) => wireValue);
        const value = wireValues.map((wireValue) => wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined).filter(x => x !== undefined);
        if (!value.length)
            break collection;
        result.tagsForTagProficiency = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = (_a = (wireValue.type === WireType.LengthDelimited ? decodeBinary_3(wireValue.value) : undefined)) === null || _a === void 0 ? void 0 : _a.value;
        if (value === undefined)
            break field;
        result.skillTagValue = value;
    }
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 4).map(([, wireValue]) => wireValue);
        const value = wireValues.map((wireValue) => wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined).filter(x => x !== undefined);
        if (!value.length)
            break collection;
        result.superordinateCategoryNameDeclarations = value;
    }
    field: {
        const wireValue = wireFields.get(5);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.imageSetId = value;
    }
    field: {
        const wireValue = wireFields.get(6);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_4(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.proficiencyDeclaration = value;
    }
    return result;
}
