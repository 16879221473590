export const num2name = {
    0: "STATUS_UNSPECIFIED",
    1: "ANONYMOUS",
    2: "NORMAL",
    3: "SUSPENDED",
    4: "WITHDRAW",
    5: "BLOCKED",
};
export const name2num = {
    STATUS_UNSPECIFIED: 0,
    ANONYMOUS: 1,
    NORMAL: 2,
    SUSPENDED: 3,
    WITHDRAW: 4,
    BLOCKED: 5,
};
