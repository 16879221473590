// @ts-nocheck
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "./SelfLearningHierarchyQuery";
import { encodeJson as encodeJson_2, decodeJson as decodeJson_2, encodeBinary as encodeBinary_2, decodeBinary as decodeBinary_2, } from "./SelfLearningHierarchyConfig";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../../../../runtime/json/scalar";
import { WireType, } from "../../../../../../runtime/wire/index";
import { default as serialize, } from "../../../../../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../../../../../runtime/wire/scalar";
import { default as deserialize, } from "../../../../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        id: "0",
        isReady: false,
        queries: [],
        parentId: "0",
        config: undefined,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.id !== undefined)
        result.id = tsValueToJsonValueFns.int64(value.id);
    if (value.isReady !== undefined)
        result.isReady = tsValueToJsonValueFns.bool(value.isReady);
    result.queries = value.queries.map(value => encodeJson_1(value));
    if (value.parentId !== undefined)
        result.parentId = tsValueToJsonValueFns.int64(value.parentId);
    if (value.config !== undefined)
        result.config = encodeJson_2(value.config);
    return result;
}
export function decodeJson(value) {
    var _a, _b;
    const result = getDefaultValue();
    if (value.id !== undefined)
        result.id = jsonValueToTsValueFns.int64(value.id);
    if (value.isReady !== undefined)
        result.isReady = jsonValueToTsValueFns.bool(value.isReady);
    result.queries = (_b = (_a = value.queries) === null || _a === void 0 ? void 0 : _a.map((value) => decodeJson_1(value))) !== null && _b !== void 0 ? _b : [];
    if (value.parentId !== undefined)
        result.parentId = jsonValueToTsValueFns.int64(value.parentId);
    if (value.config !== undefined)
        result.config = decodeJson_2(value.config);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.id !== undefined) {
        const tsValue = value.id;
        result.push([1, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.isReady !== undefined) {
        const tsValue = value.isReady;
        result.push([2, tsValueToWireValueFns.bool(tsValue)]);
    }
    for (const tsValue of value.queries) {
        result.push([3, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    if (value.parentId !== undefined) {
        const tsValue = value.parentId;
        result.push([4, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.config !== undefined) {
        const tsValue = value.config;
        result.push([5, { type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.id = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.bool(wireValue);
        if (value === undefined)
            break field;
        result.isReady = value;
    }
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 3).map(([, wireValue]) => wireValue);
        const value = wireValues.map((wireValue) => wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined).filter(x => x !== undefined);
        if (!value.length)
            break collection;
        result.queries = value;
    }
    field: {
        const wireValue = wireFields.get(4);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.parentId = value;
    }
    field: {
        const wireValue = wireFields.get(5);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.config = value;
    }
    return result;
}
