import { StripeProductBoardCreateTemplateFromJSON, StripeProductBoardCreateTemplateToJSON, } from './StripeProductBoardCreateTemplate';
/**
 * Check if a given object implements the CreateStripeProductBoardRequest interface.
 */
export function instanceOfCreateStripeProductBoardRequest(value) {
    let isInstance = true;
    isInstance = isInstance && "template" in value;
    return isInstance;
}
export function CreateStripeProductBoardRequestFromJSON(json) {
    return CreateStripeProductBoardRequestFromJSONTyped(json, false);
}
export function CreateStripeProductBoardRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'template': StripeProductBoardCreateTemplateFromJSON(json['template']),
    };
}
export function CreateStripeProductBoardRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'template': StripeProductBoardCreateTemplateToJSON(value.template),
    };
}
