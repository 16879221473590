/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { SelfLearningContentTypeFromJSON, SelfLearningContentTypeToJSON, } from './SelfLearningContentType';
import { SelfLearningUnitGroupFilterFromJSON, SelfLearningUnitGroupFilterToJSON, } from './SelfLearningUnitGroupFilter';
/**
 * Check if a given object implements the SelfLearningUnitGroup interface.
 */
export function instanceOfSelfLearningUnitGroup(value) {
    let isInstance = true;
    isInstance = isInstance && "detailDescription" in value;
    isInstance = isInstance && "tagList" in value;
    isInstance = isInstance && "subTitle" in value;
    isInstance = isInstance && "tagProficiency" in value;
    isInstance = isInstance && "backgroundImageResourceId" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "contentType" in value;
    isInstance = isInstance && "contentCount" in value;
    return isInstance;
}
export function SelfLearningUnitGroupFromJSON(json) {
    return SelfLearningUnitGroupFromJSONTyped(json, false);
}
export function SelfLearningUnitGroupFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'detailDescription': json['detailDescription'],
        'tagList': json['tagList'],
        'groupFilter': !exists(json, 'groupFilter') ? undefined : SelfLearningUnitGroupFilterFromJSON(json['groupFilter']),
        'subTitle': json['subTitle'],
        'tagProficiency': json['tagProficiency'],
        'backgroundImageResourceId': json['backgroundImageResourceId'],
        'id': json['id'],
        'title': json['title'],
        'contentType': SelfLearningContentTypeFromJSON(json['contentType']),
        'contentCount': json['contentCount'],
    };
}
export function SelfLearningUnitGroupToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'detailDescription': value.detailDescription,
        'tagList': value.tagList,
        'groupFilter': SelfLearningUnitGroupFilterToJSON(value.groupFilter),
        'subTitle': value.subTitle,
        'tagProficiency': value.tagProficiency,
        'backgroundImageResourceId': value.backgroundImageResourceId,
        'id': value.id,
        'title': value.title,
        'contentType': SelfLearningContentTypeToJSON(value.contentType),
        'contentCount': value.contentCount,
    };
}
