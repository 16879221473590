/**
 * Check if a given object implements the UpdateAgreementInfoRequest interface.
 */
export function instanceOfUpdateAgreementInfoRequest(value) {
    let isInstance = true;
    isInstance = isInstance && "marketing" in value;
    isInstance = isInstance && "push" in value;
    return isInstance;
}
export function UpdateAgreementInfoRequestFromJSON(json) {
    return UpdateAgreementInfoRequestFromJSONTyped(json, false);
}
export function UpdateAgreementInfoRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'marketing': json['marketing'],
        'push': json['push'],
    };
}
export function UpdateAgreementInfoRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'marketing': value.marketing,
        'push': value.push,
    };
}
