import { OfferGroupListItemFromJSON, OfferGroupListItemToJSON, } from './OfferGroupListItem';
/**
 * Check if a given object implements the ListOfferGroupsResponse interface.
 */
export function instanceOfListOfferGroupsResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "offerGroups" in value;
    isInstance = isInstance && "total" in value;
    return isInstance;
}
export function ListOfferGroupsResponseFromJSON(json) {
    return ListOfferGroupsResponseFromJSONTyped(json, false);
}
export function ListOfferGroupsResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'offerGroups': (json['offerGroups'].map(OfferGroupListItemFromJSON)),
        'total': json['total'],
    };
}
export function ListOfferGroupsResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'offerGroups': (value.offerGroups.map(OfferGroupListItemToJSON)),
        'total': value.total,
    };
}
