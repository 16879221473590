import { SelfLearningContentTypeFromJSON, SelfLearningContentTypeToJSON, } from './SelfLearningContentType';
import { SelfLearningUnitGroupFilterFromJSON, SelfLearningUnitGroupFilterToJSON, } from './SelfLearningUnitGroupFilter';
/**
 * Check if a given object implements the SelfLearningUnitGroupFilters interface.
 */
export function instanceOfSelfLearningUnitGroupFilters(value) {
    let isInstance = true;
    isInstance = isInstance && "showAll" in value;
    isInstance = isInstance && "filters" in value;
    isInstance = isInstance && "contentType" in value;
    return isInstance;
}
export function SelfLearningUnitGroupFiltersFromJSON(json) {
    return SelfLearningUnitGroupFiltersFromJSONTyped(json, false);
}
export function SelfLearningUnitGroupFiltersFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'showAll': json['showAll'],
        'filters': (json['filters'].map(SelfLearningUnitGroupFilterFromJSON)),
        'contentType': SelfLearningContentTypeFromJSON(json['contentType']),
    };
}
export function SelfLearningUnitGroupFiltersToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'showAll': value.showAll,
        'filters': (value.filters.map(SelfLearningUnitGroupFilterToJSON)),
        'contentType': SelfLearningContentTypeToJSON(value.contentType),
    };
}
