export const num2name = {
    0: "LEGACY_TOEIC_LOGIN_PROVIDER_UNSPECIFIED",
    1: "FACEBOOK",
    2: "APPLE",
    3: "KAKAO",
    4: "EMAIL",
};
export const name2num = {
    LEGACY_TOEIC_LOGIN_PROVIDER_UNSPECIFIED: 0,
    FACEBOOK: 1,
    APPLE: 2,
    KAKAO: 3,
    EMAIL: 4,
};
