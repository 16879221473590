import { SelfLearningUnitGroupFromJSON, SelfLearningUnitGroupToJSON, } from './SelfLearningUnitGroup';
/**
 * Check if a given object implements the SelfLearningUnitGroupResponse interface.
 */
export function instanceOfSelfLearningUnitGroupResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "selfLearningUnitGroup" in value;
    return isInstance;
}
export function SelfLearningUnitGroupResponseFromJSON(json) {
    return SelfLearningUnitGroupResponseFromJSONTyped(json, false);
}
export function SelfLearningUnitGroupResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'selfLearningUnitGroup': SelfLearningUnitGroupFromJSON(json['selfLearningUnitGroup']),
    };
}
export function SelfLearningUnitGroupResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'selfLearningUnitGroup': SelfLearningUnitGroupToJSON(value.selfLearningUnitGroup),
    };
}
