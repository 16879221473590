// @ts-nocheck
import { name2num, num2name, } from "./PaymentType";
import { name2num as name2num_1, num2name as num2name_1, } from "./(PaymentEvent)/Status";
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "../../google/protobuf/Timestamp";
import { name2num as name2num_2, num2name as num2name_2, } from "./PaymentCurrency";
import { name2num as name2num_3, num2name as num2name_3, } from "./PaymentMethod";
import { name2num as name2num_4, num2name as num2name_4, } from "./IamportPg";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../runtime/json/scalar";
import { WireType, } from "../../../runtime/wire/index";
import { default as serialize, } from "../../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../../runtime/wire/scalar";
import { default as Long, } from "../../../runtime/Long";
import { default as deserialize, } from "../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        id: "0",
        domainId: "0",
        domainName: "",
        orderId: "0",
        paymentType: "UNKNOWN",
        status: "UNKNOWN",
        billId: "",
        transactionId: "",
        pgTransactionId: "",
        cardTransactionId: "",
        amount: "",
        receiptUrl: "",
        eventTime: undefined,
        paymentProviderId: "0",
        iamportPgId: "",
        currency: "UNSET",
        method: "UNSET_PAYMENT_METHOD",
        createdAt: undefined,
        refundId: "",
        refundUrl: "",
        iamportPg: "IAMPORT_PG_UNSPECIFIED",
        iamportIdentificationCode: "",
        cardName: "",
        cardMonths: 0,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.id !== undefined)
        result.id = tsValueToJsonValueFns.int64(value.id);
    if (value.domainId !== undefined)
        result.domainId = tsValueToJsonValueFns.int64(value.domainId);
    if (value.domainName !== undefined)
        result.domainName = tsValueToJsonValueFns.string(value.domainName);
    if (value.orderId !== undefined)
        result.orderId = tsValueToJsonValueFns.int64(value.orderId);
    if (value.paymentType !== undefined)
        result.paymentType = tsValueToJsonValueFns.enum(value.paymentType);
    if (value.status !== undefined)
        result.status = tsValueToJsonValueFns.enum(value.status);
    if (value.billId !== undefined)
        result.billId = tsValueToJsonValueFns.string(value.billId);
    if (value.transactionId !== undefined)
        result.transactionId = tsValueToJsonValueFns.string(value.transactionId);
    if (value.pgTransactionId !== undefined)
        result.pgTransactionId = tsValueToJsonValueFns.string(value.pgTransactionId);
    if (value.cardTransactionId !== undefined)
        result.cardTransactionId = tsValueToJsonValueFns.string(value.cardTransactionId);
    if (value.amount !== undefined)
        result.amount = tsValueToJsonValueFns.string(value.amount);
    if (value.receiptUrl !== undefined)
        result.receiptUrl = tsValueToJsonValueFns.string(value.receiptUrl);
    if (value.eventTime !== undefined)
        result.eventTime = encodeJson_1(value.eventTime);
    if (value.paymentProviderId !== undefined)
        result.paymentProviderId = tsValueToJsonValueFns.int64(value.paymentProviderId);
    if (value.iamportPgId !== undefined)
        result.iamportPgId = tsValueToJsonValueFns.string(value.iamportPgId);
    if (value.currency !== undefined)
        result.currency = tsValueToJsonValueFns.enum(value.currency);
    if (value.method !== undefined)
        result.method = tsValueToJsonValueFns.enum(value.method);
    if (value.createdAt !== undefined)
        result.createdAt = encodeJson_1(value.createdAt);
    if (value.refundId !== undefined)
        result.refundId = tsValueToJsonValueFns.string(value.refundId);
    if (value.refundUrl !== undefined)
        result.refundUrl = tsValueToJsonValueFns.string(value.refundUrl);
    if (value.iamportPg !== undefined)
        result.iamportPg = tsValueToJsonValueFns.enum(value.iamportPg);
    if (value.iamportIdentificationCode !== undefined)
        result.iamportIdentificationCode = tsValueToJsonValueFns.string(value.iamportIdentificationCode);
    if (value.cardName !== undefined)
        result.cardName = tsValueToJsonValueFns.string(value.cardName);
    if (value.cardMonths !== undefined)
        result.cardMonths = tsValueToJsonValueFns.int32(value.cardMonths);
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.id !== undefined)
        result.id = jsonValueToTsValueFns.int64(value.id);
    if (value.domainId !== undefined)
        result.domainId = jsonValueToTsValueFns.int64(value.domainId);
    if (value.domainName !== undefined)
        result.domainName = jsonValueToTsValueFns.string(value.domainName);
    if (value.orderId !== undefined)
        result.orderId = jsonValueToTsValueFns.int64(value.orderId);
    if (value.paymentType !== undefined)
        result.paymentType = jsonValueToTsValueFns.enum(value.paymentType);
    if (value.status !== undefined)
        result.status = jsonValueToTsValueFns.enum(value.status);
    if (value.billId !== undefined)
        result.billId = jsonValueToTsValueFns.string(value.billId);
    if (value.transactionId !== undefined)
        result.transactionId = jsonValueToTsValueFns.string(value.transactionId);
    if (value.pgTransactionId !== undefined)
        result.pgTransactionId = jsonValueToTsValueFns.string(value.pgTransactionId);
    if (value.cardTransactionId !== undefined)
        result.cardTransactionId = jsonValueToTsValueFns.string(value.cardTransactionId);
    if (value.amount !== undefined)
        result.amount = jsonValueToTsValueFns.string(value.amount);
    if (value.receiptUrl !== undefined)
        result.receiptUrl = jsonValueToTsValueFns.string(value.receiptUrl);
    if (value.eventTime !== undefined)
        result.eventTime = decodeJson_1(value.eventTime);
    if (value.paymentProviderId !== undefined)
        result.paymentProviderId = jsonValueToTsValueFns.int64(value.paymentProviderId);
    if (value.iamportPgId !== undefined)
        result.iamportPgId = jsonValueToTsValueFns.string(value.iamportPgId);
    if (value.currency !== undefined)
        result.currency = jsonValueToTsValueFns.enum(value.currency);
    if (value.method !== undefined)
        result.method = jsonValueToTsValueFns.enum(value.method);
    if (value.createdAt !== undefined)
        result.createdAt = decodeJson_1(value.createdAt);
    if (value.refundId !== undefined)
        result.refundId = jsonValueToTsValueFns.string(value.refundId);
    if (value.refundUrl !== undefined)
        result.refundUrl = jsonValueToTsValueFns.string(value.refundUrl);
    if (value.iamportPg !== undefined)
        result.iamportPg = jsonValueToTsValueFns.enum(value.iamportPg);
    if (value.iamportIdentificationCode !== undefined)
        result.iamportIdentificationCode = jsonValueToTsValueFns.string(value.iamportIdentificationCode);
    if (value.cardName !== undefined)
        result.cardName = jsonValueToTsValueFns.string(value.cardName);
    if (value.cardMonths !== undefined)
        result.cardMonths = jsonValueToTsValueFns.int32(value.cardMonths);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.id !== undefined) {
        const tsValue = value.id;
        result.push([1, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.domainId !== undefined) {
        const tsValue = value.domainId;
        result.push([2, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.domainName !== undefined) {
        const tsValue = value.domainName;
        result.push([3, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.orderId !== undefined) {
        const tsValue = value.orderId;
        result.push([4, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.paymentType !== undefined) {
        const tsValue = value.paymentType;
        result.push([5, { type: WireType.Varint, value: new Long(name2num[tsValue]) }]);
    }
    if (value.status !== undefined) {
        const tsValue = value.status;
        result.push([6, { type: WireType.Varint, value: new Long(name2num_1[tsValue]) }]);
    }
    if (value.billId !== undefined) {
        const tsValue = value.billId;
        result.push([7, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.transactionId !== undefined) {
        const tsValue = value.transactionId;
        result.push([8, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.pgTransactionId !== undefined) {
        const tsValue = value.pgTransactionId;
        result.push([9, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.cardTransactionId !== undefined) {
        const tsValue = value.cardTransactionId;
        result.push([10, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.amount !== undefined) {
        const tsValue = value.amount;
        result.push([11, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.receiptUrl !== undefined) {
        const tsValue = value.receiptUrl;
        result.push([12, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.eventTime !== undefined) {
        const tsValue = value.eventTime;
        result.push([13, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    if (value.paymentProviderId !== undefined) {
        const tsValue = value.paymentProviderId;
        result.push([14, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.iamportPgId !== undefined) {
        const tsValue = value.iamportPgId;
        result.push([16, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.currency !== undefined) {
        const tsValue = value.currency;
        result.push([17, { type: WireType.Varint, value: new Long(name2num_2[tsValue]) }]);
    }
    if (value.method !== undefined) {
        const tsValue = value.method;
        result.push([18, { type: WireType.Varint, value: new Long(name2num_3[tsValue]) }]);
    }
    if (value.createdAt !== undefined) {
        const tsValue = value.createdAt;
        result.push([19, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    if (value.refundId !== undefined) {
        const tsValue = value.refundId;
        result.push([20, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.refundUrl !== undefined) {
        const tsValue = value.refundUrl;
        result.push([21, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.iamportPg !== undefined) {
        const tsValue = value.iamportPg;
        result.push([22, { type: WireType.Varint, value: new Long(name2num_4[tsValue]) }]);
    }
    if (value.iamportIdentificationCode !== undefined) {
        const tsValue = value.iamportIdentificationCode;
        result.push([23, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.cardName !== undefined) {
        const tsValue = value.cardName;
        result.push([24, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.cardMonths !== undefined) {
        const tsValue = value.cardMonths;
        result.push([25, tsValueToWireValueFns.int32(tsValue)]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.id = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.domainId = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.domainName = value;
    }
    field: {
        const wireValue = wireFields.get(4);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.orderId = value;
    }
    field: {
        const wireValue = wireFields.get(5);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.paymentType = value;
    }
    field: {
        const wireValue = wireFields.get(6);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name_1[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.status = value;
    }
    field: {
        const wireValue = wireFields.get(7);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.billId = value;
    }
    field: {
        const wireValue = wireFields.get(8);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.transactionId = value;
    }
    field: {
        const wireValue = wireFields.get(9);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.pgTransactionId = value;
    }
    field: {
        const wireValue = wireFields.get(10);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.cardTransactionId = value;
    }
    field: {
        const wireValue = wireFields.get(11);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.amount = value;
    }
    field: {
        const wireValue = wireFields.get(12);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.receiptUrl = value;
    }
    field: {
        const wireValue = wireFields.get(13);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.eventTime = value;
    }
    field: {
        const wireValue = wireFields.get(14);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.paymentProviderId = value;
    }
    field: {
        const wireValue = wireFields.get(16);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.iamportPgId = value;
    }
    field: {
        const wireValue = wireFields.get(17);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name_2[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.currency = value;
    }
    field: {
        const wireValue = wireFields.get(18);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name_3[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.method = value;
    }
    field: {
        const wireValue = wireFields.get(19);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.createdAt = value;
    }
    field: {
        const wireValue = wireFields.get(20);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.refundId = value;
    }
    field: {
        const wireValue = wireFields.get(21);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.refundUrl = value;
    }
    field: {
        const wireValue = wireFields.get(22);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name_4[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.iamportPg = value;
    }
    field: {
        const wireValue = wireFields.get(23);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.iamportIdentificationCode = value;
    }
    field: {
        const wireValue = wireFields.get(24);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.cardName = value;
    }
    field: {
        const wireValue = wireFields.get(25);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int32(wireValue);
        if (value === undefined)
            break field;
        result.cardMonths = value;
    }
    return result;
}
