export var DEFAULT_DATE_FORMAT = "yyyy-MM-dd HH:mm:ss";
export var SIZE_MAX = 0x7fffffff;
export var TIMESTAMP_ZERO = {
    seconds: "0",
    nanos: 0
};
// 5000-01-01
export var TIMESTAMP_MAX = {
    seconds: "95617584000",
    nanos: 0
};
