// @ts-nocheck
import { name2num, num2name, } from "./(User)/Status";
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "../../google/protobuf/Timestamp";
import { encodeJson as encodeJson_2, decodeJson as decodeJson_2, encodeBinary as encodeBinary_2, decodeBinary as decodeBinary_2, } from "./(User)/Profile";
import { encodeJson as encodeJson_3, decodeJson as decodeJson_3, encodeBinary as encodeBinary_3, decodeBinary as decodeBinary_3, } from "./(User)/Agreement";
import { encodeJson as encodeJson_4, decodeJson as decodeJson_4, encodeBinary as encodeBinary_4, decodeBinary as decodeBinary_4, } from "./(User)/StatusDetail";
import { encodeJson as encodeJson_5, decodeJson as decodeJson_5, encodeBinary as encodeBinary_5, decodeBinary as decodeBinary_5, } from "./(User)/Provider";
import { name2num as name2num_1, num2name as num2name_1, } from "../../inside/Country";
import { encodeJson as encodeJson_6, decodeJson as decodeJson_6, encodeBinary as encodeBinary_6, decodeBinary as decodeBinary_6, } from "./(User)/OnboardingResult";
import { encodeJson as encodeJson_7, decodeJson as decodeJson_7, encodeBinary as encodeBinary_7, decodeBinary as decodeBinary_7, } from "./(User)/UpdateHistory";
import { name2num as name2num_2, num2name as num2name_2, } from "../(User)/(EstimatedInfo)/UserSeg";
import { name2num as name2num_3, num2name as num2name_3, } from "./(User)/AuthorityStatus";
import { encodeJson as encodeJson_8, decodeJson as decodeJson_8, encodeBinary as encodeBinary_8, decodeBinary as decodeBinary_8, } from "./LegacyUser";
import { name2num as name2num_4, num2name as num2name_4, } from "./(User)/PaymentExperience";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../runtime/json/scalar";
import { WireType, } from "../../../runtime/wire/index";
import { default as serialize, } from "../../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../../runtime/wire/scalar";
import { default as Long, } from "../../../runtime/Long";
import { default as deserialize, } from "../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        id: "0",
        authId: "",
        status: "STATUS_UNSPECIFIED",
        createdAt: undefined,
        lastActivityAt: undefined,
        profile: undefined,
        agreement: undefined,
        statusDetail: undefined,
        provider: undefined,
        firstCountry: "UNSPECIFIED_COUNTRY",
        onboardingResult: undefined,
        updateHistory: [],
        userSeg: "USER_SAG_UNSPECIFIED",
        country: "UNSPECIFIED_COUNTRY",
        authorityStatus: "AUTHORITY_STATUS_UNSPECIFIED",
        legacyUser: undefined,
        paymentExperience: "PAYMENT_EXPERIENCE_UNSPECIFIED",
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.id !== undefined)
        result.id = tsValueToJsonValueFns.int64(value.id);
    if (value.authId !== undefined)
        result.authId = tsValueToJsonValueFns.string(value.authId);
    if (value.status !== undefined)
        result.status = tsValueToJsonValueFns.enum(value.status);
    if (value.createdAt !== undefined)
        result.createdAt = encodeJson_1(value.createdAt);
    if (value.lastActivityAt !== undefined)
        result.lastActivityAt = encodeJson_1(value.lastActivityAt);
    if (value.profile !== undefined)
        result.profile = encodeJson_2(value.profile);
    if (value.agreement !== undefined)
        result.agreement = encodeJson_3(value.agreement);
    if (value.statusDetail !== undefined)
        result.statusDetail = encodeJson_4(value.statusDetail);
    if (value.provider !== undefined)
        result.provider = encodeJson_5(value.provider);
    if (value.firstCountry !== undefined)
        result.firstCountry = tsValueToJsonValueFns.enum(value.firstCountry);
    if (value.onboardingResult !== undefined)
        result.onboardingResult = encodeJson_6(value.onboardingResult);
    result.updateHistory = value.updateHistory.map(value => encodeJson_7(value));
    if (value.userSeg !== undefined)
        result.userSeg = tsValueToJsonValueFns.enum(value.userSeg);
    if (value.country !== undefined)
        result.country = tsValueToJsonValueFns.enum(value.country);
    if (value.authorityStatus !== undefined)
        result.authorityStatus = tsValueToJsonValueFns.enum(value.authorityStatus);
    if (value.legacyUser !== undefined)
        result.legacyUser = encodeJson_8(value.legacyUser);
    if (value.paymentExperience !== undefined)
        result.paymentExperience = tsValueToJsonValueFns.enum(value.paymentExperience);
    return result;
}
export function decodeJson(value) {
    var _a, _b;
    const result = getDefaultValue();
    if (value.id !== undefined)
        result.id = jsonValueToTsValueFns.int64(value.id);
    if (value.authId !== undefined)
        result.authId = jsonValueToTsValueFns.string(value.authId);
    if (value.status !== undefined)
        result.status = jsonValueToTsValueFns.enum(value.status);
    if (value.createdAt !== undefined)
        result.createdAt = decodeJson_1(value.createdAt);
    if (value.lastActivityAt !== undefined)
        result.lastActivityAt = decodeJson_1(value.lastActivityAt);
    if (value.profile !== undefined)
        result.profile = decodeJson_2(value.profile);
    if (value.agreement !== undefined)
        result.agreement = decodeJson_3(value.agreement);
    if (value.statusDetail !== undefined)
        result.statusDetail = decodeJson_4(value.statusDetail);
    if (value.provider !== undefined)
        result.provider = decodeJson_5(value.provider);
    if (value.firstCountry !== undefined)
        result.firstCountry = jsonValueToTsValueFns.enum(value.firstCountry);
    if (value.onboardingResult !== undefined)
        result.onboardingResult = decodeJson_6(value.onboardingResult);
    result.updateHistory = (_b = (_a = value.updateHistory) === null || _a === void 0 ? void 0 : _a.map((value) => decodeJson_7(value))) !== null && _b !== void 0 ? _b : [];
    if (value.userSeg !== undefined)
        result.userSeg = jsonValueToTsValueFns.enum(value.userSeg);
    if (value.country !== undefined)
        result.country = jsonValueToTsValueFns.enum(value.country);
    if (value.authorityStatus !== undefined)
        result.authorityStatus = jsonValueToTsValueFns.enum(value.authorityStatus);
    if (value.legacyUser !== undefined)
        result.legacyUser = decodeJson_8(value.legacyUser);
    if (value.paymentExperience !== undefined)
        result.paymentExperience = jsonValueToTsValueFns.enum(value.paymentExperience);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.id !== undefined) {
        const tsValue = value.id;
        result.push([1, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.authId !== undefined) {
        const tsValue = value.authId;
        result.push([2, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.status !== undefined) {
        const tsValue = value.status;
        result.push([3, { type: WireType.Varint, value: new Long(name2num[tsValue]) }]);
    }
    if (value.createdAt !== undefined) {
        const tsValue = value.createdAt;
        result.push([4, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    if (value.lastActivityAt !== undefined) {
        const tsValue = value.lastActivityAt;
        result.push([5, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    if (value.profile !== undefined) {
        const tsValue = value.profile;
        result.push([6, { type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }]);
    }
    if (value.agreement !== undefined) {
        const tsValue = value.agreement;
        result.push([7, { type: WireType.LengthDelimited, value: encodeBinary_3(tsValue) }]);
    }
    if (value.statusDetail !== undefined) {
        const tsValue = value.statusDetail;
        result.push([8, { type: WireType.LengthDelimited, value: encodeBinary_4(tsValue) }]);
    }
    if (value.provider !== undefined) {
        const tsValue = value.provider;
        result.push([9, { type: WireType.LengthDelimited, value: encodeBinary_5(tsValue) }]);
    }
    if (value.firstCountry !== undefined) {
        const tsValue = value.firstCountry;
        result.push([10, { type: WireType.Varint, value: new Long(name2num_1[tsValue]) }]);
    }
    if (value.onboardingResult !== undefined) {
        const tsValue = value.onboardingResult;
        result.push([11, { type: WireType.LengthDelimited, value: encodeBinary_6(tsValue) }]);
    }
    for (const tsValue of value.updateHistory) {
        result.push([12, { type: WireType.LengthDelimited, value: encodeBinary_7(tsValue) }]);
    }
    if (value.userSeg !== undefined) {
        const tsValue = value.userSeg;
        result.push([13, { type: WireType.Varint, value: new Long(name2num_2[tsValue]) }]);
    }
    if (value.country !== undefined) {
        const tsValue = value.country;
        result.push([14, { type: WireType.Varint, value: new Long(name2num_1[tsValue]) }]);
    }
    if (value.authorityStatus !== undefined) {
        const tsValue = value.authorityStatus;
        result.push([16, { type: WireType.Varint, value: new Long(name2num_3[tsValue]) }]);
    }
    if (value.legacyUser !== undefined) {
        const tsValue = value.legacyUser;
        result.push([17, { type: WireType.LengthDelimited, value: encodeBinary_8(tsValue) }]);
    }
    if (value.paymentExperience !== undefined) {
        const tsValue = value.paymentExperience;
        result.push([18, { type: WireType.Varint, value: new Long(name2num_4[tsValue]) }]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.id = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.authId = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.status = value;
    }
    field: {
        const wireValue = wireFields.get(4);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.createdAt = value;
    }
    field: {
        const wireValue = wireFields.get(5);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.lastActivityAt = value;
    }
    field: {
        const wireValue = wireFields.get(6);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.profile = value;
    }
    field: {
        const wireValue = wireFields.get(7);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_3(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.agreement = value;
    }
    field: {
        const wireValue = wireFields.get(8);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_4(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.statusDetail = value;
    }
    field: {
        const wireValue = wireFields.get(9);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_5(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.provider = value;
    }
    field: {
        const wireValue = wireFields.get(10);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name_1[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.firstCountry = value;
    }
    field: {
        const wireValue = wireFields.get(11);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_6(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.onboardingResult = value;
    }
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 12).map(([, wireValue]) => wireValue);
        const value = wireValues.map((wireValue) => wireValue.type === WireType.LengthDelimited ? decodeBinary_7(wireValue.value) : undefined).filter(x => x !== undefined);
        if (!value.length)
            break collection;
        result.updateHistory = value;
    }
    field: {
        const wireValue = wireFields.get(13);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name_2[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.userSeg = value;
    }
    field: {
        const wireValue = wireFields.get(14);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name_1[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.country = value;
    }
    field: {
        const wireValue = wireFields.get(16);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name_3[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.authorityStatus = value;
    }
    field: {
        const wireValue = wireFields.get(17);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_8(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.legacyUser = value;
    }
    field: {
        const wireValue = wireFields.get(18);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name_4[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.paymentExperience = value;
    }
    return result;
}
