// @ts-nocheck
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "../../google/protobuf/Timestamp";
import { encodeJson as encodeJson_2, decodeJson as decodeJson_2, encodeBinary as encodeBinary_3, decodeBinary as decodeBinary_3, } from "./EstimatedInfo";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../runtime/json/scalar";
import { WireType, } from "../../../runtime/wire/index";
import { default as serialize, } from "../../../runtime/wire/serialize";
import { encodeBinary as encodeBinary_2, decodeBinary as decodeBinary_2, } from "../../google/protobuf/Int32Value";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../../runtime/wire/scalar";
import { default as deserialize, } from "../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        updatedAt: undefined,
        targetScore: undefined,
        estimatedInfo: undefined,
        currentCycleOrdinal: 0,
        totalNumberOfCycles: 0,
        estimatedScore: 0,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.updatedAt !== undefined)
        result.updatedAt = encodeJson_1(value.updatedAt);
    if (value.targetScore !== undefined)
        result.targetScore = value.targetScore;
    if (value.estimatedInfo !== undefined)
        result.estimatedInfo = encodeJson_2(value.estimatedInfo);
    if (value.currentCycleOrdinal !== undefined)
        result.currentCycleOrdinal = tsValueToJsonValueFns.int32(value.currentCycleOrdinal);
    if (value.totalNumberOfCycles !== undefined)
        result.totalNumberOfCycles = tsValueToJsonValueFns.int32(value.totalNumberOfCycles);
    if (value.estimatedScore !== undefined)
        result.estimatedScore = tsValueToJsonValueFns.int32(value.estimatedScore);
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.updatedAt !== undefined)
        result.updatedAt = decodeJson_1(value.updatedAt);
    if (value.targetScore !== undefined)
        result.targetScore = value.targetScore;
    if (value.estimatedInfo !== undefined)
        result.estimatedInfo = decodeJson_2(value.estimatedInfo);
    if (value.currentCycleOrdinal !== undefined)
        result.currentCycleOrdinal = jsonValueToTsValueFns.int32(value.currentCycleOrdinal);
    if (value.totalNumberOfCycles !== undefined)
        result.totalNumberOfCycles = jsonValueToTsValueFns.int32(value.totalNumberOfCycles);
    if (value.estimatedScore !== undefined)
        result.estimatedScore = jsonValueToTsValueFns.int32(value.estimatedScore);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.updatedAt !== undefined) {
        const tsValue = value.updatedAt;
        result.push([1, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    if (value.targetScore !== undefined) {
        const tsValue = value.targetScore;
        result.push([2, ((tsValue) => ({ type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }))({ value: tsValue })]);
    }
    if (value.estimatedInfo !== undefined) {
        const tsValue = value.estimatedInfo;
        result.push([3, { type: WireType.LengthDelimited, value: encodeBinary_3(tsValue) }]);
    }
    if (value.currentCycleOrdinal !== undefined) {
        const tsValue = value.currentCycleOrdinal;
        result.push([4, tsValueToWireValueFns.int32(tsValue)]);
    }
    if (value.totalNumberOfCycles !== undefined) {
        const tsValue = value.totalNumberOfCycles;
        result.push([5, tsValueToWireValueFns.int32(tsValue)]);
    }
    if (value.estimatedScore !== undefined) {
        const tsValue = value.estimatedScore;
        result.push([6, tsValueToWireValueFns.int32(tsValue)]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    var _a;
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.updatedAt = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = (_a = (wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined)) === null || _a === void 0 ? void 0 : _a.value;
        if (value === undefined)
            break field;
        result.targetScore = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_3(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.estimatedInfo = value;
    }
    field: {
        const wireValue = wireFields.get(4);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int32(wireValue);
        if (value === undefined)
            break field;
        result.currentCycleOrdinal = value;
    }
    field: {
        const wireValue = wireFields.get(5);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int32(wireValue);
        if (value === undefined)
            break field;
        result.totalNumberOfCycles = value;
    }
    field: {
        const wireValue = wireFields.get(6);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int32(wireValue);
        if (value === undefined)
            break field;
        result.estimatedScore = value;
    }
    return result;
}
