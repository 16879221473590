/**
 * Check if a given object implements the Offer interface.
 */
export function instanceOfOffer(value) {
    let isInstance = true;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "updatedAt" in value;
    return isInstance;
}
export function OfferFromJSON(json) {
    return OfferFromJSONTyped(json, false);
}
export function OfferFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'createdAt': (new Date(json['createdAt'])),
        'id': json['id'],
        'updatedAt': (new Date(json['updatedAt'])),
    };
}
export function OfferToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'createdAt': (value.createdAt.toISOString()),
        'id': value.id,
        'updatedAt': (value.updatedAt.toISOString()),
    };
}
