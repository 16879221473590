/* tslint:disable */
/* eslint-disable */
/**
 * - **ACTIVE**: 유료 사용중
 * - **INACTIVE_WAITING_FOR_FULFILLMENT**: 유료 사용 대기중
 * - **INACTIVE_NO_FULFILLMENT**: 권한 없음
 * @export
 */
export const AuthorityStatus = {
    ACTIVE: 'ACTIVE',
    INACTIVE_WAITING_FOR_FULFILLMENT: 'INACTIVE_WAITING_FOR_FULFILLMENT',
    INACTIVE_NO_FULFILLMENT: 'INACTIVE_NO_FULFILLMENT'
};
export function AuthorityStatusFromJSON(json) {
    return AuthorityStatusFromJSONTyped(json, false);
}
export function AuthorityStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function AuthorityStatusToJSON(value) {
    return value;
}
