import { AuthorityStatusFromJSON, AuthorityStatusToJSON, } from './AuthorityStatus';
/**
 * Check if a given object implements the AuthorityStatusByDomainItem interface.
 */
export function instanceOfAuthorityStatusByDomainItem(value) {
    let isInstance = true;
    isInstance = isInstance && "learningDomainId" in value;
    isInstance = isInstance && "status" in value;
    return isInstance;
}
export function AuthorityStatusByDomainItemFromJSON(json) {
    return AuthorityStatusByDomainItemFromJSONTyped(json, false);
}
export function AuthorityStatusByDomainItemFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'learningDomainId': json['learningDomainId'],
        'status': AuthorityStatusFromJSON(json['status']),
    };
}
export function AuthorityStatusByDomainItemToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'learningDomainId': value.learningDomainId,
        'status': AuthorityStatusToJSON(value.status),
    };
}
