/**
 * Check if a given object implements the IssuePassFromTemplateResponse interface.
 */
export function instanceOfIssuePassFromTemplateResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "passId" in value;
    return isInstance;
}
export function IssuePassFromTemplateResponseFromJSON(json) {
    return IssuePassFromTemplateResponseFromJSONTyped(json, false);
}
export function IssuePassFromTemplateResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'passId': json['passId'],
    };
}
export function IssuePassFromTemplateResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'passId': value.passId,
    };
}
