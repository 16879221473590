/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { CountingConfigFromJSON, CountingConfigToJSON, } from './CountingConfig';
import { PermitManagementInfoFromJSON, PermitManagementInfoToJSON, } from './PermitManagementInfo';
import { PermitTypeListItemFromJSON, PermitTypeListItemToJSON, } from './PermitTypeListItem';
/**
 * Check if a given object implements the PermitListItem interface.
 */
export function instanceOfPermitListItem(value) {
    let isInstance = true;
    isInstance = isInstance && "permitType" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "managementInfo" in value;
    isInstance = isInstance && "countingConfig" in value;
    isInstance = isInstance && "resourceIds" in value;
    return isInstance;
}
export function PermitListItemFromJSON(json) {
    return PermitListItemFromJSONTyped(json, false);
}
export function PermitListItemFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'permitType': PermitTypeListItemFromJSON(json['permitType']),
        'publishedAt': !exists(json, 'publishedAt') ? undefined : (new Date(json['publishedAt'])),
        'id': json['id'],
        'managementInfo': PermitManagementInfoFromJSON(json['managementInfo']),
        'countingConfig': CountingConfigFromJSON(json['countingConfig']),
        'resourceIds': json['resourceIds'],
    };
}
export function PermitListItemToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'permitType': PermitTypeListItemToJSON(value.permitType),
        'publishedAt': value.publishedAt === undefined ? undefined : (value.publishedAt.toISOString()),
        'id': value.id,
        'managementInfo': PermitManagementInfoToJSON(value.managementInfo),
        'countingConfig': CountingConfigToJSON(value.countingConfig),
        'resourceIds': value.resourceIds,
    };
}
