/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { CountingConfigFromJSON, CountingConfigToJSON, } from './CountingConfig';
import { PermitManagementInfoFromJSON, PermitManagementInfoToJSON, } from './PermitManagementInfo';
/**
 * Check if a given object implements the Permit interface.
 */
export function instanceOfPermit(value) {
    let isInstance = true;
    isInstance = isInstance && "country" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "managementInfo" in value;
    isInstance = isInstance && "permitTypeId" in value;
    isInstance = isInstance && "countingConfig" in value;
    isInstance = isInstance && "learningDomainId" in value;
    isInstance = isInstance && "resourceIds" in value;
    isInstance = isInstance && "updatedAt" in value;
    return isInstance;
}
export function PermitFromJSON(json) {
    return PermitFromJSONTyped(json, false);
}
export function PermitFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'country': json['country'],
        'createdAt': (new Date(json['createdAt'])),
        'publishedAt': !exists(json, 'publishedAt') ? undefined : (new Date(json['publishedAt'])),
        'id': json['id'],
        'managementInfo': PermitManagementInfoFromJSON(json['managementInfo']),
        'permitTypeId': json['permitTypeId'],
        'countingConfig': CountingConfigFromJSON(json['countingConfig']),
        'learningDomainId': json['learningDomainId'],
        'resourceIds': json['resourceIds'],
        'updatedAt': (new Date(json['updatedAt'])),
    };
}
export function PermitToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'country': value.country,
        'createdAt': (value.createdAt.toISOString()),
        'publishedAt': value.publishedAt === undefined ? undefined : (value.publishedAt.toISOString()),
        'id': value.id,
        'managementInfo': PermitManagementInfoToJSON(value.managementInfo),
        'permitTypeId': value.permitTypeId,
        'countingConfig': CountingConfigToJSON(value.countingConfig),
        'learningDomainId': value.learningDomainId,
        'resourceIds': value.resourceIds,
        'updatedAt': (value.updatedAt.toISOString()),
    };
}
