/**
 * Check if a given object implements the CreateOfferGroupDetailImage interface.
 */
export function instanceOfCreateOfferGroupDetailImage(value) {
    let isInstance = true;
    isInstance = isInstance && "imageAssetId" in value;
    return isInstance;
}
export function CreateOfferGroupDetailImageFromJSON(json) {
    return CreateOfferGroupDetailImageFromJSONTyped(json, false);
}
export function CreateOfferGroupDetailImageFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'imageAssetId': json['imageAssetId'],
    };
}
export function CreateOfferGroupDetailImageToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'imageAssetId': value.imageAssetId,
    };
}
