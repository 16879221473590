export const num2name = {
    0: "ERROR_UNSPECIFIED",
    1: "ERROR_INVALID_SUBSCRIPTION_PRODUCT_TEMPLATE",
    2: "ERROR_SUBSCRIPTION_PRODUCT_ALREADY_PUBLISHED",
    3: "ERROR_SUBSCRIPTION_PRODUCT_NOT_FOUND",
};
export const name2num = {
    ERROR_UNSPECIFIED: 0,
    ERROR_INVALID_SUBSCRIPTION_PRODUCT_TEMPLATE: 1,
    ERROR_SUBSCRIPTION_PRODUCT_ALREADY_PUBLISHED: 2,
    ERROR_SUBSCRIPTION_PRODUCT_NOT_FOUND: 3,
};
