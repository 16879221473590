/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { UserRegistrationTypeFromJSON, UserRegistrationTypeToJSON, } from './UserRegistrationType';
import { UserStatusFromJSON, UserStatusToJSON, } from './UserStatus';
/**
 * Check if a given object implements the UserListItem interface.
 */
export function instanceOfUserListItem(value) {
    let isInstance = true;
    isInstance = isInstance && "pushAgreed" in value;
    isInstance = isInstance && "registrationType" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "locale" in value;
    isInstance = isInstance && "marketingAgreed" in value;
    isInstance = isInstance && "status" in value;
    return isInstance;
}
export function UserListItemFromJSON(json) {
    return UserListItemFromJSONTyped(json, false);
}
export function UserListItemFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'pushAgreed': json['pushAgreed'],
        'lastSelectedLearningDomainId': !exists(json, 'lastSelectedLearningDomainId') ? undefined : json['lastSelectedLearningDomainId'],
        'registrationType': UserRegistrationTypeFromJSON(json['registrationType']),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'id': json['id'],
        'locale': json['locale'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'marketingAgreed': json['marketingAgreed'],
        'status': UserStatusFromJSON(json['status']),
    };
}
export function UserListItemToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'pushAgreed': value.pushAgreed,
        'lastSelectedLearningDomainId': value.lastSelectedLearningDomainId,
        'registrationType': UserRegistrationTypeToJSON(value.registrationType),
        'name': value.name,
        'id': value.id,
        'locale': value.locale,
        'email': value.email,
        'marketingAgreed': value.marketingAgreed,
        'status': UserStatusToJSON(value.status),
    };
}
