export const num2name = {
    0: "ERROR_UNSPECIFIED",
    1: "ERROR_INVALID_DISPLAY_CONFIG",
    2: "ERROR_PRODUCT_NOT_FOUND",
};
export const name2num = {
    ERROR_UNSPECIFIED: 0,
    ERROR_INVALID_DISPLAY_CONFIG: 1,
    ERROR_PRODUCT_NOT_FOUND: 2,
};
