// @ts-nocheck
import { name2num, num2name, } from "./(B2bDashboardUser)/Status";
import { name2num as name2num_1, num2name as num2name_1, } from "./(B2bDashboardUser)/Role";
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "./(B2bDashboardUser)/Info";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../runtime/json/scalar";
import { WireType, } from "../../../runtime/wire/index";
import { default as serialize, } from "../../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, unpackFns, } from "../../../runtime/wire/scalar";
import { default as Long, } from "../../../runtime/Long";
import { default as deserialize, } from "../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        id: "0",
        organizationId: "0",
        authId: "",
        status: "TUTOR_STATUS_UNSPECIFIED",
        classIds: [],
        roles: [],
        info: undefined,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.id !== undefined)
        result.id = tsValueToJsonValueFns.int64(value.id);
    if (value.organizationId !== undefined)
        result.organizationId = tsValueToJsonValueFns.int64(value.organizationId);
    if (value.authId !== undefined)
        result.authId = tsValueToJsonValueFns.string(value.authId);
    if (value.status !== undefined)
        result.status = tsValueToJsonValueFns.enum(value.status);
    result.classIds = value.classIds.map(value => tsValueToJsonValueFns.int64(value));
    result.roles = value.roles.map(value => tsValueToJsonValueFns.enum(value));
    if (value.info !== undefined)
        result.info = encodeJson_1(value.info);
    return result;
}
export function decodeJson(value) {
    var _a, _b, _c, _d;
    const result = getDefaultValue();
    if (value.id !== undefined)
        result.id = jsonValueToTsValueFns.int64(value.id);
    if (value.organizationId !== undefined)
        result.organizationId = jsonValueToTsValueFns.int64(value.organizationId);
    if (value.authId !== undefined)
        result.authId = jsonValueToTsValueFns.string(value.authId);
    if (value.status !== undefined)
        result.status = jsonValueToTsValueFns.enum(value.status);
    result.classIds = (_b = (_a = value.classIds) === null || _a === void 0 ? void 0 : _a.map((value) => jsonValueToTsValueFns.int64(value))) !== null && _b !== void 0 ? _b : [];
    result.roles = (_d = (_c = value.roles) === null || _c === void 0 ? void 0 : _c.map((value) => jsonValueToTsValueFns.enum(value))) !== null && _d !== void 0 ? _d : [];
    if (value.info !== undefined)
        result.info = decodeJson_1(value.info);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.id !== undefined) {
        const tsValue = value.id;
        result.push([1, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.organizationId !== undefined) {
        const tsValue = value.organizationId;
        result.push([2, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.authId !== undefined) {
        const tsValue = value.authId;
        result.push([3, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.status !== undefined) {
        const tsValue = value.status;
        result.push([4, { type: WireType.Varint, value: new Long(name2num[tsValue]) }]);
    }
    for (const tsValue of value.classIds) {
        result.push([5, tsValueToWireValueFns.int64(tsValue)]);
    }
    for (const tsValue of value.roles) {
        result.push([6, { type: WireType.Varint, value: new Long(name2num_1[tsValue]) }]);
    }
    if (value.info !== undefined) {
        const tsValue = value.info;
        result.push([7, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.id = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.organizationId = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.authId = value;
    }
    field: {
        const wireValue = wireFields.get(4);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.status = value;
    }
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 5).map(([, wireValue]) => wireValue);
        const value = Array.from(unpackFns.int64(wireValues));
        if (!value.length)
            break collection;
        result.classIds = value;
    }
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 6).map(([, wireValue]) => wireValue);
        const value = Array.from(unpackFns.int32(wireValues)).map(num => num2name_1[num]);
        if (!value.length)
            break collection;
        result.roles = value;
    }
    field: {
        const wireValue = wireFields.get(7);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.info = value;
    }
    return result;
}
