/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { OnboardingPassTemplateFromJSON, OnboardingPassTemplateToJSON, } from './OnboardingPassTemplate';
/**
 * Check if a given object implements the GetOnboardingPassTemplateResponse interface.
 */
export function instanceOfGetOnboardingPassTemplateResponse(value) {
    let isInstance = true;
    return isInstance;
}
export function GetOnboardingPassTemplateResponseFromJSON(json) {
    return GetOnboardingPassTemplateResponseFromJSONTyped(json, false);
}
export function GetOnboardingPassTemplateResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'onboardingPassTemplate': !exists(json, 'onboardingPassTemplate') ? undefined : OnboardingPassTemplateFromJSON(json['onboardingPassTemplate']),
    };
}
export function GetOnboardingPassTemplateResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'onboardingPassTemplate': OnboardingPassTemplateToJSON(value.onboardingPassTemplate),
    };
}
