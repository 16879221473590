import { SelfLearningContentTypeFromJSON, SelfLearningContentTypeToJSON, } from './SelfLearningContentType';
/**
 * Check if a given object implements the SelfLearningContentTypeResponse interface.
 */
export function instanceOfSelfLearningContentTypeResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "value" in value;
    return isInstance;
}
export function SelfLearningContentTypeResponseFromJSON(json) {
    return SelfLearningContentTypeResponseFromJSONTyped(json, false);
}
export function SelfLearningContentTypeResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'value': SelfLearningContentTypeFromJSON(json['value']),
    };
}
export function SelfLearningContentTypeResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'value': SelfLearningContentTypeToJSON(value.value),
    };
}
