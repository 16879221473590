/* tslint:disable */
/* eslint-disable */
import * as runtime from '../runtime';
import { CreateOfferGroupRequestToJSON, CreateOfferGroupResponseFromJSON, GetOfferGroupResponseFromJSON, ListOfferGroupsResponseFromJSON, UpdateOfferGroupRequestToJSON, UpdateOfferGroupResponseFromJSON, } from '../models';
/**
 *
 */
export class CommerceAdminV2OfferGroupApi extends runtime.BaseAPI {
    /**
     * Offer Group 을 생성한다.
     */
    async createOfferGroupRaw(requestParameters, initOverrides) {
        if (requestParameters.createOfferGroupRequest === null || requestParameters.createOfferGroupRequest === undefined) {
            throw new runtime.RequiredError('createOfferGroupRequest', 'Required parameter requestParameters.createOfferGroupRequest was null or undefined when calling createOfferGroup.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/admin/commerce/v2/offer-group/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateOfferGroupRequestToJSON(requestParameters.createOfferGroupRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => CreateOfferGroupResponseFromJSON(jsonValue));
    }
    /**
     * Offer Group 을 생성한다.
     */
    async createOfferGroup(requestParameters, initOverrides) {
        const response = await this.createOfferGroupRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Offer Group을 조회한다.
     */
    async getOfferGroupRaw(requestParameters, initOverrides) {
        if (requestParameters.offerGroupId === null || requestParameters.offerGroupId === undefined) {
            throw new runtime.RequiredError('offerGroupId', 'Required parameter requestParameters.offerGroupId was null or undefined when calling getOfferGroup.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/admin/commerce/v2/offer-group/{offerGroupId}`.replace(`{${"offerGroupId"}}`, encodeURIComponent(String(requestParameters.offerGroupId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GetOfferGroupResponseFromJSON(jsonValue));
    }
    /**
     * Offer Group을 조회한다.
     */
    async getOfferGroup(requestParameters, initOverrides) {
        const response = await this.getOfferGroupRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Offer Group 목록을 조회한다.
     */
    async listOfferGroupsRaw(requestParameters, initOverrides) {
        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size', 'Required parameter requestParameters.size was null or undefined when calling listOfferGroups.');
        }
        const queryParameters = {};
        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }
        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }
        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/api/admin/commerce/v2/offer-group/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ListOfferGroupsResponseFromJSON(jsonValue));
    }
    /**
     * Offer Group 목록을 조회한다.
     */
    async listOfferGroups(requestParameters, initOverrides) {
        const response = await this.listOfferGroupsRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Offer Group을 publish한다.
     */
    async publishOfferGroupRaw(requestParameters, initOverrides) {
        if (requestParameters.offerGroupId === null || requestParameters.offerGroupId === undefined) {
            throw new runtime.RequiredError('offerGroupId', 'Required parameter requestParameters.offerGroupId was null or undefined when calling publishOfferGroup.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/admin/commerce/v2/offer-group/{offerGroupId}/publish`.replace(`{${"offerGroupId"}}`, encodeURIComponent(String(requestParameters.offerGroupId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Offer Group을 publish한다.
     */
    async publishOfferGroup(requestParameters, initOverrides) {
        await this.publishOfferGroupRaw(requestParameters, initOverrides);
    }
    /**
     * Offer Group을 수정한다. Publish된 Offer Group은 type, paymentType을 제외하고 모두 수정가능하다.
     */
    async updateOfferGroupRaw(requestParameters, initOverrides) {
        if (requestParameters.offerGroupId === null || requestParameters.offerGroupId === undefined) {
            throw new runtime.RequiredError('offerGroupId', 'Required parameter requestParameters.offerGroupId was null or undefined when calling updateOfferGroup.');
        }
        if (requestParameters.updateOfferGroupRequest === null || requestParameters.updateOfferGroupRequest === undefined) {
            throw new runtime.RequiredError('updateOfferGroupRequest', 'Required parameter requestParameters.updateOfferGroupRequest was null or undefined when calling updateOfferGroup.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/admin/commerce/v2/offer-group/{offerGroupId}`.replace(`{${"offerGroupId"}}`, encodeURIComponent(String(requestParameters.offerGroupId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateOfferGroupRequestToJSON(requestParameters.updateOfferGroupRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateOfferGroupResponseFromJSON(jsonValue));
    }
    /**
     * Offer Group을 수정한다. Publish된 Offer Group은 type, paymentType을 제외하고 모두 수정가능하다.
     */
    async updateOfferGroup(requestParameters, initOverrides) {
        const response = await this.updateOfferGroupRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
