import { StripeProductBoardFromJSON, StripeProductBoardToJSON, } from './StripeProductBoard';
/**
 * Check if a given object implements the GetStripePorductBoardReponse interface.
 */
export function instanceOfGetStripePorductBoardReponse(value) {
    let isInstance = true;
    isInstance = isInstance && "productBoard" in value;
    return isInstance;
}
export function GetStripePorductBoardReponseFromJSON(json) {
    return GetStripePorductBoardReponseFromJSONTyped(json, false);
}
export function GetStripePorductBoardReponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'productBoard': StripeProductBoardFromJSON(json['productBoard']),
    };
}
export function GetStripePorductBoardReponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'productBoard': StripeProductBoardToJSON(value.productBoard),
    };
}
