import { PeriodFromJSON, PeriodToJSON, } from './Period';
import { StripeBillingConfigFromJSON, StripeBillingConfigToJSON, } from './StripeBillingConfig';
/**
 * Check if a given object implements the StripeProductListItem interface.
 */
export function instanceOfStripeProductListItem(value) {
    let isInstance = true;
    isInstance = isInstance && "isInternal" in value;
    isInstance = isInstance && "isPublished" in value;
    isInstance = isInstance && "displayPeriod" in value;
    isInstance = isInstance && "salesPeriod" in value;
    isInstance = isInstance && "billingConfig" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "id" in value;
    return isInstance;
}
export function StripeProductListItemFromJSON(json) {
    return StripeProductListItemFromJSONTyped(json, false);
}
export function StripeProductListItemFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'isInternal': json['isInternal'],
        'isPublished': json['isPublished'],
        'displayPeriod': PeriodFromJSON(json['displayPeriod']),
        'salesPeriod': PeriodFromJSON(json['salesPeriod']),
        'billingConfig': StripeBillingConfigFromJSON(json['billingConfig']),
        'name': json['name'],
        'description': json['description'],
        'id': json['id'],
    };
}
export function StripeProductListItemToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'isInternal': value.isInternal,
        'isPublished': value.isPublished,
        'displayPeriod': PeriodToJSON(value.displayPeriod),
        'salesPeriod': PeriodToJSON(value.salesPeriod),
        'billingConfig': StripeBillingConfigToJSON(value.billingConfig),
        'name': value.name,
        'description': value.description,
        'id': value.id,
    };
}
