import { SelfLearningUnitFromJSON, SelfLearningUnitToJSON, } from './SelfLearningUnit';
/**
 * Check if a given object implements the SelfLearningUnitResponse interface.
 */
export function instanceOfSelfLearningUnitResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "selfLearningUnit" in value;
    return isInstance;
}
export function SelfLearningUnitResponseFromJSON(json) {
    return SelfLearningUnitResponseFromJSONTyped(json, false);
}
export function SelfLearningUnitResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'selfLearningUnit': SelfLearningUnitFromJSON(json['selfLearningUnit']),
    };
}
export function SelfLearningUnitResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'selfLearningUnit': SelfLearningUnitToJSON(value.selfLearningUnit),
    };
}
