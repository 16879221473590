/* tslint:disable */
/* eslint-disable */
import * as runtime from '../runtime';
import { SelfLearningContentTypesResponseV2FromJSON, } from '../models';
/**
 *
 */
export class SelfLearningContentTypeAdminV2Api extends runtime.BaseAPI {
    /**
     * 선택학습 유닛 그룹 타입 목록을 반환한다.  도메인 & 국가별로 응답하는 목록이 달라질 수 있다.
     * 선택학습 유닛 그룹 타입 목록을 반환한다.
     */
    async getContentTypesV2Raw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/admin/content-learning/v2/self-learning/content-types/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => SelfLearningContentTypesResponseV2FromJSON(jsonValue));
    }
    /**
     * 선택학습 유닛 그룹 타입 목록을 반환한다.  도메인 & 국가별로 응답하는 목록이 달라질 수 있다.
     * 선택학습 유닛 그룹 타입 목록을 반환한다.
     */
    async getContentTypesV2(requestParameters, initOverrides) {
        const response = await this.getContentTypesV2Raw(requestParameters, initOverrides);
        return await response.value();
    }
}
