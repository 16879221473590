export const num2name = {
    0: "STATUS_UNSPECIFIED",
    1: "STATUS_ENABLED",
    2: "STATUS_DISABLED",
};
export const name2num = {
    STATUS_UNSPECIFIED: 0,
    STATUS_ENABLED: 1,
    STATUS_DISABLED: 2,
};
