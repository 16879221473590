export const num2name = {
    0: "ERROR_UNSPECIFIED",
    1: "ERROR_NOT_FOUND",
    2: "ERROR_INVALID_TITLE",
    3: "ERROR_INVALID_COUNTRY",
    4: "ERROR_INVALID_THUMBNAIL_IMAGE_SET",
    5: "ERROR_INVALID_DETAIL_IMAGE_SET",
    6: "ERROR_INVALID_MAIN_BUTTON",
    7: "ERROR_INVALID_SUB_BUTTONS",
    8: "ERROR_INVALID_STARTS_AT_ENDS_AT",
};
export const name2num = {
    ERROR_UNSPECIFIED: 0,
    ERROR_NOT_FOUND: 1,
    ERROR_INVALID_TITLE: 2,
    ERROR_INVALID_COUNTRY: 3,
    ERROR_INVALID_THUMBNAIL_IMAGE_SET: 4,
    ERROR_INVALID_DETAIL_IMAGE_SET: 5,
    ERROR_INVALID_MAIN_BUTTON: 6,
    ERROR_INVALID_SUB_BUTTONS: 7,
    ERROR_INVALID_STARTS_AT_ENDS_AT: 8,
};
