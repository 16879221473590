/* tslint:disable */
/* eslint-disable */
import * as runtime from '../runtime';
import { CreateSelfLearningUnitRequestToJSON, SelfLearningUnitResponseFromJSON, SelfLearningUnitsResponseFromJSON, UpdateSelfLearningUnitRequestToJSON, } from '../models';
/**
 *
 */
export class SelfLearningUnitAdminV1Api extends runtime.BaseAPI {
    /**
     * 선택학습 유닛을 생성한다.
     * 선택학습 유닛을 생성한다.
     */
    async createSelfLearningUnitRaw(requestParameters, initOverrides) {
        if (requestParameters.createSelfLearningUnitRequest === null || requestParameters.createSelfLearningUnitRequest === undefined) {
            throw new runtime.RequiredError('createSelfLearningUnitRequest', 'Required parameter requestParameters.createSelfLearningUnitRequest was null or undefined when calling createSelfLearningUnit.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/admin/content-learning/v1/self-learning/units/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateSelfLearningUnitRequestToJSON(requestParameters.createSelfLearningUnitRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => SelfLearningUnitResponseFromJSON(jsonValue));
    }
    /**
     * 선택학습 유닛을 생성한다.
     * 선택학습 유닛을 생성한다.
     */
    async createSelfLearningUnit(requestParameters, initOverrides) {
        const response = await this.createSelfLearningUnitRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 선택학습 유닛을 삭제한다.
     * 선택학습 유닛을 삭제한다.
     */
    async deleteSelfLearningUnitRaw(requestParameters, initOverrides) {
        if (requestParameters.unitId === null || requestParameters.unitId === undefined) {
            throw new runtime.RequiredError('unitId', 'Required parameter requestParameters.unitId was null or undefined when calling deleteSelfLearningUnit.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/admin/content-learning/v1/self-learning/units/{unitId}`.replace(`{${"unitId"}}`, encodeURIComponent(String(requestParameters.unitId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     * 선택학습 유닛을 삭제한다.
     * 선택학습 유닛을 삭제한다.
     */
    async deleteSelfLearningUnit(requestParameters, initOverrides) {
        await this.deleteSelfLearningUnitRaw(requestParameters, initOverrides);
    }
    /**
     * 선택학습 유닛을 조회한다.
     * 선택학습 유닛을 조회한다.
     */
    async getSelfLearningUnitRaw(requestParameters, initOverrides) {
        if (requestParameters.unitId === null || requestParameters.unitId === undefined) {
            throw new runtime.RequiredError('unitId', 'Required parameter requestParameters.unitId was null or undefined when calling getSelfLearningUnit.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/admin/content-learning/v1/self-learning/units/{unitId}`.replace(`{${"unitId"}}`, encodeURIComponent(String(requestParameters.unitId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => SelfLearningUnitResponseFromJSON(jsonValue));
    }
    /**
     * 선택학습 유닛을 조회한다.
     * 선택학습 유닛을 조회한다.
     */
    async getSelfLearningUnit(requestParameters, initOverrides) {
        const response = await this.getSelfLearningUnitRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 선택학습 유닛 목록을 조회한다.  ## 검색 조건 `unitGroupId`가 `null`이면 `unassigned`인 모든 유닛들을 검색한다.\\ `unitGroupId`가 `null`이 아니면 해당 유닛 그룹에 속한 유닛들을 검색한다.\\ 만약 `unitGroupId`에 해당하는 유닛 그룹이 존재하지 않거나 요청의 `contentType`와 합치하지 않으면 empty list를 응답한다.  ## 정렬 방법 최근에 생성된 순서대로 정렬된다.  ## 참고 admin이 설정한 순서대로 목록을 얻기 원한다면 `/api/admin/content-learning/v1/self-learning/unit-groups/{unitGroupId}/units` api를 사용한다.
     * 선택학습 유닛 목록을 조회한다.
     */
    async getSelfLearningUnitsRaw(requestParameters, initOverrides) {
        if (requestParameters.contentType === null || requestParameters.contentType === undefined) {
            throw new runtime.RequiredError('contentType', 'Required parameter requestParameters.contentType was null or undefined when calling getSelfLearningUnits.');
        }
        const queryParameters = {};
        if (requestParameters.unitGroupId !== undefined) {
            queryParameters['unitGroupId'] = requestParameters.unitGroupId;
        }
        if (requestParameters.contentType !== undefined) {
            queryParameters['contentType'] = requestParameters.contentType;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/api/admin/content-learning/v1/self-learning/units/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => SelfLearningUnitsResponseFromJSON(jsonValue));
    }
    /**
     * 선택학습 유닛 목록을 조회한다.  ## 검색 조건 `unitGroupId`가 `null`이면 `unassigned`인 모든 유닛들을 검색한다.\\ `unitGroupId`가 `null`이 아니면 해당 유닛 그룹에 속한 유닛들을 검색한다.\\ 만약 `unitGroupId`에 해당하는 유닛 그룹이 존재하지 않거나 요청의 `contentType`와 합치하지 않으면 empty list를 응답한다.  ## 정렬 방법 최근에 생성된 순서대로 정렬된다.  ## 참고 admin이 설정한 순서대로 목록을 얻기 원한다면 `/api/admin/content-learning/v1/self-learning/unit-groups/{unitGroupId}/units` api를 사용한다.
     * 선택학습 유닛 목록을 조회한다.
     */
    async getSelfLearningUnits(requestParameters, initOverrides) {
        const response = await this.getSelfLearningUnitsRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 선택학습 유닛을 수정한다.
     * 선택학습 유닛을 수정한다.
     */
    async updateSelfLearningUnitRaw(requestParameters, initOverrides) {
        if (requestParameters.unitId === null || requestParameters.unitId === undefined) {
            throw new runtime.RequiredError('unitId', 'Required parameter requestParameters.unitId was null or undefined when calling updateSelfLearningUnit.');
        }
        if (requestParameters.updateSelfLearningUnitRequest === null || requestParameters.updateSelfLearningUnitRequest === undefined) {
            throw new runtime.RequiredError('updateSelfLearningUnitRequest', 'Required parameter requestParameters.updateSelfLearningUnitRequest was null or undefined when calling updateSelfLearningUnit.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/admin/content-learning/v1/self-learning/units/{unitId}`.replace(`{${"unitId"}}`, encodeURIComponent(String(requestParameters.unitId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateSelfLearningUnitRequestToJSON(requestParameters.updateSelfLearningUnitRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => SelfLearningUnitResponseFromJSON(jsonValue));
    }
    /**
     * 선택학습 유닛을 수정한다.
     * 선택학습 유닛을 수정한다.
     */
    async updateSelfLearningUnit(requestParameters, initOverrides) {
        const response = await this.updateSelfLearningUnitRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
