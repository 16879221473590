// @ts-nocheck
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../runtime/json/scalar";
import { default as serialize, } from "../../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../../runtime/wire/scalar";
import { default as deserialize, } from "../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        userIdentifier: undefined,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    var _a;
    const result = {};
    switch ((_a = value.userIdentifier) === null || _a === void 0 ? void 0 : _a.field) {
        case "userLongId": {
            result.userLongId = tsValueToJsonValueFns.int64(value.userIdentifier.value);
            break;
        }
        case "userAuthId": {
            result.userAuthId = tsValueToJsonValueFns.string(value.userIdentifier.value);
            break;
        }
    }
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.userLongId !== undefined)
        result.userIdentifier = { field: "userLongId", value: jsonValueToTsValueFns.int64(value.userLongId) };
    if (value.userAuthId !== undefined)
        result.userIdentifier = { field: "userAuthId", value: jsonValueToTsValueFns.string(value.userAuthId) };
    return result;
}
export function encodeBinary(value) {
    var _a;
    const result = [];
    switch ((_a = value.userIdentifier) === null || _a === void 0 ? void 0 : _a.field) {
        case "userLongId": {
            const tsValue = value.userIdentifier.value;
            result.push([2, tsValueToWireValueFns.int64(tsValue)]);
            break;
        }
        case "userAuthId": {
            const tsValue = value.userIdentifier.value;
            result.push([3, tsValueToWireValueFns.string(tsValue)]);
            break;
        }
    }
    return serialize(result);
}
const oneofFieldNumbersMap = {
    userIdentifier: new Set([2, 3]),
};
const oneofFieldNamesMap = {
    userIdentifier: new Map([
        [2, "userLongId"],
        [3, "userAuthId"],
    ]),
};
export function decodeBinary(binary) {
    var _a;
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    const wireFieldNumbers = Array.from(wireFields.keys()).reverse();
    oneof: {
        const oneofFieldNumbers = oneofFieldNumbersMap.userIdentifier;
        const oneofFieldNames = oneofFieldNamesMap.userIdentifier;
        const fieldNumber = wireFieldNumbers.find(v => oneofFieldNumbers.has(v));
        if (fieldNumber == null)
            break oneof;
        const wireValue = wireFields.get(fieldNumber);
        const wireValueToTsValueMap = {
            [2](wireValue) { return wireValueToTsValueFns.int64(wireValue); },
            [3](wireValue) { return wireValueToTsValueFns.string(wireValue); },
        };
        const value = (_a = wireValueToTsValueMap[fieldNumber]) === null || _a === void 0 ? void 0 : _a.call(wireValueToTsValueMap, wireValue);
        if (value === undefined)
            break oneof;
        result.userIdentifier = { field: oneofFieldNames.get(fieldNumber), value: value };
    }
    return result;
}
