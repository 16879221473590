import React from 'react';
import {Snackbar} from '@mui/material';
import MuiAlert from '@mui/material/Alert';

export const SnackbarContext = React.createContext<{
  isSnackbarOpen: boolean;
  message: string | null;
  severity: SeverityType;
  showSnackbar: (message: string, severity?: SeverityType) => void;
  setSnackbarOpen: React.Dispatch<React.SetStateAction<boolean>>;
}>(null as any);

type SeverityType = 'error' | 'success' | 'info' | 'warning' | undefined;

export const useSnackbarContext = () => React.useContext(SnackbarContext);

const SnackbarMessage: React.FC = () => {
  const {message, isSnackbarOpen, setSnackbarOpen, severity} = useSnackbarContext();

  return (
    <SnackbarAlert
      message={message}
      isSnackbarOpen={isSnackbarOpen}
      setSnackbarOpen={setSnackbarOpen}
      severity={severity}
    />
  );
};

export interface SnackbarAlertProps {
  message: string | null;
  severity: SeverityType;
  setSnackbarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isSnackbarOpen: boolean;
}

export const SnackbarAlert: React.FC<SnackbarAlertProps> = ({message, severity, setSnackbarOpen, isSnackbarOpen}) => {
  return (
    <Snackbar
      anchorOrigin={{vertical: 'top', horizontal: 'center'}}
      open={isSnackbarOpen}
      autoHideDuration={3000}
      onClose={() => setSnackbarOpen(false)}>
      <MuiAlert elevation={6} variant="filled" severity={severity} onClose={() => setSnackbarOpen(false)}>
        {message}
      </MuiAlert>
    </Snackbar>
  );
};

export function useSnackbar(): [
  {
    isSnackbarOpen: boolean;
    message: string | null;
    severity: SeverityType;
    showSnackbar: (message: string, severity?: SeverityType) => void;
  },
  React.Dispatch<React.SetStateAction<boolean>>
] {
  const [isSnackbarOpen, setSnackbarOpen] = React.useState(false);
  const [message, setMessage] = React.useState<null | string>(null);
  const [severity, setSeverity] = React.useState<SeverityType>('error');

  const showSnackbar = React.useCallback((message: string, severity: SeverityType = 'error') => {
    setMessage(message);
    setSeverity(severity);
    setSnackbarOpen(true);
  }, []);

  return [{isSnackbarOpen, message, severity, showSnackbar}, setSnackbarOpen];
}

export default SnackbarMessage;
