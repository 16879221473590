/**
 * Check if a given object implements the IssueTicketFromTemplateResponse interface.
 */
export function instanceOfIssueTicketFromTemplateResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "ticketIds" in value;
    return isInstance;
}
export function IssueTicketFromTemplateResponseFromJSON(json) {
    return IssueTicketFromTemplateResponseFromJSONTyped(json, false);
}
export function IssueTicketFromTemplateResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'ticketIds': json['ticketIds'],
    };
}
export function IssueTicketFromTemplateResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'ticketIds': value.ticketIds,
    };
}
