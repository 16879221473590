/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { DisplayInfoFromJSON, DisplayInfoToJSON, } from './DisplayInfo';
import { PermitFromJSON, PermitToJSON, } from './Permit';
/**
 * Check if a given object implements the Ticket interface.
 */
export function instanceOfTicket(value) {
    let isInstance = true;
    isInstance = isInstance && "country" in value;
    isInstance = isInstance && "ticketTemplateId" in value;
    isInstance = isInstance && "isUsed" in value;
    isInstance = isInstance && "isRefundable" in value;
    isInstance = isInstance && "expireAtExclusive" in value;
    isInstance = isInstance && "isUsable" in value;
    isInstance = isInstance && "profileId" in value;
    isInstance = isInstance && "permit" in value;
    isInstance = isInstance && "displayInfo" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "isExpired" in value;
    isInstance = isInstance && "learningDomainId" in value;
    return isInstance;
}
export function TicketFromJSON(json) {
    return TicketFromJSONTyped(json, false);
}
export function TicketFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'country': json['country'],
        'ticketTemplateId': json['ticketTemplateId'],
        'isUsed': json['isUsed'],
        'isRefundable': json['isRefundable'],
        'expireAtExclusive': (new Date(json['expireAtExclusive'])),
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'isUsable': json['isUsable'],
        'profileId': json['profileId'],
        'permit': PermitFromJSON(json['permit']),
        'displayInfo': DisplayInfoFromJSON(json['displayInfo']),
        'usedAt': !exists(json, 'usedAt') ? undefined : (new Date(json['usedAt'])),
        'id': json['id'],
        'isExpired': json['isExpired'],
        'learningDomainId': json['learningDomainId'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
    };
}
export function TicketToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'country': value.country,
        'ticketTemplateId': value.ticketTemplateId,
        'isUsed': value.isUsed,
        'isRefundable': value.isRefundable,
        'expireAtExclusive': (value.expireAtExclusive.toISOString()),
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'isUsable': value.isUsable,
        'profileId': value.profileId,
        'permit': PermitToJSON(value.permit),
        'displayInfo': DisplayInfoToJSON(value.displayInfo),
        'usedAt': value.usedAt === undefined ? undefined : (value.usedAt.toISOString()),
        'id': value.id,
        'isExpired': value.isExpired,
        'learningDomainId': value.learningDomainId,
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
    };
}
