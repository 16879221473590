import { SelfLearningUnitContentConditionEntryFromJSON, SelfLearningUnitContentConditionEntryToJSON, } from './SelfLearningUnitContentConditionEntry';
/**
 * Check if a given object implements the SelfLearningUnitContentQueryQuestionByContentConditions interface.
 */
export function instanceOfSelfLearningUnitContentQueryQuestionByContentConditions(value) {
    let isInstance = true;
    isInstance = isInstance && "include" in value;
    isInstance = isInstance && "exclude" in value;
    return isInstance;
}
export function SelfLearningUnitContentQueryQuestionByContentConditionsFromJSON(json) {
    return SelfLearningUnitContentQueryQuestionByContentConditionsFromJSONTyped(json, false);
}
export function SelfLearningUnitContentQueryQuestionByContentConditionsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'include': (json['include'].map(SelfLearningUnitContentConditionEntryFromJSON)),
        'exclude': (json['exclude'].map(SelfLearningUnitContentConditionEntryFromJSON)),
    };
}
export function SelfLearningUnitContentQueryQuestionByContentConditionsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'include': (value.include.map(SelfLearningUnitContentConditionEntryToJSON)),
        'exclude': (value.exclude.map(SelfLearningUnitContentConditionEntryToJSON)),
    };
}
