import React from 'react';
import {useAtom} from 'jotai';

import {riiidLearningDomainAtom} from '#app/atoms/learning-domain';

/**
 * Use this hook after initializing atom value with SantaAdminHttpClientGuard
 */
export default function useRiiidLearningDomain() {
  const [riiidLearningDomain, setRiiidLearningDomain] = useAtom(riiidLearningDomainAtom);

  if (!riiidLearningDomain) {
    throw new Error('riiid learning domain not specified');
  }

  return React.useMemo(
    () => ({riiidLearningDomain, setRiiidLearningDomain}),
    [riiidLearningDomain, setRiiidLearningDomain]
  );
}
