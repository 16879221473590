/* tslint:disable */
/* eslint-disable */
import * as runtime from '../runtime';
import { ListPermitTypesResponseFromJSON, } from '../models';
/**
 *
 */
export class PermitAdminV1PermitTypeApi extends runtime.BaseAPI {
    /**
     * 권한 목록을 조회합니다.
     */
    async listPermitTypesRaw(requestParameters, initOverrides) {
        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size', 'Required parameter requestParameters.size was null or undefined when calling listPermitTypes.');
        }
        const queryParameters = {};
        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }
        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/api/admin/permit/v1/permit-type/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ListPermitTypesResponseFromJSON(jsonValue));
    }
    /**
     * 권한 목록을 조회합니다.
     */
    async listPermitTypes(requestParameters, initOverrides) {
        const response = await this.listPermitTypesRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
