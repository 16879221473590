import { LearningDomainListItemFromJSON, LearningDomainListItemToJSON, } from './LearningDomainListItem';
/**
 * Check if a given object implements the ListLearningDomainsResponse interface.
 */
export function instanceOfListLearningDomainsResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "total" in value;
    isInstance = isInstance && "learningDomains" in value;
    return isInstance;
}
export function ListLearningDomainsResponseFromJSON(json) {
    return ListLearningDomainsResponseFromJSONTyped(json, false);
}
export function ListLearningDomainsResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'total': json['total'],
        'learningDomains': (json['learningDomains'].map(LearningDomainListItemFromJSON)),
    };
}
export function ListLearningDomainsResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'total': value.total,
        'learningDomains': (value.learningDomains.map(LearningDomainListItemToJSON)),
    };
}
