/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { DisplayInfoFromJSON, DisplayInfoToJSON, } from './DisplayInfo';
import { PassTemplateManagementInfoFromJSON, PassTemplateManagementInfoToJSON, } from './PassTemplateManagementInfo';
import { PermitListItemFromJSON, PermitListItemToJSON, } from './PermitListItem';
/**
 * Check if a given object implements the PassTemplateListItem interface.
 */
export function instanceOfPassTemplateListItem(value) {
    let isInstance = true;
    isInstance = isInstance && "permits" in value;
    isInstance = isInstance && "displayInfo" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "managementInfo" in value;
    return isInstance;
}
export function PassTemplateListItemFromJSON(json) {
    return PassTemplateListItemFromJSONTyped(json, false);
}
export function PassTemplateListItemFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'permits': (json['permits'].map(PermitListItemFromJSON)),
        'publishedAt': !exists(json, 'publishedAt') ? undefined : (new Date(json['publishedAt'])),
        'displayInfo': DisplayInfoFromJSON(json['displayInfo']),
        'id': json['id'],
        'managementInfo': PassTemplateManagementInfoFromJSON(json['managementInfo']),
    };
}
export function PassTemplateListItemToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'permits': (value.permits.map(PermitListItemToJSON)),
        'publishedAt': value.publishedAt === undefined ? undefined : (value.publishedAt.toISOString()),
        'displayInfo': DisplayInfoToJSON(value.displayInfo),
        'id': value.id,
        'managementInfo': PassTemplateManagementInfoToJSON(value.managementInfo),
    };
}
