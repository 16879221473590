/* tslint:disable */
/* eslint-disable */
/**
 * - **ACTIVE**: 정상 계정
 * - **PENDING**: 가입 대기중.\
 * 2023-03-15 기준 Google authorization flow를 통해 인증을 진행하였지만 약관동의와 회원정보 기입을 하지 않은 상태
 * - **BLOCKED**: 정지 계정.\
 * 2023-03-15 기준 따로 구현해놓은 상태가 아님
 * - **WITHDRAWN**: 탈퇴 계정
 * @export
 */
export const UserStatus = {
    ACTIVE: 'ACTIVE',
    PENDING: 'PENDING',
    BLOCKED: 'BLOCKED',
    WITHDRAWN: 'WITHDRAWN'
};
export function UserStatusFromJSON(json) {
    return UserStatusFromJSONTyped(json, false);
}
export function UserStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function UserStatusToJSON(value) {
    return value;
}
