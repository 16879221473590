var formatKey = [
    "yyyy",
    "MM",
    "dd",
    "HH",
    "mm",
    "ss"
];
var viewMap = {
    yyyy: "year",
    MM: "month",
    dd: "day",
    HH: "hours",
    mm: "minutes",
    ss: "seconds"
};
export default function makeViewsFromInputFormat(format) {
    var result = Array.from(format.matchAll(/\b[a-z]+\b/gi)).flat();
    return result.filter(function(key) {
        return formatKey.includes(key);
    }).map(function(key) {
        return viewMap[key];
    });
}
