/**
 * Check if a given object implements the OfferGroupDetailImage interface.
 */
export function instanceOfOfferGroupDetailImage(value) {
    let isInstance = true;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "imageAssetId" in value;
    isInstance = isInstance && "offerGroupId" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "updatedAt" in value;
    return isInstance;
}
export function OfferGroupDetailImageFromJSON(json) {
    return OfferGroupDetailImageFromJSONTyped(json, false);
}
export function OfferGroupDetailImageFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'createdAt': (new Date(json['createdAt'])),
        'imageAssetId': json['imageAssetId'],
        'offerGroupId': json['offerGroupId'],
        'id': json['id'],
        'updatedAt': (new Date(json['updatedAt'])),
    };
}
export function OfferGroupDetailImageToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'createdAt': (value.createdAt.toISOString()),
        'imageAssetId': value.imageAssetId,
        'offerGroupId': value.offerGroupId,
        'id': value.id,
        'updatedAt': (value.updatedAt.toISOString()),
    };
}
