import { CountingConfigFromJSON, CountingConfigToJSON, } from './CountingConfig';
import { PermitManagementInfoFromJSON, PermitManagementInfoToJSON, } from './PermitManagementInfo';
/**
 * Check if a given object implements the CreatePermitRequest interface.
 */
export function instanceOfCreatePermitRequest(value) {
    let isInstance = true;
    isInstance = isInstance && "managementInfo" in value;
    isInstance = isInstance && "permitTypeId" in value;
    isInstance = isInstance && "countingConfig" in value;
    isInstance = isInstance && "resourceIds" in value;
    return isInstance;
}
export function CreatePermitRequestFromJSON(json) {
    return CreatePermitRequestFromJSONTyped(json, false);
}
export function CreatePermitRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'managementInfo': PermitManagementInfoFromJSON(json['managementInfo']),
        'permitTypeId': json['permitTypeId'],
        'countingConfig': CountingConfigFromJSON(json['countingConfig']),
        'resourceIds': json['resourceIds'],
    };
}
export function CreatePermitRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'managementInfo': PermitManagementInfoToJSON(value.managementInfo),
        'permitTypeId': value.permitTypeId,
        'countingConfig': CountingConfigToJSON(value.countingConfig),
        'resourceIds': value.resourceIds,
    };
}
