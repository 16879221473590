// @ts-nocheck
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_3, decodeBinary as decodeBinary_3, } from "../RegistrationTypeValue";
import { encodeJson as encodeJson_2, decodeJson as decodeJson_2, encodeBinary as encodeBinary_4, decodeBinary as decodeBinary_4, } from "../(User)/StatusValue";
import { name2num, num2name, } from "../../../inside/Country";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../../runtime/json/scalar";
import { WireType, } from "../../../../runtime/wire/index";
import { default as serialize, } from "../../../../runtime/wire/serialize";
import { encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "../../../google/protobuf/Int64Value";
import { encodeBinary as encodeBinary_2, decodeBinary as decodeBinary_2, } from "../../../google/protobuf/StringValue";
import { default as Long, } from "../../../../runtime/Long";
import { default as deserialize, } from "../../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        userId: undefined,
        providerId: undefined,
        registrationType: undefined,
        status: undefined,
        country: "UNSPECIFIED_COUNTRY",
        authId: undefined,
        likeSupportingField: undefined,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    var _a;
    const result = {};
    if (value.userId !== undefined)
        result.userId = value.userId;
    if (value.providerId !== undefined)
        result.providerId = value.providerId;
    if (value.registrationType !== undefined)
        result.registrationType = encodeJson_1(value.registrationType);
    if (value.status !== undefined)
        result.status = encodeJson_2(value.status);
    if (value.country !== undefined)
        result.country = tsValueToJsonValueFns.enum(value.country);
    if (value.authId !== undefined)
        result.authId = value.authId;
    switch ((_a = value.likeSupportingField) === null || _a === void 0 ? void 0 : _a.field) {
        case "nickname": {
            result.nickname = value.likeSupportingField.value;
            break;
        }
        case "contactEmail": {
            result.contactEmail = value.likeSupportingField.value;
            break;
        }
    }
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.userId !== undefined)
        result.userId = value.userId;
    if (value.providerId !== undefined)
        result.providerId = value.providerId;
    if (value.registrationType !== undefined)
        result.registrationType = decodeJson_1(value.registrationType);
    if (value.status !== undefined)
        result.status = decodeJson_2(value.status);
    if (value.country !== undefined)
        result.country = jsonValueToTsValueFns.enum(value.country);
    if (value.authId !== undefined)
        result.authId = value.authId;
    if (value.nickname !== undefined)
        result.likeSupportingField = { field: "nickname", value: value.nickname };
    if (value.contactEmail !== undefined)
        result.likeSupportingField = { field: "contactEmail", value: value.contactEmail };
    return result;
}
export function encodeBinary(value) {
    var _a;
    const result = [];
    if (value.userId !== undefined) {
        const tsValue = value.userId;
        result.push([1, ((tsValue) => ({ type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }))({ value: tsValue })]);
    }
    if (value.providerId !== undefined) {
        const tsValue = value.providerId;
        result.push([2, ((tsValue) => ({ type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }))({ value: tsValue })]);
    }
    if (value.registrationType !== undefined) {
        const tsValue = value.registrationType;
        result.push([3, { type: WireType.LengthDelimited, value: encodeBinary_3(tsValue) }]);
    }
    if (value.status !== undefined) {
        const tsValue = value.status;
        result.push([5, { type: WireType.LengthDelimited, value: encodeBinary_4(tsValue) }]);
    }
    if (value.country !== undefined) {
        const tsValue = value.country;
        result.push([7, { type: WireType.Varint, value: new Long(name2num[tsValue]) }]);
    }
    if (value.authId !== undefined) {
        const tsValue = value.authId;
        result.push([8, ((tsValue) => ({ type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }))({ value: tsValue })]);
    }
    switch ((_a = value.likeSupportingField) === null || _a === void 0 ? void 0 : _a.field) {
        case "nickname": {
            const tsValue = value.likeSupportingField.value;
            result.push([4, ((tsValue) => ({ type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }))({ value: tsValue })]);
            break;
        }
        case "contactEmail": {
            const tsValue = value.likeSupportingField.value;
            result.push([6, ((tsValue) => ({ type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }))({ value: tsValue })]);
            break;
        }
    }
    return serialize(result);
}
const oneofFieldNumbersMap = {
    likeSupportingField: new Set([4, 6]),
};
const oneofFieldNamesMap = {
    likeSupportingField: new Map([
        [4, "nickname"],
        [6, "contactEmail"],
    ]),
};
export function decodeBinary(binary) {
    var _a, _b, _c, _d;
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    const wireFieldNumbers = Array.from(wireFields.keys()).reverse();
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = (_a = (wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined)) === null || _a === void 0 ? void 0 : _a.value;
        if (value === undefined)
            break field;
        result.userId = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = (_b = (wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined)) === null || _b === void 0 ? void 0 : _b.value;
        if (value === undefined)
            break field;
        result.providerId = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_3(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.registrationType = value;
    }
    field: {
        const wireValue = wireFields.get(5);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_4(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.status = value;
    }
    field: {
        const wireValue = wireFields.get(7);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.country = value;
    }
    field: {
        const wireValue = wireFields.get(8);
        if (wireValue === undefined)
            break field;
        const value = (_c = (wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined)) === null || _c === void 0 ? void 0 : _c.value;
        if (value === undefined)
            break field;
        result.authId = value;
    }
    oneof: {
        const oneofFieldNumbers = oneofFieldNumbersMap.likeSupportingField;
        const oneofFieldNames = oneofFieldNamesMap.likeSupportingField;
        const fieldNumber = wireFieldNumbers.find(v => oneofFieldNumbers.has(v));
        if (fieldNumber == null)
            break oneof;
        const wireValue = wireFields.get(fieldNumber);
        const wireValueToTsValueMap = {
            [4](wireValue) { var _a; return (_a = (wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined)) === null || _a === void 0 ? void 0 : _a.value; },
            [6](wireValue) { var _a; return (_a = (wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined)) === null || _a === void 0 ? void 0 : _a.value; },
        };
        const value = (_d = wireValueToTsValueMap[fieldNumber]) === null || _d === void 0 ? void 0 : _d.call(wireValueToTsValueMap, wireValue);
        if (value === undefined)
            break oneof;
        result.likeSupportingField = { field: oneofFieldNames.get(fieldNumber), value: value };
    }
    return result;
}
