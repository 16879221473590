/* tslint:disable */
/* eslint-disable */
import * as runtime from '../runtime';
import { GetLearningDomainResponseFromJSON, ListLearningDomainsResponseFromJSON, UpdateLearningDomainImageSetRequestToJSON, UpdateLearningDomainInfoRequestToJSON, UpdateLearningDomainStatusRequestToJSON, } from '../models';
/**
 *
 */
export class LearningDomainAdminV1Api extends runtime.BaseAPI {
    /**
     * 학습 도메인을 조회합니다.
     */
    async getLearningDomainRaw(requestParameters, initOverrides) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling getLearningDomain.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/admin/learning-domain/v1/learning-domain/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GetLearningDomainResponseFromJSON(jsonValue));
    }
    /**
     * 학습 도메인을 조회합니다.
     */
    async getLearningDomain(requestParameters, initOverrides) {
        const response = await this.getLearningDomainRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 전체 학습 도메인 목록을 조회합니다.
     */
    async listLearningDomainsRaw(requestParameters, initOverrides) {
        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size', 'Required parameter requestParameters.size was null or undefined when calling listLearningDomains.');
        }
        const queryParameters = {};
        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }
        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/api/admin/learning-domain/v1/learning-domain/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ListLearningDomainsResponseFromJSON(jsonValue));
    }
    /**
     * 전체 학습 도메인 목록을 조회합니다.
     */
    async listLearningDomains(requestParameters, initOverrides) {
        const response = await this.listLearningDomainsRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 학습 도메인의 이미지 세트를 수정합니다.
     */
    async updateLearningDomainImageSetRaw(requestParameters, initOverrides) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling updateLearningDomainImageSet.');
        }
        if (requestParameters.updateLearningDomainImageSetRequest === null || requestParameters.updateLearningDomainImageSetRequest === undefined) {
            throw new runtime.RequiredError('updateLearningDomainImageSetRequest', 'Required parameter requestParameters.updateLearningDomainImageSetRequest was null or undefined when calling updateLearningDomainImageSet.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/admin/learning-domain/v1/learning-domain/{id}/image-set`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateLearningDomainImageSetRequestToJSON(requestParameters.updateLearningDomainImageSetRequest),
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     * 학습 도메인의 이미지 세트를 수정합니다.
     */
    async updateLearningDomainImageSet(requestParameters, initOverrides) {
        await this.updateLearningDomainImageSetRaw(requestParameters, initOverrides);
    }
    /**
     * 학습 도메인 정보를 수정합니다.
     */
    async updateLearningDomainInfoRaw(requestParameters, initOverrides) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling updateLearningDomainInfo.');
        }
        if (requestParameters.language === null || requestParameters.language === undefined) {
            throw new runtime.RequiredError('language', 'Required parameter requestParameters.language was null or undefined when calling updateLearningDomainInfo.');
        }
        if (requestParameters.updateLearningDomainInfoRequest === null || requestParameters.updateLearningDomainInfoRequest === undefined) {
            throw new runtime.RequiredError('updateLearningDomainInfoRequest', 'Required parameter requestParameters.updateLearningDomainInfoRequest was null or undefined when calling updateLearningDomainInfo.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/admin/learning-domain/v1/learning-domain/{id}/info/{language}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"language"}}`, encodeURIComponent(String(requestParameters.language))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateLearningDomainInfoRequestToJSON(requestParameters.updateLearningDomainInfoRequest),
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     * 학습 도메인 정보를 수정합니다.
     */
    async updateLearningDomainInfo(requestParameters, initOverrides) {
        await this.updateLearningDomainInfoRaw(requestParameters, initOverrides);
    }
    /**
     * 학습 도메인 상태를 수정합니다.
     */
    async updateLearningDomainStatusRaw(requestParameters, initOverrides) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling updateLearningDomainStatus.');
        }
        if (requestParameters.language === null || requestParameters.language === undefined) {
            throw new runtime.RequiredError('language', 'Required parameter requestParameters.language was null or undefined when calling updateLearningDomainStatus.');
        }
        if (requestParameters.updateLearningDomainStatusRequest === null || requestParameters.updateLearningDomainStatusRequest === undefined) {
            throw new runtime.RequiredError('updateLearningDomainStatusRequest', 'Required parameter requestParameters.updateLearningDomainStatusRequest was null or undefined when calling updateLearningDomainStatus.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/admin/learning-domain/v1/learning-domain/{id}/status/{language}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"language"}}`, encodeURIComponent(String(requestParameters.language))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateLearningDomainStatusRequestToJSON(requestParameters.updateLearningDomainStatusRequest),
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     * 학습 도메인 상태를 수정합니다.
     */
    async updateLearningDomainStatus(requestParameters, initOverrides) {
        await this.updateLearningDomainStatusRaw(requestParameters, initOverrides);
    }
}
