export const num2name = {
    0: "CYCLE_TYPE_UNSPECIFIED",
    1: "DIAGNOSIS",
    2: "COURSE",
    3: "MYNOTE",
    4: "SELF",
};
export const name2num = {
    CYCLE_TYPE_UNSPECIFIED: 0,
    DIAGNOSIS: 1,
    COURSE: 2,
    MYNOTE: 3,
    SELF: 4,
};
