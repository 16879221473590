/**
 * Check if a given object implements the HasPurchaseExperienceByDomainItem interface.
 */
export function instanceOfHasPurchaseExperienceByDomainItem(value) {
    let isInstance = true;
    isInstance = isInstance && "hasPurchased" in value;
    isInstance = isInstance && "learningDomainId" in value;
    return isInstance;
}
export function HasPurchaseExperienceByDomainItemFromJSON(json) {
    return HasPurchaseExperienceByDomainItemFromJSONTyped(json, false);
}
export function HasPurchaseExperienceByDomainItemFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'hasPurchased': json['hasPurchased'],
        'learningDomainId': json['learningDomainId'],
    };
}
export function HasPurchaseExperienceByDomainItemToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'hasPurchased': value.hasPurchased,
        'learningDomainId': value.learningDomainId,
    };
}
