/**
 * Check if a given object implements the SelfLearningUnitsSwapRequest interface.
 */
export function instanceOfSelfLearningUnitsSwapRequest(value) {
    let isInstance = true;
    isInstance = isInstance && "id2" in value;
    isInstance = isInstance && "id1" in value;
    return isInstance;
}
export function SelfLearningUnitsSwapRequestFromJSON(json) {
    return SelfLearningUnitsSwapRequestFromJSONTyped(json, false);
}
export function SelfLearningUnitsSwapRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id2': json['id2'],
        'id1': json['id1'],
    };
}
export function SelfLearningUnitsSwapRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id2': value.id2,
        'id1': value.id1,
    };
}
