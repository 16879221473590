/* tslint:disable */
/* eslint-disable */
/**
 * - **NONE**: 대상 리소스를 지정할 수 없음.
 * 항상 모든 리소스를 의미함.
 * - **OPTIONAL**: 대상 리소스를 지정할 수도 있고 하지 않을 수도 있음.
 * 지정하지 않는 경우 모든 리소스를 의미함.
 * - **REQUIRED**: 대상 리소스를 반드시 지정해야 함.
 * @export
 */
export const ResourceIdOption = {
    NONE: 'NONE',
    OPTIONAL: 'OPTIONAL',
    REQUIRED: 'REQUIRED'
};
export function ResourceIdOptionFromJSON(json) {
    return ResourceIdOptionFromJSONTyped(json, false);
}
export function ResourceIdOptionFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ResourceIdOptionToJSON(value) {
    return value;
}
