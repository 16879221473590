import Link from 'next/link';
import React from 'react';
import {Link as MaterialLink, LinkProps as MaterialLinkProps} from '@mui/material';

type RouteLinkProps = MaterialLinkProps & {href: string};

export default function RouteLink({href, children, ...props}: RouteLinkProps) {
  return (
    <Link href={href} passHref legacyBehavior>
      <MaterialLink {...props}>{children}</MaterialLink>
    </Link>
  );
}
