/**
 * Check if a given object implements the InstantRange interface.
 */
export function instanceOfInstantRange(value) {
    let isInstance = true;
    isInstance = isInstance && "endExclusive" in value;
    isInstance = isInstance && "start" in value;
    return isInstance;
}
export function InstantRangeFromJSON(json) {
    return InstantRangeFromJSONTyped(json, false);
}
export function InstantRangeFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'endExclusive': (new Date(json['endExclusive'])),
        'start': (new Date(json['start'])),
    };
}
export function InstantRangeToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'endExclusive': (value.endExclusive.toISOString()),
        'start': (value.start.toISOString()),
    };
}
