import { LearningDomainKeyFromJSON, LearningDomainKeyToJSON, } from './LearningDomainKey';
/**
 * Check if a given object implements the LearningDomainListItem interface.
 */
export function instanceOfLearningDomainListItem(value) {
    let isInstance = true;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "imageSetId" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "key" in value;
    isInstance = isInstance && "updatedAt" in value;
    return isInstance;
}
export function LearningDomainListItemFromJSON(json) {
    return LearningDomainListItemFromJSONTyped(json, false);
}
export function LearningDomainListItemFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'createdAt': (new Date(json['createdAt'])),
        'imageSetId': json['imageSetId'],
        'id': json['id'],
        'key': LearningDomainKeyFromJSON(json['key']),
        'updatedAt': (new Date(json['updatedAt'])),
    };
}
export function LearningDomainListItemToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'createdAt': (value.createdAt.toISOString()),
        'imageSetId': value.imageSetId,
        'id': value.id,
        'key': LearningDomainKeyToJSON(value.key),
        'updatedAt': (value.updatedAt.toISOString()),
    };
}
