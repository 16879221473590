import { UserListItemFromJSON, UserListItemToJSON, } from './UserListItem';
/**
 * Check if a given object implements the GetUserListResponse interface.
 */
export function instanceOfGetUserListResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "total" in value;
    isInstance = isInstance && "users" in value;
    return isInstance;
}
export function GetUserListResponseFromJSON(json) {
    return GetUserListResponseFromJSONTyped(json, false);
}
export function GetUserListResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'total': json['total'],
        'users': (json['users'].map(UserListItemFromJSON)),
    };
}
export function GetUserListResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'total': value.total,
        'users': (value.users.map(UserListItemToJSON)),
    };
}
