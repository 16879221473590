// @ts-nocheck
import { WireType, } from "../../../../../runtime/wire/index";
import { default as serialize, } from "../../../../../runtime/wire/serialize";
import { encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "../../../../google/protobuf/StringValue";
import { default as deserialize, } from "../../../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        descriptionLike: undefined,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.descriptionLike !== undefined)
        result.descriptionLike = value.descriptionLike;
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.descriptionLike !== undefined)
        result.descriptionLike = value.descriptionLike;
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.descriptionLike !== undefined) {
        const tsValue = value.descriptionLike;
        result.push([1, ((tsValue) => ({ type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }))({ value: tsValue })]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    var _a;
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = (_a = (wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined)) === null || _a === void 0 ? void 0 : _a.value;
        if (value === undefined)
            break field;
        result.descriptionLike = value;
    }
    return result;
}
