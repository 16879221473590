import { CreateOfferGroupDetailImageFromJSON, CreateOfferGroupDetailImageToJSON, } from './CreateOfferGroupDetailImage';
import { ManagementInfoFromJSON, ManagementInfoToJSON, } from './ManagementInfo';
import { OfferGroupDisplayConfigFromJSON, OfferGroupDisplayConfigToJSON, } from './OfferGroupDisplayConfig';
import { OfferGroupSalesInfoFromJSON, OfferGroupSalesInfoToJSON, } from './OfferGroupSalesInfo';
import { OfferTypeFromJSON, OfferTypeToJSON, } from './OfferType';
/**
 * Check if a given object implements the CreateOfferGroupRequest interface.
 */
export function instanceOfCreateOfferGroupRequest(value) {
    let isInstance = true;
    isInstance = isInstance && "displayConfig" in value;
    isInstance = isInstance && "offerGroupDetailImages" in value;
    isInstance = isInstance && "salesConfig" in value;
    isInstance = isInstance && "managementInfo" in value;
    isInstance = isInstance && "type" in value;
    return isInstance;
}
export function CreateOfferGroupRequestFromJSON(json) {
    return CreateOfferGroupRequestFromJSONTyped(json, false);
}
export function CreateOfferGroupRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'displayConfig': OfferGroupDisplayConfigFromJSON(json['displayConfig']),
        'offerGroupDetailImages': (json['offerGroupDetailImages'].map(CreateOfferGroupDetailImageFromJSON)),
        'salesConfig': OfferGroupSalesInfoFromJSON(json['salesConfig']),
        'managementInfo': ManagementInfoFromJSON(json['managementInfo']),
        'type': OfferTypeFromJSON(json['type']),
    };
}
export function CreateOfferGroupRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'displayConfig': OfferGroupDisplayConfigToJSON(value.displayConfig),
        'offerGroupDetailImages': (value.offerGroupDetailImages.map(CreateOfferGroupDetailImageToJSON)),
        'salesConfig': OfferGroupSalesInfoToJSON(value.salesConfig),
        'managementInfo': ManagementInfoToJSON(value.managementInfo),
        'type': OfferTypeToJSON(value.type),
    };
}
