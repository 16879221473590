import { SelfLearningUnitContentQueryQuestionByContentConditionsFromJSON, SelfLearningUnitContentQueryQuestionByContentConditionsToJSON, } from './SelfLearningUnitContentQueryQuestionByContentConditions';
/**
 * Check if a given object implements the CountAvailableQuestionContentsRequest interface.
 */
export function instanceOfCountAvailableQuestionContentsRequest(value) {
    let isInstance = true;
    isInstance = isInstance && "query" in value;
    return isInstance;
}
export function CountAvailableQuestionContentsRequestFromJSON(json) {
    return CountAvailableQuestionContentsRequestFromJSONTyped(json, false);
}
export function CountAvailableQuestionContentsRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'query': SelfLearningUnitContentQueryQuestionByContentConditionsFromJSON(json['query']),
    };
}
export function CountAvailableQuestionContentsRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'query': SelfLearningUnitContentQueryQuestionByContentConditionsToJSON(value.query),
    };
}
