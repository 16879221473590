// @ts-nocheck
import { name2num, num2name, } from "../../inside/Country";
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "../ToeicResourceImageSet";
import { encodeJson as encodeJson_2, decodeJson as decodeJson_2, encodeBinary as encodeBinary_2, decodeBinary as decodeBinary_2, } from "./(Promotion)/Button";
import { encodeJson as encodeJson_3, decodeJson as decodeJson_3, encodeBinary as encodeBinary_3, decodeBinary as decodeBinary_3, } from "../../google/protobuf/Timestamp";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../runtime/json/scalar";
import { WireType, } from "../../../runtime/wire/index";
import { default as serialize, } from "../../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../../runtime/wire/scalar";
import { default as Long, } from "../../../runtime/Long";
import { default as deserialize, } from "../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        title: "",
        country: "UNSPECIFIED_COUNTRY",
        thumbnailTitle: "",
        thumbnailDescription: "",
        thumbnailImageSet: undefined,
        detailImageSet: undefined,
        mainButton: undefined,
        subButtons: [],
        visibility: false,
        startsAt: undefined,
        endsAt: undefined,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.title !== undefined)
        result.title = tsValueToJsonValueFns.string(value.title);
    if (value.country !== undefined)
        result.country = tsValueToJsonValueFns.enum(value.country);
    if (value.thumbnailTitle !== undefined)
        result.thumbnailTitle = tsValueToJsonValueFns.string(value.thumbnailTitle);
    if (value.thumbnailDescription !== undefined)
        result.thumbnailDescription = tsValueToJsonValueFns.string(value.thumbnailDescription);
    if (value.thumbnailImageSet !== undefined)
        result.thumbnailImageSet = encodeJson_1(value.thumbnailImageSet);
    if (value.detailImageSet !== undefined)
        result.detailImageSet = encodeJson_1(value.detailImageSet);
    if (value.mainButton !== undefined)
        result.mainButton = encodeJson_2(value.mainButton);
    result.subButtons = value.subButtons.map(value => encodeJson_2(value));
    if (value.visibility !== undefined)
        result.visibility = tsValueToJsonValueFns.bool(value.visibility);
    if (value.startsAt !== undefined)
        result.startsAt = encodeJson_3(value.startsAt);
    if (value.endsAt !== undefined)
        result.endsAt = encodeJson_3(value.endsAt);
    return result;
}
export function decodeJson(value) {
    var _a, _b;
    const result = getDefaultValue();
    if (value.title !== undefined)
        result.title = jsonValueToTsValueFns.string(value.title);
    if (value.country !== undefined)
        result.country = jsonValueToTsValueFns.enum(value.country);
    if (value.thumbnailTitle !== undefined)
        result.thumbnailTitle = jsonValueToTsValueFns.string(value.thumbnailTitle);
    if (value.thumbnailDescription !== undefined)
        result.thumbnailDescription = jsonValueToTsValueFns.string(value.thumbnailDescription);
    if (value.thumbnailImageSet !== undefined)
        result.thumbnailImageSet = decodeJson_1(value.thumbnailImageSet);
    if (value.detailImageSet !== undefined)
        result.detailImageSet = decodeJson_1(value.detailImageSet);
    if (value.mainButton !== undefined)
        result.mainButton = decodeJson_2(value.mainButton);
    result.subButtons = (_b = (_a = value.subButtons) === null || _a === void 0 ? void 0 : _a.map((value) => decodeJson_2(value))) !== null && _b !== void 0 ? _b : [];
    if (value.visibility !== undefined)
        result.visibility = jsonValueToTsValueFns.bool(value.visibility);
    if (value.startsAt !== undefined)
        result.startsAt = decodeJson_3(value.startsAt);
    if (value.endsAt !== undefined)
        result.endsAt = decodeJson_3(value.endsAt);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.title !== undefined) {
        const tsValue = value.title;
        result.push([1, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.country !== undefined) {
        const tsValue = value.country;
        result.push([2, { type: WireType.Varint, value: new Long(name2num[tsValue]) }]);
    }
    if (value.thumbnailTitle !== undefined) {
        const tsValue = value.thumbnailTitle;
        result.push([3, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.thumbnailDescription !== undefined) {
        const tsValue = value.thumbnailDescription;
        result.push([4, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.thumbnailImageSet !== undefined) {
        const tsValue = value.thumbnailImageSet;
        result.push([5, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    if (value.detailImageSet !== undefined) {
        const tsValue = value.detailImageSet;
        result.push([6, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    if (value.mainButton !== undefined) {
        const tsValue = value.mainButton;
        result.push([7, { type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }]);
    }
    for (const tsValue of value.subButtons) {
        result.push([8, { type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }]);
    }
    if (value.visibility !== undefined) {
        const tsValue = value.visibility;
        result.push([9, tsValueToWireValueFns.bool(tsValue)]);
    }
    if (value.startsAt !== undefined) {
        const tsValue = value.startsAt;
        result.push([11, { type: WireType.LengthDelimited, value: encodeBinary_3(tsValue) }]);
    }
    if (value.endsAt !== undefined) {
        const tsValue = value.endsAt;
        result.push([12, { type: WireType.LengthDelimited, value: encodeBinary_3(tsValue) }]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.title = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.country = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.thumbnailTitle = value;
    }
    field: {
        const wireValue = wireFields.get(4);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.thumbnailDescription = value;
    }
    field: {
        const wireValue = wireFields.get(5);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.thumbnailImageSet = value;
    }
    field: {
        const wireValue = wireFields.get(6);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.detailImageSet = value;
    }
    field: {
        const wireValue = wireFields.get(7);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.mainButton = value;
    }
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 8).map(([, wireValue]) => wireValue);
        const value = wireValues.map((wireValue) => wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined).filter(x => x !== undefined);
        if (!value.length)
            break collection;
        result.subButtons = value;
    }
    field: {
        const wireValue = wireFields.get(9);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.bool(wireValue);
        if (value === undefined)
            break field;
        result.visibility = value;
    }
    field: {
        const wireValue = wireFields.get(11);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_3(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.startsAt = value;
    }
    field: {
        const wireValue = wireFields.get(12);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_3(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.endsAt = value;
    }
    return result;
}
