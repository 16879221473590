/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { ManagementInfoFromJSON, ManagementInfoToJSON, } from './ManagementInfo';
import { OfferGroupDisplayConfigFromJSON, OfferGroupDisplayConfigToJSON, } from './OfferGroupDisplayConfig';
import { OfferGroupSalesInfoFromJSON, OfferGroupSalesInfoToJSON, } from './OfferGroupSalesInfo';
import { OfferTypeFromJSON, OfferTypeToJSON, } from './OfferType';
import { UpdateOfferGroupDetailImageFromJSON, UpdateOfferGroupDetailImageToJSON, } from './UpdateOfferGroupDetailImage';
/**
 * Check if a given object implements the UpdateOfferGroupRequest interface.
 */
export function instanceOfUpdateOfferGroupRequest(value) {
    let isInstance = true;
    return isInstance;
}
export function UpdateOfferGroupRequestFromJSON(json) {
    return UpdateOfferGroupRequestFromJSONTyped(json, false);
}
export function UpdateOfferGroupRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'displayConfig': !exists(json, 'displayConfig') ? undefined : OfferGroupDisplayConfigFromJSON(json['displayConfig']),
        'offerGroupDetailImages': !exists(json, 'offerGroupDetailImages') ? undefined : (json['offerGroupDetailImages'].map(UpdateOfferGroupDetailImageFromJSON)),
        'salesConfig': !exists(json, 'salesConfig') ? undefined : OfferGroupSalesInfoFromJSON(json['salesConfig']),
        'managementInfo': !exists(json, 'managementInfo') ? undefined : ManagementInfoFromJSON(json['managementInfo']),
        'type': !exists(json, 'type') ? undefined : OfferTypeFromJSON(json['type']),
    };
}
export function UpdateOfferGroupRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'displayConfig': OfferGroupDisplayConfigToJSON(value.displayConfig),
        'offerGroupDetailImages': value.offerGroupDetailImages === undefined ? undefined : (value.offerGroupDetailImages.map(UpdateOfferGroupDetailImageToJSON)),
        'salesConfig': OfferGroupSalesInfoToJSON(value.salesConfig),
        'managementInfo': ManagementInfoToJSON(value.managementInfo),
        'type': OfferTypeToJSON(value.type),
    };
}
