export const num2name = {
    0: "REGISTRATION_TYPE_UNSPECIFIED",
    1: "EMAIL",
    2: "KAKAO",
    3: "APPLE",
    4: "GOOGLE",
    5: "LINE",
    6: "FACEBOOK",
};
export const name2num = {
    REGISTRATION_TYPE_UNSPECIFIED: 0,
    EMAIL: 1,
    KAKAO: 2,
    APPLE: 3,
    GOOGLE: 4,
    LINE: 5,
    FACEBOOK: 6,
};
