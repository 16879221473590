/* tslint:disable */
/* eslint-disable */
import * as runtime from '../runtime';
import { CreateStripeProductBoardRequestToJSON, CreateStripeProductBoardResponseFromJSON, GetStripePorductBoardReponseFromJSON, ListStripeProductBoardsResponseFromJSON, UpdateStripeProducBoardRequestToJSON, UpdateStripeProductBoardResponseFromJSON, } from '../models';
/**
 *
 */
export class StripeProductBoardAdminV1Api extends runtime.BaseAPI {
    /**
     */
    async createStripeProductBoardRaw(requestParameters, initOverrides) {
        if (requestParameters.createStripeProductBoardRequest === null || requestParameters.createStripeProductBoardRequest === undefined) {
            throw new runtime.RequiredError('createStripeProductBoardRequest', 'Required parameter requestParameters.createStripeProductBoardRequest was null or undefined when calling createStripeProductBoard.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/admin/commerce/v1/stripe/product-board/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateStripeProductBoardRequestToJSON(requestParameters.createStripeProductBoardRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => CreateStripeProductBoardResponseFromJSON(jsonValue));
    }
    /**
     */
    async createStripeProductBoard(requestParameters, initOverrides) {
        const response = await this.createStripeProductBoardRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async getStripeProductBoardRaw(requestParameters, initOverrides) {
        if (requestParameters.stripeProductBoardId === null || requestParameters.stripeProductBoardId === undefined) {
            throw new runtime.RequiredError('stripeProductBoardId', 'Required parameter requestParameters.stripeProductBoardId was null or undefined when calling getStripeProductBoard.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/admin/commerce/v1/stripe/product-board/{stripeProductBoardId}`.replace(`{${"stripeProductBoardId"}}`, encodeURIComponent(String(requestParameters.stripeProductBoardId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GetStripePorductBoardReponseFromJSON(jsonValue));
    }
    /**
     */
    async getStripeProductBoard(requestParameters, initOverrides) {
        const response = await this.getStripeProductBoardRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async listStripeProductBoardsRaw(requestParameters, initOverrides) {
        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size', 'Required parameter requestParameters.size was null or undefined when calling listStripeProductBoards.');
        }
        const queryParameters = {};
        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }
        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/api/admin/commerce/v1/stripe/product-board/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ListStripeProductBoardsResponseFromJSON(jsonValue));
    }
    /**
     */
    async listStripeProductBoards(requestParameters, initOverrides) {
        const response = await this.listStripeProductBoardsRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async updateStripeProductBoardRaw(requestParameters, initOverrides) {
        if (requestParameters.stripeProductBoardId === null || requestParameters.stripeProductBoardId === undefined) {
            throw new runtime.RequiredError('stripeProductBoardId', 'Required parameter requestParameters.stripeProductBoardId was null or undefined when calling updateStripeProductBoard.');
        }
        if (requestParameters.updateStripeProducBoardRequest === null || requestParameters.updateStripeProducBoardRequest === undefined) {
            throw new runtime.RequiredError('updateStripeProducBoardRequest', 'Required parameter requestParameters.updateStripeProducBoardRequest was null or undefined when calling updateStripeProductBoard.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/admin/commerce/v1/stripe/product-board/{stripeProductBoardId}`.replace(`{${"stripeProductBoardId"}}`, encodeURIComponent(String(requestParameters.stripeProductBoardId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateStripeProducBoardRequestToJSON(requestParameters.updateStripeProducBoardRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateStripeProductBoardResponseFromJSON(jsonValue));
    }
    /**
     */
    async updateStripeProductBoard(requestParameters, initOverrides) {
        const response = await this.updateStripeProductBoardRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
