import React from 'react';
import {css} from '@emotion/react';
import {CssBaseline, Toolbar} from '@mui/material';
import {useTheme} from '@mui/material/styles';

import Header from './Header';
import Navigation from './Navigation';

const DefaultLayout = ({children}: {children: React.ReactNode}) => {
  const theme = useTheme();
  return (
    <div
      css={css`
        display: flex;
      `}>
      <CssBaseline />
      <Header />
      <Navigation />
      <main
        css={css`
          width: 100%;
          height: 100vh;
          overflow-x: scroll;
          flex-grow: 1;
          padding: ${theme.spacing(3)};
        `}>
        <Toolbar />
        {children}
      </main>
    </div>
  );
};

export default DefaultLayout;
