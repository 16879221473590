export const num2name = {
    0: "STUDENT_STATUS_UNSPECIFIED",
    1: "VOID",
    2: "NORMAL",
    3: "DROP",
    4: "BLOCKED",
};
export const name2num = {
    STUDENT_STATUS_UNSPECIFIED: 0,
    VOID: 1,
    NORMAL: 2,
    DROP: 3,
    BLOCKED: 4,
};
