import {atom} from 'jotai';

import type {
  LearningDomainAdminV1Api,
  StripeProductAdminV1Api,
  StripeProductBoardAdminV1Api,
  StripeSubscriptionOrderAdminV1Api,
  UserAdminV1Api,
  PermitAdminV1PermitApi,
  PermitAdminV1PermitTypeApi,
  PermitAdminV1TicketApi,
  PermitAdminV1PassApi,
  AssetAdminV1Api,
  CommerceAdminV2OfferGroupApi,
} from '@santa-web/gen/open-api/admin';
import type {
  SelfLearningContentTypeAdminV2Api,
  SelfLearningUnitAdminV1Api,
  SelfLearningUnitGroupAdminV1Api,
  SelfLearningUnitByUnitGroupAdminV1Api,
  SelfLearningQuestionContentsAdminV1Api,
  SelfLearningVerificationAdminV1Api,
} from '@santa-web/gen/open-api/content-learning-admin';

export interface SantaAdminHttpClient {
  LearningDomainService: LearningDomainAdminV1Api;
  UserService: UserAdminV1Api;
  StripeProductService: StripeProductAdminV1Api;
  StripeProductBoardService: StripeProductBoardAdminV1Api;
  StripeSubscriptionOrderService: StripeSubscriptionOrderAdminV1Api;
  SelfLearningContentTypeService: SelfLearningContentTypeAdminV2Api;
  SelfLearningUnitService: SelfLearningUnitAdminV1Api;
  SelfLearningUnitGroupService: SelfLearningUnitGroupAdminV1Api;
  SelfLearningUnitByUnitGroupService: SelfLearningUnitByUnitGroupAdminV1Api;
  SelfLearningQuestionContentsService: SelfLearningQuestionContentsAdminV1Api;
  PermitService: PermitAdminV1PermitApi;
  PermitTypeService: PermitAdminV1PermitTypeApi;
  TicketService: PermitAdminV1TicketApi;
  PassService: PermitAdminV1PassApi;
  CommerceOfferGroupService: CommerceAdminV2OfferGroupApi;
  SelfLearningVerificationService: SelfLearningVerificationAdminV1Api;
  AssetService: AssetAdminV1Api;
}

export const santaAdminHttpClientAtom = atom<SantaAdminHttpClient | null>(null);
