import { OnboardingTicketTemplateListItemFromJSON, OnboardingTicketTemplateListItemToJSON, } from './OnboardingTicketTemplateListItem';
/**
 * Check if a given object implements the ListOnboardingTicketTemplatesResponse interface.
 */
export function instanceOfListOnboardingTicketTemplatesResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "onboardingTicketTemplates" in value;
    isInstance = isInstance && "total" in value;
    return isInstance;
}
export function ListOnboardingTicketTemplatesResponseFromJSON(json) {
    return ListOnboardingTicketTemplatesResponseFromJSONTyped(json, false);
}
export function ListOnboardingTicketTemplatesResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'onboardingTicketTemplates': (json['onboardingTicketTemplates'].map(OnboardingTicketTemplateListItemFromJSON)),
        'total': json['total'],
    };
}
export function ListOnboardingTicketTemplatesResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'onboardingTicketTemplates': (value.onboardingTicketTemplates.map(OnboardingTicketTemplateListItemToJSON)),
        'total': value.total,
    };
}
