export const num2name = {
    0: "SUBSCRIPTION_EVENT_UNSPECIFIED",
    1: "SUBSCRIPTION_EVENT_CREATE",
    2: "SUBSCRIPTION_EVENT_ACTIVATE",
    3: "SUBSCRIPTION_EVENT_CANCEL",
    4: "SUBSCRIPTION_EVENT_HOLD",
    5: "SUBSCRIPTION_EVENT_EXPIRE",
};
export const name2num = {
    SUBSCRIPTION_EVENT_UNSPECIFIED: 0,
    SUBSCRIPTION_EVENT_CREATE: 1,
    SUBSCRIPTION_EVENT_ACTIVATE: 2,
    SUBSCRIPTION_EVENT_CANCEL: 3,
    SUBSCRIPTION_EVENT_HOLD: 4,
    SUBSCRIPTION_EVENT_EXPIRE: 5,
};
