/* tslint:disable */
/* eslint-disable */
import * as runtime from '../runtime';
import { CreateStripeProductRequestToJSON, CreateStripeProductResponseFromJSON, GetStripeProductResponseFromJSON, ListStripeProductsResponseFromJSON, UpdateStripeProductRequestToJSON, UpdateStripeProductResponseFromJSON, } from '../models';
/**
 *
 */
export class StripeProductAdminV1Api extends runtime.BaseAPI {
    /**
     * 상품을 생성하고 Stripe에 등록한다.
     */
    async createStripeProductRaw(requestParameters, initOverrides) {
        if (requestParameters.createStripeProductRequest === null || requestParameters.createStripeProductRequest === undefined) {
            throw new runtime.RequiredError('createStripeProductRequest', 'Required parameter requestParameters.createStripeProductRequest was null or undefined when calling createStripeProduct.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/admin/commerce/v1/stripe/product/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateStripeProductRequestToJSON(requestParameters.createStripeProductRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => CreateStripeProductResponseFromJSON(jsonValue));
    }
    /**
     * 상품을 생성하고 Stripe에 등록한다.
     */
    async createStripeProduct(requestParameters, initOverrides) {
        const response = await this.createStripeProductRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async getStripeProductRaw(requestParameters, initOverrides) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling getStripeProduct.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/admin/commerce/v1/stripe/product/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GetStripeProductResponseFromJSON(jsonValue));
    }
    /**
     */
    async getStripeProduct(requestParameters, initOverrides) {
        const response = await this.getStripeProductRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async listStripeProductsRaw(requestParameters, initOverrides) {
        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size', 'Required parameter requestParameters.size was null or undefined when calling listStripeProducts.');
        }
        const queryParameters = {};
        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }
        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }
        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/api/admin/commerce/v1/stripe/product/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ListStripeProductsResponseFromJSON(jsonValue));
    }
    /**
     */
    async listStripeProducts(requestParameters, initOverrides) {
        const response = await this.listStripeProductsRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 상품을 확정하여 판매 가능한 상태로 변경. Stripe로의 상품 등록도 이 때 진행된다.
     */
    async publishStripeProductRaw(requestParameters, initOverrides) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling publishStripeProduct.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/admin/commerce/v1/stripe/product/{id}/publish`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     * 상품을 확정하여 판매 가능한 상태로 변경. Stripe로의 상품 등록도 이 때 진행된다.
     */
    async publishStripeProduct(requestParameters, initOverrides) {
        await this.publishStripeProductRaw(requestParameters, initOverrides);
    }
    /**
     * 상품을 수정한다. 확정된 상품은 수정이 불가능하다.
     */
    async updateStripeProductRaw(requestParameters, initOverrides) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling updateStripeProduct.');
        }
        if (requestParameters.updateStripeProductRequest === null || requestParameters.updateStripeProductRequest === undefined) {
            throw new runtime.RequiredError('updateStripeProductRequest', 'Required parameter requestParameters.updateStripeProductRequest was null or undefined when calling updateStripeProduct.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/admin/commerce/v1/stripe/product/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateStripeProductRequestToJSON(requestParameters.updateStripeProductRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateStripeProductResponseFromJSON(jsonValue));
    }
    /**
     * 상품을 수정한다. 확정된 상품은 수정이 불가능하다.
     */
    async updateStripeProduct(requestParameters, initOverrides) {
        const response = await this.updateStripeProductRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
