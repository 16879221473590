import ja from 'admin/app/locales/ja-JP/translation.json';
import ko from 'admin/app/locales/ko-KR/translation.json';

export default {
  ko: {translation: ko},
  ja: {translation: ja},
};

declare module 'i18next' {
  export interface CustomTypeOptions {
    resources: {
      translation: {[key in keyof typeof ko & keyof typeof ja]: string};
    };
  }
}
