/* tslint:disable */
/* eslint-disable */
import * as runtime from '../runtime';
import { CountAvailableQuestionContentsRequestToJSON, CountAvailableQuestionContentsResponseFromJSON, GetAvailableKeysForQuestionContentsResponseFromJSON, } from '../models';
/**
 *
 */
export class SelfLearningQuestionContentsAdminV1Api extends runtime.BaseAPI {
    /**
     * 주어진 쿼리의 조건을 만족하는 문제 콘텐츠의 개수를 센다. 콘텐츠를 셀 때 요청 페이로드의 쿼리 이외에도 아래 조건이 추가로 고려된다:  - 콘텐츠의 학습 도메인 및 로케일 값이 현재 어드민 사용자의 학습 도메인 및 국가와 합치한다. - 콘텐츠의 상태가 `PUBLISHED`이다.
     * 주어진 쿼리로 선택학습 문제에서 제공 가능한 문제의 개수를 센다.
     */
    async countAvailableQuestionContentsRaw(requestParameters, initOverrides) {
        if (requestParameters.countAvailableQuestionContentsRequest === null || requestParameters.countAvailableQuestionContentsRequest === undefined) {
            throw new runtime.RequiredError('countAvailableQuestionContentsRequest', 'Required parameter requestParameters.countAvailableQuestionContentsRequest was null or undefined when calling countAvailableQuestionContents.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/admin/content-learning/v1/self-learning/question-contents/count`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CountAvailableQuestionContentsRequestToJSON(requestParameters.countAvailableQuestionContentsRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => CountAvailableQuestionContentsResponseFromJSON(jsonValue));
    }
    /**
     * 주어진 쿼리의 조건을 만족하는 문제 콘텐츠의 개수를 센다. 콘텐츠를 셀 때 요청 페이로드의 쿼리 이외에도 아래 조건이 추가로 고려된다:  - 콘텐츠의 학습 도메인 및 로케일 값이 현재 어드민 사용자의 학습 도메인 및 국가와 합치한다. - 콘텐츠의 상태가 `PUBLISHED`이다.
     * 주어진 쿼리로 선택학습 문제에서 제공 가능한 문제의 개수를 센다.
     */
    async countAvailableQuestionContents(requestParameters, initOverrides) {
        const response = await this.countAvailableQuestionContentsRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * `QUESTION` 타입의 `SelfLearningUnit`의 `contentQuery`를 설정할 때  `SelfLearningUnitContentConditionEntry.key`의 값으로 가능한 모든 값을 반환한다.  학습 도메인 별로 응답이 달라질 수 있다.
     * 문제 타입의 선택학습 유닛의 콘텐츠 조건으로 설정 가능한 키 목록을 반환한다.
     */
    async getAvailableKeysForQuestionContentsRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/admin/content-learning/v1/self-learning/question-contents/keys`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GetAvailableKeysForQuestionContentsResponseFromJSON(jsonValue));
    }
    /**
     * `QUESTION` 타입의 `SelfLearningUnit`의 `contentQuery`를 설정할 때  `SelfLearningUnitContentConditionEntry.key`의 값으로 가능한 모든 값을 반환한다.  학습 도메인 별로 응답이 달라질 수 있다.
     * 문제 타입의 선택학습 유닛의 콘텐츠 조건으로 설정 가능한 키 목록을 반환한다.
     */
    async getAvailableKeysForQuestionContents(requestParameters, initOverrides) {
        const response = await this.getAvailableKeysForQuestionContentsRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
