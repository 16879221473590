import {useQuery} from '@tanstack/react-query';
import {useAtomValue} from 'jotai';

import {LearningDomainListItem} from '@santa-web/gen/open-api/admin/models/LearningDomainListItem';
import {tokenAtom} from '#app/atoms/token';
import {useHttpClient} from '#app/components/SantaAdminHttpClientGuard';

export const useListLearningDomains = () => {
  const token = useAtomValue(tokenAtom);
  const {LearningDomainService} = useHttpClient({headers: {Authorization: `Bearer ${token}`}});

  return useQuery<LearningDomainListItem[]>({
    queryKey: ['listLearningDomains'],
    enabled: token !== null,
    staleTime: Infinity,
    cacheTime: Infinity,
    queryFn: async () => {
      const {learningDomains} = await LearningDomainService.listLearningDomains({size: 100});
      return learningDomains;
    },
  });
};
