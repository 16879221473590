/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { DisplayInfoFromJSON, DisplayInfoToJSON, } from './DisplayInfo';
import { TicketTemplateManagementInfoFromJSON, TicketTemplateManagementInfoToJSON, } from './TicketTemplateManagementInfo';
/**
 * Check if a given object implements the UpdateTicketTemplateRequest interface.
 */
export function instanceOfUpdateTicketTemplateRequest(value) {
    let isInstance = true;
    return isInstance;
}
export function UpdateTicketTemplateRequestFromJSON(json) {
    return UpdateTicketTemplateRequestFromJSONTyped(json, false);
}
export function UpdateTicketTemplateRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'expirationInDays': !exists(json, 'expirationInDays') ? undefined : json['expirationInDays'],
        'permitId': !exists(json, 'permitId') ? undefined : json['permitId'],
        'displayInfo': !exists(json, 'displayInfo') ? undefined : DisplayInfoFromJSON(json['displayInfo']),
        'managementInfo': !exists(json, 'managementInfo') ? undefined : TicketTemplateManagementInfoFromJSON(json['managementInfo']),
    };
}
export function UpdateTicketTemplateRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'expirationInDays': value.expirationInDays,
        'permitId': value.permitId,
        'displayInfo': DisplayInfoToJSON(value.displayInfo),
        'managementInfo': TicketTemplateManagementInfoToJSON(value.managementInfo),
    };
}
