// @ts-nocheck
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../runtime/json/scalar";
import { default as serialize, } from "../../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../../runtime/wire/scalar";
import { default as deserialize, } from "../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        x1Id: "0",
        x2Id: "0",
        x3Id: "0",
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.x1Id !== undefined)
        result.x1Id = tsValueToJsonValueFns.int64(value.x1Id);
    if (value.x2Id !== undefined)
        result.x2Id = tsValueToJsonValueFns.int64(value.x2Id);
    if (value.x3Id !== undefined)
        result.x3Id = tsValueToJsonValueFns.int64(value.x3Id);
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.x1Id !== undefined)
        result.x1Id = jsonValueToTsValueFns.int64(value.x1Id);
    if (value.x2Id !== undefined)
        result.x2Id = jsonValueToTsValueFns.int64(value.x2Id);
    if (value.x3Id !== undefined)
        result.x3Id = jsonValueToTsValueFns.int64(value.x3Id);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.x1Id !== undefined) {
        const tsValue = value.x1Id;
        result.push([1, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.x2Id !== undefined) {
        const tsValue = value.x2Id;
        result.push([2, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.x3Id !== undefined) {
        const tsValue = value.x3Id;
        result.push([3, tsValueToWireValueFns.int64(tsValue)]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.x1Id = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.x2Id = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.x3Id = value;
    }
    return result;
}
