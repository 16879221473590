/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { SelfLearningContentTypeFromJSON, SelfLearningContentTypeToJSON, } from './SelfLearningContentType';
/**
 * Check if a given object implements the UpdateSelfLearningUnitGroupRequest interface.
 */
export function instanceOfUpdateSelfLearningUnitGroupRequest(value) {
    let isInstance = true;
    isInstance = isInstance && "detailDescription" in value;
    isInstance = isInstance && "tagList" in value;
    isInstance = isInstance && "subTitle" in value;
    isInstance = isInstance && "backgroundImageResourceId" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "contentType" in value;
    return isInstance;
}
export function UpdateSelfLearningUnitGroupRequestFromJSON(json) {
    return UpdateSelfLearningUnitGroupRequestFromJSONTyped(json, false);
}
export function UpdateSelfLearningUnitGroupRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'detailDescription': json['detailDescription'],
        'tagList': json['tagList'],
        'subTitle': json['subTitle'],
        'backgroundImageResourceId': json['backgroundImageResourceId'],
        'id': json['id'],
        'title': json['title'],
        'contentType': SelfLearningContentTypeFromJSON(json['contentType']),
        'groupFilterId': !exists(json, 'groupFilterId') ? undefined : json['groupFilterId'],
    };
}
export function UpdateSelfLearningUnitGroupRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'detailDescription': value.detailDescription,
        'tagList': value.tagList,
        'subTitle': value.subTitle,
        'backgroundImageResourceId': value.backgroundImageResourceId,
        'id': value.id,
        'title': value.title,
        'contentType': SelfLearningContentTypeToJSON(value.contentType),
        'groupFilterId': value.groupFilterId,
    };
}
