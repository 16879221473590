export const num2name = {
    0: "WITHDRAW_REASON_UNSPECIFIED",
    1: "TIRED",
    2: "UNUSEFUL",
};
export const name2num = {
    WITHDRAW_REASON_UNSPECIFIED: 0,
    TIRED: 1,
    UNUSEFUL: 2,
};
