/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { PeriodFromJSON, PeriodToJSON, } from './Period';
import { ThumbnailFgImageAlignmentFromJSON, ThumbnailFgImageAlignmentToJSON, } from './ThumbnailFgImageAlignment';
import { TimerConfigFromJSON, TimerConfigToJSON, } from './TimerConfig';
/**
 * Check if a given object implements the DisplayConfig interface.
 */
export function instanceOfDisplayConfig(value) {
    let isInstance = true;
    isInstance = isInstance && "thumbnailBgImageSetId" in value;
    isInstance = isInstance && "thumbnailFgImageSetId" in value;
    isInstance = isInstance && "displayPeriod" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "cautionLink" in value;
    isInstance = isInstance && "detailImageSetIds" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "thumbnailFgImageAlignment" in value;
    return isInstance;
}
export function DisplayConfigFromJSON(json) {
    return DisplayConfigFromJSONTyped(json, false);
}
export function DisplayConfigFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'thumbnailBgImageSetId': json['thumbnailBgImageSetId'],
        'timerConfig': !exists(json, 'timerConfig') ? undefined : TimerConfigFromJSON(json['timerConfig']),
        'thumbnailFgImageSetId': json['thumbnailFgImageSetId'],
        'displayPeriod': PeriodFromJSON(json['displayPeriod']),
        'description': json['description'],
        'cautionLink': json['cautionLink'],
        'detailImageSetIds': json['detailImageSetIds'],
        'title': json['title'],
        'thumbnailFgImageAlignment': ThumbnailFgImageAlignmentFromJSON(json['thumbnailFgImageAlignment']),
    };
}
export function DisplayConfigToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'thumbnailBgImageSetId': value.thumbnailBgImageSetId,
        'timerConfig': TimerConfigToJSON(value.timerConfig),
        'thumbnailFgImageSetId': value.thumbnailFgImageSetId,
        'displayPeriod': PeriodToJSON(value.displayPeriod),
        'description': value.description,
        'cautionLink': value.cautionLink,
        'detailImageSetIds': value.detailImageSetIds,
        'title': value.title,
        'thumbnailFgImageAlignment': ThumbnailFgImageAlignmentToJSON(value.thumbnailFgImageAlignment),
    };
}
