/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { AgreementInfoFromJSON, AgreementInfoToJSON, } from './AgreementInfo';
import { AuthorityStatusByDomainItemFromJSON, AuthorityStatusByDomainItemToJSON, } from './AuthorityStatusByDomainItem';
import { ContactFromJSON, ContactToJSON, } from './Contact';
import { HasPurchaseExperienceByDomainItemFromJSON, HasPurchaseExperienceByDomainItemToJSON, } from './HasPurchaseExperienceByDomainItem';
import { UserActivityFromJSON, UserActivityToJSON, } from './UserActivity';
import { UserRegistrationTypeFromJSON, UserRegistrationTypeToJSON, } from './UserRegistrationType';
import { UserStatusFromJSON, UserStatusToJSON, } from './UserStatus';
/**
 * Check if a given object implements the UserDetail interface.
 */
export function instanceOfUserDetail(value) {
    let isInstance = true;
    isInstance = isInstance && "authorityStatusByLearningDomain" in value;
    isInstance = isInstance && "hasPurchaseExperienceByLearningDomain" in value;
    isInstance = isInstance && "locale" in value;
    isInstance = isInstance && "authId" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "registrationType" in value;
    isInstance = isInstance && "contact" in value;
    isInstance = isInstance && "agreementInfo" in value;
    isInstance = isInstance && "lastActivity" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "status" in value;
    return isInstance;
}
export function UserDetailFromJSON(json) {
    return UserDetailFromJSONTyped(json, false);
}
export function UserDetailFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'authorityStatusByLearningDomain': (json['authorityStatusByLearningDomain'].map(AuthorityStatusByDomainItemFromJSON)),
        'withdrawReason': !exists(json, 'withdrawReason') ? undefined : json['withdrawReason'],
        'registeredAt': !exists(json, 'registeredAt') ? undefined : (new Date(json['registeredAt'])),
        'hasPurchaseExperienceByLearningDomain': (json['hasPurchaseExperienceByLearningDomain'].map(HasPurchaseExperienceByDomainItemFromJSON)),
        'locale': json['locale'],
        'authId': json['authId'],
        'createdAt': (new Date(json['createdAt'])),
        'lastSelectedLearningDomainId': !exists(json, 'lastSelectedLearningDomainId') ? undefined : json['lastSelectedLearningDomainId'],
        'registrationType': UserRegistrationTypeFromJSON(json['registrationType']),
        'contact': ContactFromJSON(json['contact']),
        'agreementInfo': AgreementInfoFromJSON(json['agreementInfo']),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'lastActivity': UserActivityFromJSON(json['lastActivity']),
        'id': json['id'],
        'status': UserStatusFromJSON(json['status']),
    };
}
export function UserDetailToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'authorityStatusByLearningDomain': (value.authorityStatusByLearningDomain.map(AuthorityStatusByDomainItemToJSON)),
        'withdrawReason': value.withdrawReason,
        'registeredAt': value.registeredAt === undefined ? undefined : (value.registeredAt.toISOString()),
        'hasPurchaseExperienceByLearningDomain': (value.hasPurchaseExperienceByLearningDomain.map(HasPurchaseExperienceByDomainItemToJSON)),
        'locale': value.locale,
        'authId': value.authId,
        'createdAt': (value.createdAt.toISOString()),
        'lastSelectedLearningDomainId': value.lastSelectedLearningDomainId,
        'registrationType': UserRegistrationTypeToJSON(value.registrationType),
        'contact': ContactToJSON(value.contact),
        'agreementInfo': AgreementInfoToJSON(value.agreementInfo),
        'name': value.name,
        'lastActivity': UserActivityToJSON(value.lastActivity),
        'id': value.id,
        'status': UserStatusToJSON(value.status),
    };
}
