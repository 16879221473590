/* tslint:disable */
/* eslint-disable */
/**
 *
 * @export
 */
export const CountingType = {
    DAILY: 'DAILY',
    NONE: 'NONE'
};
export function CountingTypeFromJSON(json) {
    return CountingTypeFromJSONTyped(json, false);
}
export function CountingTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function CountingTypeToJSON(value) {
    return value;
}
