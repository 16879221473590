interface Config {
  hostname: string;
  httpHostname: string;
  accessTokenOrigin: string;
  cmsUrl: string;
}

export type Env = 'production' | 'staging' | 'development' | 'preview';

let environment: Env;
let config: Config;

if (process.env.NOW_ENV === 'production') {
  environment = 'production';
  config = {
    hostname: 'https://toeic-admin.prod.riiid.cloud:31400',
    httpHostname: 'https://toeic-admin-http-api.prod.riiid.cloud',
    accessTokenOrigin: 'https://toeic-admin.prod.riiid.cloud',
    cmsUrl: 'https://santa-cms.prod.riiid.cloud/',
  };
} else if (process.env.NOW_ENV === 'staging') {
  environment = 'staging';
  config = {
    hostname: 'https://toeic-admin.stg.riiid.cloud:31400',
    httpHostname: 'https://toeic-admin-http-api.stg.riiid.cloud',
    accessTokenOrigin: 'https://toeic-admin.stg.riiid.cloud',
    cmsUrl: 'https://santa-cms.stg.riiid.cloud/',
  };
} else if (process.env.NOW_ENV === 'development') {
  environment = 'development';
  config = {
    hostname: 'https://toeic-admin.dev.riiid.cloud:31400',
    httpHostname: 'https://toeic-admin-http-api.dev.riiid.cloud',
    accessTokenOrigin: 'https://toeic-admin.dev.riiid.cloud',
    cmsUrl: 'https://santa-cms.dev.riiid.cloud/',
  };
} else {
  environment = 'preview';
  config = {
    hostname: 'https://toeic-admin.dev.riiid.cloud:31400',
    httpHostname: 'https://toeic-admin-http-api.dev.riiid.cloud',
    accessTokenOrigin: 'https://toeic-admin.dev.riiid.cloud',
    cmsUrl: 'https://santa-cms.dev.riiid.cloud/',
  };
}

export const env = environment;

export type VercelEnv = 'development' | 'preview' | 'production';

let vercelEnvironment: VercelEnv;

if (
  process.env.VERCEL_ENV === 'development' ||
  process.env.VERCEL_ENV === 'preview' ||
  process.env.VERCEL_ENV === 'production'
) {
  vercelEnvironment = process.env.VERCEL_ENV;
} else {
  vercelEnvironment = 'development';
}

export const vercelEnv = vercelEnvironment;

export const SENTRY_DSN = 'https://cda1fbf654f24f5e8250570a61a2b881@o314078.ingest.sentry.io/5836360';

export const enableProdDevtools = process.env.ENABLE_PROD_DEVTOOLS;

export default config;
