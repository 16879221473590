/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
/**
 * Check if a given object implements the SelfLearningUnitMetadata interface.
 */
export function instanceOfSelfLearningUnitMetadata(value) {
    let isInstance = true;
    return isInstance;
}
export function SelfLearningUnitMetadataFromJSON(json) {
    return SelfLearningUnitMetadataFromJSONTyped(json, false);
}
export function SelfLearningUnitMetadataFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'unitFilterId': !exists(json, 'unitFilterId') ? undefined : json['unitFilterId'],
        'unitGroupId': !exists(json, 'unitGroupId') ? undefined : json['unitGroupId'],
    };
}
export function SelfLearningUnitMetadataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'unitFilterId': value.unitFilterId,
        'unitGroupId': value.unitGroupId,
    };
}
