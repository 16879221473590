import { StripeProductFromJSON, StripeProductToJSON, } from './StripeProduct';
import { StripeSubscriptionOrderStatusFromJSON, StripeSubscriptionOrderStatusToJSON, } from './StripeSubscriptionOrderStatus';
/**
 * Check if a given object implements the StripeSubscriptionOrder interface.
 */
export function instanceOfStripeSubscriptionOrder(value) {
    let isInstance = true;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "product" in value;
    isInstance = isInstance && "checkoutSessionId" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "userId" in value;
    isInstance = isInstance && "authId" in value;
    isInstance = isInstance && "status" in value;
    return isInstance;
}
export function StripeSubscriptionOrderFromJSON(json) {
    return StripeSubscriptionOrderFromJSONTyped(json, false);
}
export function StripeSubscriptionOrderFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'createdAt': (new Date(json['createdAt'])),
        'product': StripeProductFromJSON(json['product']),
        'checkoutSessionId': json['checkoutSessionId'],
        'id': json['id'],
        'userId': json['userId'],
        'authId': json['authId'],
        'status': StripeSubscriptionOrderStatusFromJSON(json['status']),
    };
}
export function StripeSubscriptionOrderToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'createdAt': (value.createdAt.toISOString()),
        'product': StripeProductToJSON(value.product),
        'checkoutSessionId': value.checkoutSessionId,
        'id': value.id,
        'userId': value.userId,
        'authId': value.authId,
        'status': StripeSubscriptionOrderStatusToJSON(value.status),
    };
}
