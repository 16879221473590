export const num2name = {
    0: "ERROR_UNSPECIFIED",
    1: "ERROR_NOT_FOUND",
    2: "ERROR_ALREADY_ENDED",
    3: "ERROR_NOT_STARTED",
};
export const name2num = {
    ERROR_UNSPECIFIED: 0,
    ERROR_NOT_FOUND: 1,
    ERROR_ALREADY_ENDED: 2,
    ERROR_NOT_STARTED: 3,
};
