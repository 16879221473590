import { LanguageFromJSON, LanguageToJSON, } from './Language';
/**
 * Check if a given object implements the LearningDomainStatus interface.
 */
export function instanceOfLearningDomainStatus(value) {
    let isInstance = true;
    isInstance = isInstance && "visibility" in value;
    isInstance = isInstance && "language" in value;
    isInstance = isInstance && "availability" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "isInFreePromotion" in value;
    return isInstance;
}
export function LearningDomainStatusFromJSON(json) {
    return LearningDomainStatusFromJSONTyped(json, false);
}
export function LearningDomainStatusFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'visibility': json['visibility'],
        'language': LanguageFromJSON(json['language']),
        'availability': json['availability'],
        'id': json['id'],
        'isInFreePromotion': json['isInFreePromotion'],
    };
}
export function LearningDomainStatusToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'visibility': value.visibility,
        'language': LanguageToJSON(value.language),
        'availability': value.availability,
        'id': value.id,
        'isInFreePromotion': value.isInFreePromotion,
    };
}
