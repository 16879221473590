function _array_like_to_array(arr, len) {
    if (len == null || len > arr.length) len = arr.length;
    for(var i = 0, arr2 = new Array(len); i < len; i++)arr2[i] = arr[i];
    return arr2;
}
function _array_with_holes(arr) {
    if (Array.isArray(arr)) return arr;
}
function _iterable_to_array_limit(arr, i) {
    var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"];
    if (_i == null) return;
    var _arr = [];
    var _n = true;
    var _d = false;
    var _s, _e;
    try {
        for(_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true){
            _arr.push(_s.value);
            if (i && _arr.length === i) break;
        }
    } catch (err) {
        _d = true;
        _e = err;
    } finally{
        try {
            if (!_n && _i["return"] != null) _i["return"]();
        } finally{
            if (_d) throw _e;
        }
    }
    return _arr;
}
function _non_iterable_rest() {
    throw new TypeError("Invalid attempt to destructure non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _sliced_to_array(arr, i) {
    return _array_with_holes(arr) || _iterable_to_array_limit(arr, i) || _unsupported_iterable_to_array(arr, i) || _non_iterable_rest();
}
function _unsupported_iterable_to_array(o, minLen) {
    if (!o) return;
    if (typeof o === "string") return _array_like_to_array(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === "Object" && o.constructor) n = o.constructor.name;
    if (n === "Map" || n === "Set") return Array.from(n);
    if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _array_like_to_array(o, minLen);
}
var regexMap = {
    years: /[0-9]* years/,
    months: /[0-9]* mons/,
    days: /[0-9]* days/,
    hours: /[0-9]* hours/,
    minutes: /[0-9]* mins/,
    seconds: /[0-9]* secs/
};
var getCount = function(value, regex) {
    var parsed = regex.exec(value);
    if (!parsed) return 0;
    var _parsed = _sliced_to_array(parsed, 1), target = _parsed[0];
    return parseInt(target.split(" ")[0]);
};
/**
 * @param value - offset string. example - '5 years 2 mons 1 days 3 hours 2 mins 4 secs'
 * https://github.com/pgjdbc/r2dbc-postgresql/blob/312dafc2443e4e5482c7789e20e666a0b2ba3214/src/main/java/io/r2dbc/postgresql/codec/Interval.java#L667
 */ export var parseTimeOffsetString = function(value) {
    return {
        years: getCount(value, regexMap.years),
        months: getCount(value, regexMap.months),
        days: getCount(value, regexMap.days),
        hours: getCount(value, regexMap.hours),
        minutes: getCount(value, regexMap.minutes),
        seconds: getCount(value, regexMap.seconds)
    };
};
