import { StripeProductListItemFromJSON, StripeProductListItemToJSON, } from './StripeProductListItem';
/**
 * Check if a given object implements the ListStripeProductsResponse interface.
 */
export function instanceOfListStripeProductsResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "total" in value;
    isInstance = isInstance && "products" in value;
    return isInstance;
}
export function ListStripeProductsResponseFromJSON(json) {
    return ListStripeProductsResponseFromJSONTyped(json, false);
}
export function ListStripeProductsResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'total': json['total'],
        'products': (json['products'].map(StripeProductListItemFromJSON)),
    };
}
export function ListStripeProductsResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'total': value.total,
        'products': (value.products.map(StripeProductListItemToJSON)),
    };
}
