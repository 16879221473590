// @ts-nocheck
import { name2num, num2name, } from "../../../virtual_exam/(ToeicVirtualExam)/Status";
import { name2num as name2num_1, num2name as num2name_1, } from "../../../virtual_exam/(ToeicVirtualExam)/Type";
import { name2num as name2num_2, num2name as num2name_2, } from "../../../../inside/Country";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../../../runtime/json/scalar";
import { WireType, } from "../../../../../runtime/wire/index";
import { default as serialize, } from "../../../../../runtime/wire/serialize";
import { default as Long, } from "../../../../../runtime/Long";
import { encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "../../../../google/protobuf/StringValue";
import { default as deserialize, } from "../../../../../runtime/wire/deserialize";
import { unpackFns, } from "../../../../../runtime/wire/scalar";
export function getDefaultValue() {
    return {
        status: [],
        type: [],
        country: [],
        contentGroupId: undefined,
        titleLike: undefined,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    result.status = value.status.map(value => tsValueToJsonValueFns.enum(value));
    result.type = value.type.map(value => tsValueToJsonValueFns.enum(value));
    result.country = value.country.map(value => tsValueToJsonValueFns.enum(value));
    if (value.contentGroupId !== undefined)
        result.contentGroupId = value.contentGroupId;
    if (value.titleLike !== undefined)
        result.titleLike = value.titleLike;
    return result;
}
export function decodeJson(value) {
    var _a, _b, _c, _d, _e, _f;
    const result = getDefaultValue();
    result.status = (_b = (_a = value.status) === null || _a === void 0 ? void 0 : _a.map((value) => jsonValueToTsValueFns.enum(value))) !== null && _b !== void 0 ? _b : [];
    result.type = (_d = (_c = value.type) === null || _c === void 0 ? void 0 : _c.map((value) => jsonValueToTsValueFns.enum(value))) !== null && _d !== void 0 ? _d : [];
    result.country = (_f = (_e = value.country) === null || _e === void 0 ? void 0 : _e.map((value) => jsonValueToTsValueFns.enum(value))) !== null && _f !== void 0 ? _f : [];
    if (value.contentGroupId !== undefined)
        result.contentGroupId = value.contentGroupId;
    if (value.titleLike !== undefined)
        result.titleLike = value.titleLike;
    return result;
}
export function encodeBinary(value) {
    const result = [];
    for (const tsValue of value.status) {
        result.push([1, { type: WireType.Varint, value: new Long(name2num[tsValue]) }]);
    }
    for (const tsValue of value.type) {
        result.push([2, { type: WireType.Varint, value: new Long(name2num_1[tsValue]) }]);
    }
    for (const tsValue of value.country) {
        result.push([3, { type: WireType.Varint, value: new Long(name2num_2[tsValue]) }]);
    }
    if (value.contentGroupId !== undefined) {
        const tsValue = value.contentGroupId;
        result.push([4, ((tsValue) => ({ type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }))({ value: tsValue })]);
    }
    if (value.titleLike !== undefined) {
        const tsValue = value.titleLike;
        result.push([5, ((tsValue) => ({ type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }))({ value: tsValue })]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    var _a, _b;
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 1).map(([, wireValue]) => wireValue);
        const value = Array.from(unpackFns.int32(wireValues)).map(num => num2name[num]);
        if (!value.length)
            break collection;
        result.status = value;
    }
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 2).map(([, wireValue]) => wireValue);
        const value = Array.from(unpackFns.int32(wireValues)).map(num => num2name_1[num]);
        if (!value.length)
            break collection;
        result.type = value;
    }
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 3).map(([, wireValue]) => wireValue);
        const value = Array.from(unpackFns.int32(wireValues)).map(num => num2name_2[num]);
        if (!value.length)
            break collection;
        result.country = value;
    }
    field: {
        const wireValue = wireFields.get(4);
        if (wireValue === undefined)
            break field;
        const value = (_a = (wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined)) === null || _a === void 0 ? void 0 : _a.value;
        if (value === undefined)
            break field;
        result.contentGroupId = value;
    }
    field: {
        const wireValue = wireFields.get(5);
        if (wireValue === undefined)
            break field;
        const value = (_b = (wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined)) === null || _b === void 0 ? void 0 : _b.value;
        if (value === undefined)
            break field;
        result.titleLike = value;
    }
    return result;
}
