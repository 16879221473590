/**
 * Check if a given object implements the SetDefaultPermitsRequest interface.
 */
export function instanceOfSetDefaultPermitsRequest(value) {
    let isInstance = true;
    isInstance = isInstance && "permitIds" in value;
    return isInstance;
}
export function SetDefaultPermitsRequestFromJSON(json) {
    return SetDefaultPermitsRequestFromJSONTyped(json, false);
}
export function SetDefaultPermitsRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'permitIds': json['permitIds'],
    };
}
export function SetDefaultPermitsRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'permitIds': value.permitIds,
    };
}
