/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
/**
 * Check if a given object implements the SetOnboardingPassTemplateResponse interface.
 */
export function instanceOfSetOnboardingPassTemplateResponse(value) {
    let isInstance = true;
    return isInstance;
}
export function SetOnboardingPassTemplateResponseFromJSON(json) {
    return SetOnboardingPassTemplateResponseFromJSONTyped(json, false);
}
export function SetOnboardingPassTemplateResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'onboardingPassTemplateId': !exists(json, 'onboardingPassTemplateId') ? undefined : json['onboardingPassTemplateId'],
    };
}
export function SetOnboardingPassTemplateResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'onboardingPassTemplateId': value.onboardingPassTemplateId,
    };
}
