export const num2name = {
    0: "CELL_TYPE_UNSPECIFIED",
    1: "QUESTION",
    2: "LESSON",
    3: "VOCABULARY",
    4: "REVIEW",
    5: "MOCK_EXAM",
};
export const name2num = {
    CELL_TYPE_UNSPECIFIED: 0,
    QUESTION: 1,
    LESSON: 2,
    VOCABULARY: 3,
    REVIEW: 4,
    MOCK_EXAM: 5,
};
