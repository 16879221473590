// @ts-nocheck
import { name2num, num2name, } from "./(Product)/DurationType";
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "./Goods";
import { encodeJson as encodeJson_2, decodeJson as decodeJson_2, encodeBinary as encodeBinary_2, decodeBinary as decodeBinary_2, } from "./ProductSelectOption";
import { encodeJson as encodeJson_3, decodeJson as decodeJson_3, encodeBinary as encodeBinary_3, decodeBinary as decodeBinary_3, } from "./(Product)/SaleConfig";
import { encodeJson as encodeJson_4, decodeJson as decodeJson_4, encodeBinary as encodeBinary_4, decodeBinary as decodeBinary_4, } from "./(Product)/PaymentConfig";
import { encodeJson as encodeJson_5, decodeJson as decodeJson_5, encodeBinary as encodeBinary_5, decodeBinary as decodeBinary_5, } from "./(Product)/DisplayConfig";
import { name2num as name2num_1, num2name as num2name_1, } from "./(Product)/ProductCategory";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../runtime/json/scalar";
import { WireType, } from "../../../runtime/wire/index";
import { default as serialize, } from "../../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../../runtime/wire/scalar";
import { default as Long, } from "../../../runtime/Long";
import { encodeBinary as encodeBinary_6, decodeBinary as decodeBinary_6, } from "../../google/protobuf/Int64Value";
import { default as deserialize, } from "../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        id: "0",
        durationType: "DURATION_TYPE_UNSPECIFIED",
        defaultGoods: [],
        selectOptions: [],
        saleConfig: undefined,
        paymentConfig: undefined,
        displayConfig: undefined,
        isPublished: false,
        category: "PRODUCT_CATEGORY_UNSPECIFIED",
        durationAmount: undefined,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    var _a;
    const result = {};
    if (value.id !== undefined)
        result.id = tsValueToJsonValueFns.int64(value.id);
    if (value.durationType !== undefined)
        result.durationType = tsValueToJsonValueFns.enum(value.durationType);
    result.defaultGoods = value.defaultGoods.map(value => encodeJson_1(value));
    result.selectOptions = value.selectOptions.map(value => encodeJson_2(value));
    if (value.saleConfig !== undefined)
        result.saleConfig = encodeJson_3(value.saleConfig);
    if (value.paymentConfig !== undefined)
        result.paymentConfig = encodeJson_4(value.paymentConfig);
    if (value.displayConfig !== undefined)
        result.displayConfig = encodeJson_5(value.displayConfig);
    if (value.isPublished !== undefined)
        result.isPublished = tsValueToJsonValueFns.bool(value.isPublished);
    if (value.category !== undefined)
        result.category = tsValueToJsonValueFns.enum(value.category);
    switch ((_a = value.durationAmount) === null || _a === void 0 ? void 0 : _a.field) {
        case "days": {
            result.days = value.durationAmount.value;
            break;
        }
        case "months": {
            result.months = value.durationAmount.value;
            break;
        }
    }
    return result;
}
export function decodeJson(value) {
    var _a, _b, _c, _d;
    const result = getDefaultValue();
    if (value.id !== undefined)
        result.id = jsonValueToTsValueFns.int64(value.id);
    if (value.durationType !== undefined)
        result.durationType = jsonValueToTsValueFns.enum(value.durationType);
    result.defaultGoods = (_b = (_a = value.defaultGoods) === null || _a === void 0 ? void 0 : _a.map((value) => decodeJson_1(value))) !== null && _b !== void 0 ? _b : [];
    result.selectOptions = (_d = (_c = value.selectOptions) === null || _c === void 0 ? void 0 : _c.map((value) => decodeJson_2(value))) !== null && _d !== void 0 ? _d : [];
    if (value.saleConfig !== undefined)
        result.saleConfig = decodeJson_3(value.saleConfig);
    if (value.paymentConfig !== undefined)
        result.paymentConfig = decodeJson_4(value.paymentConfig);
    if (value.displayConfig !== undefined)
        result.displayConfig = decodeJson_5(value.displayConfig);
    if (value.isPublished !== undefined)
        result.isPublished = jsonValueToTsValueFns.bool(value.isPublished);
    if (value.category !== undefined)
        result.category = jsonValueToTsValueFns.enum(value.category);
    if (value.days !== undefined)
        result.durationAmount = { field: "days", value: value.days };
    if (value.months !== undefined)
        result.durationAmount = { field: "months", value: value.months };
    return result;
}
export function encodeBinary(value) {
    var _a;
    const result = [];
    if (value.id !== undefined) {
        const tsValue = value.id;
        result.push([1, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.durationType !== undefined) {
        const tsValue = value.durationType;
        result.push([2, { type: WireType.Varint, value: new Long(name2num[tsValue]) }]);
    }
    for (const tsValue of value.defaultGoods) {
        result.push([4, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    for (const tsValue of value.selectOptions) {
        result.push([5, { type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }]);
    }
    if (value.saleConfig !== undefined) {
        const tsValue = value.saleConfig;
        result.push([6, { type: WireType.LengthDelimited, value: encodeBinary_3(tsValue) }]);
    }
    if (value.paymentConfig !== undefined) {
        const tsValue = value.paymentConfig;
        result.push([7, { type: WireType.LengthDelimited, value: encodeBinary_4(tsValue) }]);
    }
    if (value.displayConfig !== undefined) {
        const tsValue = value.displayConfig;
        result.push([8, { type: WireType.LengthDelimited, value: encodeBinary_5(tsValue) }]);
    }
    if (value.isPublished !== undefined) {
        const tsValue = value.isPublished;
        result.push([9, tsValueToWireValueFns.bool(tsValue)]);
    }
    if (value.category !== undefined) {
        const tsValue = value.category;
        result.push([10, { type: WireType.Varint, value: new Long(name2num_1[tsValue]) }]);
    }
    switch ((_a = value.durationAmount) === null || _a === void 0 ? void 0 : _a.field) {
        case "days": {
            const tsValue = value.durationAmount.value;
            result.push([3, ((tsValue) => ({ type: WireType.LengthDelimited, value: encodeBinary_6(tsValue) }))({ value: tsValue })]);
            break;
        }
        case "months": {
            const tsValue = value.durationAmount.value;
            result.push([11, ((tsValue) => ({ type: WireType.LengthDelimited, value: encodeBinary_6(tsValue) }))({ value: tsValue })]);
            break;
        }
    }
    return serialize(result);
}
const oneofFieldNumbersMap = {
    durationAmount: new Set([3, 11]),
};
const oneofFieldNamesMap = {
    durationAmount: new Map([
        [3, "days"],
        [11, "months"],
    ]),
};
export function decodeBinary(binary) {
    var _a;
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    const wireFieldNumbers = Array.from(wireFields.keys()).reverse();
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.id = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.durationType = value;
    }
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 4).map(([, wireValue]) => wireValue);
        const value = wireValues.map((wireValue) => wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined).filter(x => x !== undefined);
        if (!value.length)
            break collection;
        result.defaultGoods = value;
    }
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 5).map(([, wireValue]) => wireValue);
        const value = wireValues.map((wireValue) => wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined).filter(x => x !== undefined);
        if (!value.length)
            break collection;
        result.selectOptions = value;
    }
    field: {
        const wireValue = wireFields.get(6);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_3(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.saleConfig = value;
    }
    field: {
        const wireValue = wireFields.get(7);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_4(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.paymentConfig = value;
    }
    field: {
        const wireValue = wireFields.get(8);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_5(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.displayConfig = value;
    }
    field: {
        const wireValue = wireFields.get(9);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.bool(wireValue);
        if (value === undefined)
            break field;
        result.isPublished = value;
    }
    field: {
        const wireValue = wireFields.get(10);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name_1[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.category = value;
    }
    oneof: {
        const oneofFieldNumbers = oneofFieldNumbersMap.durationAmount;
        const oneofFieldNames = oneofFieldNamesMap.durationAmount;
        const fieldNumber = wireFieldNumbers.find(v => oneofFieldNumbers.has(v));
        if (fieldNumber == null)
            break oneof;
        const wireValue = wireFields.get(fieldNumber);
        const wireValueToTsValueMap = {
            [3](wireValue) { var _a; return (_a = (wireValue.type === WireType.LengthDelimited ? decodeBinary_6(wireValue.value) : undefined)) === null || _a === void 0 ? void 0 : _a.value; },
            [11](wireValue) { var _a; return (_a = (wireValue.type === WireType.LengthDelimited ? decodeBinary_6(wireValue.value) : undefined)) === null || _a === void 0 ? void 0 : _a.value; },
        };
        const value = (_a = wireValueToTsValueMap[fieldNumber]) === null || _a === void 0 ? void 0 : _a.call(wireValueToTsValueMap, wireValue);
        if (value === undefined)
            break oneof;
        result.durationAmount = { field: oneofFieldNames.get(fieldNumber), value: value };
    }
    return result;
}
