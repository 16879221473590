/* tslint:disable */
/* eslint-disable */
/**
 * - **PAID**: 유료 사용자
 * - **FREE_WITH_PAID_EXPERIENCE**: 무료 사용자 & 유료 경험 있음
 * - **FREE_WITHOUT_PAID_EXPERIENCE_AND_WITHIN_3_DAYS**: 무료 사용자 & 유료 경험 없음 & 가입 이후 3일 이내
 * - **FREE_WITHOUT_PAID_EXPERIENCE_AND_AFTER_3_DAYS**: 무료 사용자 & 유료 경험 없음 & 가입 이후 3일 초과
 * - **ANONYMOUS**: 익명 사용자
 * @export
 */
export const UserSegmentation = {
    PAID: 'PAID',
    FREE_WITH_PAID_EXPERIENCE: 'FREE_WITH_PAID_EXPERIENCE',
    FREE_WITHOUT_PAID_EXPERIENCE_AND_WITHIN_3_DAYS: 'FREE_WITHOUT_PAID_EXPERIENCE_AND_WITHIN_3_DAYS',
    FREE_WITHOUT_PAID_EXPERIENCE_AND_AFTER_3_DAYS: 'FREE_WITHOUT_PAID_EXPERIENCE_AND_AFTER_3_DAYS',
    ANONYMOUS: 'ANONYMOUS'
};
export function UserSegmentationFromJSON(json) {
    return UserSegmentationFromJSONTyped(json, false);
}
export function UserSegmentationFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function UserSegmentationToJSON(value) {
    return value;
}
