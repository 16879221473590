/* tslint:disable */
/* eslint-disable */
import * as runtime from '../runtime';
import { PublishSelfLearningRequestToJSON, } from '../models';
/**
 *
 */
export class SelfLearningVerificationAdminV1Api extends runtime.BaseAPI {
    /**
     * 작업된 최신의 선택학습을 유저들에게 공개한다.  자세한 사항은 [노션](https://www.notion.so/riiid/Publish-5546e2b700e741cdbc068105e99feef5?pvs=4)을 참고한다.
     * 작업된 최신의 선택학습을 유저들에게 공개한다.
     */
    async publishSelfLearningRaw(requestParameters, initOverrides) {
        if (requestParameters.publishSelfLearningRequest === null || requestParameters.publishSelfLearningRequest === undefined) {
            throw new runtime.RequiredError('publishSelfLearningRequest', 'Required parameter requestParameters.publishSelfLearningRequest was null or undefined when calling publishSelfLearning.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/admin/content-learning/v1/self-learning/verification/publish`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PublishSelfLearningRequestToJSON(requestParameters.publishSelfLearningRequest),
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     * 작업된 최신의 선택학습을 유저들에게 공개한다.  자세한 사항은 [노션](https://www.notion.so/riiid/Publish-5546e2b700e741cdbc068105e99feef5?pvs=4)을 참고한다.
     * 작업된 최신의 선택학습을 유저들에게 공개한다.
     */
    async publishSelfLearning(requestParameters, initOverrides) {
        await this.publishSelfLearningRaw(requestParameters, initOverrides);
    }
}
