import { CountingTypeFromJSON, CountingTypeToJSON, } from './CountingType';
import { PermitFormFromJSON, PermitFormToJSON, } from './PermitForm';
import { ResourceIdOptionFromJSON, ResourceIdOptionToJSON, } from './ResourceIdOption';
/**
 * Check if a given object implements the PermitTypeListItem interface.
 */
export function instanceOfPermitTypeListItem(value) {
    let isInstance = true;
    isInstance = isInstance && "availableForm" in value;
    isInstance = isInstance && "availableCountingType" in value;
    isInstance = isInstance && "resourceIdOption" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "id" in value;
    return isInstance;
}
export function PermitTypeListItemFromJSON(json) {
    return PermitTypeListItemFromJSONTyped(json, false);
}
export function PermitTypeListItemFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'availableForm': (json['availableForm'].map(PermitFormFromJSON)),
        'availableCountingType': (json['availableCountingType'].map(CountingTypeFromJSON)),
        'resourceIdOption': ResourceIdOptionFromJSON(json['resourceIdOption']),
        'name': json['name'],
        'id': json['id'],
    };
}
export function PermitTypeListItemToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'availableForm': (value.availableForm.map(PermitFormToJSON)),
        'availableCountingType': (value.availableCountingType.map(CountingTypeToJSON)),
        'resourceIdOption': ResourceIdOptionToJSON(value.resourceIdOption),
        'name': value.name,
        'id': value.id,
    };
}
