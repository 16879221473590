export const num2name = {
    0: "NO_ERROR",
    1: "ERROR_MAIN_POPUP_BANNER_NOT_FOUND",
    2: "ERROR_MAIN_POPUP_BANNER_NOT_UPDATABLE",
    3: "ERROR_INVALID_COUNTRY",
    4: "ERROR_INVALID_STATUS",
    5: "ERROR_RESOURCE_IMAGE_SET_DOES_NOT_EXIST",
    6: "ERROR_INVALID_START_TIME_END_TIME",
};
export const name2num = {
    NO_ERROR: 0,
    ERROR_MAIN_POPUP_BANNER_NOT_FOUND: 1,
    ERROR_MAIN_POPUP_BANNER_NOT_UPDATABLE: 2,
    ERROR_INVALID_COUNTRY: 3,
    ERROR_INVALID_STATUS: 4,
    ERROR_RESOURCE_IMAGE_SET_DOES_NOT_EXIST: 5,
    ERROR_INVALID_START_TIME_END_TIME: 6,
};
