import { PermitFromJSON, PermitToJSON, } from './Permit';
/**
 * Check if a given object implements the GetPermitResponse interface.
 */
export function instanceOfGetPermitResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "permit" in value;
    return isInstance;
}
export function GetPermitResponseFromJSON(json) {
    return GetPermitResponseFromJSONTyped(json, false);
}
export function GetPermitResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'permit': PermitFromJSON(json['permit']),
    };
}
export function GetPermitResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'permit': PermitToJSON(value.permit),
    };
}
