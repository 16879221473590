// @ts-nocheck
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "../../../b2b/(B2bStudent)/PrivacyInfo";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../../../runtime/json/scalar";
import { WireType, } from "../../../../../runtime/wire/index";
import { default as serialize, } from "../../../../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../../../../runtime/wire/scalar";
import { default as deserialize, } from "../../../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        email: "",
        privacyInfo: undefined,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.email !== undefined)
        result.email = tsValueToJsonValueFns.string(value.email);
    if (value.privacyInfo !== undefined)
        result.privacyInfo = encodeJson_1(value.privacyInfo);
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.email !== undefined)
        result.email = jsonValueToTsValueFns.string(value.email);
    if (value.privacyInfo !== undefined)
        result.privacyInfo = decodeJson_1(value.privacyInfo);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.email !== undefined) {
        const tsValue = value.email;
        result.push([1, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.privacyInfo !== undefined) {
        const tsValue = value.privacyInfo;
        result.push([2, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.email = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.privacyInfo = value;
    }
    return result;
}
