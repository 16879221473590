// @ts-nocheck
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_2, decodeBinary as decodeBinary_2, } from "./(B2bStudent)/PrivacyInfo";
import { name2num, num2name, } from "./(B2bStudent)/Status";
import { encodeJson as encodeJson_2, decodeJson as decodeJson_2, encodeBinary as encodeBinary_3, decodeBinary as decodeBinary_3, } from "../../google/protobuf/Timestamp";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../runtime/json/scalar";
import { WireType, } from "../../../runtime/wire/index";
import { default as serialize, } from "../../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../../runtime/wire/scalar";
import { encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "../../google/protobuf/StringValue";
import { default as Long, } from "../../../runtime/Long";
import { default as deserialize, } from "../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        id: "0",
        orginizationId: "0",
        classId: "0",
        orderId: "0",
        email: "",
        authId: undefined,
        privacyInfo: undefined,
        status: "STUDENT_STATUS_UNSPECIFIED",
        learningStartedAt: undefined,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.id !== undefined)
        result.id = tsValueToJsonValueFns.int64(value.id);
    if (value.orginizationId !== undefined)
        result.orginizationId = tsValueToJsonValueFns.int64(value.orginizationId);
    if (value.classId !== undefined)
        result.classId = tsValueToJsonValueFns.int64(value.classId);
    if (value.orderId !== undefined)
        result.orderId = tsValueToJsonValueFns.int64(value.orderId);
    if (value.email !== undefined)
        result.email = tsValueToJsonValueFns.string(value.email);
    if (value.authId !== undefined)
        result.authId = value.authId;
    if (value.privacyInfo !== undefined)
        result.privacyInfo = encodeJson_1(value.privacyInfo);
    if (value.status !== undefined)
        result.status = tsValueToJsonValueFns.enum(value.status);
    if (value.learningStartedAt !== undefined)
        result.learningStartedAt = encodeJson_2(value.learningStartedAt);
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.id !== undefined)
        result.id = jsonValueToTsValueFns.int64(value.id);
    if (value.orginizationId !== undefined)
        result.orginizationId = jsonValueToTsValueFns.int64(value.orginizationId);
    if (value.classId !== undefined)
        result.classId = jsonValueToTsValueFns.int64(value.classId);
    if (value.orderId !== undefined)
        result.orderId = jsonValueToTsValueFns.int64(value.orderId);
    if (value.email !== undefined)
        result.email = jsonValueToTsValueFns.string(value.email);
    if (value.authId !== undefined)
        result.authId = value.authId;
    if (value.privacyInfo !== undefined)
        result.privacyInfo = decodeJson_1(value.privacyInfo);
    if (value.status !== undefined)
        result.status = jsonValueToTsValueFns.enum(value.status);
    if (value.learningStartedAt !== undefined)
        result.learningStartedAt = decodeJson_2(value.learningStartedAt);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.id !== undefined) {
        const tsValue = value.id;
        result.push([1, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.orginizationId !== undefined) {
        const tsValue = value.orginizationId;
        result.push([2, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.classId !== undefined) {
        const tsValue = value.classId;
        result.push([3, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.orderId !== undefined) {
        const tsValue = value.orderId;
        result.push([4, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.email !== undefined) {
        const tsValue = value.email;
        result.push([5, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.authId !== undefined) {
        const tsValue = value.authId;
        result.push([6, ((tsValue) => ({ type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }))({ value: tsValue })]);
    }
    if (value.privacyInfo !== undefined) {
        const tsValue = value.privacyInfo;
        result.push([7, { type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }]);
    }
    if (value.status !== undefined) {
        const tsValue = value.status;
        result.push([8, { type: WireType.Varint, value: new Long(name2num[tsValue]) }]);
    }
    if (value.learningStartedAt !== undefined) {
        const tsValue = value.learningStartedAt;
        result.push([9, { type: WireType.LengthDelimited, value: encodeBinary_3(tsValue) }]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    var _a;
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.id = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.orginizationId = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.classId = value;
    }
    field: {
        const wireValue = wireFields.get(4);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.orderId = value;
    }
    field: {
        const wireValue = wireFields.get(5);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.email = value;
    }
    field: {
        const wireValue = wireFields.get(6);
        if (wireValue === undefined)
            break field;
        const value = (_a = (wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined)) === null || _a === void 0 ? void 0 : _a.value;
        if (value === undefined)
            break field;
        result.authId = value;
    }
    field: {
        const wireValue = wireFields.get(7);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.privacyInfo = value;
    }
    field: {
        const wireValue = wireFields.get(8);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.status = value;
    }
    field: {
        const wireValue = wireFields.get(9);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_3(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.learningStartedAt = value;
    }
    return result;
}
