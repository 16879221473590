export const num2name = {
    0: "NO_ERROR",
    1: "ERROR_INVALID_COUNTRY",
    2: "ERROR_INVALID_STATUS",
    3: "ERROR_RESOURCE_IMAGE_SET_DOES_NOT_EXIST",
    4: "ERROR_INVALID_START_TIME_END_TIME",
};
export const name2num = {
    NO_ERROR: 0,
    ERROR_INVALID_COUNTRY: 1,
    ERROR_INVALID_STATUS: 2,
    ERROR_RESOURCE_IMAGE_SET_DOES_NOT_EXIST: 3,
    ERROR_INVALID_START_TIME_END_TIME: 4,
};
