// @ts-nocheck
import { name2num, num2name, } from "./(SubscriptionProduct)/RenewalInterval";
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "./(SubscriptionProduct)/SaleConfig";
import { encodeJson as encodeJson_2, decodeJson as decodeJson_2, encodeBinary as encodeBinary_2, decodeBinary as decodeBinary_2, } from "../../../../toeic/commerce/(Product)/(ProductTemplate)/PaymentConfigTemplate";
import { encodeJson as encodeJson_3, decodeJson as decodeJson_3, encodeBinary as encodeBinary_3, decodeBinary as decodeBinary_3, } from "../../../../toeic/commerce/(Product)/DisplayConfig";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../../../runtime/json/scalar";
import { WireType, } from "../../../../../runtime/wire/index";
import { default as serialize, } from "../../../../../runtime/wire/serialize";
import { tsValueToWireValueFns, unpackFns, } from "../../../../../runtime/wire/scalar";
import { default as Long, } from "../../../../../runtime/Long";
import { default as deserialize, } from "../../../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        goodsIds: [],
        renewalInterval: "RENEWAL_INTERVAL_UNSPECIFIED",
        saleConfig: undefined,
        paymentConfigTemplate: undefined,
        displayConfig: undefined,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    result.goodsIds = value.goodsIds.map(value => tsValueToJsonValueFns.uint64(value));
    if (value.renewalInterval !== undefined)
        result.renewalInterval = tsValueToJsonValueFns.enum(value.renewalInterval);
    if (value.saleConfig !== undefined)
        result.saleConfig = encodeJson_1(value.saleConfig);
    if (value.paymentConfigTemplate !== undefined)
        result.paymentConfigTemplate = encodeJson_2(value.paymentConfigTemplate);
    if (value.displayConfig !== undefined)
        result.displayConfig = encodeJson_3(value.displayConfig);
    return result;
}
export function decodeJson(value) {
    var _a, _b;
    const result = getDefaultValue();
    result.goodsIds = (_b = (_a = value.goodsIds) === null || _a === void 0 ? void 0 : _a.map((value) => jsonValueToTsValueFns.uint64(value))) !== null && _b !== void 0 ? _b : [];
    if (value.renewalInterval !== undefined)
        result.renewalInterval = jsonValueToTsValueFns.enum(value.renewalInterval);
    if (value.saleConfig !== undefined)
        result.saleConfig = decodeJson_1(value.saleConfig);
    if (value.paymentConfigTemplate !== undefined)
        result.paymentConfigTemplate = decodeJson_2(value.paymentConfigTemplate);
    if (value.displayConfig !== undefined)
        result.displayConfig = decodeJson_3(value.displayConfig);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    for (const tsValue of value.goodsIds) {
        result.push([1, tsValueToWireValueFns.uint64(tsValue)]);
    }
    if (value.renewalInterval !== undefined) {
        const tsValue = value.renewalInterval;
        result.push([2, { type: WireType.Varint, value: new Long(name2num[tsValue]) }]);
    }
    if (value.saleConfig !== undefined) {
        const tsValue = value.saleConfig;
        result.push([3, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    if (value.paymentConfigTemplate !== undefined) {
        const tsValue = value.paymentConfigTemplate;
        result.push([4, { type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }]);
    }
    if (value.displayConfig !== undefined) {
        const tsValue = value.displayConfig;
        result.push([5, { type: WireType.LengthDelimited, value: encodeBinary_3(tsValue) }]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 1).map(([, wireValue]) => wireValue);
        const value = Array.from(unpackFns.uint64(wireValues));
        if (!value.length)
            break collection;
        result.goodsIds = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.renewalInterval = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.saleConfig = value;
    }
    field: {
        const wireValue = wireFields.get(4);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.paymentConfigTemplate = value;
    }
    field: {
        const wireValue = wireFields.get(5);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_3(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.displayConfig = value;
    }
    return result;
}
