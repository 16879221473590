import React from 'react';
import {Backdrop, CircularProgress, useTheme} from '@mui/material';

export interface LoadingBackdropProps {
  isLoading: boolean;
  onClick?(): void;
}

export default function LoadingBackdrop({isLoading, onClick}: LoadingBackdropProps) {
  const theme = useTheme();
  return (
    <Backdrop open={isLoading} onClick={onClick} css={{zIndex: theme.zIndex.appBar - 1}}>
      <CircularProgress />
    </Backdrop>
  );
}

export const LoadingPanelContext = React.createContext<{
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}>(null as any);

export const useLoadingPanel = (isLoading: boolean) => {
  const {setLoading} = React.useContext(LoadingPanelContext);

  React.useEffect(() => {
    setLoading(isLoading);
  }, [isLoading, setLoading]);
};
