import { DisplayInfoFromJSON, DisplayInfoToJSON, } from './DisplayInfo';
import { PassTemplateManagementInfoFromJSON, PassTemplateManagementInfoToJSON, } from './PassTemplateManagementInfo';
/**
 * Check if a given object implements the CreatePassTemplateRequest interface.
 */
export function instanceOfCreatePassTemplateRequest(value) {
    let isInstance = true;
    isInstance = isInstance && "permitIds" in value;
    isInstance = isInstance && "displayInfo" in value;
    isInstance = isInstance && "managementInfo" in value;
    return isInstance;
}
export function CreatePassTemplateRequestFromJSON(json) {
    return CreatePassTemplateRequestFromJSONTyped(json, false);
}
export function CreatePassTemplateRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'permitIds': json['permitIds'],
        'displayInfo': DisplayInfoFromJSON(json['displayInfo']),
        'managementInfo': PassTemplateManagementInfoFromJSON(json['managementInfo']),
    };
}
export function CreatePassTemplateRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'permitIds': value.permitIds,
        'displayInfo': DisplayInfoToJSON(value.displayInfo),
        'managementInfo': PassTemplateManagementInfoToJSON(value.managementInfo),
    };
}
