import { StripeProductListItemFromJSON, StripeProductListItemToJSON, } from './StripeProductListItem';
/**
 * Check if a given object implements the StripeProductBoard interface.
 */
export function instanceOfStripeProductBoard(value) {
    let isInstance = true;
    isInstance = isInstance && "boardCode" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "products" in value;
    return isInstance;
}
export function StripeProductBoardFromJSON(json) {
    return StripeProductBoardFromJSONTyped(json, false);
}
export function StripeProductBoardFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'boardCode': json['boardCode'],
        'description': json['description'],
        'id': json['id'],
        'products': (json['products'].map(StripeProductListItemFromJSON)),
    };
}
export function StripeProductBoardToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'boardCode': value.boardCode,
        'description': value.description,
        'id': value.id,
        'products': (value.products.map(StripeProductListItemToJSON)),
    };
}
