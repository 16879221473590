/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { SelfLearningContentTypeFromJSON, SelfLearningContentTypeToJSON, } from './SelfLearningContentType';
import { SelfLearningUnitOneOfContentQueryFromJSON, SelfLearningUnitOneOfContentQueryToJSON, } from './SelfLearningUnitOneOfContentQuery';
/**
 * Check if a given object implements the UpdateSelfLearningUnitRequest interface.
 */
export function instanceOfUpdateSelfLearningUnitRequest(value) {
    let isInstance = true;
    isInstance = isInstance && "contentQuery" in value;
    isInstance = isInstance && "tagList" in value;
    isInstance = isInstance && "iconResourceId" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "contentType" in value;
    return isInstance;
}
export function UpdateSelfLearningUnitRequestFromJSON(json) {
    return UpdateSelfLearningUnitRequestFromJSONTyped(json, false);
}
export function UpdateSelfLearningUnitRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'contentQuery': SelfLearningUnitOneOfContentQueryFromJSON(json['contentQuery']),
        'tagList': json['tagList'],
        'subTitle': !exists(json, 'subTitle') ? undefined : json['subTitle'],
        'iconResourceId': json['iconResourceId'],
        'id': json['id'],
        'title': json['title'],
        'contentType': SelfLearningContentTypeFromJSON(json['contentType']),
    };
}
export function UpdateSelfLearningUnitRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'contentQuery': SelfLearningUnitOneOfContentQueryToJSON(value.contentQuery),
        'tagList': value.tagList,
        'subTitle': value.subTitle,
        'iconResourceId': value.iconResourceId,
        'id': value.id,
        'title': value.title,
        'contentType': SelfLearningContentTypeToJSON(value.contentType),
    };
}
