import { SelfLearningUnitFromJSON, SelfLearningUnitToJSON, } from './SelfLearningUnit';
import { SelfLearningUnitMetadataFromJSON, SelfLearningUnitMetadataToJSON, } from './SelfLearningUnitMetadata';
/**
 * Check if a given object implements the SelfLearningUnitWithMetaResponse interface.
 */
export function instanceOfSelfLearningUnitWithMetaResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "selfLearningUnit" in value;
    isInstance = isInstance && "meta" in value;
    return isInstance;
}
export function SelfLearningUnitWithMetaResponseFromJSON(json) {
    return SelfLearningUnitWithMetaResponseFromJSONTyped(json, false);
}
export function SelfLearningUnitWithMetaResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'selfLearningUnit': SelfLearningUnitFromJSON(json['selfLearningUnit']),
        'meta': SelfLearningUnitMetadataFromJSON(json['meta']),
    };
}
export function SelfLearningUnitWithMetaResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'selfLearningUnit': SelfLearningUnitToJSON(value.selfLearningUnit),
        'meta': SelfLearningUnitMetadataToJSON(value.meta),
    };
}
