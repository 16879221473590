/* tslint:disable */
/* eslint-disable */
import * as runtime from '../runtime';
import { AssignUnitFilterToSelfLearningUnitRequestToJSON, SelfLearningUnitFiltersFromJSON, SelfLearningUnitFiltersToJSON, SelfLearningUnitResponseFromJSON, SelfLearningUnitsResponseFromJSON, SelfLearningUnitsSwapRequestToJSON, SelfLearningUnitsWithMetadataResponseFromJSON, } from '../models';
/**
 *
 */
export class SelfLearningUnitByUnitGroupAdminV1Api extends runtime.BaseAPI {
    /**
     * 선택학습 유닛에 특정 필터를 할당한다.
     * 선택학습 유닛에 특정 필터를 할당한다.
     */
    async assignFilterSelfLearningUnitRaw(requestParameters, initOverrides) {
        if (requestParameters.unitGroupId === null || requestParameters.unitGroupId === undefined) {
            throw new runtime.RequiredError('unitGroupId', 'Required parameter requestParameters.unitGroupId was null or undefined when calling assignFilterSelfLearningUnit.');
        }
        if (requestParameters.unitId === null || requestParameters.unitId === undefined) {
            throw new runtime.RequiredError('unitId', 'Required parameter requestParameters.unitId was null or undefined when calling assignFilterSelfLearningUnit.');
        }
        if (requestParameters.assignUnitFilterToSelfLearningUnitRequest === null || requestParameters.assignUnitFilterToSelfLearningUnitRequest === undefined) {
            throw new runtime.RequiredError('assignUnitFilterToSelfLearningUnitRequest', 'Required parameter requestParameters.assignUnitFilterToSelfLearningUnitRequest was null or undefined when calling assignFilterSelfLearningUnit.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/admin/content-learning/v1/self-learning/unit-groups/{unitGroupId}/units/{unitId}/unit-filter`.replace(`{${"unitGroupId"}}`, encodeURIComponent(String(requestParameters.unitGroupId))).replace(`{${"unitId"}}`, encodeURIComponent(String(requestParameters.unitId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: AssignUnitFilterToSelfLearningUnitRequestToJSON(requestParameters.assignUnitFilterToSelfLearningUnitRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => SelfLearningUnitResponseFromJSON(jsonValue));
    }
    /**
     * 선택학습 유닛에 특정 필터를 할당한다.
     * 선택학습 유닛에 특정 필터를 할당한다.
     */
    async assignFilterSelfLearningUnit(requestParameters, initOverrides) {
        const response = await this.assignFilterSelfLearningUnitRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 선택학습 유닛을 특정 그룹에 할당한다.
     * 선택학습 유닛을 특정 그룹에 할당한다.
     */
    async assignSelfLearningUnitToUnitGroupRaw(requestParameters, initOverrides) {
        if (requestParameters.unitGroupId === null || requestParameters.unitGroupId === undefined) {
            throw new runtime.RequiredError('unitGroupId', 'Required parameter requestParameters.unitGroupId was null or undefined when calling assignSelfLearningUnitToUnitGroup.');
        }
        if (requestParameters.unitId === null || requestParameters.unitId === undefined) {
            throw new runtime.RequiredError('unitId', 'Required parameter requestParameters.unitId was null or undefined when calling assignSelfLearningUnitToUnitGroup.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/admin/content-learning/v1/self-learning/unit-groups/{unitGroupId}/units/{unitId}`.replace(`{${"unitGroupId"}}`, encodeURIComponent(String(requestParameters.unitGroupId))).replace(`{${"unitId"}}`, encodeURIComponent(String(requestParameters.unitId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => SelfLearningUnitResponseFromJSON(jsonValue));
    }
    /**
     * 선택학습 유닛을 특정 그룹에 할당한다.
     * 선택학습 유닛을 특정 그룹에 할당한다.
     */
    async assignSelfLearningUnitToUnitGroup(requestParameters, initOverrides) {
        const response = await this.assignSelfLearningUnitToUnitGroupRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 선택학습 유닛 그룹에 포함된 유닛의 필터 목록을 가져온다.\\ `showAll`이 `true`인 경우에는 클라이언트에서 필터 목록 제일 앞에 `전체`를 표시해야 한다.
     * 선택학습 유닛 그룹에 포함된 유닛의 필터 목록을 가져온다.
     */
    async getSelfLearningUnitFilterListRaw(requestParameters, initOverrides) {
        if (requestParameters.unitGroupId === null || requestParameters.unitGroupId === undefined) {
            throw new runtime.RequiredError('unitGroupId', 'Required parameter requestParameters.unitGroupId was null or undefined when calling getSelfLearningUnitFilterList.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/admin/content-learning/v1/self-learning/unit-groups/{unitGroupId}/unit-filters`.replace(`{${"unitGroupId"}}`, encodeURIComponent(String(requestParameters.unitGroupId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => SelfLearningUnitFiltersFromJSON(jsonValue));
    }
    /**
     * 선택학습 유닛 그룹에 포함된 유닛의 필터 목록을 가져온다.\\ `showAll`이 `true`인 경우에는 클라이언트에서 필터 목록 제일 앞에 `전체`를 표시해야 한다.
     * 선택학습 유닛 그룹에 포함된 유닛의 필터 목록을 가져온다.
     */
    async getSelfLearningUnitFilterList(requestParameters, initOverrides) {
        const response = await this.getSelfLearningUnitFilterListRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 선택학습 유닛 그룹에 포함된 유닛 목록을 조회한다.  ## 참고 `/api/admin/content-learning/v1/self-learning/units/` api와 달리 admin이 정한 순서를 유지한 상태로 목록을 조회한다.
     * 선택학습 유닛 그룹에 포함된 유닛 목록을 조회한다.
     */
    async getSelfLearningUnitsByUnitGroupRaw(requestParameters, initOverrides) {
        if (requestParameters.unitGroupId === null || requestParameters.unitGroupId === undefined) {
            throw new runtime.RequiredError('unitGroupId', 'Required parameter requestParameters.unitGroupId was null or undefined when calling getSelfLearningUnitsByUnitGroup.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/admin/content-learning/v1/self-learning/unit-groups/{unitGroupId}/units`.replace(`{${"unitGroupId"}}`, encodeURIComponent(String(requestParameters.unitGroupId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => SelfLearningUnitsWithMetadataResponseFromJSON(jsonValue));
    }
    /**
     * 선택학습 유닛 그룹에 포함된 유닛 목록을 조회한다.  ## 참고 `/api/admin/content-learning/v1/self-learning/units/` api와 달리 admin이 정한 순서를 유지한 상태로 목록을 조회한다.
     * 선택학습 유닛 그룹에 포함된 유닛 목록을 조회한다.
     */
    async getSelfLearningUnitsByUnitGroup(requestParameters, initOverrides) {
        const response = await this.getSelfLearningUnitsByUnitGroupRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 선택학습 유닛 그룹 목록에서 두 항목의 위치를 변경한다.
     * 선택학습 유닛 그룹 목록에서 두 항목의 위치를 변경한다.
     */
    async swapSelfLearningUnitsPositionRaw(requestParameters, initOverrides) {
        if (requestParameters.unitGroupId === null || requestParameters.unitGroupId === undefined) {
            throw new runtime.RequiredError('unitGroupId', 'Required parameter requestParameters.unitGroupId was null or undefined when calling swapSelfLearningUnitsPosition.');
        }
        if (requestParameters.selfLearningUnitsSwapRequest === null || requestParameters.selfLearningUnitsSwapRequest === undefined) {
            throw new runtime.RequiredError('selfLearningUnitsSwapRequest', 'Required parameter requestParameters.selfLearningUnitsSwapRequest was null or undefined when calling swapSelfLearningUnitsPosition.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/admin/content-learning/v1/self-learning/unit-groups/{unitGroupId}/units/swap`.replace(`{${"unitGroupId"}}`, encodeURIComponent(String(requestParameters.unitGroupId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: SelfLearningUnitsSwapRequestToJSON(requestParameters.selfLearningUnitsSwapRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => SelfLearningUnitsResponseFromJSON(jsonValue));
    }
    /**
     * 선택학습 유닛 그룹 목록에서 두 항목의 위치를 변경한다.
     * 선택학습 유닛 그룹 목록에서 두 항목의 위치를 변경한다.
     */
    async swapSelfLearningUnitsPosition(requestParameters, initOverrides) {
        const response = await this.swapSelfLearningUnitsPositionRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 선택학습 유닛에 특정 필터를 할당 해제한다.
     * 선택학습 유닛에 특정 필터를 할당 해제한다.
     */
    async unassignFilterSelfLearningUnitRaw(requestParameters, initOverrides) {
        if (requestParameters.unitGroupId === null || requestParameters.unitGroupId === undefined) {
            throw new runtime.RequiredError('unitGroupId', 'Required parameter requestParameters.unitGroupId was null or undefined when calling unassignFilterSelfLearningUnit.');
        }
        if (requestParameters.unitId === null || requestParameters.unitId === undefined) {
            throw new runtime.RequiredError('unitId', 'Required parameter requestParameters.unitId was null or undefined when calling unassignFilterSelfLearningUnit.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/admin/content-learning/v1/self-learning/unit-groups/{unitGroupId}/units/{unitId}/unit-filter`.replace(`{${"unitGroupId"}}`, encodeURIComponent(String(requestParameters.unitGroupId))).replace(`{${"unitId"}}`, encodeURIComponent(String(requestParameters.unitId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     * 선택학습 유닛에 특정 필터를 할당 해제한다.
     * 선택학습 유닛에 특정 필터를 할당 해제한다.
     */
    async unassignFilterSelfLearningUnit(requestParameters, initOverrides) {
        await this.unassignFilterSelfLearningUnitRaw(requestParameters, initOverrides);
    }
    /**
     * 선택학습 유닛을 특정 그룹에 할당 해제한다.
     * 선택학습 유닛을 특정 그룹에 할당 해제한다.
     */
    async unassignSelfLearningUnitToUnitGroupRaw(requestParameters, initOverrides) {
        if (requestParameters.unitGroupId === null || requestParameters.unitGroupId === undefined) {
            throw new runtime.RequiredError('unitGroupId', 'Required parameter requestParameters.unitGroupId was null or undefined when calling unassignSelfLearningUnitToUnitGroup.');
        }
        if (requestParameters.unitId === null || requestParameters.unitId === undefined) {
            throw new runtime.RequiredError('unitId', 'Required parameter requestParameters.unitId was null or undefined when calling unassignSelfLearningUnitToUnitGroup.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/admin/content-learning/v1/self-learning/unit-groups/{unitGroupId}/units/{unitId}`.replace(`{${"unitGroupId"}}`, encodeURIComponent(String(requestParameters.unitGroupId))).replace(`{${"unitId"}}`, encodeURIComponent(String(requestParameters.unitId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     * 선택학습 유닛을 특정 그룹에 할당 해제한다.
     * 선택학습 유닛을 특정 그룹에 할당 해제한다.
     */
    async unassignSelfLearningUnitToUnitGroup(requestParameters, initOverrides) {
        await this.unassignSelfLearningUnitToUnitGroupRaw(requestParameters, initOverrides);
    }
    /**
     * 전달 받은 필터 목록을 최신의 필터 목록으로 설정한다.\\ 필터의 순서는 요청에 포함된 순서대로 저장된다.  1. 항목 추가: `id`가 0인 경우, 새로운 항목으로 추가한다. 1. 항목 수정: `id`가 1 이상인 경우, 해당 항목의 모든 내용을 요청한 값으로 수정한다. 1. 항목 삭제: 요청에는 없는 `id`가 서버에 존재하는 경우, 해당 항목을 삭제한다.     - 빈 항목을 보내면 모든 필터가 삭제된다.
     * 선택학습 유닛 그룹에 포함된 유닛의 필터 목록을 수정한다.
     */
    async updateSelfLearningUnitFilterListRaw(requestParameters, initOverrides) {
        if (requestParameters.unitGroupId === null || requestParameters.unitGroupId === undefined) {
            throw new runtime.RequiredError('unitGroupId', 'Required parameter requestParameters.unitGroupId was null or undefined when calling updateSelfLearningUnitFilterList.');
        }
        if (requestParameters.selfLearningUnitFilters === null || requestParameters.selfLearningUnitFilters === undefined) {
            throw new runtime.RequiredError('selfLearningUnitFilters', 'Required parameter requestParameters.selfLearningUnitFilters was null or undefined when calling updateSelfLearningUnitFilterList.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/admin/content-learning/v1/self-learning/unit-groups/{unitGroupId}/unit-filters`.replace(`{${"unitGroupId"}}`, encodeURIComponent(String(requestParameters.unitGroupId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SelfLearningUnitFiltersToJSON(requestParameters.selfLearningUnitFilters),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => SelfLearningUnitFiltersFromJSON(jsonValue));
    }
    /**
     * 전달 받은 필터 목록을 최신의 필터 목록으로 설정한다.\\ 필터의 순서는 요청에 포함된 순서대로 저장된다.  1. 항목 추가: `id`가 0인 경우, 새로운 항목으로 추가한다. 1. 항목 수정: `id`가 1 이상인 경우, 해당 항목의 모든 내용을 요청한 값으로 수정한다. 1. 항목 삭제: 요청에는 없는 `id`가 서버에 존재하는 경우, 해당 항목을 삭제한다.     - 빈 항목을 보내면 모든 필터가 삭제된다.
     * 선택학습 유닛 그룹에 포함된 유닛의 필터 목록을 수정한다.
     */
    async updateSelfLearningUnitFilterList(requestParameters, initOverrides) {
        const response = await this.updateSelfLearningUnitFilterListRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
