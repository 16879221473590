export const num2name = {
    0: "LEGACY_TOEIC_COUNTRY_UNSPECIFIED",
    1: "KR",
    2: "JP",
};
export const name2num = {
    LEGACY_TOEIC_COUNTRY_UNSPECIFIED: 0,
    KR: 1,
    JP: 2,
};
