/**
 * Check if a given object implements the SetOnboardingPassTemplateRequestOnboardingPassTemplate interface.
 */
export function instanceOfSetOnboardingPassTemplateRequestOnboardingPassTemplate(value) {
    let isInstance = true;
    isInstance = isInstance && "expirationInDays" in value;
    isInstance = isInstance && "passTemplateId" in value;
    return isInstance;
}
export function SetOnboardingPassTemplateRequestOnboardingPassTemplateFromJSON(json) {
    return SetOnboardingPassTemplateRequestOnboardingPassTemplateFromJSONTyped(json, false);
}
export function SetOnboardingPassTemplateRequestOnboardingPassTemplateFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'expirationInDays': json['expirationInDays'],
        'passTemplateId': json['passTemplateId'],
    };
}
export function SetOnboardingPassTemplateRequestOnboardingPassTemplateToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'expirationInDays': value.expirationInDays,
        'passTemplateId': value.passTemplateId,
    };
}
