/**
 * Check if a given object implements the IssueTicketFromTemplateRequest interface.
 */
export function instanceOfIssueTicketFromTemplateRequest(value) {
    let isInstance = true;
    isInstance = isInstance && "reason" in value;
    isInstance = isInstance && "quantity" in value;
    isInstance = isInstance && "userId" in value;
    return isInstance;
}
export function IssueTicketFromTemplateRequestFromJSON(json) {
    return IssueTicketFromTemplateRequestFromJSONTyped(json, false);
}
export function IssueTicketFromTemplateRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'reason': json['reason'],
        'quantity': json['quantity'],
        'userId': json['userId'],
    };
}
export function IssueTicketFromTemplateRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'reason': value.reason,
        'quantity': value.quantity,
        'userId': value.userId,
    };
}
