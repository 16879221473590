/* tslint:disable */
/* eslint-disable */
/**
 *
 * @export
 */
export const SelfLearningUnitStatus = {
    NONE: 'NONE',
    PUBLISHED: 'PUBLISHED'
};
export function SelfLearningUnitStatusFromJSON(json) {
    return SelfLearningUnitStatusFromJSONTyped(json, false);
}
export function SelfLearningUnitStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function SelfLearningUnitStatusToJSON(value) {
    return value;
}
