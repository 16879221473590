import { StripeProductBoardFromJSON, StripeProductBoardToJSON, } from './StripeProductBoard';
/**
 * Check if a given object implements the UpdateStripeProductBoardResponse interface.
 */
export function instanceOfUpdateStripeProductBoardResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "updatedProductBoard" in value;
    return isInstance;
}
export function UpdateStripeProductBoardResponseFromJSON(json) {
    return UpdateStripeProductBoardResponseFromJSONTyped(json, false);
}
export function UpdateStripeProductBoardResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'updatedProductBoard': StripeProductBoardFromJSON(json['updatedProductBoard']),
    };
}
export function UpdateStripeProductBoardResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'updatedProductBoard': StripeProductBoardToJSON(value.updatedProductBoard),
    };
}
