import { PassTemplateListItemFromJSON, PassTemplateListItemToJSON, } from './PassTemplateListItem';
/**
 * Check if a given object implements the OnboardingPassTemplate interface.
 */
export function instanceOfOnboardingPassTemplate(value) {
    let isInstance = true;
    isInstance = isInstance && "country" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "expirationInDays" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "passTemplate" in value;
    isInstance = isInstance && "learningDomainId" in value;
    isInstance = isInstance && "updatedAt" in value;
    return isInstance;
}
export function OnboardingPassTemplateFromJSON(json) {
    return OnboardingPassTemplateFromJSONTyped(json, false);
}
export function OnboardingPassTemplateFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'country': json['country'],
        'createdAt': (new Date(json['createdAt'])),
        'expirationInDays': json['expirationInDays'],
        'id': json['id'],
        'passTemplate': PassTemplateListItemFromJSON(json['passTemplate']),
        'learningDomainId': json['learningDomainId'],
        'updatedAt': (new Date(json['updatedAt'])),
    };
}
export function OnboardingPassTemplateToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'country': value.country,
        'createdAt': (value.createdAt.toISOString()),
        'expirationInDays': value.expirationInDays,
        'id': value.id,
        'passTemplate': PassTemplateListItemToJSON(value.passTemplate),
        'learningDomainId': value.learningDomainId,
        'updatedAt': (value.updatedAt.toISOString()),
    };
}
