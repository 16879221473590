import { SelfLearningUnitGroupFromJSON, SelfLearningUnitGroupToJSON, } from './SelfLearningUnitGroup';
/**
 * Check if a given object implements the SelfLearningUnitGroupsResponse interface.
 */
export function instanceOfSelfLearningUnitGroupsResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "selfLearningUnitGroups" in value;
    return isInstance;
}
export function SelfLearningUnitGroupsResponseFromJSON(json) {
    return SelfLearningUnitGroupsResponseFromJSONTyped(json, false);
}
export function SelfLearningUnitGroupsResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'selfLearningUnitGroups': (json['selfLearningUnitGroups'].map(SelfLearningUnitGroupFromJSON)),
    };
}
export function SelfLearningUnitGroupsResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'selfLearningUnitGroups': (value.selfLearningUnitGroups.map(SelfLearningUnitGroupToJSON)),
    };
}
