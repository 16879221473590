// @ts-nocheck
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "../../../Pageable";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../../runtime/json/scalar";
import { WireType, } from "../../../../runtime/wire/index";
import { default as serialize, } from "../../../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../../../runtime/wire/scalar";
import { default as deserialize, } from "../../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        pageable: undefined,
        selection: undefined,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    var _a;
    const result = {};
    if (value.pageable !== undefined)
        result.pageable = encodeJson_1(value.pageable);
    switch ((_a = value.selection) === null || _a === void 0 ? void 0 : _a.field) {
        case "organizationId": {
            result.organizationId = tsValueToJsonValueFns.int64(value.selection.value);
            break;
        }
        case "classId": {
            result.classId = tsValueToJsonValueFns.int64(value.selection.value);
            break;
        }
    }
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.pageable !== undefined)
        result.pageable = decodeJson_1(value.pageable);
    if (value.organizationId !== undefined)
        result.selection = { field: "organizationId", value: jsonValueToTsValueFns.int64(value.organizationId) };
    if (value.classId !== undefined)
        result.selection = { field: "classId", value: jsonValueToTsValueFns.int64(value.classId) };
    return result;
}
export function encodeBinary(value) {
    var _a;
    const result = [];
    if (value.pageable !== undefined) {
        const tsValue = value.pageable;
        result.push([1, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    switch ((_a = value.selection) === null || _a === void 0 ? void 0 : _a.field) {
        case "organizationId": {
            const tsValue = value.selection.value;
            result.push([2, tsValueToWireValueFns.int64(tsValue)]);
            break;
        }
        case "classId": {
            const tsValue = value.selection.value;
            result.push([3, tsValueToWireValueFns.int64(tsValue)]);
            break;
        }
    }
    return serialize(result);
}
const oneofFieldNumbersMap = {
    selection: new Set([2, 3]),
};
const oneofFieldNamesMap = {
    selection: new Map([
        [2, "organizationId"],
        [3, "classId"],
    ]),
};
export function decodeBinary(binary) {
    var _a;
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    const wireFieldNumbers = Array.from(wireFields.keys()).reverse();
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.pageable = value;
    }
    oneof: {
        const oneofFieldNumbers = oneofFieldNumbersMap.selection;
        const oneofFieldNames = oneofFieldNamesMap.selection;
        const fieldNumber = wireFieldNumbers.find(v => oneofFieldNumbers.has(v));
        if (fieldNumber == null)
            break oneof;
        const wireValue = wireFields.get(fieldNumber);
        const wireValueToTsValueMap = {
            [2](wireValue) { return wireValueToTsValueFns.int64(wireValue); },
            [3](wireValue) { return wireValueToTsValueFns.int64(wireValue); },
        };
        const value = (_a = wireValueToTsValueMap[fieldNumber]) === null || _a === void 0 ? void 0 : _a.call(wireValueToTsValueMap, wireValue);
        if (value === undefined)
            break oneof;
        result.selection = { field: oneofFieldNames.get(fieldNumber), value: value };
    }
    return result;
}
