export const num2name = {
    0: "USER_SAG_UNSPECIFIED",
    1: "BASIC",
    2: "INTERMEDIATE",
    3: "ADVANCED",
};
export const name2num = {
    USER_SAG_UNSPECIFIED: 0,
    BASIC: 1,
    INTERMEDIATE: 2,
    ADVANCED: 3,
};
