// @ts-nocheck
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "../../../Pageable";
import { WireType, } from "../../../../runtime/wire/index";
import { default as serialize, } from "../../../../runtime/wire/serialize";
import { encodeBinary as encodeBinary_2, decodeBinary as decodeBinary_2, } from "../../../google/protobuf/Int64Value";
import { default as deserialize, } from "../../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        pageable: undefined,
        organizationId: undefined,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.pageable !== undefined)
        result.pageable = encodeJson_1(value.pageable);
    if (value.organizationId !== undefined)
        result.organizationId = value.organizationId;
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.pageable !== undefined)
        result.pageable = decodeJson_1(value.pageable);
    if (value.organizationId !== undefined)
        result.organizationId = value.organizationId;
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.pageable !== undefined) {
        const tsValue = value.pageable;
        result.push([1, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    if (value.organizationId !== undefined) {
        const tsValue = value.organizationId;
        result.push([2, ((tsValue) => ({ type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }))({ value: tsValue })]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    var _a;
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.pageable = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = (_a = (wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined)) === null || _a === void 0 ? void 0 : _a.value;
        if (value === undefined)
            break field;
        result.organizationId = value;
    }
    return result;
}
