import { AssetFromJSON, AssetToJSON, } from './Asset';
/**
 * Check if a given object implements the GetAssetResponse interface.
 */
export function instanceOfGetAssetResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "asset" in value;
    return isInstance;
}
export function GetAssetResponseFromJSON(json) {
    return GetAssetResponseFromJSONTyped(json, false);
}
export function GetAssetResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'asset': AssetFromJSON(json['asset']),
    };
}
export function GetAssetResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'asset': AssetToJSON(value.asset),
    };
}
