import { SelfLearningUnitFilterFromJSON, SelfLearningUnitFilterToJSON, } from './SelfLearningUnitFilter';
/**
 * Check if a given object implements the SelfLearningUnitFilters interface.
 */
export function instanceOfSelfLearningUnitFilters(value) {
    let isInstance = true;
    isInstance = isInstance && "showAll" in value;
    isInstance = isInstance && "filters" in value;
    return isInstance;
}
export function SelfLearningUnitFiltersFromJSON(json) {
    return SelfLearningUnitFiltersFromJSONTyped(json, false);
}
export function SelfLearningUnitFiltersFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'showAll': json['showAll'],
        'filters': (json['filters'].map(SelfLearningUnitFilterFromJSON)),
    };
}
export function SelfLearningUnitFiltersToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'showAll': value.showAll,
        'filters': (value.filters.map(SelfLearningUnitFilterToJSON)),
    };
}
