export const num2name = {
    0: "ERROR_UNSPECIFIED",
    1: "ERROR_INVALID_SUBSCRIPTION_PRODUCT_BOARD_TEMPLATE",
    2: "ERROR_SUBSCRIPTION_PRODUCT_BOARD_DOES_NOT_EXIST",
};
export const name2num = {
    ERROR_UNSPECIFIED: 0,
    ERROR_INVALID_SUBSCRIPTION_PRODUCT_BOARD_TEMPLATE: 1,
    ERROR_SUBSCRIPTION_PRODUCT_BOARD_DOES_NOT_EXIST: 2,
};
