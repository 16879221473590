/**
 * Check if a given object implements the SetOnboardingTicketTemplatesResponse interface.
 */
export function instanceOfSetOnboardingTicketTemplatesResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "onboardingTicketTemplateIds" in value;
    return isInstance;
}
export function SetOnboardingTicketTemplatesResponseFromJSON(json) {
    return SetOnboardingTicketTemplatesResponseFromJSONTyped(json, false);
}
export function SetOnboardingTicketTemplatesResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'onboardingTicketTemplateIds': json['onboardingTicketTemplateIds'],
    };
}
export function SetOnboardingTicketTemplatesResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'onboardingTicketTemplateIds': value.onboardingTicketTemplateIds,
    };
}
