import { TicketFromJSON, TicketToJSON, } from './Ticket';
/**
 * Check if a given object implements the GetTicketResponse interface.
 */
export function instanceOfGetTicketResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "ticket" in value;
    return isInstance;
}
export function GetTicketResponseFromJSON(json) {
    return GetTicketResponseFromJSONTyped(json, false);
}
export function GetTicketResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'ticket': TicketFromJSON(json['ticket']),
    };
}
export function GetTicketResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'ticket': TicketToJSON(value.ticket),
    };
}
