export const num2name = {
    0: "PAYMENT_EXPERIENCE_UNSPECIFIED",
    1: "PAYMENT_EXPERIENCE_PAID",
    2: "PAYMENT_EXPERIENCE_REFUNDED",
    3: "PAYMENT_EXPERIENCE_NON_PAID",
};
export const name2num = {
    PAYMENT_EXPERIENCE_UNSPECIFIED: 0,
    PAYMENT_EXPERIENCE_PAID: 1,
    PAYMENT_EXPERIENCE_REFUNDED: 2,
    PAYMENT_EXPERIENCE_NON_PAID: 3,
};
