// @ts-nocheck
import { name2num, num2name, } from "../../../authority/AuthorityType";
import { name2num as name2num_1, num2name as num2name_1, } from "../../../authority/AuthorityLimit";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../../../runtime/json/scalar";
import { WireType, } from "../../../../../runtime/wire/index";
import { default as serialize, } from "../../../../../runtime/wire/serialize";
import { default as Long, } from "../../../../../runtime/Long";
import { encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "../../../../google/protobuf/StringValue";
import { default as deserialize, } from "../../../../../runtime/wire/deserialize";
import { unpackFns, } from "../../../../../runtime/wire/scalar";
export function getDefaultValue() {
    return {
        type: [],
        limit: [],
        descriptionLike: undefined,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    result.type = value.type.map(value => tsValueToJsonValueFns.enum(value));
    result.limit = value.limit.map(value => tsValueToJsonValueFns.enum(value));
    if (value.descriptionLike !== undefined)
        result.descriptionLike = value.descriptionLike;
    return result;
}
export function decodeJson(value) {
    var _a, _b, _c, _d;
    const result = getDefaultValue();
    result.type = (_b = (_a = value.type) === null || _a === void 0 ? void 0 : _a.map((value) => jsonValueToTsValueFns.enum(value))) !== null && _b !== void 0 ? _b : [];
    result.limit = (_d = (_c = value.limit) === null || _c === void 0 ? void 0 : _c.map((value) => jsonValueToTsValueFns.enum(value))) !== null && _d !== void 0 ? _d : [];
    if (value.descriptionLike !== undefined)
        result.descriptionLike = value.descriptionLike;
    return result;
}
export function encodeBinary(value) {
    const result = [];
    for (const tsValue of value.type) {
        result.push([1, { type: WireType.Varint, value: new Long(name2num[tsValue]) }]);
    }
    for (const tsValue of value.limit) {
        result.push([2, { type: WireType.Varint, value: new Long(name2num_1[tsValue]) }]);
    }
    if (value.descriptionLike !== undefined) {
        const tsValue = value.descriptionLike;
        result.push([3, ((tsValue) => ({ type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }))({ value: tsValue })]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    var _a;
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 1).map(([, wireValue]) => wireValue);
        const value = Array.from(unpackFns.int32(wireValues)).map(num => num2name[num]);
        if (!value.length)
            break collection;
        result.type = value;
    }
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 2).map(([, wireValue]) => wireValue);
        const value = Array.from(unpackFns.int32(wireValues)).map(num => num2name_1[num]);
        if (!value.length)
            break collection;
        result.limit = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = (_a = (wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined)) === null || _a === void 0 ? void 0 : _a.value;
        if (value === undefined)
            break field;
        result.descriptionLike = value;
    }
    return result;
}
