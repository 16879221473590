export const num2name = {
    0: "UNKNOWN",
    1: "PAY_REQUESTED",
    2: "PAY_FAILED",
    3: "PAY_SUCCESS",
    4: "CANCEL_REQUESTED",
    5: "CANCEL_FAILED",
    6: "CANCEL_SUCCESS",
    7: "PAYBACK_REQUESTED",
    8: "PAYBACK_FAILED",
    9: "PAYBACK_SUCCESS",
};
export const name2num = {
    UNKNOWN: 0,
    PAY_REQUESTED: 1,
    PAY_FAILED: 2,
    PAY_SUCCESS: 3,
    CANCEL_REQUESTED: 4,
    CANCEL_FAILED: 5,
    CANCEL_SUCCESS: 6,
    PAYBACK_REQUESTED: 7,
    PAYBACK_FAILED: 8,
    PAYBACK_SUCCESS: 9,
};
