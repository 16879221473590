import { TicketTemplateListItemFromJSON, TicketTemplateListItemToJSON, } from './TicketTemplateListItem';
/**
 * Check if a given object implements the ListTicketTemplateResponse interface.
 */
export function instanceOfListTicketTemplateResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "total" in value;
    isInstance = isInstance && "ticketTemplates" in value;
    return isInstance;
}
export function ListTicketTemplateResponseFromJSON(json) {
    return ListTicketTemplateResponseFromJSONTyped(json, false);
}
export function ListTicketTemplateResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'total': json['total'],
        'ticketTemplates': (json['ticketTemplates'].map(TicketTemplateListItemFromJSON)),
    };
}
export function ListTicketTemplateResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'total': value.total,
        'ticketTemplates': (value.ticketTemplates.map(TicketTemplateListItemToJSON)),
    };
}
