/* tslint:disable */
/* eslint-disable */
import * as runtime from '../runtime';
import { CreatePermitRequestToJSON, CreatePermitResponseFromJSON, GetPermitResponseFromJSON, ListDefaultPermitsResponseFromJSON, ListPermitResponseFromJSON, SetDefaultPermitsRequestToJSON, SetDefaultPermitsResponseFromJSON, UpdatePermitRequestToJSON, } from '../models';
/**
 *
 */
export class PermitAdminV1PermitApi extends runtime.BaseAPI {
    /**
     * Permit을 생성한다.
     */
    async createPermitRaw(requestParameters, initOverrides) {
        if (requestParameters.createPermitRequest === null || requestParameters.createPermitRequest === undefined) {
            throw new runtime.RequiredError('createPermitRequest', 'Required parameter requestParameters.createPermitRequest was null or undefined when calling createPermit.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/admin/permit/v1/permit/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreatePermitRequestToJSON(requestParameters.createPermitRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => CreatePermitResponseFromJSON(jsonValue));
    }
    /**
     * Permit을 생성한다.
     */
    async createPermit(requestParameters, initOverrides) {
        const response = await this.createPermitRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Permit을 조회한다.
     */
    async getPermitRaw(requestParameters, initOverrides) {
        if (requestParameters.permitId === null || requestParameters.permitId === undefined) {
            throw new runtime.RequiredError('permitId', 'Required parameter requestParameters.permitId was null or undefined when calling getPermit.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/admin/permit/v1/permit/{permitId}`.replace(`{${"permitId"}}`, encodeURIComponent(String(requestParameters.permitId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GetPermitResponseFromJSON(jsonValue));
    }
    /**
     * Permit을 조회한다.
     */
    async getPermit(requestParameters, initOverrides) {
        const response = await this.getPermitRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 현재 default permit으로 설정된 모든 permit의 목록을 조회한다.
     */
    async listDefaultPermitsRaw(requestParameters, initOverrides) {
        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size', 'Required parameter requestParameters.size was null or undefined when calling listDefaultPermits.');
        }
        const queryParameters = {};
        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }
        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/api/admin/permit/v1/permit/default`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ListDefaultPermitsResponseFromJSON(jsonValue));
    }
    /**
     * 현재 default permit으로 설정된 모든 permit의 목록을 조회한다.
     */
    async listDefaultPermits(requestParameters, initOverrides) {
        const response = await this.listDefaultPermitsRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Permit 목록을 조회한다.
     */
    async listPermitsRaw(requestParameters, initOverrides) {
        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size', 'Required parameter requestParameters.size was null or undefined when calling listPermits.');
        }
        const queryParameters = {};
        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }
        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }
        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }
        if (requestParameters.availableForm !== undefined) {
            queryParameters['availableForm'] = requestParameters.availableForm;
        }
        if (requestParameters.permitTypeId !== undefined) {
            queryParameters['permitTypeId'] = requestParameters.permitTypeId;
        }
        if (requestParameters.countingType !== undefined) {
            queryParameters['countingType'] = requestParameters.countingType;
        }
        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }
        if (requestParameters.description !== undefined) {
            queryParameters['description'] = requestParameters.description;
        }
        if (requestParameters.isPublished !== undefined) {
            queryParameters['isPublished'] = requestParameters.isPublished;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/api/admin/permit/v1/permit/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ListPermitResponseFromJSON(jsonValue));
    }
    /**
     * Permit 목록을 조회한다.
     */
    async listPermits(requestParameters, initOverrides) {
        const response = await this.listPermitsRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Permit을 publish한다.
     */
    async publishPermitRaw(requestParameters, initOverrides) {
        if (requestParameters.permitId === null || requestParameters.permitId === undefined) {
            throw new runtime.RequiredError('permitId', 'Required parameter requestParameters.permitId was null or undefined when calling publishPermit.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/admin/permit/v1/permit/{permitId}/publish`.replace(`{${"permitId"}}`, encodeURIComponent(String(requestParameters.permitId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Permit을 publish한다.
     */
    async publishPermit(requestParameters, initOverrides) {
        await this.publishPermitRaw(requestParameters, initOverrides);
    }
    /**
     * Default permit을 설정한다.
     */
    async setDefaultPermitsRaw(requestParameters, initOverrides) {
        if (requestParameters.setDefaultPermitsRequest === null || requestParameters.setDefaultPermitsRequest === undefined) {
            throw new runtime.RequiredError('setDefaultPermitsRequest', 'Required parameter requestParameters.setDefaultPermitsRequest was null or undefined when calling setDefaultPermits.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/admin/permit/v1/permit/default`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetDefaultPermitsRequestToJSON(requestParameters.setDefaultPermitsRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => SetDefaultPermitsResponseFromJSON(jsonValue));
    }
    /**
     * Default permit을 설정한다.
     */
    async setDefaultPermits(requestParameters, initOverrides) {
        const response = await this.setDefaultPermitsRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Permit을 수정한다. Publish된 permit은 managementInfo만 수정할 수 있다.
     */
    async updatePermitRaw(requestParameters, initOverrides) {
        if (requestParameters.permitId === null || requestParameters.permitId === undefined) {
            throw new runtime.RequiredError('permitId', 'Required parameter requestParameters.permitId was null or undefined when calling updatePermit.');
        }
        if (requestParameters.updatePermitRequest === null || requestParameters.updatePermitRequest === undefined) {
            throw new runtime.RequiredError('updatePermitRequest', 'Required parameter requestParameters.updatePermitRequest was null or undefined when calling updatePermit.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/admin/permit/v1/permit/{permitId}`.replace(`{${"permitId"}}`, encodeURIComponent(String(requestParameters.permitId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdatePermitRequestToJSON(requestParameters.updatePermitRequest),
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Permit을 수정한다. Publish된 permit은 managementInfo만 수정할 수 있다.
     */
    async updatePermit(requestParameters, initOverrides) {
        await this.updatePermitRaw(requestParameters, initOverrides);
    }
}
