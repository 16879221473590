/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { CountingConfigFromJSON, CountingConfigToJSON, } from './CountingConfig';
import { PermitManagementInfoFromJSON, PermitManagementInfoToJSON, } from './PermitManagementInfo';
/**
 * Check if a given object implements the UpdatePermitRequest interface.
 */
export function instanceOfUpdatePermitRequest(value) {
    let isInstance = true;
    return isInstance;
}
export function UpdatePermitRequestFromJSON(json) {
    return UpdatePermitRequestFromJSONTyped(json, false);
}
export function UpdatePermitRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'managementInfo': !exists(json, 'managementInfo') ? undefined : PermitManagementInfoFromJSON(json['managementInfo']),
        'permitTypeId': !exists(json, 'permitTypeId') ? undefined : json['permitTypeId'],
        'countingConfig': !exists(json, 'countingConfig') ? undefined : CountingConfigFromJSON(json['countingConfig']),
        'resourceIds': !exists(json, 'resourceIds') ? undefined : json['resourceIds'],
    };
}
export function UpdatePermitRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'managementInfo': PermitManagementInfoToJSON(value.managementInfo),
        'permitTypeId': value.permitTypeId,
        'countingConfig': CountingConfigToJSON(value.countingConfig),
        'resourceIds': value.resourceIds,
    };
}
