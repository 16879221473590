/* tslint:disable */
/* eslint-disable */
/**
 *
 * @export
 */
export const SelfLearningUnitContentQueryOneOfCase = {
    QUESTION_BY_CONTENT_CONDITIONS: 'QUESTION_BY_CONTENT_CONDITIONS',
    LESSON_BY_CONTENT_ID: 'LESSON_BY_CONTENT_ID',
    VOCA_BY_CONTENT_IDS: 'VOCA_BY_CONTENT_IDS'
};
export function SelfLearningUnitContentQueryOneOfCaseFromJSON(json) {
    return SelfLearningUnitContentQueryOneOfCaseFromJSONTyped(json, false);
}
export function SelfLearningUnitContentQueryOneOfCaseFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function SelfLearningUnitContentQueryOneOfCaseToJSON(value) {
    return value;
}
