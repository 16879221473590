import { CountingTypeFromJSON, CountingTypeToJSON, } from './CountingType';
/**
 * Check if a given object implements the CountingConfig interface.
 */
export function instanceOfCountingConfig(value) {
    let isInstance = true;
    isInstance = isInstance && "count" in value;
    isInstance = isInstance && "countingType" in value;
    return isInstance;
}
export function CountingConfigFromJSON(json) {
    return CountingConfigFromJSONTyped(json, false);
}
export function CountingConfigFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'count': json['count'],
        'countingType': CountingTypeFromJSON(json['countingType']),
    };
}
export function CountingConfigToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'count': value.count,
        'countingType': CountingTypeToJSON(value.countingType),
    };
}
