// @ts-nocheck
import { name2num, num2name, } from "./(LegacyUser)/LegacyToeicProviderType";
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_2, decodeBinary as decodeBinary_2, } from "../../google/protobuf/Timestamp";
import { name2num as name2num_1, num2name as num2name_1, } from "./(LegacyUser)/LegacyToeicCountry";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../runtime/json/scalar";
import { WireType, } from "../../../runtime/wire/index";
import { default as serialize, } from "../../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../../runtime/wire/scalar";
import { default as Long, } from "../../../runtime/Long";
import { encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "../../google/protobuf/StringValue";
import { encodeBinary as encodeBinary_3, decodeBinary as decodeBinary_3, } from "../../google/protobuf/BoolValue";
import { default as deserialize, } from "../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        id: "0",
        uuid: "",
        providerType: "LEGACY_TOEIC_LOGIN_PROVIDER_UNSPECIFIED",
        providerId: undefined,
        contactEmail: "",
        name: "",
        isServiceTermsAgreed: false,
        serviceTermsAgreedAt: undefined,
        isMarketingAgreed: undefined,
        marketingAgreedAt: undefined,
        country: "LEGACY_TOEIC_COUNTRY_UNSPECIFIED",
        signedUpAt: undefined,
        createdAt: undefined,
        updatedAt: undefined,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.id !== undefined)
        result.id = tsValueToJsonValueFns.int64(value.id);
    if (value.uuid !== undefined)
        result.uuid = tsValueToJsonValueFns.string(value.uuid);
    if (value.providerType !== undefined)
        result.providerType = tsValueToJsonValueFns.enum(value.providerType);
    if (value.providerId !== undefined)
        result.providerId = value.providerId;
    if (value.contactEmail !== undefined)
        result.contactEmail = tsValueToJsonValueFns.string(value.contactEmail);
    if (value.name !== undefined)
        result.name = tsValueToJsonValueFns.string(value.name);
    if (value.isServiceTermsAgreed !== undefined)
        result.isServiceTermsAgreed = tsValueToJsonValueFns.bool(value.isServiceTermsAgreed);
    if (value.serviceTermsAgreedAt !== undefined)
        result.serviceTermsAgreedAt = encodeJson_1(value.serviceTermsAgreedAt);
    if (value.isMarketingAgreed !== undefined)
        result.isMarketingAgreed = value.isMarketingAgreed;
    if (value.marketingAgreedAt !== undefined)
        result.marketingAgreedAt = encodeJson_1(value.marketingAgreedAt);
    if (value.country !== undefined)
        result.country = tsValueToJsonValueFns.enum(value.country);
    if (value.signedUpAt !== undefined)
        result.signedUpAt = encodeJson_1(value.signedUpAt);
    if (value.createdAt !== undefined)
        result.createdAt = encodeJson_1(value.createdAt);
    if (value.updatedAt !== undefined)
        result.updatedAt = encodeJson_1(value.updatedAt);
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.id !== undefined)
        result.id = jsonValueToTsValueFns.int64(value.id);
    if (value.uuid !== undefined)
        result.uuid = jsonValueToTsValueFns.string(value.uuid);
    if (value.providerType !== undefined)
        result.providerType = jsonValueToTsValueFns.enum(value.providerType);
    if (value.providerId !== undefined)
        result.providerId = value.providerId;
    if (value.contactEmail !== undefined)
        result.contactEmail = jsonValueToTsValueFns.string(value.contactEmail);
    if (value.name !== undefined)
        result.name = jsonValueToTsValueFns.string(value.name);
    if (value.isServiceTermsAgreed !== undefined)
        result.isServiceTermsAgreed = jsonValueToTsValueFns.bool(value.isServiceTermsAgreed);
    if (value.serviceTermsAgreedAt !== undefined)
        result.serviceTermsAgreedAt = decodeJson_1(value.serviceTermsAgreedAt);
    if (value.isMarketingAgreed !== undefined)
        result.isMarketingAgreed = value.isMarketingAgreed;
    if (value.marketingAgreedAt !== undefined)
        result.marketingAgreedAt = decodeJson_1(value.marketingAgreedAt);
    if (value.country !== undefined)
        result.country = jsonValueToTsValueFns.enum(value.country);
    if (value.signedUpAt !== undefined)
        result.signedUpAt = decodeJson_1(value.signedUpAt);
    if (value.createdAt !== undefined)
        result.createdAt = decodeJson_1(value.createdAt);
    if (value.updatedAt !== undefined)
        result.updatedAt = decodeJson_1(value.updatedAt);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.id !== undefined) {
        const tsValue = value.id;
        result.push([1, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.uuid !== undefined) {
        const tsValue = value.uuid;
        result.push([2, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.providerType !== undefined) {
        const tsValue = value.providerType;
        result.push([3, { type: WireType.Varint, value: new Long(name2num[tsValue]) }]);
    }
    if (value.providerId !== undefined) {
        const tsValue = value.providerId;
        result.push([4, ((tsValue) => ({ type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }))({ value: tsValue })]);
    }
    if (value.contactEmail !== undefined) {
        const tsValue = value.contactEmail;
        result.push([5, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.name !== undefined) {
        const tsValue = value.name;
        result.push([6, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.isServiceTermsAgreed !== undefined) {
        const tsValue = value.isServiceTermsAgreed;
        result.push([7, tsValueToWireValueFns.bool(tsValue)]);
    }
    if (value.serviceTermsAgreedAt !== undefined) {
        const tsValue = value.serviceTermsAgreedAt;
        result.push([8, { type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }]);
    }
    if (value.isMarketingAgreed !== undefined) {
        const tsValue = value.isMarketingAgreed;
        result.push([9, ((tsValue) => ({ type: WireType.LengthDelimited, value: encodeBinary_3(tsValue) }))({ value: tsValue })]);
    }
    if (value.marketingAgreedAt !== undefined) {
        const tsValue = value.marketingAgreedAt;
        result.push([10, { type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }]);
    }
    if (value.country !== undefined) {
        const tsValue = value.country;
        result.push([11, { type: WireType.Varint, value: new Long(name2num_1[tsValue]) }]);
    }
    if (value.signedUpAt !== undefined) {
        const tsValue = value.signedUpAt;
        result.push([12, { type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }]);
    }
    if (value.createdAt !== undefined) {
        const tsValue = value.createdAt;
        result.push([13, { type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }]);
    }
    if (value.updatedAt !== undefined) {
        const tsValue = value.updatedAt;
        result.push([14, { type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    var _a, _b;
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.id = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.uuid = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.providerType = value;
    }
    field: {
        const wireValue = wireFields.get(4);
        if (wireValue === undefined)
            break field;
        const value = (_a = (wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined)) === null || _a === void 0 ? void 0 : _a.value;
        if (value === undefined)
            break field;
        result.providerId = value;
    }
    field: {
        const wireValue = wireFields.get(5);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.contactEmail = value;
    }
    field: {
        const wireValue = wireFields.get(6);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.name = value;
    }
    field: {
        const wireValue = wireFields.get(7);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.bool(wireValue);
        if (value === undefined)
            break field;
        result.isServiceTermsAgreed = value;
    }
    field: {
        const wireValue = wireFields.get(8);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.serviceTermsAgreedAt = value;
    }
    field: {
        const wireValue = wireFields.get(9);
        if (wireValue === undefined)
            break field;
        const value = (_b = (wireValue.type === WireType.LengthDelimited ? decodeBinary_3(wireValue.value) : undefined)) === null || _b === void 0 ? void 0 : _b.value;
        if (value === undefined)
            break field;
        result.isMarketingAgreed = value;
    }
    field: {
        const wireValue = wireFields.get(10);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.marketingAgreedAt = value;
    }
    field: {
        const wireValue = wireFields.get(11);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name_1[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.country = value;
    }
    field: {
        const wireValue = wireFields.get(12);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.signedUpAt = value;
    }
    field: {
        const wireValue = wireFields.get(13);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.createdAt = value;
    }
    field: {
        const wireValue = wireFields.get(14);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.updatedAt = value;
    }
    return result;
}
