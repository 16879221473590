import { SelfLearningContentTypeFromJSON, SelfLearningContentTypeToJSON, } from './SelfLearningContentType';
import { SelfLearningUnitOneOfContentQueryFromJSON, SelfLearningUnitOneOfContentQueryToJSON, } from './SelfLearningUnitOneOfContentQuery';
import { SelfLearningUnitStatusFromJSON, SelfLearningUnitStatusToJSON, } from './SelfLearningUnitStatus';
/**
 * Check if a given object implements the SelfLearningUnit interface.
 */
export function instanceOfSelfLearningUnit(value) {
    let isInstance = true;
    isInstance = isInstance && "contentQuery" in value;
    isInstance = isInstance && "tagList" in value;
    isInstance = isInstance && "subTitle" in value;
    isInstance = isInstance && "iconResourceId" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "contentType" in value;
    isInstance = isInstance && "status" in value;
    return isInstance;
}
export function SelfLearningUnitFromJSON(json) {
    return SelfLearningUnitFromJSONTyped(json, false);
}
export function SelfLearningUnitFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'contentQuery': SelfLearningUnitOneOfContentQueryFromJSON(json['contentQuery']),
        'tagList': json['tagList'],
        'subTitle': json['subTitle'],
        'iconResourceId': json['iconResourceId'],
        'id': json['id'],
        'title': json['title'],
        'contentType': SelfLearningContentTypeFromJSON(json['contentType']),
        'status': SelfLearningUnitStatusFromJSON(json['status']),
    };
}
export function SelfLearningUnitToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'contentQuery': SelfLearningUnitOneOfContentQueryToJSON(value.contentQuery),
        'tagList': value.tagList,
        'subTitle': value.subTitle,
        'iconResourceId': value.iconResourceId,
        'id': value.id,
        'title': value.title,
        'contentType': SelfLearningContentTypeToJSON(value.contentType),
        'status': SelfLearningUnitStatusToJSON(value.status),
    };
}
