// @ts-nocheck
import { name2num, num2name, } from "./(SubscriptionEventLog)/SubscriptionEvent";
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "../../../../../google/protobuf/Timestamp";
import { encodeJson as encodeJson_2, decodeJson as decodeJson_2, encodeBinary as encodeBinary_2, decodeBinary as decodeBinary_2, } from "./(SubscriptionEventLog)/DebugInfo";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../../../../runtime/json/scalar";
import { WireType, } from "../../../../../../runtime/wire/index";
import { default as serialize, } from "../../../../../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../../../../../runtime/wire/scalar";
import { default as Long, } from "../../../../../../runtime/Long";
import { default as deserialize, } from "../../../../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        id: "0",
        subscriptionId: "0",
        subscriptionEvent: "SUBSCRIPTION_EVENT_UNSPECIFIED",
        eventAt: undefined,
        debugInfo: undefined,
        timestamp: undefined,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.id !== undefined)
        result.id = tsValueToJsonValueFns.uint64(value.id);
    if (value.subscriptionId !== undefined)
        result.subscriptionId = tsValueToJsonValueFns.uint64(value.subscriptionId);
    if (value.subscriptionEvent !== undefined)
        result.subscriptionEvent = tsValueToJsonValueFns.enum(value.subscriptionEvent);
    if (value.eventAt !== undefined)
        result.eventAt = encodeJson_1(value.eventAt);
    if (value.debugInfo !== undefined)
        result.debugInfo = encodeJson_2(value.debugInfo);
    if (value.timestamp !== undefined)
        result.timestamp = encodeJson_1(value.timestamp);
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.id !== undefined)
        result.id = jsonValueToTsValueFns.uint64(value.id);
    if (value.subscriptionId !== undefined)
        result.subscriptionId = jsonValueToTsValueFns.uint64(value.subscriptionId);
    if (value.subscriptionEvent !== undefined)
        result.subscriptionEvent = jsonValueToTsValueFns.enum(value.subscriptionEvent);
    if (value.eventAt !== undefined)
        result.eventAt = decodeJson_1(value.eventAt);
    if (value.debugInfo !== undefined)
        result.debugInfo = decodeJson_2(value.debugInfo);
    if (value.timestamp !== undefined)
        result.timestamp = decodeJson_1(value.timestamp);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.id !== undefined) {
        const tsValue = value.id;
        result.push([1, tsValueToWireValueFns.uint64(tsValue)]);
    }
    if (value.subscriptionId !== undefined) {
        const tsValue = value.subscriptionId;
        result.push([2, tsValueToWireValueFns.uint64(tsValue)]);
    }
    if (value.subscriptionEvent !== undefined) {
        const tsValue = value.subscriptionEvent;
        result.push([3, { type: WireType.Varint, value: new Long(name2num[tsValue]) }]);
    }
    if (value.eventAt !== undefined) {
        const tsValue = value.eventAt;
        result.push([4, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    if (value.debugInfo !== undefined) {
        const tsValue = value.debugInfo;
        result.push([5, { type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }]);
    }
    if (value.timestamp !== undefined) {
        const tsValue = value.timestamp;
        result.push([6, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.uint64(wireValue);
        if (value === undefined)
            break field;
        result.id = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.uint64(wireValue);
        if (value === undefined)
            break field;
        result.subscriptionId = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.subscriptionEvent = value;
    }
    field: {
        const wireValue = wireFields.get(4);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.eventAt = value;
    }
    field: {
        const wireValue = wireFields.get(5);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.debugInfo = value;
    }
    field: {
        const wireValue = wireFields.get(6);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.timestamp = value;
    }
    return result;
}
