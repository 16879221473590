/* tslint:disable */
/* eslint-disable */
/**
 *
 * @export
 */
export const SelfLearningContentType = {
    QUESTION: 'QUESTION',
    LESSON: 'LESSON',
    VOCABULARY: 'VOCABULARY'
};
export function SelfLearningContentTypeFromJSON(json) {
    return SelfLearningContentTypeFromJSONTyped(json, false);
}
export function SelfLearningContentTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function SelfLearningContentTypeToJSON(value) {
    return value;
}
