export const num2name = {
    0: "ERROR_UNSPECIFIED",
    1: "ERROR_INVALID_PRODUCT_TEMPLATE",
    2: "ERROR_PRODUCT_NOT_FOUND",
    3: "ERROR_PRODUCT_ALREADY_PUBLISHED",
};
export const name2num = {
    ERROR_UNSPECIFIED: 0,
    ERROR_INVALID_PRODUCT_TEMPLATE: 1,
    ERROR_PRODUCT_NOT_FOUND: 2,
    ERROR_PRODUCT_ALREADY_PUBLISHED: 3,
};
