// @ts-nocheck
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "../../virtual_exam/(ToeicVirtualExam)/Metadata";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../../runtime/json/scalar";
import { WireType, } from "../../../../runtime/wire/index";
import { default as serialize, } from "../../../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../../../runtime/wire/scalar";
import { default as deserialize, } from "../../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        virtualExamId: "0",
        metadata: undefined,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.virtualExamId !== undefined)
        result.virtualExamId = tsValueToJsonValueFns.int64(value.virtualExamId);
    if (value.metadata !== undefined)
        result.metadata = encodeJson_1(value.metadata);
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.virtualExamId !== undefined)
        result.virtualExamId = jsonValueToTsValueFns.int64(value.virtualExamId);
    if (value.metadata !== undefined)
        result.metadata = decodeJson_1(value.metadata);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.virtualExamId !== undefined) {
        const tsValue = value.virtualExamId;
        result.push([1, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.metadata !== undefined) {
        const tsValue = value.metadata;
        result.push([2, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.virtualExamId = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.metadata = value;
    }
    return result;
}
