export const num2name = {
    0: "TYPE_UNSPECIFIED",
    1: "TYPE_CREATE_AUTHENTICATION",
    2: "TYPE_CREATE_DEVICE_ACCESS_LOG",
    3: "TYPE_UPDATE_AUTHENTICATION",
    4: "TYPE_GET_USER_TOKEN_BY_LOGIN",
    5: "TYPE_GET_USER_TOKEN_BY_REFRESH",
};
export const name2num = {
    TYPE_UNSPECIFIED: 0,
    TYPE_CREATE_AUTHENTICATION: 1,
    TYPE_CREATE_DEVICE_ACCESS_LOG: 2,
    TYPE_UPDATE_AUTHENTICATION: 3,
    TYPE_GET_USER_TOKEN_BY_LOGIN: 4,
    TYPE_GET_USER_TOKEN_BY_REFRESH: 5,
};
