import type {LearningDomainListItem} from '@santa-web/gen/open-api/admin';

export type IsoCountryCodes = 'KR' | 'JP' | 'US' | 'TW' | 'VN' | 'TH';

export const RIIID_COUNTRY_HEADER_KEY = 'riiid-country';

export const isoCountrySelections: Record<IsoCountryCodes, string> = {
  KR: 'KR 🇰🇷',
  JP: 'JP 🇯🇵',
  US: 'US 🇺🇸',
  TW: 'TW 🇹🇼',
  VN: 'VN 🇻🇳',
  TH: 'TH 🇹🇭',
};

export const availableCountriesForLearningDomains: Record<LearningDomainListItem['key'], IsoCountryCodes[]> = {
  G_TELP: ['KR'],
  TOEIC: ['KR', 'JP', 'US', 'TW', 'VN', 'TH'],
};
