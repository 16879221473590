// @ts-nocheck
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_3, decodeBinary as decodeBinary_3, } from "../../../../google/protobuf/Timestamp";
import { name2num, num2name, } from "../../../commerce/OrderStatus";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../../../runtime/json/scalar";
import { WireType, } from "../../../../../runtime/wire/index";
import { default as serialize, } from "../../../../../runtime/wire/serialize";
import { encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "../../../../google/protobuf/StringValue";
import { encodeBinary as encodeBinary_2, decodeBinary as decodeBinary_2, } from "../../../../google/protobuf/Int64Value";
import { default as Long, } from "../../../../../runtime/Long";
import { default as deserialize, } from "../../../../../runtime/wire/deserialize";
import { unpackFns, } from "../../../../../runtime/wire/scalar";
export function getDefaultValue() {
    return {
        userId: undefined,
        productId: undefined,
        createdAtFrom: undefined,
        createdAtUntil: undefined,
        status: [],
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.userId !== undefined)
        result.userId = value.userId;
    if (value.productId !== undefined)
        result.productId = value.productId;
    if (value.createdAtFrom !== undefined)
        result.createdAtFrom = encodeJson_1(value.createdAtFrom);
    if (value.createdAtUntil !== undefined)
        result.createdAtUntil = encodeJson_1(value.createdAtUntil);
    result.status = value.status.map(value => tsValueToJsonValueFns.enum(value));
    return result;
}
export function decodeJson(value) {
    var _a, _b;
    const result = getDefaultValue();
    if (value.userId !== undefined)
        result.userId = value.userId;
    if (value.productId !== undefined)
        result.productId = value.productId;
    if (value.createdAtFrom !== undefined)
        result.createdAtFrom = decodeJson_1(value.createdAtFrom);
    if (value.createdAtUntil !== undefined)
        result.createdAtUntil = decodeJson_1(value.createdAtUntil);
    result.status = (_b = (_a = value.status) === null || _a === void 0 ? void 0 : _a.map((value) => jsonValueToTsValueFns.enum(value))) !== null && _b !== void 0 ? _b : [];
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.userId !== undefined) {
        const tsValue = value.userId;
        result.push([1, ((tsValue) => ({ type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }))({ value: tsValue })]);
    }
    if (value.productId !== undefined) {
        const tsValue = value.productId;
        result.push([2, ((tsValue) => ({ type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }))({ value: tsValue })]);
    }
    if (value.createdAtFrom !== undefined) {
        const tsValue = value.createdAtFrom;
        result.push([3, { type: WireType.LengthDelimited, value: encodeBinary_3(tsValue) }]);
    }
    if (value.createdAtUntil !== undefined) {
        const tsValue = value.createdAtUntil;
        result.push([4, { type: WireType.LengthDelimited, value: encodeBinary_3(tsValue) }]);
    }
    for (const tsValue of value.status) {
        result.push([5, { type: WireType.Varint, value: new Long(name2num[tsValue]) }]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    var _a, _b;
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = (_a = (wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined)) === null || _a === void 0 ? void 0 : _a.value;
        if (value === undefined)
            break field;
        result.userId = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = (_b = (wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined)) === null || _b === void 0 ? void 0 : _b.value;
        if (value === undefined)
            break field;
        result.productId = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_3(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.createdAtFrom = value;
    }
    field: {
        const wireValue = wireFields.get(4);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_3(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.createdAtUntil = value;
    }
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 5).map(([, wireValue]) => wireValue);
        const value = Array.from(unpackFns.int32(wireValues)).map(num => num2name[num]);
        if (!value.length)
            break collection;
        result.status = value;
    }
    return result;
}
