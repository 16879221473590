import {useRouter} from 'next/router';
import React, {useMemo} from 'react';
import {useAtom, useAtomValue} from 'jotai';

import {
  Configuration as AdminConfiguration,
  LearningDomainAdminV1Api,
  StripeProductAdminV1Api,
  StripeProductBoardAdminV1Api,
  StripeSubscriptionOrderAdminV1Api,
  UserAdminV1Api,
  PermitAdminV1PermitApi,
  PermitAdminV1PermitTypeApi,
  PermitAdminV1TicketApi,
  PermitAdminV1PassApi,
  ConfigurationParameters,
  AssetAdminV1Api,
  CommerceAdminV2OfferGroupApi,
} from '@santa-web/gen/open-api/admin';
import {
  Configuration as ContentLearningAdminConfiguration,
  SelfLearningUnitGroupAdminV1Api,
  SelfLearningUnitAdminV1Api,
  SelfLearningUnitByUnitGroupAdminV1Api,
  SelfLearningContentTypeAdminV2Api,
  SelfLearningQuestionContentsAdminV1Api,
  SelfLearningVerificationAdminV1Api,
} from '@santa-web/gen/open-api/content-learning-admin';
import {signOut} from '@santa-web/gen/ssp/backoffice-auth';
import {riiidCountryAtom} from '#app/atoms/country';
import {SantaAdminHttpClient, santaAdminHttpClientAtom} from '#app/atoms/http-client';
import {
  ADMIN_RIIID_LEARNING_DOMAIN_STORAGE_KEY,
  riiidLearningDomainAtom,
  RIIID_LEARNING_DOMAIN_HEADER_KEY,
} from '#app/atoms/learning-domain';
import {tokenAtom} from '#app/atoms/token';
import config, {vercelEnv} from '#app/config';
import {RIIID_COUNTRY_HEADER_KEY, availableCountriesForLearningDomains} from '#app/constants/isoCountries';
import {useListLearningDomains} from '#app/hooks/useListLearningDomains';
import {SantaFetchError, SantaResponseError} from '#app/utils/error';

import {useSnackbarContext} from './Snackbar';

interface CreateHttpClientProps {
  headers: Record<string, string>;
}

export const useHttpClient = ({headers}: CreateHttpClientProps): SantaAdminHttpClient => {
  const router = useRouter();
  const {showSnackbar} = useSnackbarContext();

  const param: ConfigurationParameters = React.useMemo(
    () => ({
      basePath: config.httpHostname,
      headers: headers,
      middleware: [
        {
          onError: async ({error}) => {
            if (error instanceof Error) {
              throw new SantaFetchError(error);
            }
            throw error;
          },
          post: async ({response}) => {
            if (response.status >= 200 && response.status < 300) {
              return response;
            }
            const santaResponseError = await SantaResponseError.fromResponse(response);
            if (response.status === 401) {
              showSnackbar(`요청 실패 | 권한이 만료되었습니다`);
              if (vercelEnv !== 'production') {
                localStorage.removeItem('token');
                window.location.reload();
              } else {
                signOut();
              }
            } else if (response.status === 403) {
              showSnackbar(`요청 실패 | 권한이 없습니다`);
              await router.replace('/403');
              return;
            } else {
              showSnackbar(`요청 실패 | ${santaResponseError.santaErrorCode ?? santaResponseError.message}`);
            }
            throw santaResponseError;
          },
        },
      ],
    }),
    [headers, router, showSnackbar]
  );
  const adminConfiguration = React.useMemo(() => new AdminConfiguration(param), [param]);
  const contentLearningAdminConfiguration = React.useMemo(() => new ContentLearningAdminConfiguration(param), [param]);

  return React.useMemo(
    (): SantaAdminHttpClient => ({
      LearningDomainService: new LearningDomainAdminV1Api(adminConfiguration),
      UserService: new UserAdminV1Api(adminConfiguration),
      StripeProductService: new StripeProductAdminV1Api(adminConfiguration),
      StripeProductBoardService: new StripeProductBoardAdminV1Api(adminConfiguration),
      StripeSubscriptionOrderService: new StripeSubscriptionOrderAdminV1Api(adminConfiguration),
      SelfLearningContentTypeService: new SelfLearningContentTypeAdminV2Api(contentLearningAdminConfiguration),
      SelfLearningUnitService: new SelfLearningUnitAdminV1Api(contentLearningAdminConfiguration),
      SelfLearningUnitGroupService: new SelfLearningUnitGroupAdminV1Api(contentLearningAdminConfiguration),
      SelfLearningUnitByUnitGroupService: new SelfLearningUnitByUnitGroupAdminV1Api(contentLearningAdminConfiguration),
      SelfLearningQuestionContentsService: new SelfLearningQuestionContentsAdminV1Api(
        contentLearningAdminConfiguration
      ),
      SelfLearningVerificationService: new SelfLearningVerificationAdminV1Api(contentLearningAdminConfiguration),
      PermitService: new PermitAdminV1PermitApi(adminConfiguration),
      PermitTypeService: new PermitAdminV1PermitTypeApi(adminConfiguration),
      TicketService: new PermitAdminV1TicketApi(adminConfiguration),
      PassService: new PermitAdminV1PassApi(adminConfiguration),
      AssetService: new AssetAdminV1Api(adminConfiguration),
      CommerceOfferGroupService: new CommerceAdminV2OfferGroupApi(adminConfiguration),
    }),
    [adminConfiguration, contentLearningAdminConfiguration]
  );
};

const SantaAdminHttpClientGuard = ({children}: {children: React.ReactNode}) => {
  const [currentRiiidLearningDomain, setCurrentRiiidLearningDomain] = useAtom(riiidLearningDomainAtom);
  const [httpClient, setHttpClient] = useAtom(santaAdminHttpClientAtom);
  const [riiidCountry, setRiiidCountry] = useAtom(riiidCountryAtom);
  const {data: learningDomainList} = useListLearningDomains();
  const token = useAtomValue(tokenAtom);
  const createdHttpClient = useHttpClient({
    headers: useMemo(
      () => ({
        Authorization: `Bearer ${token}`,
        [RIIID_LEARNING_DOMAIN_HEADER_KEY]: String(currentRiiidLearningDomain?.id),
        [RIIID_COUNTRY_HEADER_KEY]: riiidCountry,
      }),
      [token, currentRiiidLearningDomain, riiidCountry]
    ),
  });

  // Initialize riiidLearningDomain
  React.useEffect(() => {
    if (currentRiiidLearningDomain !== null || learningDomainList == null) {
      return;
    }
    const storedRiiidLearningDomainId = localStorage.getItem(ADMIN_RIIID_LEARNING_DOMAIN_STORAGE_KEY);
    if (storedRiiidLearningDomainId) {
      const storedLearningDomainId = Number(storedRiiidLearningDomainId);
      const learningDomain = learningDomainList.find(({id}) => id === storedLearningDomainId);
      if (Number.isSafeInteger(storedLearningDomainId) && learningDomain) {
        setCurrentRiiidLearningDomain(learningDomain);
        return;
      }
      localStorage.removeItem(ADMIN_RIIID_LEARNING_DOMAIN_STORAGE_KEY);
    }
    setCurrentRiiidLearningDomain(learningDomainList[0]);
  }, [currentRiiidLearningDomain, setCurrentRiiidLearningDomain, learningDomainList]);

  // Initialize HttpClient
  React.useEffect(() => {
    if (currentRiiidLearningDomain) {
      setHttpClient(createdHttpClient);
    }
  }, [currentRiiidLearningDomain, setHttpClient, createdHttpClient]);

  // Sync country with riiidLearningDomain
  React.useEffect(() => {
    if (currentRiiidLearningDomain) {
      availableCountriesForLearningDomains[currentRiiidLearningDomain.key].includes(riiidCountry) ||
        setRiiidCountry('KR');
    }
  }, [riiidCountry, currentRiiidLearningDomain, setRiiidCountry]);

  if (httpClient == null || currentRiiidLearningDomain == null) {
    return null;
  }
  return <>{children}</>;
};

export default React.memo(SantaAdminHttpClientGuard);
