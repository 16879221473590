// @ts-nocheck
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "./SettlementPeriod";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../../../runtime/json/scalar";
import { WireType, } from "../../../../../runtime/wire/index";
import { default as serialize, } from "../../../../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../../../../runtime/wire/scalar";
import { default as deserialize, } from "../../../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        period: undefined,
        appleCurrencyData: new Map(),
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.period !== undefined)
        result.period = encodeJson_1(value.period);
    if (value.appleCurrencyData !== undefined)
        result.appleCurrencyData = Object.fromEntries([...value.appleCurrencyData.entries()].map(([key, value]) => [key, tsValueToJsonValueFns.double(value)]));
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.period !== undefined)
        result.period = decodeJson_1(value.period);
    if (value.appleCurrencyData !== undefined)
        result.appleCurrencyData = Object.fromEntries([...value.appleCurrencyData.entries()].map(([key, value]) => [key, jsonValueToTsValueFns.double(value)]));
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.period !== undefined) {
        const tsValue = value.period;
        result.push([1, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    {
        const fields = value.appleCurrencyData.entries();
        for (const [key, value] of fields) {
            result.push([2, { type: WireType.LengthDelimited, value: serialize([[1, tsValueToWireValueFns.string(key)], [2, tsValueToWireValueFns.double(value)]]) }]);
        }
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.period = value;
    }
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 2).map(([, wireValue]) => wireValue);
        const value = wireValues.map((wireValue) => (() => { if (wireValue.type !== WireType.LengthDelimited) {
            return;
        } const { 1: key, 2: value } = Object.fromEntries(deserialize(wireValue.value)); if (key === undefined || value === undefined)
            return; return [wireValueToTsValueFns.string(key), wireValueToTsValueFns.double(value)]; })()).filter(x => x !== undefined);
        if (!value.length)
            break collection;
        result.appleCurrencyData = new Map(value);
    }
    return result;
}
