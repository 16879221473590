import { StripeProductFromJSON, StripeProductToJSON, } from './StripeProduct';
/**
 * Check if a given object implements the UpdateStripeProductResponse interface.
 */
export function instanceOfUpdateStripeProductResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "updatedProduct" in value;
    return isInstance;
}
export function UpdateStripeProductResponseFromJSON(json) {
    return UpdateStripeProductResponseFromJSONTyped(json, false);
}
export function UpdateStripeProductResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'updatedProduct': StripeProductFromJSON(json['updatedProduct']),
    };
}
export function UpdateStripeProductResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'updatedProduct': StripeProductToJSON(value.updatedProduct),
    };
}
