// @ts-nocheck
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_2, decodeBinary as decodeBinary_2, } from "../../../google/protobuf/Timestamp";
import { WireType, } from "../../../../runtime/wire/index";
import { default as serialize, } from "../../../../runtime/wire/serialize";
import { encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "../../../google/protobuf/BoolValue";
import { default as deserialize, } from "../../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        agreeServiceTerms: undefined,
        agreePrivacy: undefined,
        agreeMarketing: undefined,
        agreeMarketingAt: undefined,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.agreeServiceTerms !== undefined)
        result.agreeServiceTerms = value.agreeServiceTerms;
    if (value.agreePrivacy !== undefined)
        result.agreePrivacy = value.agreePrivacy;
    if (value.agreeMarketing !== undefined)
        result.agreeMarketing = value.agreeMarketing;
    if (value.agreeMarketingAt !== undefined)
        result.agreeMarketingAt = encodeJson_1(value.agreeMarketingAt);
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.agreeServiceTerms !== undefined)
        result.agreeServiceTerms = value.agreeServiceTerms;
    if (value.agreePrivacy !== undefined)
        result.agreePrivacy = value.agreePrivacy;
    if (value.agreeMarketing !== undefined)
        result.agreeMarketing = value.agreeMarketing;
    if (value.agreeMarketingAt !== undefined)
        result.agreeMarketingAt = decodeJson_1(value.agreeMarketingAt);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.agreeServiceTerms !== undefined) {
        const tsValue = value.agreeServiceTerms;
        result.push([1, ((tsValue) => ({ type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }))({ value: tsValue })]);
    }
    if (value.agreePrivacy !== undefined) {
        const tsValue = value.agreePrivacy;
        result.push([2, ((tsValue) => ({ type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }))({ value: tsValue })]);
    }
    if (value.agreeMarketing !== undefined) {
        const tsValue = value.agreeMarketing;
        result.push([3, ((tsValue) => ({ type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }))({ value: tsValue })]);
    }
    if (value.agreeMarketingAt !== undefined) {
        const tsValue = value.agreeMarketingAt;
        result.push([4, { type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    var _a, _b, _c;
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = (_a = (wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined)) === null || _a === void 0 ? void 0 : _a.value;
        if (value === undefined)
            break field;
        result.agreeServiceTerms = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = (_b = (wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined)) === null || _b === void 0 ? void 0 : _b.value;
        if (value === undefined)
            break field;
        result.agreePrivacy = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = (_c = (wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined)) === null || _c === void 0 ? void 0 : _c.value;
        if (value === undefined)
            break field;
        result.agreeMarketing = value;
    }
    field: {
        const wireValue = wireFields.get(4);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.agreeMarketingAt = value;
    }
    return result;
}
