import { OfferGroupDisplayInfoFromJSON, OfferGroupDisplayInfoToJSON, } from './OfferGroupDisplayInfo';
/**
 * Check if a given object implements the OfferGroupDisplayConfig interface.
 */
export function instanceOfOfferGroupDisplayConfig(value) {
    let isInstance = true;
    isInstance = isInstance && "displayInfo" in value;
    return isInstance;
}
export function OfferGroupDisplayConfigFromJSON(json) {
    return OfferGroupDisplayConfigFromJSONTyped(json, false);
}
export function OfferGroupDisplayConfigFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'displayInfo': OfferGroupDisplayInfoFromJSON(json['displayInfo']),
    };
}
export function OfferGroupDisplayConfigToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'displayInfo': OfferGroupDisplayInfoToJSON(value.displayInfo),
    };
}
