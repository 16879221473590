// @ts-nocheck
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "./(ListGiftCodeTypesResponse)/GiftCodeTypeStatus";
import { encodeJson as encodeJson_2, decodeJson as decodeJson_2, encodeBinary as encodeBinary_2, decodeBinary as decodeBinary_2, } from "../../../Page";
import { WireType, } from "../../../../runtime/wire/index";
import { default as serialize, } from "../../../../runtime/wire/serialize";
import { default as deserialize, } from "../../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        types: [],
        page: undefined,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    result.types = value.types.map(value => encodeJson_1(value));
    if (value.page !== undefined)
        result.page = encodeJson_2(value.page);
    return result;
}
export function decodeJson(value) {
    var _a, _b;
    const result = getDefaultValue();
    result.types = (_b = (_a = value.types) === null || _a === void 0 ? void 0 : _a.map((value) => decodeJson_1(value))) !== null && _b !== void 0 ? _b : [];
    if (value.page !== undefined)
        result.page = decodeJson_2(value.page);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    for (const tsValue of value.types) {
        result.push([1, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    if (value.page !== undefined) {
        const tsValue = value.page;
        result.push([2, { type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 1).map(([, wireValue]) => wireValue);
        const value = wireValues.map((wireValue) => wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined).filter(x => x !== undefined);
        if (!value.length)
            break collection;
        result.types = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.page = value;
    }
    return result;
}
