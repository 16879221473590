/**
 * Check if a given object implements the SetDefaultPermitsResponse interface.
 */
export function instanceOfSetDefaultPermitsResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "defaultPermitIds" in value;
    return isInstance;
}
export function SetDefaultPermitsResponseFromJSON(json) {
    return SetDefaultPermitsResponseFromJSONTyped(json, false);
}
export function SetDefaultPermitsResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'defaultPermitIds': json['defaultPermitIds'],
    };
}
export function SetDefaultPermitsResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'defaultPermitIds': value.defaultPermitIds,
    };
}
