// @ts-nocheck
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_3, decodeBinary as decodeBinary_3, } from "../../../google/protobuf/Timestamp";
import { name2num, num2name, } from "../../../inside/Country";
import { name2num as name2num_1, num2name as num2name_1, } from "../../../inside/Language";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../../runtime/json/scalar";
import { WireType, } from "../../../../runtime/wire/index";
import { default as serialize, } from "../../../../runtime/wire/serialize";
import { encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "../../../google/protobuf/StringValue";
import { encodeBinary as encodeBinary_2, decodeBinary as decodeBinary_2, } from "../../../google/protobuf/Int64Value";
import { default as Long, } from "../../../../runtime/Long";
import { default as deserialize, } from "../../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        nickname: undefined,
        latestScore: undefined,
        targetScore: undefined,
        examinationDate: undefined,
        phoneNumber: undefined,
        contactEmail: undefined,
        country: "UNSPECIFIED_COUNTRY",
        language: "UNSPECIFIED_LANGUAGE",
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.nickname !== undefined)
        result.nickname = value.nickname;
    if (value.latestScore !== undefined)
        result.latestScore = value.latestScore;
    if (value.targetScore !== undefined)
        result.targetScore = value.targetScore;
    if (value.examinationDate !== undefined)
        result.examinationDate = encodeJson_1(value.examinationDate);
    if (value.phoneNumber !== undefined)
        result.phoneNumber = value.phoneNumber;
    if (value.contactEmail !== undefined)
        result.contactEmail = value.contactEmail;
    if (value.country !== undefined)
        result.country = tsValueToJsonValueFns.enum(value.country);
    if (value.language !== undefined)
        result.language = tsValueToJsonValueFns.enum(value.language);
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.nickname !== undefined)
        result.nickname = value.nickname;
    if (value.latestScore !== undefined)
        result.latestScore = value.latestScore;
    if (value.targetScore !== undefined)
        result.targetScore = value.targetScore;
    if (value.examinationDate !== undefined)
        result.examinationDate = decodeJson_1(value.examinationDate);
    if (value.phoneNumber !== undefined)
        result.phoneNumber = value.phoneNumber;
    if (value.contactEmail !== undefined)
        result.contactEmail = value.contactEmail;
    if (value.country !== undefined)
        result.country = jsonValueToTsValueFns.enum(value.country);
    if (value.language !== undefined)
        result.language = jsonValueToTsValueFns.enum(value.language);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.nickname !== undefined) {
        const tsValue = value.nickname;
        result.push([1, ((tsValue) => ({ type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }))({ value: tsValue })]);
    }
    if (value.latestScore !== undefined) {
        const tsValue = value.latestScore;
        result.push([2, ((tsValue) => ({ type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }))({ value: tsValue })]);
    }
    if (value.targetScore !== undefined) {
        const tsValue = value.targetScore;
        result.push([3, ((tsValue) => ({ type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }))({ value: tsValue })]);
    }
    if (value.examinationDate !== undefined) {
        const tsValue = value.examinationDate;
        result.push([4, { type: WireType.LengthDelimited, value: encodeBinary_3(tsValue) }]);
    }
    if (value.phoneNumber !== undefined) {
        const tsValue = value.phoneNumber;
        result.push([5, ((tsValue) => ({ type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }))({ value: tsValue })]);
    }
    if (value.contactEmail !== undefined) {
        const tsValue = value.contactEmail;
        result.push([6, ((tsValue) => ({ type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }))({ value: tsValue })]);
    }
    if (value.country !== undefined) {
        const tsValue = value.country;
        result.push([7, { type: WireType.Varint, value: new Long(name2num[tsValue]) }]);
    }
    if (value.language !== undefined) {
        const tsValue = value.language;
        result.push([8, { type: WireType.Varint, value: new Long(name2num_1[tsValue]) }]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    var _a, _b, _c, _d, _e;
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = (_a = (wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined)) === null || _a === void 0 ? void 0 : _a.value;
        if (value === undefined)
            break field;
        result.nickname = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = (_b = (wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined)) === null || _b === void 0 ? void 0 : _b.value;
        if (value === undefined)
            break field;
        result.latestScore = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = (_c = (wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined)) === null || _c === void 0 ? void 0 : _c.value;
        if (value === undefined)
            break field;
        result.targetScore = value;
    }
    field: {
        const wireValue = wireFields.get(4);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_3(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.examinationDate = value;
    }
    field: {
        const wireValue = wireFields.get(5);
        if (wireValue === undefined)
            break field;
        const value = (_d = (wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined)) === null || _d === void 0 ? void 0 : _d.value;
        if (value === undefined)
            break field;
        result.phoneNumber = value;
    }
    field: {
        const wireValue = wireFields.get(6);
        if (wireValue === undefined)
            break field;
        const value = (_e = (wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined)) === null || _e === void 0 ? void 0 : _e.value;
        if (value === undefined)
            break field;
        result.contactEmail = value;
    }
    field: {
        const wireValue = wireFields.get(7);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.country = value;
    }
    field: {
        const wireValue = wireFields.get(8);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name_1[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.language = value;
    }
    return result;
}
