import { InstantRangeFromJSON, InstantRangeToJSON, } from './InstantRange';
import { PaymentTypeFromJSON, PaymentTypeToJSON, } from './PaymentType';
/**
 * Check if a given object implements the OfferGroupSalesInfo interface.
 */
export function instanceOfOfferGroupSalesInfo(value) {
    let isInstance = true;
    isInstance = isInstance && "salesPeriod" in value;
    isInstance = isInstance && "paymentType" in value;
    return isInstance;
}
export function OfferGroupSalesInfoFromJSON(json) {
    return OfferGroupSalesInfoFromJSONTyped(json, false);
}
export function OfferGroupSalesInfoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'salesPeriod': InstantRangeFromJSON(json['salesPeriod']),
        'paymentType': PaymentTypeFromJSON(json['paymentType']),
    };
}
export function OfferGroupSalesInfoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'salesPeriod': InstantRangeToJSON(value.salesPeriod),
        'paymentType': PaymentTypeToJSON(value.paymentType),
    };
}
