/* tslint:disable */
/* eslint-disable */
import * as runtime from '../runtime';
import { GetUserDetailResponseFromJSON, GetUserListResponseFromJSON, ListUserStripeSubscriptionOrdersResponseFromJSON, UpdateAgreementInfoRequestToJSON, UpdateAgreementInfoResponseFromJSON, WithdrawUserResponseFromJSON, } from '../models';
/**
 *
 */
export class UserAdminV1Api extends runtime.BaseAPI {
    /**
     */
    async getUserDetailRaw(requestParameters, initOverrides) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling getUserDetail.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/admin/user/v1/user/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GetUserDetailResponseFromJSON(jsonValue));
    }
    /**
     */
    async getUserDetail(requestParameters, initOverrides) {
        const response = await this.getUserDetailRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async getUserListRaw(requestParameters, initOverrides) {
        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size', 'Required parameter requestParameters.size was null or undefined when calling getUserList.');
        }
        const queryParameters = {};
        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }
        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }
        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }
        if (requestParameters.authId !== undefined) {
            queryParameters['authId'] = requestParameters.authId;
        }
        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }
        if (requestParameters.email !== undefined) {
            queryParameters['email'] = requestParameters.email;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/api/admin/user/v1/user/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GetUserListResponseFromJSON(jsonValue));
    }
    /**
     */
    async getUserList(requestParameters, initOverrides) {
        const response = await this.getUserListRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 사용자의 구매(주문) 목록을 가져옵니다.
     */
    async listStripeSubscriptionOrdersRaw(requestParameters, initOverrides) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling listStripeSubscriptionOrders.');
        }
        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size', 'Required parameter requestParameters.size was null or undefined when calling listStripeSubscriptionOrders.');
        }
        const queryParameters = {};
        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }
        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/api/admin/user/v1/user/{id}/stripe-subscription-orders`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ListUserStripeSubscriptionOrdersResponseFromJSON(jsonValue));
    }
    /**
     * 사용자의 구매(주문) 목록을 가져옵니다.
     */
    async listStripeSubscriptionOrders(requestParameters, initOverrides) {
        const response = await this.listStripeSubscriptionOrdersRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 사용자의 약관 동의 정보를 업데이트합니다.
     */
    async updateAgreementInfoRaw(requestParameters, initOverrides) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling updateAgreementInfo.');
        }
        if (requestParameters.updateAgreementInfoRequest === null || requestParameters.updateAgreementInfoRequest === undefined) {
            throw new runtime.RequiredError('updateAgreementInfoRequest', 'Required parameter requestParameters.updateAgreementInfoRequest was null or undefined when calling updateAgreementInfo.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/admin/user/v1/user/{id}/agreement`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateAgreementInfoRequestToJSON(requestParameters.updateAgreementInfoRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateAgreementInfoResponseFromJSON(jsonValue));
    }
    /**
     * 사용자의 약관 동의 정보를 업데이트합니다.
     */
    async updateAgreementInfo(requestParameters, initOverrides) {
        const response = await this.updateAgreementInfoRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 사용자를 탈퇴 처리합니다.
     */
    async withdrawUserRaw(requestParameters, initOverrides) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling withdrawUser.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/admin/user/v1/user/{id}/withdraw`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => WithdrawUserResponseFromJSON(jsonValue));
    }
    /**
     * 사용자를 탈퇴 처리합니다.
     */
    async withdrawUser(requestParameters, initOverrides) {
        const response = await this.withdrawUserRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
