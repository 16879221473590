import React from 'react';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import DashboardIcon from '@mui/icons-material/Dashboard';
import Forest from '@mui/icons-material/Forest';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import LibraryBooks from '@mui/icons-material/LibraryBooks';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import ParkIcon from '@mui/icons-material/Park';
import PeopleIcon from '@mui/icons-material/People';
import PublicIcon from '@mui/icons-material/Public';
import SchoolIcon from '@mui/icons-material/School';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import ShoppingBasketOutlinedIcon from '@mui/icons-material/ShoppingBasketOutlined';
import SubtitlesIcon from '@mui/icons-material/Subtitles';
import TextureIcon from '@mui/icons-material/Texture';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import ViewCarouselOutlinedIcon from '@mui/icons-material/ViewCarouselOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {LearningDomainKey} from '@santa-web/gen/open-api/admin';

import type {IsoCountryCodes} from './isoCountries';

export interface SingleNavigationItem {
  type?: 'single';
  icon?: React.ReactNode;
  text: string;
  to: string;
  exposeConditions: {learningDomains?: LearningDomainKey[]; countries?: IsoCountryCodes[]}[];
}

interface GroupNavigationItem {
  type: 'group';
  icon?: React.ReactNode;
  text: string;
  children: SingleNavigationItem[];
}

export type NavigationItem = SingleNavigationItem | GroupNavigationItem;

export const NAVIGATION_ITEMS: NavigationItem[] = [
  {
    icon: <PeopleIcon />,
    text: '유저 관리',
    to: '/users',
    exposeConditions: [],
  },
  {
    icon: <ConfirmationNumberIcon />,
    text: '쿠폰 관리',
    to: '/coupon/list',
    exposeConditions: [{learningDomains: ['TOEIC']}],
  },
  {
    icon: <SubtitlesIcon />,
    text: '마케팅 배너 관리',
    to: '/marketing-banner',
    exposeConditions: [{learningDomains: ['TOEIC']}],
  },
  {
    icon: <SubtitlesIcon />,
    text: '마케팅 팝업 관리',
    to: '/marketing-popup/list',
    exposeConditions: [{learningDomains: ['TOEIC']}],
  },
  {
    icon: <AccessTimeIcon />,
    text: '이벤트 관리',
    to: '/promotion/list',
    exposeConditions: [{learningDomains: ['TOEIC']}],
  },
  {
    type: 'group',
    text: 'Business Operation',
    children: [
      {
        text: 'Permit',
        to: '/permit/list',
        exposeConditions: [{learningDomains: ['G_TELP']}],
      },
      {
        text: 'Ticket Template',
        to: '/ticket-template/list',
        exposeConditions: [{learningDomains: ['G_TELP']}],
      },
      {
        text: 'Pass Template',
        to: '/pass-template/list',
        exposeConditions: [{learningDomains: ['G_TELP']}],
      },
      {
        text: 'Issue Pass & Tickets',
        to: '/issue-pass-tickets',
        exposeConditions: [{learningDomains: ['G_TELP']}],
      },
      {
        text: 'Default Permit',
        to: '/default-permit',
        exposeConditions: [{learningDomains: ['G_TELP']}],
      },
      {
        text: 'Onboarding Pass & Ticket',
        to: '/onboarding-pass-ticket',
        exposeConditions: [{learningDomains: ['G_TELP']}],
      },
      {
        text: 'Single Purchase',
        to: '/offer-group/single-purchase',
        exposeConditions: [{learningDomains: ['G_TELP']}],
      },
      {
        text: 'Subscription',
        to: '/offer-group/subscription',
        exposeConditions: [{learningDomains: ['G_TELP']}],
      },
    ],
  },
  {
    type: 'group',
    text: '상품 관리',
    children: [
      {
        icon: <VisibilityIcon />,
        text: '권한 설정',
        to: '/authority/list',
        exposeConditions: [{learningDomains: ['TOEIC']}],
      },
      {
        icon: <MonetizationOnIcon />,
        text: '재화 설정',
        to: '/goods/list',
        exposeConditions: [{learningDomains: ['TOEIC']}],
      },
      {
        icon: <ShoppingBasketIcon />,
        text: '상품 설정',
        to: '/product/list',
        exposeConditions: [{learningDomains: ['TOEIC']}],
      },
      {
        icon: <ViewCarouselIcon />,
        text: '상품 보드 설정',
        to: '/product-board/list',
        exposeConditions: [{learningDomains: ['TOEIC']}],
      },
      {
        icon: <AddShoppingCartIcon />,
        text: '상품 지급',
        to: '/product/supply',
        exposeConditions: [{learningDomains: ['TOEIC']}],
      },
      {
        icon: <ShoppingBasketOutlinedIcon />,
        text: '구독 상품 설정',
        to: '/subscription-product/list',
        exposeConditions: [{learningDomains: ['TOEIC']}],
      },
      {
        icon: <ViewCarouselOutlinedIcon />,
        text: '구독 상품 보드 설정',
        to: '/subscription-product-board/list',
        exposeConditions: [{learningDomains: ['TOEIC']}],
      },
      {
        icon: <TextureIcon />,
        text: '특가 상품 설정',
        to: '/stripe-product/list',
        exposeConditions: [{learningDomains: ['TOEIC']}],
      },
      {
        icon: <DashboardIcon />,
        text: '특가 상품 보드 설정',
        to: '/stripe-product-board/list',
        exposeConditions: [{learningDomains: ['TOEIC']}],
      },
    ],
  },
  {
    type: 'group',
    text: '권한 요소 관리',
    children: [
      {
        icon: <LocalShippingIcon />,
        text: '배송 폼 관리',
        to: '/deliverable/list',
        exposeConditions: [{learningDomains: ['TOEIC']}],
      },
      {
        icon: <ImageSearchIcon />,
        text: '리소스 관리',
        to: '/resource/list',
        exposeConditions: [{learningDomains: ['TOEIC']}],
      },
      {
        icon: <SchoolIcon />,
        text: '응시권 관리',
        to: '/gift-code/list',
        exposeConditions: [{learningDomains: ['TOEIC']}],
      },
    ],
  },
  {
    type: 'group',
    text: '시스템 관리',
    children: [
      {
        icon: <CardGiftcardIcon />,
        text: '무료권한 설정',
        to: '/free-authority/list',
        exposeConditions: [{learningDomains: ['TOEIC']}],
      },
      {
        icon: <PublicIcon />,
        text: '국가별 권한 관리',
        to: '/authority/country/list',
        exposeConditions: [{learningDomains: ['TOEIC']}],
      },
      {
        icon: <SettingsApplicationsIcon />,
        text: '시스템 설정 변경',
        to: '/system/list',
        exposeConditions: [{learningDomains: ['TOEIC']}],
      },
    ],
  },
  {
    type: 'group',
    text: '기관 관리',
    children: [
      {
        icon: <AccountBoxIcon />,
        text: '구매기관 관리',
        to: '/organization-plan/list',
        exposeConditions: [{learningDomains: ['TOEIC']}],
      },
      {
        icon: <AccountBoxIcon />,
        text: '구매기관 생성',
        to: '/organization-plan/create',
        exposeConditions: [{learningDomains: ['TOEIC']}],
      },
    ],
  },
  {
    type: 'group',
    text: '컨텐츠 관리',
    children: [
      {
        icon: <LibraryBooks />,
        text: '모의고사 관리',
        to: '/virtual-exam/list',
        exposeConditions: [{learningDomains: ['TOEIC']}],
      },
      {
        icon: <ParkIcon />,
        text: '선택학습 관리',
        to: '/self-learning/tree',
        exposeConditions: [{learningDomains: ['TOEIC']}],
      },
      {
        icon: <Forest />,
        text: '선택학습 관리',
        to: '/self-learning',
        exposeConditions: [{learningDomains: ['G_TELP'], countries: ['KR']}],
      },
    ],
  },
  {
    type: 'single',
    icon: <BorderColorIcon />,
    text: '대사 작업',
    to: '/settlement',
    exposeConditions: [{learningDomains: ['TOEIC']}],
  },
];
