/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { DisplayInfoFromJSON, DisplayInfoToJSON, } from './DisplayInfo';
import { PassTemplateManagementInfoFromJSON, PassTemplateManagementInfoToJSON, } from './PassTemplateManagementInfo';
/**
 * Check if a given object implements the UpdatePassTemplateRequest interface.
 */
export function instanceOfUpdatePassTemplateRequest(value) {
    let isInstance = true;
    return isInstance;
}
export function UpdatePassTemplateRequestFromJSON(json) {
    return UpdatePassTemplateRequestFromJSONTyped(json, false);
}
export function UpdatePassTemplateRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'permitIds': !exists(json, 'permitIds') ? undefined : json['permitIds'],
        'displayInfo': !exists(json, 'displayInfo') ? undefined : DisplayInfoFromJSON(json['displayInfo']),
        'managementInfo': !exists(json, 'managementInfo') ? undefined : PassTemplateManagementInfoFromJSON(json['managementInfo']),
    };
}
export function UpdatePassTemplateRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'permitIds': value.permitIds,
        'displayInfo': DisplayInfoToJSON(value.displayInfo),
        'managementInfo': PassTemplateManagementInfoToJSON(value.managementInfo),
    };
}
