/**
 * Check if a given object implements the SelfLearningUnitContentConditionEntry interface.
 */
export function instanceOfSelfLearningUnitContentConditionEntry(value) {
    let isInstance = true;
    isInstance = isInstance && "values" in value;
    isInstance = isInstance && "key" in value;
    return isInstance;
}
export function SelfLearningUnitContentConditionEntryFromJSON(json) {
    return SelfLearningUnitContentConditionEntryFromJSONTyped(json, false);
}
export function SelfLearningUnitContentConditionEntryFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'values': json['values'],
        'key': json['key'],
    };
}
export function SelfLearningUnitContentConditionEntryToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'values': value.values,
        'key': value.key,
    };
}
