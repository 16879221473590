/* tslint:disable */
/* eslint-disable */
/**
 * - **ONE_TIME_PURCHASE**: 단건
 * - **SUBSCRIPTION**: 구독
 * @export
 */
export const OfferType = {
    ONE_TIME_PURCHASE: 'ONE_TIME_PURCHASE',
    SUBSCRIPTION: 'SUBSCRIPTION'
};
export function OfferTypeFromJSON(json) {
    return OfferTypeFromJSONTyped(json, false);
}
export function OfferTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function OfferTypeToJSON(value) {
    return value;
}
