/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { SetOnboardingPassTemplateRequestOnboardingPassTemplateFromJSON, SetOnboardingPassTemplateRequestOnboardingPassTemplateToJSON, } from './SetOnboardingPassTemplateRequestOnboardingPassTemplate';
/**
 * Check if a given object implements the SetOnboardingPassTemplateRequest interface.
 */
export function instanceOfSetOnboardingPassTemplateRequest(value) {
    let isInstance = true;
    return isInstance;
}
export function SetOnboardingPassTemplateRequestFromJSON(json) {
    return SetOnboardingPassTemplateRequestFromJSONTyped(json, false);
}
export function SetOnboardingPassTemplateRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'onboardingPassTemplate': !exists(json, 'onboardingPassTemplate') ? undefined : SetOnboardingPassTemplateRequestOnboardingPassTemplateFromJSON(json['onboardingPassTemplate']),
    };
}
export function SetOnboardingPassTemplateRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'onboardingPassTemplate': SetOnboardingPassTemplateRequestOnboardingPassTemplateToJSON(value.onboardingPassTemplate),
    };
}
