// @ts-nocheck
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "./DisplayNameDeclaration";
import { encodeJson as encodeJson_2, decodeJson as decodeJson_2, encodeBinary as encodeBinary_2, decodeBinary as decodeBinary_2, } from "./(UpdateDisplayNamesRequest)/TagTextsDeclaration";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../../../../runtime/json/scalar";
import { WireType, } from "../../../../../../runtime/wire/index";
import { default as serialize, } from "../../../../../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../../../../../runtime/wire/scalar";
import { default as deserialize, } from "../../../../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        selfLearningHierarchyId: "0",
        titleDeclarations: [],
        subTextDeclarations: [],
        descriptionDeclarations: [],
        superordinateCategoryNameDeclarations: [],
        tagTextDeclarations: [],
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.selfLearningHierarchyId !== undefined)
        result.selfLearningHierarchyId = tsValueToJsonValueFns.int64(value.selfLearningHierarchyId);
    result.titleDeclarations = value.titleDeclarations.map(value => encodeJson_1(value));
    result.subTextDeclarations = value.subTextDeclarations.map(value => encodeJson_1(value));
    result.descriptionDeclarations = value.descriptionDeclarations.map(value => encodeJson_1(value));
    result.superordinateCategoryNameDeclarations = value.superordinateCategoryNameDeclarations.map(value => encodeJson_1(value));
    result.tagTextDeclarations = value.tagTextDeclarations.map(value => encodeJson_2(value));
    return result;
}
export function decodeJson(value) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    const result = getDefaultValue();
    if (value.selfLearningHierarchyId !== undefined)
        result.selfLearningHierarchyId = jsonValueToTsValueFns.int64(value.selfLearningHierarchyId);
    result.titleDeclarations = (_b = (_a = value.titleDeclarations) === null || _a === void 0 ? void 0 : _a.map((value) => decodeJson_1(value))) !== null && _b !== void 0 ? _b : [];
    result.subTextDeclarations = (_d = (_c = value.subTextDeclarations) === null || _c === void 0 ? void 0 : _c.map((value) => decodeJson_1(value))) !== null && _d !== void 0 ? _d : [];
    result.descriptionDeclarations = (_f = (_e = value.descriptionDeclarations) === null || _e === void 0 ? void 0 : _e.map((value) => decodeJson_1(value))) !== null && _f !== void 0 ? _f : [];
    result.superordinateCategoryNameDeclarations = (_h = (_g = value.superordinateCategoryNameDeclarations) === null || _g === void 0 ? void 0 : _g.map((value) => decodeJson_1(value))) !== null && _h !== void 0 ? _h : [];
    result.tagTextDeclarations = (_k = (_j = value.tagTextDeclarations) === null || _j === void 0 ? void 0 : _j.map((value) => decodeJson_2(value))) !== null && _k !== void 0 ? _k : [];
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.selfLearningHierarchyId !== undefined) {
        const tsValue = value.selfLearningHierarchyId;
        result.push([1, tsValueToWireValueFns.int64(tsValue)]);
    }
    for (const tsValue of value.titleDeclarations) {
        result.push([2, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    for (const tsValue of value.subTextDeclarations) {
        result.push([3, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    for (const tsValue of value.descriptionDeclarations) {
        result.push([4, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    for (const tsValue of value.superordinateCategoryNameDeclarations) {
        result.push([5, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    for (const tsValue of value.tagTextDeclarations) {
        result.push([6, { type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.selfLearningHierarchyId = value;
    }
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 2).map(([, wireValue]) => wireValue);
        const value = wireValues.map((wireValue) => wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined).filter(x => x !== undefined);
        if (!value.length)
            break collection;
        result.titleDeclarations = value;
    }
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 3).map(([, wireValue]) => wireValue);
        const value = wireValues.map((wireValue) => wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined).filter(x => x !== undefined);
        if (!value.length)
            break collection;
        result.subTextDeclarations = value;
    }
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 4).map(([, wireValue]) => wireValue);
        const value = wireValues.map((wireValue) => wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined).filter(x => x !== undefined);
        if (!value.length)
            break collection;
        result.descriptionDeclarations = value;
    }
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 5).map(([, wireValue]) => wireValue);
        const value = wireValues.map((wireValue) => wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined).filter(x => x !== undefined);
        if (!value.length)
            break collection;
        result.superordinateCategoryNameDeclarations = value;
    }
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 6).map(([, wireValue]) => wireValue);
        const value = wireValues.map((wireValue) => wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined).filter(x => x !== undefined);
        if (!value.length)
            break collection;
        result.tagTextDeclarations = value;
    }
    return result;
}
