import { LearningDomainDetailFromJSON, LearningDomainDetailToJSON, } from './LearningDomainDetail';
/**
 * Check if a given object implements the GetLearningDomainResponse interface.
 */
export function instanceOfGetLearningDomainResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "learningDomain" in value;
    return isInstance;
}
export function GetLearningDomainResponseFromJSON(json) {
    return GetLearningDomainResponseFromJSONTyped(json, false);
}
export function GetLearningDomainResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'learningDomain': LearningDomainDetailFromJSON(json['learningDomain']),
    };
}
export function GetLearningDomainResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'learningDomain': LearningDomainDetailToJSON(value.learningDomain),
    };
}
