import { SelfLearningUnitWithMetaResponseFromJSON, SelfLearningUnitWithMetaResponseToJSON, } from './SelfLearningUnitWithMetaResponse';
/**
 * Check if a given object implements the SelfLearningUnitsWithMetadataResponse interface.
 */
export function instanceOfSelfLearningUnitsWithMetadataResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "selfLearningUnits" in value;
    return isInstance;
}
export function SelfLearningUnitsWithMetadataResponseFromJSON(json) {
    return SelfLearningUnitsWithMetadataResponseFromJSONTyped(json, false);
}
export function SelfLearningUnitsWithMetadataResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'selfLearningUnits': (json['selfLearningUnits'].map(SelfLearningUnitWithMetaResponseFromJSON)),
    };
}
export function SelfLearningUnitsWithMetadataResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'selfLearningUnits': (value.selfLearningUnits.map(SelfLearningUnitWithMetaResponseToJSON)),
    };
}
