/**
 * Check if a given object implements the SelfLearningUnitContentQueryVocaByContentIds interface.
 */
export function instanceOfSelfLearningUnitContentQueryVocaByContentIds(value) {
    let isInstance = true;
    isInstance = isInstance && "contentIds" in value;
    return isInstance;
}
export function SelfLearningUnitContentQueryVocaByContentIdsFromJSON(json) {
    return SelfLearningUnitContentQueryVocaByContentIdsFromJSONTyped(json, false);
}
export function SelfLearningUnitContentQueryVocaByContentIdsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'contentIds': json['contentIds'],
    };
}
export function SelfLearningUnitContentQueryVocaByContentIdsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'contentIds': value.contentIds,
    };
}
