/* tslint:disable */
/* eslint-disable */
import * as runtime from '../runtime';
import { CreateSelfLearningUnitGroupRequestToJSON, SelfLearningUnitGroupFiltersFromJSON, SelfLearningUnitGroupFiltersToJSON, SelfLearningUnitGroupResponseFromJSON, SelfLearningUnitGroupsResponseFromJSON, SelfLearningUnitGroupsSwapRequestToJSON, UpdateSelfLearningUnitGroupRequestToJSON, } from '../models';
/**
 *
 */
export class SelfLearningUnitGroupAdminV1Api extends runtime.BaseAPI {
    /**
     * 선택학습 유닛 그룹을 생성한다.
     * 선택학습 유닛 그룹을 생성한다.
     */
    async createSelfLearningUnitGroupRaw(requestParameters, initOverrides) {
        if (requestParameters.createSelfLearningUnitGroupRequest === null || requestParameters.createSelfLearningUnitGroupRequest === undefined) {
            throw new runtime.RequiredError('createSelfLearningUnitGroupRequest', 'Required parameter requestParameters.createSelfLearningUnitGroupRequest was null or undefined when calling createSelfLearningUnitGroup.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/admin/content-learning/v1/self-learning/unit-groups/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateSelfLearningUnitGroupRequestToJSON(requestParameters.createSelfLearningUnitGroupRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => SelfLearningUnitGroupResponseFromJSON(jsonValue));
    }
    /**
     * 선택학습 유닛 그룹을 생성한다.
     * 선택학습 유닛 그룹을 생성한다.
     */
    async createSelfLearningUnitGroup(requestParameters, initOverrides) {
        const response = await this.createSelfLearningUnitGroupRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 선택학습 유닛 그룹을 조회한다.
     * 선택학습 유닛 그룹을 조회한다.
     */
    async getSelfLearningUnitGroupRaw(requestParameters, initOverrides) {
        if (requestParameters.unitGroupId === null || requestParameters.unitGroupId === undefined) {
            throw new runtime.RequiredError('unitGroupId', 'Required parameter requestParameters.unitGroupId was null or undefined when calling getSelfLearningUnitGroup.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/admin/content-learning/v1/self-learning/unit-groups/{unitGroupId}`.replace(`{${"unitGroupId"}}`, encodeURIComponent(String(requestParameters.unitGroupId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => SelfLearningUnitGroupResponseFromJSON(jsonValue));
    }
    /**
     * 선택학습 유닛 그룹을 조회한다.
     * 선택학습 유닛 그룹을 조회한다.
     */
    async getSelfLearningUnitGroup(requestParameters, initOverrides) {
        const response = await this.getSelfLearningUnitGroupRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * `SelfLearningContentType`에 해당하는 선택학습 유닛 그룹 필터 목록 을 가져온다.\\ `showAll`이 `true`인 경우에는 클라이언트에서 필터 목록 제일 앞에 `전체`를 표시해야 한다.
     * 선택학습 유닛 그룹 필터 목록을 가져온다.
     */
    async getSelfLearningUnitGroupFilterListRaw(requestParameters, initOverrides) {
        if (requestParameters.contentType === null || requestParameters.contentType === undefined) {
            throw new runtime.RequiredError('contentType', 'Required parameter requestParameters.contentType was null or undefined when calling getSelfLearningUnitGroupFilterList.');
        }
        const queryParameters = {};
        if (requestParameters.contentType !== undefined) {
            queryParameters['contentType'] = requestParameters.contentType;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/api/admin/content-learning/v1/self-learning/unit-group-filters/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => SelfLearningUnitGroupFiltersFromJSON(jsonValue));
    }
    /**
     * `SelfLearningContentType`에 해당하는 선택학습 유닛 그룹 필터 목록 을 가져온다.\\ `showAll`이 `true`인 경우에는 클라이언트에서 필터 목록 제일 앞에 `전체`를 표시해야 한다.
     * 선택학습 유닛 그룹 필터 목록을 가져온다.
     */
    async getSelfLearningUnitGroupFilterList(requestParameters, initOverrides) {
        const response = await this.getSelfLearningUnitGroupFilterListRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 선택학습 유닛 그룹 목록을 조회한다.
     * 선택학습 유닛 그룹 목록을 조회한다.
     */
    async getSelfLearningUnitGroupsRaw(requestParameters, initOverrides) {
        if (requestParameters.contentType === null || requestParameters.contentType === undefined) {
            throw new runtime.RequiredError('contentType', 'Required parameter requestParameters.contentType was null or undefined when calling getSelfLearningUnitGroups.');
        }
        const queryParameters = {};
        if (requestParameters.contentType !== undefined) {
            queryParameters['contentType'] = requestParameters.contentType;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/api/admin/content-learning/v1/self-learning/unit-groups/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => SelfLearningUnitGroupsResponseFromJSON(jsonValue));
    }
    /**
     * 선택학습 유닛 그룹 목록을 조회한다.
     * 선택학습 유닛 그룹 목록을 조회한다.
     */
    async getSelfLearningUnitGroups(requestParameters, initOverrides) {
        const response = await this.getSelfLearningUnitGroupsRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 선택학습 유닛 그룹 목록에서 두 항목의 위치를 변경한다.
     * 선택학습 유닛 그룹 목록에서 두 항목의 위치를 변경한다.
     */
    async swapSelfLearningUnitGroupsPositionRaw(requestParameters, initOverrides) {
        if (requestParameters.selfLearningUnitGroupsSwapRequest === null || requestParameters.selfLearningUnitGroupsSwapRequest === undefined) {
            throw new runtime.RequiredError('selfLearningUnitGroupsSwapRequest', 'Required parameter requestParameters.selfLearningUnitGroupsSwapRequest was null or undefined when calling swapSelfLearningUnitGroupsPosition.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/admin/content-learning/v1/self-learning/unit-groups/swap`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: SelfLearningUnitGroupsSwapRequestToJSON(requestParameters.selfLearningUnitGroupsSwapRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => SelfLearningUnitGroupsResponseFromJSON(jsonValue));
    }
    /**
     * 선택학습 유닛 그룹 목록에서 두 항목의 위치를 변경한다.
     * 선택학습 유닛 그룹 목록에서 두 항목의 위치를 변경한다.
     */
    async swapSelfLearningUnitGroupsPosition(requestParameters, initOverrides) {
        const response = await this.swapSelfLearningUnitGroupsPositionRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 선택학습 유닛 그룹을 수정한다.
     * 선택학습 유닛 그룹을 수정한다.
     */
    async updateSelfLearningUnitGroupRaw(requestParameters, initOverrides) {
        if (requestParameters.unitGroupId === null || requestParameters.unitGroupId === undefined) {
            throw new runtime.RequiredError('unitGroupId', 'Required parameter requestParameters.unitGroupId was null or undefined when calling updateSelfLearningUnitGroup.');
        }
        if (requestParameters.updateSelfLearningUnitGroupRequest === null || requestParameters.updateSelfLearningUnitGroupRequest === undefined) {
            throw new runtime.RequiredError('updateSelfLearningUnitGroupRequest', 'Required parameter requestParameters.updateSelfLearningUnitGroupRequest was null or undefined when calling updateSelfLearningUnitGroup.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/admin/content-learning/v1/self-learning/unit-groups/{unitGroupId}`.replace(`{${"unitGroupId"}}`, encodeURIComponent(String(requestParameters.unitGroupId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateSelfLearningUnitGroupRequestToJSON(requestParameters.updateSelfLearningUnitGroupRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => SelfLearningUnitGroupResponseFromJSON(jsonValue));
    }
    /**
     * 선택학습 유닛 그룹을 수정한다.
     * 선택학습 유닛 그룹을 수정한다.
     */
    async updateSelfLearningUnitGroup(requestParameters, initOverrides) {
        const response = await this.updateSelfLearningUnitGroupRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 전달 받은 필터 목록을 최신의 필터 목록으로 설정한다.\\ 필터의 순서는 요청에 포함된 순서대로 저장된다.  1. 항목 추가: `id`가 0인 경우, 새로운 항목으로 추가한다. 1. 항목 수정: `id`가 1 이상인 경우, 해당 항목의 모든 내용을 요청한 값으로 수정한다. 1. 항목 삭제: 요청에는 없는 `id`가 서버에 존재하는 경우, 해당 항목을 삭제한다.     - 빈 항목을 보내면 모든 필터가 삭제된다.
     * 선택학습 유닛 그룹 필터 목록을 수정한다.
     */
    async updateSelfLearningUnitGroupFilterListRaw(requestParameters, initOverrides) {
        if (requestParameters.selfLearningUnitGroupFilters === null || requestParameters.selfLearningUnitGroupFilters === undefined) {
            throw new runtime.RequiredError('selfLearningUnitGroupFilters', 'Required parameter requestParameters.selfLearningUnitGroupFilters was null or undefined when calling updateSelfLearningUnitGroupFilterList.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/admin/content-learning/v1/self-learning/unit-group-filters/`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SelfLearningUnitGroupFiltersToJSON(requestParameters.selfLearningUnitGroupFilters),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => SelfLearningUnitGroupFiltersFromJSON(jsonValue));
    }
    /**
     * 전달 받은 필터 목록을 최신의 필터 목록으로 설정한다.\\ 필터의 순서는 요청에 포함된 순서대로 저장된다.  1. 항목 추가: `id`가 0인 경우, 새로운 항목으로 추가한다. 1. 항목 수정: `id`가 1 이상인 경우, 해당 항목의 모든 내용을 요청한 값으로 수정한다. 1. 항목 삭제: 요청에는 없는 `id`가 서버에 존재하는 경우, 해당 항목을 삭제한다.     - 빈 항목을 보내면 모든 필터가 삭제된다.
     * 선택학습 유닛 그룹 필터 목록을 수정한다.
     */
    async updateSelfLearningUnitGroupFilterList(requestParameters, initOverrides) {
        const response = await this.updateSelfLearningUnitGroupFilterListRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
