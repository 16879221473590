export const num2name = {
    0: "RAW_DATA_TYPE_UNSPECIFIED",
    1: "RAW_DATA_TYPE_IAMPORT",
    2: "RAW_DATA_TYPE_KCP",
    3: "RAW_DATA_TYPE_N_PAY",
    4: "RAW_DATA_TYPE_GOOGLEPLAY",
    5: "RAW_DATA_TYPE_APPSTORE",
};
export const name2num = {
    RAW_DATA_TYPE_UNSPECIFIED: 0,
    RAW_DATA_TYPE_IAMPORT: 1,
    RAW_DATA_TYPE_KCP: 2,
    RAW_DATA_TYPE_N_PAY: 3,
    RAW_DATA_TYPE_GOOGLEPLAY: 4,
    RAW_DATA_TYPE_APPSTORE: 5,
};
