// @ts-nocheck
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "../../commerce/Product";
import { name2num, num2name, } from "./(CreateOrUpdateProductResponse)/Error";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../../runtime/json/scalar";
import { WireType, } from "../../../../runtime/wire/index";
import { default as serialize, } from "../../../../runtime/wire/serialize";
import { default as Long, } from "../../../../runtime/Long";
import { encodeBinary as encodeBinary_2, decodeBinary as decodeBinary_2, } from "../../../google/protobuf/StringValue";
import { default as deserialize, } from "../../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        product: undefined,
        error: "ERROR_UNSPECIFIED",
        errorMessage: undefined,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.product !== undefined)
        result.product = encodeJson_1(value.product);
    if (value.error !== undefined)
        result.error = tsValueToJsonValueFns.enum(value.error);
    if (value.errorMessage !== undefined)
        result.errorMessage = value.errorMessage;
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.product !== undefined)
        result.product = decodeJson_1(value.product);
    if (value.error !== undefined)
        result.error = jsonValueToTsValueFns.enum(value.error);
    if (value.errorMessage !== undefined)
        result.errorMessage = value.errorMessage;
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.product !== undefined) {
        const tsValue = value.product;
        result.push([1, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    if (value.error !== undefined) {
        const tsValue = value.error;
        result.push([2, { type: WireType.Varint, value: new Long(name2num[tsValue]) }]);
    }
    if (value.errorMessage !== undefined) {
        const tsValue = value.errorMessage;
        result.push([3, ((tsValue) => ({ type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }))({ value: tsValue })]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    var _a;
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.product = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.error = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = (_a = (wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined)) === null || _a === void 0 ? void 0 : _a.value;
        if (value === undefined)
            break field;
        result.errorMessage = value;
    }
    return result;
}
