export async function getTokenPayloadFromKeycloak() {
    return await fetch('/oauth/token').then(res => res.json());
}
export function signOut() {
    location.href = '/oauth/logout';
}
export function createFakeToken(tokenPayload) {
    const tokenPayloadJson = JSON.stringify(tokenPayload);
    const payload = Buffer.from(tokenPayloadJson).toString('base64').replace(/=*$/, '');
    return 'e30.' + payload + '.';
}
export function hasRole(tokenPayload, clientName, role) {
    var _a, _b;
    const roles = (_b = (_a = tokenPayload.resource_access) === null || _a === void 0 ? void 0 : _a[clientName]) === null || _b === void 0 ? void 0 : _b.roles;
    if (!Array.isArray(roles))
        return false;
    return roles.includes(role);
}
