import { OnboardingTicketTemplatesListItemFromJSON, OnboardingTicketTemplatesListItemToJSON, } from './OnboardingTicketTemplatesListItem';
/**
 * Check if a given object implements the SetOnboardingTicketTemplatesRequest interface.
 */
export function instanceOfSetOnboardingTicketTemplatesRequest(value) {
    let isInstance = true;
    isInstance = isInstance && "onboardingTicketTemplates" in value;
    return isInstance;
}
export function SetOnboardingTicketTemplatesRequestFromJSON(json) {
    return SetOnboardingTicketTemplatesRequestFromJSONTyped(json, false);
}
export function SetOnboardingTicketTemplatesRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'onboardingTicketTemplates': (json['onboardingTicketTemplates'].map(OnboardingTicketTemplatesListItemFromJSON)),
    };
}
export function SetOnboardingTicketTemplatesRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'onboardingTicketTemplates': (value.onboardingTicketTemplates.map(OnboardingTicketTemplatesListItemToJSON)),
    };
}
