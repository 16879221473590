import { StripeProductFromJSON, StripeProductToJSON, } from './StripeProduct';
/**
 * Check if a given object implements the CreateStripeProductResponse interface.
 */
export function instanceOfCreateStripeProductResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "createdProduct" in value;
    return isInstance;
}
export function CreateStripeProductResponseFromJSON(json) {
    return CreateStripeProductResponseFromJSONTyped(json, false);
}
export function CreateStripeProductResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'createdProduct': StripeProductFromJSON(json['createdProduct']),
    };
}
export function CreateStripeProductResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'createdProduct': StripeProductToJSON(value.createdProduct),
    };
}
