import { SelfLearningUnitFromJSON, SelfLearningUnitToJSON, } from './SelfLearningUnit';
/**
 * Check if a given object implements the SelfLearningUnitsResponse interface.
 */
export function instanceOfSelfLearningUnitsResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "selfLearningUnits" in value;
    return isInstance;
}
export function SelfLearningUnitsResponseFromJSON(json) {
    return SelfLearningUnitsResponseFromJSONTyped(json, false);
}
export function SelfLearningUnitsResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'selfLearningUnits': (json['selfLearningUnits'].map(SelfLearningUnitFromJSON)),
    };
}
export function SelfLearningUnitsResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'selfLearningUnits': (value.selfLearningUnits.map(SelfLearningUnitToJSON)),
    };
}
