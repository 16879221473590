/* tslint:disable */
/* eslint-disable */
import * as runtime from '../runtime';
import { CreatePassTemplateRequestToJSON, CreatePassTemplateResponseFromJSON, GetOnboardingPassTemplateResponseFromJSON, GetPassTemplateResponseFromJSON, IssuePassFromTemplateRequestToJSON, IssuePassFromTemplateResponseFromJSON, ListPassTemplateResponseFromJSON, SetOnboardingPassTemplateRequestToJSON, SetOnboardingPassTemplateResponseFromJSON, UpdatePassTemplateRequestToJSON, } from '../models';
/**
 *
 */
export class PermitAdminV1PassApi extends runtime.BaseAPI {
    /**
     * Pass를 활성화한다. 활성화된 pass를 다시 활성화 요청하는 경우 아무 일도 일어나지 않는다.
     */
    async activatePassRaw(requestParameters, initOverrides) {
        if (requestParameters.passId === null || requestParameters.passId === undefined) {
            throw new runtime.RequiredError('passId', 'Required parameter requestParameters.passId was null or undefined when calling activatePass.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/admin/permit/v1/pass/{passId}/activate`.replace(`{${"passId"}}`, encodeURIComponent(String(requestParameters.passId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Pass를 활성화한다. 활성화된 pass를 다시 활성화 요청하는 경우 아무 일도 일어나지 않는다.
     */
    async activatePass(requestParameters, initOverrides) {
        await this.activatePassRaw(requestParameters, initOverrides);
    }
    /**
     * Pass Template을 생성한다.
     */
    async createPassTemplateRaw(requestParameters, initOverrides) {
        if (requestParameters.createPassTemplateRequest === null || requestParameters.createPassTemplateRequest === undefined) {
            throw new runtime.RequiredError('createPassTemplateRequest', 'Required parameter requestParameters.createPassTemplateRequest was null or undefined when calling createPassTemplate.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/admin/permit/v1/pass/template`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreatePassTemplateRequestToJSON(requestParameters.createPassTemplateRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => CreatePassTemplateResponseFromJSON(jsonValue));
    }
    /**
     * Pass Template을 생성한다.
     */
    async createPassTemplate(requestParameters, initOverrides) {
        const response = await this.createPassTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 현재 설정된 onboarding pass template을 조회한다.
     */
    async getOnboardingPassTemplateRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/admin/permit/v1/pass/template/onboarding`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GetOnboardingPassTemplateResponseFromJSON(jsonValue));
    }
    /**
     * 현재 설정된 onboarding pass template을 조회한다.
     */
    async getOnboardingPassTemplate(requestParameters, initOverrides) {
        const response = await this.getOnboardingPassTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Pass template을 조회한다.
     */
    async getPassTemplateRaw(requestParameters, initOverrides) {
        if (requestParameters.passTemplateId === null || requestParameters.passTemplateId === undefined) {
            throw new runtime.RequiredError('passTemplateId', 'Required parameter requestParameters.passTemplateId was null or undefined when calling getPassTemplate.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/admin/permit/v1/pass/template/{passTemplateId}`.replace(`{${"passTemplateId"}}`, encodeURIComponent(String(requestParameters.passTemplateId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GetPassTemplateResponseFromJSON(jsonValue));
    }
    /**
     * Pass template을 조회한다.
     */
    async getPassTemplate(requestParameters, initOverrides) {
        const response = await this.getPassTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Pass template에 해당하는 pass를 지정한 profile에 지급한다.
     */
    async issuePassFromTemplateRaw(requestParameters, initOverrides) {
        if (requestParameters.passTemplateId === null || requestParameters.passTemplateId === undefined) {
            throw new runtime.RequiredError('passTemplateId', 'Required parameter requestParameters.passTemplateId was null or undefined when calling issuePassFromTemplate.');
        }
        if (requestParameters.issuePassFromTemplateRequest === null || requestParameters.issuePassFromTemplateRequest === undefined) {
            throw new runtime.RequiredError('issuePassFromTemplateRequest', 'Required parameter requestParameters.issuePassFromTemplateRequest was null or undefined when calling issuePassFromTemplate.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/admin/permit/v1/pass/template/{passTemplateId}/issue`.replace(`{${"passTemplateId"}}`, encodeURIComponent(String(requestParameters.passTemplateId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IssuePassFromTemplateRequestToJSON(requestParameters.issuePassFromTemplateRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => IssuePassFromTemplateResponseFromJSON(jsonValue));
    }
    /**
     * Pass template에 해당하는 pass를 지정한 profile에 지급한다.
     */
    async issuePassFromTemplate(requestParameters, initOverrides) {
        const response = await this.issuePassFromTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Pass Template 목록을 조회한다.
     */
    async listPassTemplatesRaw(requestParameters, initOverrides) {
        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size', 'Required parameter requestParameters.size was null or undefined when calling listPassTemplates.');
        }
        const queryParameters = {};
        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }
        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }
        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }
        if (requestParameters.permitTypeId !== undefined) {
            queryParameters['permitTypeId'] = requestParameters.permitTypeId;
        }
        if (requestParameters.description !== undefined) {
            queryParameters['description'] = requestParameters.description;
        }
        if (requestParameters.isPublished !== undefined) {
            queryParameters['isPublished'] = requestParameters.isPublished;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/api/admin/permit/v1/pass/template`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ListPassTemplateResponseFromJSON(jsonValue));
    }
    /**
     * Pass Template 목록을 조회한다.
     */
    async listPassTemplates(requestParameters, initOverrides) {
        const response = await this.listPassTemplatesRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Pass template을 publish 한다.
     */
    async publishPassTemplateRaw(requestParameters, initOverrides) {
        if (requestParameters.passTemplateId === null || requestParameters.passTemplateId === undefined) {
            throw new runtime.RequiredError('passTemplateId', 'Required parameter requestParameters.passTemplateId was null or undefined when calling publishPassTemplate.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/admin/permit/v1/pass/template/{passTemplateId}/publish`.replace(`{${"passTemplateId"}}`, encodeURIComponent(String(requestParameters.passTemplateId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Pass template을 publish 한다.
     */
    async publishPassTemplate(requestParameters, initOverrides) {
        await this.publishPassTemplateRaw(requestParameters, initOverrides);
    }
    /**
     * Onboarding pass template을 설정한다.
     */
    async setOnboardingPassTemplateRaw(requestParameters, initOverrides) {
        if (requestParameters.setOnboardingPassTemplateRequest === null || requestParameters.setOnboardingPassTemplateRequest === undefined) {
            throw new runtime.RequiredError('setOnboardingPassTemplateRequest', 'Required parameter requestParameters.setOnboardingPassTemplateRequest was null or undefined when calling setOnboardingPassTemplate.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/admin/permit/v1/pass/template/onboarding`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetOnboardingPassTemplateRequestToJSON(requestParameters.setOnboardingPassTemplateRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => SetOnboardingPassTemplateResponseFromJSON(jsonValue));
    }
    /**
     * Onboarding pass template을 설정한다.
     */
    async setOnboardingPassTemplate(requestParameters, initOverrides) {
        const response = await this.setOnboardingPassTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Pass template을 수정한다.
     */
    async updatePassTemplateRaw(requestParameters, initOverrides) {
        if (requestParameters.passTemplateId === null || requestParameters.passTemplateId === undefined) {
            throw new runtime.RequiredError('passTemplateId', 'Required parameter requestParameters.passTemplateId was null or undefined when calling updatePassTemplate.');
        }
        if (requestParameters.updatePassTemplateRequest === null || requestParameters.updatePassTemplateRequest === undefined) {
            throw new runtime.RequiredError('updatePassTemplateRequest', 'Required parameter requestParameters.updatePassTemplateRequest was null or undefined when calling updatePassTemplate.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/admin/permit/v1/pass/template/{passTemplateId}`.replace(`{${"passTemplateId"}}`, encodeURIComponent(String(requestParameters.passTemplateId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdatePassTemplateRequestToJSON(requestParameters.updatePassTemplateRequest),
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Pass template을 수정한다.
     */
    async updatePassTemplate(requestParameters, initOverrides) {
        await this.updatePassTemplateRaw(requestParameters, initOverrides);
    }
}
