/**
 * Check if a given object implements the GetAvailableKeysForQuestionContentsResponse interface.
 */
export function instanceOfGetAvailableKeysForQuestionContentsResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "availableKeys" in value;
    return isInstance;
}
export function GetAvailableKeysForQuestionContentsResponseFromJSON(json) {
    return GetAvailableKeysForQuestionContentsResponseFromJSONTyped(json, false);
}
export function GetAvailableKeysForQuestionContentsResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'availableKeys': json['availableKeys'],
    };
}
export function GetAvailableKeysForQuestionContentsResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'availableKeys': value.availableKeys,
    };
}
