import { PermitTypeListItemFromJSON, PermitTypeListItemToJSON, } from './PermitTypeListItem';
/**
 * Check if a given object implements the ListPermitTypesResponse interface.
 */
export function instanceOfListPermitTypesResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "total" in value;
    isInstance = isInstance && "permitTypes" in value;
    return isInstance;
}
export function ListPermitTypesResponseFromJSON(json) {
    return ListPermitTypesResponseFromJSONTyped(json, false);
}
export function ListPermitTypesResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'total': json['total'],
        'permitTypes': (json['permitTypes'].map(PermitTypeListItemFromJSON)),
    };
}
export function ListPermitTypesResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'total': value.total,
        'permitTypes': (value.permitTypes.map(PermitTypeListItemToJSON)),
    };
}
