import { DisplayConfigFromJSON, DisplayConfigToJSON, } from './DisplayConfig';
import { SalesConfigFromJSON, SalesConfigToJSON, } from './SalesConfig';
import { StripeBillingConfigFromJSON, StripeBillingConfigToJSON, } from './StripeBillingConfig';
/**
 * Check if a given object implements the StripeProductTemplate interface.
 */
export function instanceOfStripeProductTemplate(value) {
    let isInstance = true;
    isInstance = isInstance && "isInternal" in value;
    isInstance = isInstance && "displayConfig" in value;
    isInstance = isInstance && "billingConfig" in value;
    isInstance = isInstance && "salesConfig" in value;
    isInstance = isInstance && "goodsIds" in value;
    return isInstance;
}
export function StripeProductTemplateFromJSON(json) {
    return StripeProductTemplateFromJSONTyped(json, false);
}
export function StripeProductTemplateFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'isInternal': json['isInternal'],
        'displayConfig': DisplayConfigFromJSON(json['displayConfig']),
        'billingConfig': StripeBillingConfigFromJSON(json['billingConfig']),
        'salesConfig': SalesConfigFromJSON(json['salesConfig']),
        'goodsIds': json['goodsIds'],
    };
}
export function StripeProductTemplateToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'isInternal': value.isInternal,
        'displayConfig': DisplayConfigToJSON(value.displayConfig),
        'billingConfig': StripeBillingConfigToJSON(value.billingConfig),
        'salesConfig': SalesConfigToJSON(value.salesConfig),
        'goodsIds': value.goodsIds,
    };
}
