// @ts-nocheck
import { name2num, num2name, } from "./(User)/Status";
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "../../google/protobuf/Timestamp";
import { encodeJson as encodeJson_2, decodeJson as decodeJson_2, encodeBinary as encodeBinary_2, decodeBinary as decodeBinary_2, } from "./(User)/Agreement";
import { name2num as name2num_1, num2name as num2name_1, } from "../../inside/Country";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../runtime/json/scalar";
import { WireType, } from "../../../runtime/wire/index";
import { default as serialize, } from "../../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../../runtime/wire/scalar";
import { default as Long, } from "../../../runtime/Long";
import { encodeBinary as encodeBinary_3, decodeBinary as decodeBinary_3, } from "../../google/protobuf/StringValue";
import { default as deserialize, } from "../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        id: "0",
        authId: "",
        status: "STATUS_UNSPECIFIED",
        createdAt: undefined,
        lastActivityAt: undefined,
        agreement: undefined,
        nickname: undefined,
        contactEmail: undefined,
        country: "UNSPECIFIED_COUNTRY",
        isMigrated: false,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.id !== undefined)
        result.id = tsValueToJsonValueFns.int64(value.id);
    if (value.authId !== undefined)
        result.authId = tsValueToJsonValueFns.string(value.authId);
    if (value.status !== undefined)
        result.status = tsValueToJsonValueFns.enum(value.status);
    if (value.createdAt !== undefined)
        result.createdAt = encodeJson_1(value.createdAt);
    if (value.lastActivityAt !== undefined)
        result.lastActivityAt = encodeJson_1(value.lastActivityAt);
    if (value.agreement !== undefined)
        result.agreement = encodeJson_2(value.agreement);
    if (value.nickname !== undefined)
        result.nickname = value.nickname;
    if (value.contactEmail !== undefined)
        result.contactEmail = value.contactEmail;
    if (value.country !== undefined)
        result.country = tsValueToJsonValueFns.enum(value.country);
    if (value.isMigrated !== undefined)
        result.isMigrated = tsValueToJsonValueFns.bool(value.isMigrated);
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.id !== undefined)
        result.id = jsonValueToTsValueFns.int64(value.id);
    if (value.authId !== undefined)
        result.authId = jsonValueToTsValueFns.string(value.authId);
    if (value.status !== undefined)
        result.status = jsonValueToTsValueFns.enum(value.status);
    if (value.createdAt !== undefined)
        result.createdAt = decodeJson_1(value.createdAt);
    if (value.lastActivityAt !== undefined)
        result.lastActivityAt = decodeJson_1(value.lastActivityAt);
    if (value.agreement !== undefined)
        result.agreement = decodeJson_2(value.agreement);
    if (value.nickname !== undefined)
        result.nickname = value.nickname;
    if (value.contactEmail !== undefined)
        result.contactEmail = value.contactEmail;
    if (value.country !== undefined)
        result.country = jsonValueToTsValueFns.enum(value.country);
    if (value.isMigrated !== undefined)
        result.isMigrated = jsonValueToTsValueFns.bool(value.isMigrated);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.id !== undefined) {
        const tsValue = value.id;
        result.push([1, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.authId !== undefined) {
        const tsValue = value.authId;
        result.push([2, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.status !== undefined) {
        const tsValue = value.status;
        result.push([3, { type: WireType.Varint, value: new Long(name2num[tsValue]) }]);
    }
    if (value.createdAt !== undefined) {
        const tsValue = value.createdAt;
        result.push([4, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    if (value.lastActivityAt !== undefined) {
        const tsValue = value.lastActivityAt;
        result.push([5, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    if (value.agreement !== undefined) {
        const tsValue = value.agreement;
        result.push([6, { type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }]);
    }
    if (value.nickname !== undefined) {
        const tsValue = value.nickname;
        result.push([7, ((tsValue) => ({ type: WireType.LengthDelimited, value: encodeBinary_3(tsValue) }))({ value: tsValue })]);
    }
    if (value.contactEmail !== undefined) {
        const tsValue = value.contactEmail;
        result.push([8, ((tsValue) => ({ type: WireType.LengthDelimited, value: encodeBinary_3(tsValue) }))({ value: tsValue })]);
    }
    if (value.country !== undefined) {
        const tsValue = value.country;
        result.push([9, { type: WireType.Varint, value: new Long(name2num_1[tsValue]) }]);
    }
    if (value.isMigrated !== undefined) {
        const tsValue = value.isMigrated;
        result.push([10, tsValueToWireValueFns.bool(tsValue)]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    var _a, _b;
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.id = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.authId = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.status = value;
    }
    field: {
        const wireValue = wireFields.get(4);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.createdAt = value;
    }
    field: {
        const wireValue = wireFields.get(5);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.lastActivityAt = value;
    }
    field: {
        const wireValue = wireFields.get(6);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.agreement = value;
    }
    field: {
        const wireValue = wireFields.get(7);
        if (wireValue === undefined)
            break field;
        const value = (_a = (wireValue.type === WireType.LengthDelimited ? decodeBinary_3(wireValue.value) : undefined)) === null || _a === void 0 ? void 0 : _a.value;
        if (value === undefined)
            break field;
        result.nickname = value;
    }
    field: {
        const wireValue = wireFields.get(8);
        if (wireValue === undefined)
            break field;
        const value = (_b = (wireValue.type === WireType.LengthDelimited ? decodeBinary_3(wireValue.value) : undefined)) === null || _b === void 0 ? void 0 : _b.value;
        if (value === undefined)
            break field;
        result.contactEmail = value;
    }
    field: {
        const wireValue = wireFields.get(9);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name_1[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.country = value;
    }
    field: {
        const wireValue = wireFields.get(10);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.bool(wireValue);
        if (value === undefined)
            break field;
        result.isMigrated = value;
    }
    return result;
}
