// @ts-nocheck
import { encodeJson as encodeJson_2, decodeJson as decodeJson_2, encodeBinary as encodeBinary_2, decodeBinary as decodeBinary_2, } from "../../TimeRange";
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "./Domain";
import { name2num, num2name, } from "./(Coupon)/Type";
import { encodeJson as encodeJson_3, decodeJson as decodeJson_3, encodeBinary as encodeBinary_3, decodeBinary as decodeBinary_3, } from "../../google/protobuf/Timestamp";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../runtime/json/scalar";
import { WireType, } from "../../../runtime/wire/index";
import { default as serialize, } from "../../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../../runtime/wire/scalar";
import { default as Long, } from "../../../runtime/Long";
import { default as deserialize, } from "../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        id: "0",
        name: "",
        domain: undefined,
        marketingCode: "",
        type: "TYPE_UNSPECIFIED",
        discount: "",
        minPrice: "",
        maxDiscount: "",
        canOverlap: false,
        canIssue: false,
        validRegisterRange: undefined,
        validIssueRange: undefined,
        totalCount: "0",
        issuedCount: "0",
        createdAt: undefined,
        createdBy: "",
        updatedAt: undefined,
        updatedBy: "",
        validUseEndOption: undefined,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    var _a;
    const result = {};
    if (value.id !== undefined)
        result.id = tsValueToJsonValueFns.int64(value.id);
    if (value.name !== undefined)
        result.name = tsValueToJsonValueFns.string(value.name);
    if (value.domain !== undefined)
        result.domain = encodeJson_1(value.domain);
    if (value.marketingCode !== undefined)
        result.marketingCode = tsValueToJsonValueFns.string(value.marketingCode);
    if (value.type !== undefined)
        result.type = tsValueToJsonValueFns.enum(value.type);
    if (value.discount !== undefined)
        result.discount = tsValueToJsonValueFns.string(value.discount);
    if (value.minPrice !== undefined)
        result.minPrice = tsValueToJsonValueFns.string(value.minPrice);
    if (value.maxDiscount !== undefined)
        result.maxDiscount = tsValueToJsonValueFns.string(value.maxDiscount);
    if (value.canOverlap !== undefined)
        result.canOverlap = tsValueToJsonValueFns.bool(value.canOverlap);
    if (value.canIssue !== undefined)
        result.canIssue = tsValueToJsonValueFns.bool(value.canIssue);
    if (value.validRegisterRange !== undefined)
        result.validRegisterRange = encodeJson_2(value.validRegisterRange);
    if (value.validIssueRange !== undefined)
        result.validIssueRange = encodeJson_2(value.validIssueRange);
    if (value.totalCount !== undefined)
        result.totalCount = tsValueToJsonValueFns.int64(value.totalCount);
    if (value.issuedCount !== undefined)
        result.issuedCount = tsValueToJsonValueFns.int64(value.issuedCount);
    if (value.createdAt !== undefined)
        result.createdAt = encodeJson_3(value.createdAt);
    if (value.createdBy !== undefined)
        result.createdBy = tsValueToJsonValueFns.string(value.createdBy);
    if (value.updatedAt !== undefined)
        result.updatedAt = encodeJson_3(value.updatedAt);
    if (value.updatedBy !== undefined)
        result.updatedBy = tsValueToJsonValueFns.string(value.updatedBy);
    switch ((_a = value.validUseEndOption) === null || _a === void 0 ? void 0 : _a.field) {
        case "validUseOffsetEnd": {
            result.validUseOffsetEnd = tsValueToJsonValueFns.string(value.validUseEndOption.value);
            break;
        }
        case "validUseRange": {
            result.validUseRange = encodeJson_2(value.validUseEndOption.value);
            break;
        }
    }
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.id !== undefined)
        result.id = jsonValueToTsValueFns.int64(value.id);
    if (value.name !== undefined)
        result.name = jsonValueToTsValueFns.string(value.name);
    if (value.domain !== undefined)
        result.domain = decodeJson_1(value.domain);
    if (value.marketingCode !== undefined)
        result.marketingCode = jsonValueToTsValueFns.string(value.marketingCode);
    if (value.type !== undefined)
        result.type = jsonValueToTsValueFns.enum(value.type);
    if (value.discount !== undefined)
        result.discount = jsonValueToTsValueFns.string(value.discount);
    if (value.minPrice !== undefined)
        result.minPrice = jsonValueToTsValueFns.string(value.minPrice);
    if (value.maxDiscount !== undefined)
        result.maxDiscount = jsonValueToTsValueFns.string(value.maxDiscount);
    if (value.canOverlap !== undefined)
        result.canOverlap = jsonValueToTsValueFns.bool(value.canOverlap);
    if (value.canIssue !== undefined)
        result.canIssue = jsonValueToTsValueFns.bool(value.canIssue);
    if (value.validRegisterRange !== undefined)
        result.validRegisterRange = decodeJson_2(value.validRegisterRange);
    if (value.validIssueRange !== undefined)
        result.validIssueRange = decodeJson_2(value.validIssueRange);
    if (value.totalCount !== undefined)
        result.totalCount = jsonValueToTsValueFns.int64(value.totalCount);
    if (value.issuedCount !== undefined)
        result.issuedCount = jsonValueToTsValueFns.int64(value.issuedCount);
    if (value.createdAt !== undefined)
        result.createdAt = decodeJson_3(value.createdAt);
    if (value.createdBy !== undefined)
        result.createdBy = jsonValueToTsValueFns.string(value.createdBy);
    if (value.updatedAt !== undefined)
        result.updatedAt = decodeJson_3(value.updatedAt);
    if (value.updatedBy !== undefined)
        result.updatedBy = jsonValueToTsValueFns.string(value.updatedBy);
    if (value.validUseOffsetEnd !== undefined)
        result.validUseEndOption = { field: "validUseOffsetEnd", value: jsonValueToTsValueFns.string(value.validUseOffsetEnd) };
    if (value.validUseRange !== undefined)
        result.validUseEndOption = { field: "validUseRange", value: decodeJson_2(value.validUseRange) };
    return result;
}
export function encodeBinary(value) {
    var _a;
    const result = [];
    if (value.id !== undefined) {
        const tsValue = value.id;
        result.push([1, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.name !== undefined) {
        const tsValue = value.name;
        result.push([2, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.domain !== undefined) {
        const tsValue = value.domain;
        result.push([3, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    if (value.marketingCode !== undefined) {
        const tsValue = value.marketingCode;
        result.push([4, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.type !== undefined) {
        const tsValue = value.type;
        result.push([5, { type: WireType.Varint, value: new Long(name2num[tsValue]) }]);
    }
    if (value.discount !== undefined) {
        const tsValue = value.discount;
        result.push([6, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.minPrice !== undefined) {
        const tsValue = value.minPrice;
        result.push([7, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.maxDiscount !== undefined) {
        const tsValue = value.maxDiscount;
        result.push([8, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.canOverlap !== undefined) {
        const tsValue = value.canOverlap;
        result.push([9, tsValueToWireValueFns.bool(tsValue)]);
    }
    if (value.canIssue !== undefined) {
        const tsValue = value.canIssue;
        result.push([10, tsValueToWireValueFns.bool(tsValue)]);
    }
    if (value.validRegisterRange !== undefined) {
        const tsValue = value.validRegisterRange;
        result.push([11, { type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }]);
    }
    if (value.validIssueRange !== undefined) {
        const tsValue = value.validIssueRange;
        result.push([15, { type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }]);
    }
    if (value.totalCount !== undefined) {
        const tsValue = value.totalCount;
        result.push([16, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.issuedCount !== undefined) {
        const tsValue = value.issuedCount;
        result.push([17, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.createdAt !== undefined) {
        const tsValue = value.createdAt;
        result.push([18, { type: WireType.LengthDelimited, value: encodeBinary_3(tsValue) }]);
    }
    if (value.createdBy !== undefined) {
        const tsValue = value.createdBy;
        result.push([19, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.updatedAt !== undefined) {
        const tsValue = value.updatedAt;
        result.push([20, { type: WireType.LengthDelimited, value: encodeBinary_3(tsValue) }]);
    }
    if (value.updatedBy !== undefined) {
        const tsValue = value.updatedBy;
        result.push([21, tsValueToWireValueFns.string(tsValue)]);
    }
    switch ((_a = value.validUseEndOption) === null || _a === void 0 ? void 0 : _a.field) {
        case "validUseOffsetEnd": {
            const tsValue = value.validUseEndOption.value;
            result.push([14, tsValueToWireValueFns.string(tsValue)]);
            break;
        }
        case "validUseRange": {
            const tsValue = value.validUseEndOption.value;
            result.push([22, { type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }]);
            break;
        }
    }
    return serialize(result);
}
const oneofFieldNumbersMap = {
    validUseEndOption: new Set([14, 22]),
};
const oneofFieldNamesMap = {
    validUseEndOption: new Map([
        [14, "validUseOffsetEnd"],
        [22, "validUseRange"],
    ]),
};
export function decodeBinary(binary) {
    var _a;
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    const wireFieldNumbers = Array.from(wireFields.keys()).reverse();
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.id = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.name = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.domain = value;
    }
    field: {
        const wireValue = wireFields.get(4);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.marketingCode = value;
    }
    field: {
        const wireValue = wireFields.get(5);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.type = value;
    }
    field: {
        const wireValue = wireFields.get(6);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.discount = value;
    }
    field: {
        const wireValue = wireFields.get(7);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.minPrice = value;
    }
    field: {
        const wireValue = wireFields.get(8);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.maxDiscount = value;
    }
    field: {
        const wireValue = wireFields.get(9);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.bool(wireValue);
        if (value === undefined)
            break field;
        result.canOverlap = value;
    }
    field: {
        const wireValue = wireFields.get(10);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.bool(wireValue);
        if (value === undefined)
            break field;
        result.canIssue = value;
    }
    field: {
        const wireValue = wireFields.get(11);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.validRegisterRange = value;
    }
    field: {
        const wireValue = wireFields.get(15);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.validIssueRange = value;
    }
    field: {
        const wireValue = wireFields.get(16);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.totalCount = value;
    }
    field: {
        const wireValue = wireFields.get(17);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.issuedCount = value;
    }
    field: {
        const wireValue = wireFields.get(18);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_3(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.createdAt = value;
    }
    field: {
        const wireValue = wireFields.get(19);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.createdBy = value;
    }
    field: {
        const wireValue = wireFields.get(20);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_3(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.updatedAt = value;
    }
    field: {
        const wireValue = wireFields.get(21);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.updatedBy = value;
    }
    oneof: {
        const oneofFieldNumbers = oneofFieldNumbersMap.validUseEndOption;
        const oneofFieldNames = oneofFieldNamesMap.validUseEndOption;
        const fieldNumber = wireFieldNumbers.find(v => oneofFieldNumbers.has(v));
        if (fieldNumber == null)
            break oneof;
        const wireValue = wireFields.get(fieldNumber);
        const wireValueToTsValueMap = {
            [14](wireValue) { return wireValueToTsValueFns.string(wireValue); },
            [22](wireValue) { return wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined; },
        };
        const value = (_a = wireValueToTsValueMap[fieldNumber]) === null || _a === void 0 ? void 0 : _a.call(wireValueToTsValueMap, wireValue);
        if (value === undefined)
            break oneof;
        result.validUseEndOption = { field: oneofFieldNames.get(fieldNumber), value: value };
    }
    return result;
}
