/* tslint:disable */
/* eslint-disable */
import * as runtime from '../runtime';
import { CreateTicketTemplateRequestToJSON, CreateTicketTemplateResponseFromJSON, GetTicketResponseFromJSON, GetTicketTemplateResponseFromJSON, IssueTicketFromTemplateRequestToJSON, IssueTicketFromTemplateResponseFromJSON, ListOnboardingTicketTemplatesResponseFromJSON, ListTicketTemplateResponseFromJSON, ListTicketsResponseFromJSON, SetOnboardingTicketTemplatesRequestToJSON, SetOnboardingTicketTemplatesResponseFromJSON, UpdateTicketTemplateRequestToJSON, } from '../models';
/**
 *
 */
export class PermitAdminV1TicketApi extends runtime.BaseAPI {
    /**
     * Ticket template을 생성한다.
     */
    async createTicketTemplateRaw(requestParameters, initOverrides) {
        if (requestParameters.createTicketTemplateRequest === null || requestParameters.createTicketTemplateRequest === undefined) {
            throw new runtime.RequiredError('createTicketTemplateRequest', 'Required parameter requestParameters.createTicketTemplateRequest was null or undefined when calling createTicketTemplate.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/admin/permit/v1/ticket/template`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateTicketTemplateRequestToJSON(requestParameters.createTicketTemplateRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => CreateTicketTemplateResponseFromJSON(jsonValue));
    }
    /**
     * Ticket template을 생성한다.
     */
    async createTicketTemplate(requestParameters, initOverrides) {
        const response = await this.createTicketTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 지급된 ticket 상세 정보를 조회한다.
     */
    async getTicketRaw(requestParameters, initOverrides) {
        if (requestParameters.ticketId === null || requestParameters.ticketId === undefined) {
            throw new runtime.RequiredError('ticketId', 'Required parameter requestParameters.ticketId was null or undefined when calling getTicket.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/admin/permit/v1/ticket/{ticketId}`.replace(`{${"ticketId"}}`, encodeURIComponent(String(requestParameters.ticketId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GetTicketResponseFromJSON(jsonValue));
    }
    /**
     * 지급된 ticket 상세 정보를 조회한다.
     */
    async getTicket(requestParameters, initOverrides) {
        const response = await this.getTicketRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Ticket template을 조회한다.
     */
    async getTicketTemplateRaw(requestParameters, initOverrides) {
        if (requestParameters.ticketTemplateId === null || requestParameters.ticketTemplateId === undefined) {
            throw new runtime.RequiredError('ticketTemplateId', 'Required parameter requestParameters.ticketTemplateId was null or undefined when calling getTicketTemplate.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/admin/permit/v1/ticket/template/{ticketTemplateId}`.replace(`{${"ticketTemplateId"}}`, encodeURIComponent(String(requestParameters.ticketTemplateId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GetTicketTemplateResponseFromJSON(jsonValue));
    }
    /**
     * Ticket template을 조회한다.
     */
    async getTicketTemplate(requestParameters, initOverrides) {
        const response = await this.getTicketTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Ticket template에 해당하는 ticket을 지정한 profile에 지급한다.
     */
    async issueTicketFromTemplateRaw(requestParameters, initOverrides) {
        if (requestParameters.ticketTemplateId === null || requestParameters.ticketTemplateId === undefined) {
            throw new runtime.RequiredError('ticketTemplateId', 'Required parameter requestParameters.ticketTemplateId was null or undefined when calling issueTicketFromTemplate.');
        }
        if (requestParameters.issueTicketFromTemplateRequest === null || requestParameters.issueTicketFromTemplateRequest === undefined) {
            throw new runtime.RequiredError('issueTicketFromTemplateRequest', 'Required parameter requestParameters.issueTicketFromTemplateRequest was null or undefined when calling issueTicketFromTemplate.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/admin/permit/v1/ticket/template/{ticketTemplateId}/issue`.replace(`{${"ticketTemplateId"}}`, encodeURIComponent(String(requestParameters.ticketTemplateId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IssueTicketFromTemplateRequestToJSON(requestParameters.issueTicketFromTemplateRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => IssueTicketFromTemplateResponseFromJSON(jsonValue));
    }
    /**
     * Ticket template에 해당하는 ticket을 지정한 profile에 지급한다.
     */
    async issueTicketFromTemplate(requestParameters, initOverrides) {
        const response = await this.issueTicketFromTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 현재 설정된 onboarding ticket template을 조회한다.
     */
    async listOnboardingTicketTemplatesRaw(requestParameters, initOverrides) {
        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size', 'Required parameter requestParameters.size was null or undefined when calling listOnboardingTicketTemplates.');
        }
        const queryParameters = {};
        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }
        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/api/admin/permit/v1/ticket/template/onboarding`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ListOnboardingTicketTemplatesResponseFromJSON(jsonValue));
    }
    /**
     * 현재 설정된 onboarding ticket template을 조회한다.
     */
    async listOnboardingTicketTemplates(requestParameters, initOverrides) {
        const response = await this.listOnboardingTicketTemplatesRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Ticket template 목록을 조회한다.
     */
    async listTicketTemplatesRaw(requestParameters, initOverrides) {
        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size', 'Required parameter requestParameters.size was null or undefined when calling listTicketTemplates.');
        }
        const queryParameters = {};
        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }
        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }
        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }
        if (requestParameters.permitTypeId !== undefined) {
            queryParameters['permitTypeId'] = requestParameters.permitTypeId;
        }
        if (requestParameters.description !== undefined) {
            queryParameters['description'] = requestParameters.description;
        }
        if (requestParameters.isPublished !== undefined) {
            queryParameters['isPublished'] = requestParameters.isPublished;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/api/admin/permit/v1/ticket/template`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ListTicketTemplateResponseFromJSON(jsonValue));
    }
    /**
     * Ticket template 목록을 조회한다.
     */
    async listTicketTemplates(requestParameters, initOverrides) {
        const response = await this.listTicketTemplatesRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 지정된 profile에 지급된 ticket 목록을 조회한다.
     */
    async listTicketsRaw(requestParameters, initOverrides) {
        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size', 'Required parameter requestParameters.size was null or undefined when calling listTickets.');
        }
        if (requestParameters.profileId === null || requestParameters.profileId === undefined) {
            throw new runtime.RequiredError('profileId', 'Required parameter requestParameters.profileId was null or undefined when calling listTickets.');
        }
        const queryParameters = {};
        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }
        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }
        if (requestParameters.profileId !== undefined) {
            queryParameters['profileId'] = requestParameters.profileId;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/api/admin/permit/v1/ticket/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ListTicketsResponseFromJSON(jsonValue));
    }
    /**
     * 지정된 profile에 지급된 ticket 목록을 조회한다.
     */
    async listTickets(requestParameters, initOverrides) {
        const response = await this.listTicketsRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Ticket template을 publish한다.
     */
    async publishTicketTemplateRaw(requestParameters, initOverrides) {
        if (requestParameters.ticketTemplateId === null || requestParameters.ticketTemplateId === undefined) {
            throw new runtime.RequiredError('ticketTemplateId', 'Required parameter requestParameters.ticketTemplateId was null or undefined when calling publishTicketTemplate.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/admin/permit/v1/ticket/template/{ticketTemplateId}/publish`.replace(`{${"ticketTemplateId"}}`, encodeURIComponent(String(requestParameters.ticketTemplateId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Ticket template을 publish한다.
     */
    async publishTicketTemplate(requestParameters, initOverrides) {
        await this.publishTicketTemplateRaw(requestParameters, initOverrides);
    }
    /**
     * Onboarding ticket template을 설정한다.
     */
    async setOnboardingTicketTemplatesRaw(requestParameters, initOverrides) {
        if (requestParameters.setOnboardingTicketTemplatesRequest === null || requestParameters.setOnboardingTicketTemplatesRequest === undefined) {
            throw new runtime.RequiredError('setOnboardingTicketTemplatesRequest', 'Required parameter requestParameters.setOnboardingTicketTemplatesRequest was null or undefined when calling setOnboardingTicketTemplates.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/admin/permit/v1/ticket/template/onboarding`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetOnboardingTicketTemplatesRequestToJSON(requestParameters.setOnboardingTicketTemplatesRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => SetOnboardingTicketTemplatesResponseFromJSON(jsonValue));
    }
    /**
     * Onboarding ticket template을 설정한다.
     */
    async setOnboardingTicketTemplates(requestParameters, initOverrides) {
        const response = await this.setOnboardingTicketTemplatesRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * TicketTemplate을 수정한다.
     */
    async updateTicketTemplateRaw(requestParameters, initOverrides) {
        if (requestParameters.ticketTemplateId === null || requestParameters.ticketTemplateId === undefined) {
            throw new runtime.RequiredError('ticketTemplateId', 'Required parameter requestParameters.ticketTemplateId was null or undefined when calling updateTicketTemplate.');
        }
        if (requestParameters.updateTicketTemplateRequest === null || requestParameters.updateTicketTemplateRequest === undefined) {
            throw new runtime.RequiredError('updateTicketTemplateRequest', 'Required parameter requestParameters.updateTicketTemplateRequest was null or undefined when calling updateTicketTemplate.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/admin/permit/v1/ticket/template/{ticketTemplateId}`.replace(`{${"ticketTemplateId"}}`, encodeURIComponent(String(requestParameters.ticketTemplateId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateTicketTemplateRequestToJSON(requestParameters.updateTicketTemplateRequest),
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     * TicketTemplate을 수정한다.
     */
    async updateTicketTemplate(requestParameters, initOverrides) {
        await this.updateTicketTemplateRaw(requestParameters, initOverrides);
    }
}
