/**
 * Check if a given object implements the OnboardingTicketTemplatesListItem interface.
 */
export function instanceOfOnboardingTicketTemplatesListItem(value) {
    let isInstance = true;
    isInstance = isInstance && "quantity" in value;
    isInstance = isInstance && "ticketTemplateId" in value;
    return isInstance;
}
export function OnboardingTicketTemplatesListItemFromJSON(json) {
    return OnboardingTicketTemplatesListItemFromJSONTyped(json, false);
}
export function OnboardingTicketTemplatesListItemFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'quantity': json['quantity'],
        'ticketTemplateId': json['ticketTemplateId'],
    };
}
export function OnboardingTicketTemplatesListItemToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'quantity': value.quantity,
        'ticketTemplateId': value.ticketTemplateId,
    };
}
