import React from 'react';
import {css} from '@emotion/react';
import {AppBar, Toolbar} from '@mui/material';

import {DRAWER_WIDTH} from './Navigation';

const Header: React.FC = () => {
  return (
    <AppBar
      position="fixed"
      css={css`
        width: calc(100% - ${DRAWER_WIDTH}px);
        margin-left: ${DRAWER_WIDTH}px;
      `}>
      <Toolbar />
    </AppBar>
  );
};

export default Header;
