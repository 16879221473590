import { isValid as isValidDate, parseISO } from "date-fns";
import { isPbTimestamp } from "../proto/isPbTimestamp";
import { isTimeRange } from "../proto/isTimeRange";
import { toDateRangeString } from "../proto/toDateRangeString";
import { toDateString } from "../proto/toDateString";
export var formatAnyValue = function(v) {
    if (v == null) return undefined;
    if (isPbTimestamp(v)) {
        return toDateString(v);
    }
    if (!Number.isNaN(Number.parseInt(v))) {
        return v.toString();
    }
    // https://github.com/date-fns/date-fns/issues/1575
    if (typeof v === "string" && isValidDate(parseISO(v))) {
        return toDateString(parseISO(v));
    }
    if (typeof v !== "string" && isValidDate(v)) {
        return toDateString(v);
    }
    if (isTimeRange(v)) {
        return toDateRangeString(v);
    }
    return v.toString();
};
