/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { DisplayInfoFromJSON, DisplayInfoToJSON, } from './DisplayInfo';
import { PassTemplateManagementInfoFromJSON, PassTemplateManagementInfoToJSON, } from './PassTemplateManagementInfo';
import { PermitFromJSON, PermitToJSON, } from './Permit';
/**
 * Check if a given object implements the PassTemplate interface.
 */
export function instanceOfPassTemplate(value) {
    let isInstance = true;
    isInstance = isInstance && "country" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "permits" in value;
    isInstance = isInstance && "displayInfo" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "managementInfo" in value;
    isInstance = isInstance && "learningDomainId" in value;
    isInstance = isInstance && "updatedAt" in value;
    return isInstance;
}
export function PassTemplateFromJSON(json) {
    return PassTemplateFromJSONTyped(json, false);
}
export function PassTemplateFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'country': json['country'],
        'createdAt': (new Date(json['createdAt'])),
        'permits': (json['permits'].map(PermitFromJSON)),
        'publishedAt': !exists(json, 'publishedAt') ? undefined : (new Date(json['publishedAt'])),
        'displayInfo': DisplayInfoFromJSON(json['displayInfo']),
        'id': json['id'],
        'managementInfo': PassTemplateManagementInfoFromJSON(json['managementInfo']),
        'learningDomainId': json['learningDomainId'],
        'updatedAt': (new Date(json['updatedAt'])),
    };
}
export function PassTemplateToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'country': value.country,
        'createdAt': (value.createdAt.toISOString()),
        'permits': (value.permits.map(PermitToJSON)),
        'publishedAt': value.publishedAt === undefined ? undefined : (value.publishedAt.toISOString()),
        'displayInfo': DisplayInfoToJSON(value.displayInfo),
        'id': value.id,
        'managementInfo': PassTemplateManagementInfoToJSON(value.managementInfo),
        'learningDomainId': value.learningDomainId,
        'updatedAt': (value.updatedAt.toISOString()),
    };
}
