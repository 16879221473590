function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
import FileSaver from "file-saver";
import xlsx from "xlsx";
export default function exportDataToExcel(data) {
    var fileName = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : "exportData";
    var sheetData = xlsx.utils.json_to_sheet(data);
    var workBook = {
        Sheets: _define_property({}, fileName, sheetData),
        SheetNames: [
            fileName
        ]
    };
    var excelBuffer = xlsx.write(workBook, {
        bookType: "xlsx",
        type: "array"
    });
    var excelFileContent = new Blob([
        excelBuffer
    ], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
    });
    FileSaver.saveAs(excelFileContent, "".concat(fileName, ".xlsx"));
}
