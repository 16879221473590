export const num2name = {
    0: "PROCESS_STATUS_UNSPECIFIED",
    1: "PROCESS_STATUS_RUNNING",
    2: "PROCESS_STATUS_FINISHED",
};
export const name2num = {
    PROCESS_STATUS_UNSPECIFIED: 0,
    PROCESS_STATUS_RUNNING: 1,
    PROCESS_STATUS_FINISHED: 2,
};
