import { Cell, Column, RenderMode, RowHeaderCell, Table2, ColumnHeaderCell } from "@blueprintjs/table";
var styles = function(theme) {
    return "\n  .bp5-table-cell {\n    display: flex !important;\n    align-items: center;\n    background-color: ".concat(theme.palette.background.paper, ";\n    color: ").concat(theme.palette.getContrastText(theme.palette.background.paper), ";\n  }\n  .bp5-table-header {\n    padding: 0 8px;\n    display: flex !important;\n    flex-direction: column;\n    justify-content: center !important;\n    background-color: ").concat(theme.palette.background.default, ";\n    color: ").concat(theme.palette.getContrastText(theme.palette.background.default), ";\n  }\n  .bp5-table-menu {\n    background-color: ").concat(theme.palette.background.default, ";\n    color: ").concat(theme.palette.getContrastText(theme.palette.background.default), ";\n  }\n  .bp5-table-quadrant {\n    background-color: ").concat(theme.palette.background.default, ";\n  }\n  .bp5-table-container {\n    background-color: ").concat(theme.palette.background.default, ";\n  }\n  .bp5-table-cell-client {\n    background: ").concat(theme.palette.background.paper, ";\n  }\n  .bp5-table-row-headers {\n    background: ").concat(theme.palette.background.paper, ";\n  }\n  .bp5-table-column-headers {\n    background: ").concat(theme.palette.background.paper, ";\n  }\n  .bp5-table-row-headers-cells-container > .bp5-table-header {\n    display: grid !important;\n    align-items: center;\n}");
};
export default {
    Cell: Cell,
    Column: Column,
    RenderMode: RenderMode,
    RowHeaderCell: RowHeaderCell,
    ColumnHeaderCell: ColumnHeaderCell,
    Table: Table2,
    styles: styles
};
