// @ts-nocheck
import { encodeJson as encodeJson_4, decodeJson as decodeJson_4, encodeBinary as encodeBinary_4, decodeBinary as decodeBinary_4, } from "../../TimeRange";
import { encodeJson as encodeJson_5, decodeJson as decodeJson_5, encodeBinary as encodeBinary_5, decodeBinary as decodeBinary_5, } from "../../google/protobuf/Empty";
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "../../inside/coupon/Coupon";
import { name2num, num2name, } from "./(CouponAutoIssueSetting)/Status";
import { name2num as name2num_1, num2name as num2name_1, } from "./(CouponAutoIssueSetting)/Type";
import { encodeJson as encodeJson_2, decodeJson as decodeJson_2, encodeBinary as encodeBinary_2, decodeBinary as decodeBinary_2, } from "./(CouponAutoIssueSetting)/IssueStatus";
import { encodeJson as encodeJson_3, decodeJson as decodeJson_3, encodeBinary as encodeBinary_3, decodeBinary as decodeBinary_3, } from "../../google/protobuf/Timestamp";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../runtime/json/scalar";
import { WireType, } from "../../../runtime/wire/index";
import { default as serialize, } from "../../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../../runtime/wire/scalar";
import { default as Long, } from "../../../runtime/Long";
import { default as deserialize, } from "../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        id: "0",
        coupon: undefined,
        status: "STATUS_UNSPECIFIED",
        type: "TYPE_UNSPECIFIED",
        issueStatus: undefined,
        createdAt: undefined,
        lastUpdatedAt: undefined,
        issueCondition: undefined,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    var _a;
    const result = {};
    if (value.id !== undefined)
        result.id = tsValueToJsonValueFns.int64(value.id);
    if (value.coupon !== undefined)
        result.coupon = encodeJson_1(value.coupon);
    if (value.status !== undefined)
        result.status = tsValueToJsonValueFns.enum(value.status);
    if (value.type !== undefined)
        result.type = tsValueToJsonValueFns.enum(value.type);
    if (value.issueStatus !== undefined)
        result.issueStatus = encodeJson_2(value.issueStatus);
    if (value.createdAt !== undefined)
        result.createdAt = encodeJson_3(value.createdAt);
    if (value.lastUpdatedAt !== undefined)
        result.lastUpdatedAt = encodeJson_3(value.lastUpdatedAt);
    switch ((_a = value.issueCondition) === null || _a === void 0 ? void 0 : _a.field) {
        case "signUpRange": {
            result.signUpRange = encodeJson_4(value.issueCondition.value);
            break;
        }
        case "targetUsers": {
            result.targetUsers = encodeJson_5(value.issueCondition.value);
            break;
        }
    }
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.id !== undefined)
        result.id = jsonValueToTsValueFns.int64(value.id);
    if (value.coupon !== undefined)
        result.coupon = decodeJson_1(value.coupon);
    if (value.status !== undefined)
        result.status = jsonValueToTsValueFns.enum(value.status);
    if (value.type !== undefined)
        result.type = jsonValueToTsValueFns.enum(value.type);
    if (value.issueStatus !== undefined)
        result.issueStatus = decodeJson_2(value.issueStatus);
    if (value.createdAt !== undefined)
        result.createdAt = decodeJson_3(value.createdAt);
    if (value.lastUpdatedAt !== undefined)
        result.lastUpdatedAt = decodeJson_3(value.lastUpdatedAt);
    if (value.signUpRange !== undefined)
        result.issueCondition = { field: "signUpRange", value: decodeJson_4(value.signUpRange) };
    if (value.targetUsers !== undefined)
        result.issueCondition = { field: "targetUsers", value: decodeJson_5(value.targetUsers) };
    return result;
}
export function encodeBinary(value) {
    var _a;
    const result = [];
    if (value.id !== undefined) {
        const tsValue = value.id;
        result.push([1, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.coupon !== undefined) {
        const tsValue = value.coupon;
        result.push([2, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    if (value.status !== undefined) {
        const tsValue = value.status;
        result.push([3, { type: WireType.Varint, value: new Long(name2num[tsValue]) }]);
    }
    if (value.type !== undefined) {
        const tsValue = value.type;
        result.push([4, { type: WireType.Varint, value: new Long(name2num_1[tsValue]) }]);
    }
    if (value.issueStatus !== undefined) {
        const tsValue = value.issueStatus;
        result.push([7, { type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }]);
    }
    if (value.createdAt !== undefined) {
        const tsValue = value.createdAt;
        result.push([8, { type: WireType.LengthDelimited, value: encodeBinary_3(tsValue) }]);
    }
    if (value.lastUpdatedAt !== undefined) {
        const tsValue = value.lastUpdatedAt;
        result.push([9, { type: WireType.LengthDelimited, value: encodeBinary_3(tsValue) }]);
    }
    switch ((_a = value.issueCondition) === null || _a === void 0 ? void 0 : _a.field) {
        case "signUpRange": {
            const tsValue = value.issueCondition.value;
            result.push([5, { type: WireType.LengthDelimited, value: encodeBinary_4(tsValue) }]);
            break;
        }
        case "targetUsers": {
            const tsValue = value.issueCondition.value;
            result.push([6, { type: WireType.LengthDelimited, value: encodeBinary_5(tsValue) }]);
            break;
        }
    }
    return serialize(result);
}
const oneofFieldNumbersMap = {
    issueCondition: new Set([5, 6]),
};
const oneofFieldNamesMap = {
    issueCondition: new Map([
        [5, "signUpRange"],
        [6, "targetUsers"],
    ]),
};
export function decodeBinary(binary) {
    var _a;
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    const wireFieldNumbers = Array.from(wireFields.keys()).reverse();
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.id = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.coupon = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.status = value;
    }
    field: {
        const wireValue = wireFields.get(4);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name_1[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.type = value;
    }
    field: {
        const wireValue = wireFields.get(7);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.issueStatus = value;
    }
    field: {
        const wireValue = wireFields.get(8);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_3(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.createdAt = value;
    }
    field: {
        const wireValue = wireFields.get(9);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_3(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.lastUpdatedAt = value;
    }
    oneof: {
        const oneofFieldNumbers = oneofFieldNumbersMap.issueCondition;
        const oneofFieldNames = oneofFieldNamesMap.issueCondition;
        const fieldNumber = wireFieldNumbers.find(v => oneofFieldNumbers.has(v));
        if (fieldNumber == null)
            break oneof;
        const wireValue = wireFields.get(fieldNumber);
        const wireValueToTsValueMap = {
            [5](wireValue) { return wireValue.type === WireType.LengthDelimited ? decodeBinary_4(wireValue.value) : undefined; },
            [6](wireValue) { return wireValue.type === WireType.LengthDelimited ? decodeBinary_5(wireValue.value) : undefined; },
        };
        const value = (_a = wireValueToTsValueMap[fieldNumber]) === null || _a === void 0 ? void 0 : _a.call(wireValueToTsValueMap, wireValue);
        if (value === undefined)
            break oneof;
        result.issueCondition = { field: oneofFieldNames.get(fieldNumber), value: value };
    }
    return result;
}
