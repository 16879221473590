// @ts-nocheck
import { encodeJson as encodeJson_2, decodeJson as decodeJson_2, encodeBinary as encodeBinary_3, decodeBinary as decodeBinary_3, } from "./(UpdateHistory)/UpdateUserStatus";
import { encodeJson as encodeJson_3, decodeJson as decodeJson_3, encodeBinary as encodeBinary_4, decodeBinary as decodeBinary_4, } from "./(UpdateHistory)/UpdateAgreementMarketing";
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_2, decodeBinary as decodeBinary_2, } from "../../../google/protobuf/Timestamp";
import { WireType, } from "../../../../runtime/wire/index";
import { default as serialize, } from "../../../../runtime/wire/serialize";
import { encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "../../../google/protobuf/StringValue";
import { default as deserialize, } from "../../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        updaterAdminEmail: undefined,
        updatedAt: undefined,
        detail: undefined,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    var _a;
    const result = {};
    if (value.updaterAdminEmail !== undefined)
        result.updaterAdminEmail = value.updaterAdminEmail;
    if (value.updatedAt !== undefined)
        result.updatedAt = encodeJson_1(value.updatedAt);
    switch ((_a = value.detail) === null || _a === void 0 ? void 0 : _a.field) {
        case "updateUserStatus": {
            result.updateUserStatus = encodeJson_2(value.detail.value);
            break;
        }
        case "updateAgreementMarketing": {
            result.updateAgreementMarketing = encodeJson_3(value.detail.value);
            break;
        }
    }
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.updaterAdminEmail !== undefined)
        result.updaterAdminEmail = value.updaterAdminEmail;
    if (value.updatedAt !== undefined)
        result.updatedAt = decodeJson_1(value.updatedAt);
    if (value.updateUserStatus !== undefined)
        result.detail = { field: "updateUserStatus", value: decodeJson_2(value.updateUserStatus) };
    if (value.updateAgreementMarketing !== undefined)
        result.detail = { field: "updateAgreementMarketing", value: decodeJson_3(value.updateAgreementMarketing) };
    return result;
}
export function encodeBinary(value) {
    var _a;
    const result = [];
    if (value.updaterAdminEmail !== undefined) {
        const tsValue = value.updaterAdminEmail;
        result.push([1, ((tsValue) => ({ type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }))({ value: tsValue })]);
    }
    if (value.updatedAt !== undefined) {
        const tsValue = value.updatedAt;
        result.push([2, { type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }]);
    }
    switch ((_a = value.detail) === null || _a === void 0 ? void 0 : _a.field) {
        case "updateUserStatus": {
            const tsValue = value.detail.value;
            result.push([3, { type: WireType.LengthDelimited, value: encodeBinary_3(tsValue) }]);
            break;
        }
        case "updateAgreementMarketing": {
            const tsValue = value.detail.value;
            result.push([4, { type: WireType.LengthDelimited, value: encodeBinary_4(tsValue) }]);
            break;
        }
    }
    return serialize(result);
}
const oneofFieldNumbersMap = {
    detail: new Set([3, 4]),
};
const oneofFieldNamesMap = {
    detail: new Map([
        [3, "updateUserStatus"],
        [4, "updateAgreementMarketing"],
    ]),
};
export function decodeBinary(binary) {
    var _a, _b;
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    const wireFieldNumbers = Array.from(wireFields.keys()).reverse();
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = (_a = (wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined)) === null || _a === void 0 ? void 0 : _a.value;
        if (value === undefined)
            break field;
        result.updaterAdminEmail = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.updatedAt = value;
    }
    oneof: {
        const oneofFieldNumbers = oneofFieldNumbersMap.detail;
        const oneofFieldNames = oneofFieldNamesMap.detail;
        const fieldNumber = wireFieldNumbers.find(v => oneofFieldNumbers.has(v));
        if (fieldNumber == null)
            break oneof;
        const wireValue = wireFields.get(fieldNumber);
        const wireValueToTsValueMap = {
            [3](wireValue) { return wireValue.type === WireType.LengthDelimited ? decodeBinary_3(wireValue.value) : undefined; },
            [4](wireValue) { return wireValue.type === WireType.LengthDelimited ? decodeBinary_4(wireValue.value) : undefined; },
        };
        const value = (_b = wireValueToTsValueMap[fieldNumber]) === null || _b === void 0 ? void 0 : _b.call(wireValueToTsValueMap, wireValue);
        if (value === undefined)
            break oneof;
        result.detail = { field: oneofFieldNames.get(fieldNumber), value: value };
    }
    return result;
}
