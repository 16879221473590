/**
 * Check if a given object implements the IssuePassFromTemplateRequest interface.
 */
export function instanceOfIssuePassFromTemplateRequest(value) {
    let isInstance = true;
    isInstance = isInstance && "reason" in value;
    isInstance = isInstance && "expirationInDays" in value;
    isInstance = isInstance && "userId" in value;
    return isInstance;
}
export function IssuePassFromTemplateRequestFromJSON(json) {
    return IssuePassFromTemplateRequestFromJSONTyped(json, false);
}
export function IssuePassFromTemplateRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'reason': json['reason'],
        'expirationInDays': json['expirationInDays'],
        'userId': json['userId'],
    };
}
export function IssuePassFromTemplateRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'reason': value.reason,
        'expirationInDays': value.expirationInDays,
        'userId': value.userId,
    };
}
