export const num2name = {
    0: "TUTOR_STATUS_UNSPECIFIED",
    1: "PENDING",
    2: "NORMAL",
    3: "SUSPENDED",
    4: "TERMINATED",
};
export const name2num = {
    TUTOR_STATUS_UNSPECIFIED: 0,
    PENDING: 1,
    NORMAL: 2,
    SUSPENDED: 3,
    TERMINATED: 4,
};
