import { ManagementInfoFromJSON, ManagementInfoToJSON, } from './ManagementInfo';
import { OfferGroupSalesInfoFromJSON, OfferGroupSalesInfoToJSON, } from './OfferGroupSalesInfo';
/**
 * Check if a given object implements the OfferGroupListItem interface.
 */
export function instanceOfOfferGroupListItem(value) {
    let isInstance = true;
    isInstance = isInstance && "offerCount" in value;
    isInstance = isInstance && "isPublished" in value;
    isInstance = isInstance && "salesConfig" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "managementInfo" in value;
    return isInstance;
}
export function OfferGroupListItemFromJSON(json) {
    return OfferGroupListItemFromJSONTyped(json, false);
}
export function OfferGroupListItemFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'offerCount': json['offerCount'],
        'isPublished': json['isPublished'],
        'salesConfig': OfferGroupSalesInfoFromJSON(json['salesConfig']),
        'id': json['id'],
        'managementInfo': ManagementInfoFromJSON(json['managementInfo']),
    };
}
export function OfferGroupListItemToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'offerCount': value.offerCount,
        'isPublished': value.isPublished,
        'salesConfig': OfferGroupSalesInfoToJSON(value.salesConfig),
        'id': value.id,
        'managementInfo': ManagementInfoToJSON(value.managementInfo),
    };
}
