import { UserStripeSubscriptionOrderListItemFromJSON, UserStripeSubscriptionOrderListItemToJSON, } from './UserStripeSubscriptionOrderListItem';
/**
 * Check if a given object implements the ListUserStripeSubscriptionOrdersResponse interface.
 */
export function instanceOfListUserStripeSubscriptionOrdersResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "subscriptions" in value;
    isInstance = isInstance && "total" in value;
    return isInstance;
}
export function ListUserStripeSubscriptionOrdersResponseFromJSON(json) {
    return ListUserStripeSubscriptionOrdersResponseFromJSONTyped(json, false);
}
export function ListUserStripeSubscriptionOrdersResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'subscriptions': (json['subscriptions'].map(UserStripeSubscriptionOrderListItemFromJSON)),
        'total': json['total'],
    };
}
export function ListUserStripeSubscriptionOrdersResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'subscriptions': (value.subscriptions.map(UserStripeSubscriptionOrderListItemToJSON)),
        'total': value.total,
    };
}
