export const num2name = {
    0: "UNKNOWN",
    1: "PAID",
    2: "CANCELED",
    3: "PAID_MANUAL",
    4: "CANCELED_MANUAL",
    5: "PAYBACK",
    6: "PAYBACK_MANUAL",
};
export const name2num = {
    UNKNOWN: 0,
    PAID: 1,
    CANCELED: 2,
    PAID_MANUAL: 3,
    CANCELED_MANUAL: 4,
    PAYBACK: 5,
    PAYBACK_MANUAL: 6,
};
