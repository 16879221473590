/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { SelfLearningUnitContentQueryLessonByContentIdFromJSON, SelfLearningUnitContentQueryLessonByContentIdToJSON, } from './SelfLearningUnitContentQueryLessonByContentId';
import { SelfLearningUnitContentQueryOneOfCaseFromJSON, SelfLearningUnitContentQueryOneOfCaseToJSON, } from './SelfLearningUnitContentQueryOneOfCase';
import { SelfLearningUnitContentQueryQuestionByContentConditionsFromJSON, SelfLearningUnitContentQueryQuestionByContentConditionsToJSON, } from './SelfLearningUnitContentQueryQuestionByContentConditions';
import { SelfLearningUnitContentQueryVocaByContentIdsFromJSON, SelfLearningUnitContentQueryVocaByContentIdsToJSON, } from './SelfLearningUnitContentQueryVocaByContentIds';
/**
 * Check if a given object implements the SelfLearningUnitOneOfContentQuery interface.
 */
export function instanceOfSelfLearningUnitOneOfContentQuery(value) {
    let isInstance = true;
    isInstance = isInstance && "oneOfCase" in value;
    return isInstance;
}
export function SelfLearningUnitOneOfContentQueryFromJSON(json) {
    return SelfLearningUnitOneOfContentQueryFromJSONTyped(json, false);
}
export function SelfLearningUnitOneOfContentQueryFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'questionByContentConditions': !exists(json, 'questionByContentConditions') ? undefined : SelfLearningUnitContentQueryQuestionByContentConditionsFromJSON(json['questionByContentConditions']),
        'oneOfCase': SelfLearningUnitContentQueryOneOfCaseFromJSON(json['oneOfCase']),
        'vocaByContentIds': !exists(json, 'vocaByContentIds') ? undefined : SelfLearningUnitContentQueryVocaByContentIdsFromJSON(json['vocaByContentIds']),
        'lessonByContentId': !exists(json, 'lessonByContentId') ? undefined : SelfLearningUnitContentQueryLessonByContentIdFromJSON(json['lessonByContentId']),
    };
}
export function SelfLearningUnitOneOfContentQueryToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'questionByContentConditions': SelfLearningUnitContentQueryQuestionByContentConditionsToJSON(value.questionByContentConditions),
        'oneOfCase': SelfLearningUnitContentQueryOneOfCaseToJSON(value.oneOfCase),
        'vocaByContentIds': SelfLearningUnitContentQueryVocaByContentIdsToJSON(value.vocaByContentIds),
        'lessonByContentId': SelfLearningUnitContentQueryLessonByContentIdToJSON(value.lessonByContentId),
    };
}
