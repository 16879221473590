import '@blueprintjs/table/lib/css/table.css';
import '#app/i18n';

import {AppProps} from 'next/app';
import Head from 'next/head';
import React, {useMemo} from 'react';
import {CircularProgress, Theme, createTheme, ThemeProvider, StyledEngineProvider} from '@mui/material';
import {AppCacheProvider} from '@mui/material-nextjs/v13-pagesRouter';
import {blue, pink} from '@mui/material/colors';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {useAtom} from 'jotai';

import {Blueprint} from '@santa-web/admin-toolkit';
import {tokenAtom} from '#app/atoms/token';
import DefaultLayout from '#app/components/DefaultLayout';
import LoadingBackdrop, {LoadingPanelContext} from '#app/components/LoadingBackdrop';
import SantaAdminHttpClientGuard from '#app/components/SantaAdminHttpClientGuard';
import SnackbarMessage, {SnackbarContext, useSnackbar} from '#app/components/Snackbar';
import config, {vercelEnv} from '#app/config';
import {ToeicAdminServiceContext, useCreateToeicAdminService} from '#app/hooks/useToeicAdminService';
import {getDocumentCookieValue} from '#app/utils/cookie';

declare module '@mui/material/styles' {
  type DefaultTheme = Theme;
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

export const theme = createTheme({
  palette: {
    primary: {
      main: blue[700],
    },
    secondary: {
      main: pink[500],
    },
    background: {
      default: '#fafafa',
    },
  },
  components: {
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
    },
    MuiIconButton: {
      defaultProps: {
        size: 'large',
      },
    },
    MuiCssBaseline: {
      styleOverrides: Blueprint.styles,
    },
  },
});

export default function App({Component, pageProps}: AppProps) {
  const [isLoading, setLoading] = React.useState(false);
  const [snackbarProps, setSnackbarOpen] = useSnackbar();
  const [token, setToken] = useAtom(tokenAtom);

  React.useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side');
    let token = getDocumentCookieValue('kc-access');
    const {pathname} = window.location;

    if (vercelEnv === 'production') {
      let num = 1;
      let nextToken = getDocumentCookieValue(`kc-access-${num}`);

      while (nextToken) {
        token += nextToken;
        num++;
        nextToken = getDocumentCookieValue(`kc-access-${num}`);
      }
    } else if (pathname !== '/403' && pathname !== '/404') {
      token = localStorage.getItem('token') ?? '';
      while (!token) {
        token = window.prompt(`${config.accessTokenOrigin}\n에서 발급한 토큰을 입력해주세요.`) ?? '';
      }
      localStorage.setItem('token', token.startsWith('Bearer') ? token.substring(7) : token);
    }

    setToken(token);
    if (jssStyles) {
      jssStyles.parentElement?.removeChild(jssStyles);
    }
  }, [setToken]);

  const toeicAdminService = useCreateToeicAdminService(
    useMemo(
      () => ({
        Authorization: `Bearer ${token}`,
      }),
      [token]
    )
  );

  if (token == null) {
    return (
      <>
        <CircularProgress /> Check Authentication...
      </>
    );
  }

  return (
    <AppCacheProvider {...pageProps}>
      <Head>
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
        <title>NEW TOEIC BackOffice</title>
      </Head>
      <QueryClientProvider client={queryClient}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <LoadingPanelContext.Provider value={{setLoading}}>
                <SnackbarContext.Provider value={{...snackbarProps, setSnackbarOpen}}>
                  <LoadingBackdrop isLoading={isLoading} />
                  <SantaAdminHttpClientGuard>
                    <ToeicAdminServiceContext.Provider value={toeicAdminService}>
                      <DefaultLayout>
                        <Component {...pageProps} />
                      </DefaultLayout>
                    </ToeicAdminServiceContext.Provider>
                  </SantaAdminHttpClientGuard>
                  <SnackbarMessage />
                </SnackbarContext.Provider>
              </LoadingPanelContext.Provider>
            </LocalizationProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </QueryClientProvider>
    </AppCacheProvider>
  );
}
