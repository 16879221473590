export const num2name = {
    0: "TYPE_UNSPECIFIED",
    1: "TYPE_SIGN_UP",
    2: "TYPE_TARGET_USERS",
};
export const name2num = {
    TYPE_UNSPECIFIED: 0,
    TYPE_SIGN_UP: 1,
    TYPE_TARGET_USERS: 2,
};
