// @ts-nocheck
import { name2num, num2name, } from "./(LearningCell)/CycleType";
import { name2num as name2num_1, num2name as num2name_1, } from "./(LearningCell)/CellType";
import { name2num as name2num_2, num2name as num2name_2, } from "./(LearningCell)/State";
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "../../google/protobuf/Timestamp";
import { encodeJson as encodeJson_2, decodeJson as decodeJson_2, encodeBinary as encodeBinary_2, decodeBinary as decodeBinary_2, } from "./(LearningCell)/QuestionState";
import { encodeJson as encodeJson_3, decodeJson as decodeJson_3, encodeBinary as encodeBinary_3, decodeBinary as decodeBinary_3, } from "./EstimatedInfo";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../runtime/json/scalar";
import { WireType, } from "../../../runtime/wire/index";
import { default as serialize, } from "../../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../../runtime/wire/scalar";
import { default as Long, } from "../../../runtime/Long";
import { encodeBinary as encodeBinary_4, decodeBinary as decodeBinary_4, } from "../../google/protobuf/Int32Value";
import { default as deserialize, } from "../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        cellOrdinal: "0",
        cycleType: "CYCLE_TYPE_UNSPECIFIED",
        cellType: "CELL_TYPE_UNSPECIFIED",
        state: "STATE_UNSPECIFIED",
        createdAt: undefined,
        updatedAt: undefined,
        contentIds: [],
        questionStates: [],
        estimatedInfo: undefined,
        estimatedScore: undefined,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.cellOrdinal !== undefined)
        result.cellOrdinal = tsValueToJsonValueFns.int64(value.cellOrdinal);
    if (value.cycleType !== undefined)
        result.cycleType = tsValueToJsonValueFns.enum(value.cycleType);
    if (value.cellType !== undefined)
        result.cellType = tsValueToJsonValueFns.enum(value.cellType);
    if (value.state !== undefined)
        result.state = tsValueToJsonValueFns.enum(value.state);
    if (value.createdAt !== undefined)
        result.createdAt = encodeJson_1(value.createdAt);
    if (value.updatedAt !== undefined)
        result.updatedAt = encodeJson_1(value.updatedAt);
    result.contentIds = value.contentIds.map(value => tsValueToJsonValueFns.string(value));
    result.questionStates = value.questionStates.map(value => encodeJson_2(value));
    if (value.estimatedInfo !== undefined)
        result.estimatedInfo = encodeJson_3(value.estimatedInfo);
    if (value.estimatedScore !== undefined)
        result.estimatedScore = value.estimatedScore;
    return result;
}
export function decodeJson(value) {
    var _a, _b, _c, _d;
    const result = getDefaultValue();
    if (value.cellOrdinal !== undefined)
        result.cellOrdinal = jsonValueToTsValueFns.int64(value.cellOrdinal);
    if (value.cycleType !== undefined)
        result.cycleType = jsonValueToTsValueFns.enum(value.cycleType);
    if (value.cellType !== undefined)
        result.cellType = jsonValueToTsValueFns.enum(value.cellType);
    if (value.state !== undefined)
        result.state = jsonValueToTsValueFns.enum(value.state);
    if (value.createdAt !== undefined)
        result.createdAt = decodeJson_1(value.createdAt);
    if (value.updatedAt !== undefined)
        result.updatedAt = decodeJson_1(value.updatedAt);
    result.contentIds = (_b = (_a = value.contentIds) === null || _a === void 0 ? void 0 : _a.map((value) => jsonValueToTsValueFns.string(value))) !== null && _b !== void 0 ? _b : [];
    result.questionStates = (_d = (_c = value.questionStates) === null || _c === void 0 ? void 0 : _c.map((value) => decodeJson_2(value))) !== null && _d !== void 0 ? _d : [];
    if (value.estimatedInfo !== undefined)
        result.estimatedInfo = decodeJson_3(value.estimatedInfo);
    if (value.estimatedScore !== undefined)
        result.estimatedScore = value.estimatedScore;
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.cellOrdinal !== undefined) {
        const tsValue = value.cellOrdinal;
        result.push([1, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.cycleType !== undefined) {
        const tsValue = value.cycleType;
        result.push([2, { type: WireType.Varint, value: new Long(name2num[tsValue]) }]);
    }
    if (value.cellType !== undefined) {
        const tsValue = value.cellType;
        result.push([3, { type: WireType.Varint, value: new Long(name2num_1[tsValue]) }]);
    }
    if (value.state !== undefined) {
        const tsValue = value.state;
        result.push([4, { type: WireType.Varint, value: new Long(name2num_2[tsValue]) }]);
    }
    if (value.createdAt !== undefined) {
        const tsValue = value.createdAt;
        result.push([5, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    if (value.updatedAt !== undefined) {
        const tsValue = value.updatedAt;
        result.push([6, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    for (const tsValue of value.contentIds) {
        result.push([7, tsValueToWireValueFns.string(tsValue)]);
    }
    for (const tsValue of value.questionStates) {
        result.push([8, { type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }]);
    }
    if (value.estimatedInfo !== undefined) {
        const tsValue = value.estimatedInfo;
        result.push([9, { type: WireType.LengthDelimited, value: encodeBinary_3(tsValue) }]);
    }
    if (value.estimatedScore !== undefined) {
        const tsValue = value.estimatedScore;
        result.push([10, ((tsValue) => ({ type: WireType.LengthDelimited, value: encodeBinary_4(tsValue) }))({ value: tsValue })]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    var _a;
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.cellOrdinal = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.cycleType = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name_1[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.cellType = value;
    }
    field: {
        const wireValue = wireFields.get(4);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name_2[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.state = value;
    }
    field: {
        const wireValue = wireFields.get(5);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.createdAt = value;
    }
    field: {
        const wireValue = wireFields.get(6);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.updatedAt = value;
    }
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 7).map(([, wireValue]) => wireValue);
        const value = wireValues.map((wireValue) => wireValueToTsValueFns.string(wireValue)).filter(x => x !== undefined);
        if (!value.length)
            break collection;
        result.contentIds = value;
    }
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 8).map(([, wireValue]) => wireValue);
        const value = wireValues.map((wireValue) => wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined).filter(x => x !== undefined);
        if (!value.length)
            break collection;
        result.questionStates = value;
    }
    field: {
        const wireValue = wireFields.get(9);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_3(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.estimatedInfo = value;
    }
    field: {
        const wireValue = wireFields.get(10);
        if (wireValue === undefined)
            break field;
        const value = (_a = (wireValue.type === WireType.LengthDelimited ? decodeBinary_4(wireValue.value) : undefined)) === null || _a === void 0 ? void 0 : _a.value;
        if (value === undefined)
            break field;
        result.estimatedScore = value;
    }
    return result;
}
