export const num2name = {
    0: "NO_ERROR",
    1: "ERROR_MAIN_POPUP_BANNER_DOES_NOT_EXIST",
    2: "ERROR_MAIN_POPUP_BANNER_ALREADY_STARTED",
};
export const name2num = {
    NO_ERROR: 0,
    ERROR_MAIN_POPUP_BANNER_DOES_NOT_EXIST: 1,
    ERROR_MAIN_POPUP_BANNER_ALREADY_STARTED: 2,
};
