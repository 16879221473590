import React from 'react';
import {createGrpcWebClientImpl, Metadata} from '@pbkit/grpc-web-client';

import {
  Service as AdminSettlementService,
  createServiceClient as createAdminSettlementService,
} from '@santa-web/gen/ssp/services/santa/commerce/settlement/v1/AdminSettlementService';
import {
  Service as AdminSubscriptionEventLogService,
  createServiceClient as createAdminSubscriptionEventLogService,
} from '@santa-web/gen/ssp/services/santa/commerce/subscription/v1/admin/AdminSubscriptionEventLogService';
import {
  Service as AdminSubscriptionOrderService,
  createServiceClient as createAdminSubscriptionOrderService,
} from '@santa-web/gen/ssp/services/santa/commerce/subscription/v1/admin/AdminSubscriptionOrderService';
import {
  Service as AdminSubscriptionProductBoardService,
  createServiceClient as createAdminSubscriptionProductBoardService,
} from '@santa-web/gen/ssp/services/santa/commerce/subscription/v1/admin/AdminSubscriptionProductBoardService';
import {
  Service as AdminSubscriptionProductService,
  createServiceClient as createAdminSubscriptionProductService,
} from '@santa-web/gen/ssp/services/santa/commerce/subscription/v1/admin/AdminSubscriptionProductService';
import {
  Service as AdminSubscriptionService,
  createServiceClient as createAdminSubscriptionService,
} from '@santa-web/gen/ssp/services/santa/commerce/subscription/v1/admin/AdminSubscriptionService';
import {
  Service as SelfLearningService,
  createServiceClient as createSelfLearningService,
} from '@santa-web/gen/ssp/services/santa/contentlearning/self_learning/v1/admin/AdminSelfLearningHierarchyService';
import {
  createServiceClient as createBackofficeAuthorityManageService,
  Service as BackofficeAuthorityManageService,
} from '@santa-web/gen/ssp/services/toeic/backoffice/authority/BackofficeAuthorityManageService';
import {
  Service as BackofficeB2bClassService,
  createServiceClient as createBackofficeB2bClassService,
} from '@santa-web/gen/ssp/services/toeic/backoffice/b2b/BackofficeB2bClassService';
import {
  Service as BackofficeB2bDashboardUserService,
  createServiceClient as createBackofficeB2bDashboardUserService,
} from '@santa-web/gen/ssp/services/toeic/backoffice/b2b/BackofficeB2bDashboardUserService';
import {
  Service as BackofficeB2bOrganizationService,
  createServiceClient as createBackofficeB2bOrganizationService,
} from '@santa-web/gen/ssp/services/toeic/backoffice/b2b/BackofficeB2bOrganizationService';
import {
  Service as BackofficeB2bStudentService,
  createServiceClient as createBackofficeB2bStudentService,
} from '@santa-web/gen/ssp/services/toeic/backoffice/b2b/BackofficeB2bStudentService';
import {
  Service as BackofficeContentLearningService,
  createServiceClient as createBackofficeContentLearningService,
} from '@santa-web/gen/ssp/services/toeic/backoffice/BackofficeContentLearningService';
import {
  Service as BackofficeCouponService,
  createServiceClient as createBackofficeCouponService,
} from '@santa-web/gen/ssp/services/toeic/backoffice/BackofficeCouponService';
import {
  Service as BackofficeExamDateService,
  createServiceClient as createBackofficeExamDateService,
} from '@santa-web/gen/ssp/services/toeic/backoffice/BackofficeExamDateService';
import {
  Service as BackofficeResourceManageService,
  createServiceClient as createBackofficeResourceManageService,
} from '@santa-web/gen/ssp/services/toeic/backoffice/BackofficeResourceManageService';
import {
  Service as BackofficeSystemConfigService,
  createServiceClient as createBackofficeSystemConfigService,
} from '@santa-web/gen/ssp/services/toeic/backoffice/BackofficeSystemConfigService';
import {
  Service as BackofficeUserDeviceAccessLogService,
  createServiceClient as createBackofficeUserDeviceAccessLogService,
} from '@santa-web/gen/ssp/services/toeic/backoffice/BackofficeUserDeviceAccessLogService';
import {
  Service as BackofficeUserService,
  createServiceClient as createBackofficeUserService,
} from '@santa-web/gen/ssp/services/toeic/backoffice/BackofficeUserService';
import {
  Service as BackofficeDeliverableService,
  createServiceClient as createBackofficeDeliverableService,
} from '@santa-web/gen/ssp/services/toeic/backoffice/commerce/BackofficeDeliverableService';
import {
  Service as BackofficeFulfillmentManageService,
  createServiceClient as createBackofficeFulfillmentManageService,
} from '@santa-web/gen/ssp/services/toeic/backoffice/commerce/BackofficeFulfillmentManageService';
import {
  Service as BackofficeGiftCodeService,
  createServiceClient as createBackofficeGiftCodeService,
} from '@santa-web/gen/ssp/services/toeic/backoffice/commerce/BackofficeGiftCodeService';
import {
  Service as BackofficeOrderManageService,
  createServiceClient as createBackofficeOrderManageService,
} from '@santa-web/gen/ssp/services/toeic/backoffice/commerce/BackofficeOrderManageService';
import {
  Service as BackofficeProductBoardService,
  createServiceClient as createBackofficeProductBoardService,
} from '@santa-web/gen/ssp/services/toeic/backoffice/commerce/BackofficeProductBoardService';
import {
  createServiceClient as createBackofficeProductManageService,
  Service as BackofficeProductManageService,
} from '@santa-web/gen/ssp/services/toeic/backoffice/commerce/BackofficeProductManageService';
import {
  createServiceClient as createBackofficeStockCountService,
  Service as BackofficeStockCountService,
} from '@santa-web/gen/ssp/services/toeic/backoffice/commerce/BackofficeStockCountService';
import {
  createServiceClient as createBackofficeMarketingBannerService,
  Service as BackofficeMarketingBannerService,
} from '@santa-web/gen/ssp/services/toeic/backoffice/marketing/BackofficeMarketingBannerService';
import {
  Service as BackofficePromotionService,
  createServiceClient as createBackofficePromotionService,
} from '@santa-web/gen/ssp/services/toeic/backoffice/promotion/BackofficePromotionService';
import {
  Service as BackofficeVirtualExamService,
  createServiceClient as createBackofficeVirtualExamService,
} from '@santa-web/gen/ssp/services/toeic/backoffice/virtual_exam/BackofficeVirtualExamService';
import config, {env, enableProdDevtools} from '#app/config';

type ToeicAdminService = {
  userService: BackofficeUserService;
  examDateService: BackofficeExamDateService;
  contentLearningService: BackofficeContentLearningService;
  productManageService: BackofficeProductManageService;
  deliverableService: BackofficeDeliverableService;
  productBoardService: BackofficeProductBoardService;
  resourceManageService: BackofficeResourceManageService;
  orderManageService: BackofficeOrderManageService;
  fulfillmentManageService: BackofficeFulfillmentManageService;
  giftCodeService: BackofficeGiftCodeService;
  couponService: BackofficeCouponService;
  userDeviceAccessLogService: BackofficeUserDeviceAccessLogService;
  systemConfigService: BackofficeSystemConfigService;
  authorityManageService: BackofficeAuthorityManageService;
  marketingBannerService: BackofficeMarketingBannerService;
  stockCountService: BackofficeStockCountService;
  b2bClassService: BackofficeB2bClassService;
  b2bDashboardUserService: BackofficeB2bDashboardUserService;
  b2bOrganizationService: BackofficeB2bOrganizationService;
  b2bStudentService: BackofficeB2bStudentService;
  virtualExamService: BackofficeVirtualExamService;
  promotionService: BackofficePromotionService;
  subscriptionProductService: AdminSubscriptionProductService;
  subscriptionProductBoardService: AdminSubscriptionProductBoardService;
  subscriptionOrderService: AdminSubscriptionOrderService;
  subscriptionService: AdminSubscriptionService;
  subscriptionEventLogService: AdminSubscriptionEventLogService;
  settlementService: AdminSettlementService;
  selfLearningService: SelfLearningService;
};

export const ToeicAdminServiceContext = React.createContext<ToeicAdminService | null>(null);

const grpcServiceConfig = {devtools: env !== 'production' || enableProdDevtools === 'true' || undefined};

export default function useToeicAdminService() {
  const service = React.useContext(ToeicAdminServiceContext);
  if (!service) {
    throw new Error('toeic-admin service is not specified');
  }
  return service;
}

export function useCreateToeicAdminService(metadata?: Metadata): ToeicAdminService {
  const grpcWebClient = React.useMemo(() => {
    return createGrpcWebClientImpl({
      host: config.hostname,
      metadata,
    });
  }, [metadata]);

  return React.useMemo(
    () => ({
      userService: createBackofficeUserService(grpcWebClient, grpcServiceConfig),
      examDateService: createBackofficeExamDateService(grpcWebClient, grpcServiceConfig),
      contentLearningService: createBackofficeContentLearningService(grpcWebClient, grpcServiceConfig),
      productManageService: createBackofficeProductManageService(grpcWebClient, grpcServiceConfig),
      deliverableService: createBackofficeDeliverableService(grpcWebClient, grpcServiceConfig),
      productBoardService: createBackofficeProductBoardService(grpcWebClient, grpcServiceConfig),
      resourceManageService: createBackofficeResourceManageService(grpcWebClient, grpcServiceConfig),
      orderManageService: createBackofficeOrderManageService(grpcWebClient, grpcServiceConfig),
      fulfillmentManageService: createBackofficeFulfillmentManageService(grpcWebClient, grpcServiceConfig),
      giftCodeService: createBackofficeGiftCodeService(grpcWebClient, grpcServiceConfig),
      couponService: createBackofficeCouponService(grpcWebClient, grpcServiceConfig),
      userDeviceAccessLogService: createBackofficeUserDeviceAccessLogService(grpcWebClient, grpcServiceConfig),
      systemConfigService: createBackofficeSystemConfigService(grpcWebClient, grpcServiceConfig),
      authorityManageService: createBackofficeAuthorityManageService(grpcWebClient, grpcServiceConfig),
      marketingBannerService: createBackofficeMarketingBannerService(grpcWebClient, grpcServiceConfig),
      stockCountService: createBackofficeStockCountService(grpcWebClient, grpcServiceConfig),
      b2bClassService: createBackofficeB2bClassService(grpcWebClient, grpcServiceConfig),
      b2bDashboardUserService: createBackofficeB2bDashboardUserService(grpcWebClient, grpcServiceConfig),
      b2bOrganizationService: createBackofficeB2bOrganizationService(grpcWebClient, grpcServiceConfig),
      b2bStudentService: createBackofficeB2bStudentService(grpcWebClient, grpcServiceConfig),
      virtualExamService: createBackofficeVirtualExamService(grpcWebClient, grpcServiceConfig),
      promotionService: createBackofficePromotionService(grpcWebClient, grpcServiceConfig),
      subscriptionProductService: createAdminSubscriptionProductService(grpcWebClient, grpcServiceConfig),
      subscriptionProductBoardService: createAdminSubscriptionProductBoardService(grpcWebClient, grpcServiceConfig),
      subscriptionOrderService: createAdminSubscriptionOrderService(grpcWebClient, grpcServiceConfig),
      subscriptionService: createAdminSubscriptionService(grpcWebClient, grpcServiceConfig),
      subscriptionEventLogService: createAdminSubscriptionEventLogService(grpcWebClient, grpcServiceConfig),
      settlementService: createAdminSettlementService(grpcWebClient, grpcServiceConfig),
      selfLearningService: createSelfLearningService(grpcWebClient, grpcServiceConfig),
    }),
    [grpcWebClient]
  );
}
