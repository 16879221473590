export const num2name = {
    0: "AUTHORITY_STATUS_UNSPECIFIED",
    1: "AUTHORITY_STATUS_ACTIVE",
    2: "AUTHORITY_STATUS_INACTIVE_WAITING_FOR_FULFILLMENT",
    3: "AUTHORITY_STATUS_INACTIVE_NO_FULFILLMENT",
};
export const name2num = {
    AUTHORITY_STATUS_UNSPECIFIED: 0,
    AUTHORITY_STATUS_ACTIVE: 1,
    AUTHORITY_STATUS_INACTIVE_WAITING_FOR_FULFILLMENT: 2,
    AUTHORITY_STATUS_INACTIVE_NO_FULFILLMENT: 3,
};
