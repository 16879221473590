// @ts-nocheck
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../../runtime/json/scalar";
import { default as serialize, } from "../../../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../../../runtime/wire/scalar";
import { default as deserialize, } from "../../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        productId: "0",
        count: "0",
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.productId !== undefined)
        result.productId = tsValueToJsonValueFns.int64(value.productId);
    if (value.count !== undefined)
        result.count = tsValueToJsonValueFns.int64(value.count);
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.productId !== undefined)
        result.productId = jsonValueToTsValueFns.int64(value.productId);
    if (value.count !== undefined)
        result.count = jsonValueToTsValueFns.int64(value.count);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.productId !== undefined) {
        const tsValue = value.productId;
        result.push([1, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.count !== undefined) {
        const tsValue = value.count;
        result.push([2, tsValueToWireValueFns.int64(tsValue)]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.productId = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.count = value;
    }
    return result;
}
