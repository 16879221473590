/* tslint:disable */
/* eslint-disable */
/**
 *
 * @export
 */
export const PermitForm = {
    TICKET: 'TICKET',
    PASS: 'PASS'
};
export function PermitFormFromJSON(json) {
    return PermitFormFromJSONTyped(json, false);
}
export function PermitFormFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function PermitFormToJSON(value) {
    return value;
}
