import { SelfLearningContentTypeResponseFromJSON, SelfLearningContentTypeResponseToJSON, } from './SelfLearningContentTypeResponse';
/**
 * Check if a given object implements the SelfLearningContentTypesResponseV2 interface.
 */
export function instanceOfSelfLearningContentTypesResponseV2(value) {
    let isInstance = true;
    isInstance = isInstance && "contentTypes" in value;
    return isInstance;
}
export function SelfLearningContentTypesResponseV2FromJSON(json) {
    return SelfLearningContentTypesResponseV2FromJSONTyped(json, false);
}
export function SelfLearningContentTypesResponseV2FromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'contentTypes': (json['contentTypes'].map(SelfLearningContentTypeResponseFromJSON)),
    };
}
export function SelfLearningContentTypesResponseV2ToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'contentTypes': (value.contentTypes.map(SelfLearningContentTypeResponseToJSON)),
    };
}
