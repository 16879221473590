import { StripeProductFromJSON, StripeProductToJSON, } from './StripeProduct';
/**
 * Check if a given object implements the GetStripeProductResponse interface.
 */
export function instanceOfGetStripeProductResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "product" in value;
    return isInstance;
}
export function GetStripeProductResponseFromJSON(json) {
    return GetStripeProductResponseFromJSONTyped(json, false);
}
export function GetStripeProductResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'product': StripeProductFromJSON(json['product']),
    };
}
export function GetStripeProductResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'product': StripeProductToJSON(value.product),
    };
}
