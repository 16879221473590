import { SelfLearningContentTypeFromJSON, SelfLearningContentTypeToJSON, } from './SelfLearningContentType';
/**
 * Check if a given object implements the PublishSelfLearningRequest interface.
 */
export function instanceOfPublishSelfLearningRequest(value) {
    let isInstance = true;
    isInstance = isInstance && "contentType" in value;
    return isInstance;
}
export function PublishSelfLearningRequestFromJSON(json) {
    return PublishSelfLearningRequestFromJSONTyped(json, false);
}
export function PublishSelfLearningRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'contentType': SelfLearningContentTypeFromJSON(json['contentType']),
    };
}
export function PublishSelfLearningRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'contentType': SelfLearningContentTypeToJSON(value.contentType),
    };
}
