// @ts-nocheck
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../../runtime/json/scalar";
import { default as serialize, } from "../../../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../../../runtime/wire/scalar";
import { default as deserialize, } from "../../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        userAuthIds: [],
        productId: "0",
        days: "0",
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    result.userAuthIds = value.userAuthIds.map(value => tsValueToJsonValueFns.string(value));
    if (value.productId !== undefined)
        result.productId = tsValueToJsonValueFns.int64(value.productId);
    if (value.days !== undefined)
        result.days = tsValueToJsonValueFns.int64(value.days);
    return result;
}
export function decodeJson(value) {
    var _a, _b;
    const result = getDefaultValue();
    result.userAuthIds = (_b = (_a = value.userAuthIds) === null || _a === void 0 ? void 0 : _a.map((value) => jsonValueToTsValueFns.string(value))) !== null && _b !== void 0 ? _b : [];
    if (value.productId !== undefined)
        result.productId = jsonValueToTsValueFns.int64(value.productId);
    if (value.days !== undefined)
        result.days = jsonValueToTsValueFns.int64(value.days);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    for (const tsValue of value.userAuthIds) {
        result.push([1, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.productId !== undefined) {
        const tsValue = value.productId;
        result.push([2, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.days !== undefined) {
        const tsValue = value.days;
        result.push([3, tsValueToWireValueFns.int64(tsValue)]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 1).map(([, wireValue]) => wireValue);
        const value = wireValues.map((wireValue) => wireValueToTsValueFns.string(wireValue)).filter(x => x !== undefined);
        if (!value.length)
            break collection;
        result.userAuthIds = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.productId = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.days = value;
    }
    return result;
}
