// @ts-nocheck
import { name2num, num2name, } from "../../inside/authentication/RegistrationType";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../runtime/json/scalar";
import { WireType, } from "../../../runtime/wire/index";
import { default as serialize, } from "../../../runtime/wire/serialize";
import { default as Long, } from "../../../runtime/Long";
import { default as deserialize, } from "../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        value: "REGISTRATION_TYPE_UNSPECIFIED",
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.value !== undefined)
        result.value = tsValueToJsonValueFns.enum(value.value);
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.value !== undefined)
        result.value = jsonValueToTsValueFns.enum(value.value);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.value !== undefined) {
        const tsValue = value.value;
        result.push([1, { type: WireType.Varint, value: new Long(name2num[tsValue]) }]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.value = value;
    }
    return result;
}
