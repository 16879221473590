/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { DisplayInfoFromJSON, DisplayInfoToJSON, } from './DisplayInfo';
import { PermitListItemFromJSON, PermitListItemToJSON, } from './PermitListItem';
import { TicketTemplateManagementInfoFromJSON, TicketTemplateManagementInfoToJSON, } from './TicketTemplateManagementInfo';
/**
 * Check if a given object implements the TicketTemplateListItem interface.
 */
export function instanceOfTicketTemplateListItem(value) {
    let isInstance = true;
    isInstance = isInstance && "expirationInDays" in value;
    isInstance = isInstance && "permit" in value;
    isInstance = isInstance && "displayInfo" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "managementInfo" in value;
    return isInstance;
}
export function TicketTemplateListItemFromJSON(json) {
    return TicketTemplateListItemFromJSONTyped(json, false);
}
export function TicketTemplateListItemFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'expirationInDays': json['expirationInDays'],
        'publishedAt': !exists(json, 'publishedAt') ? undefined : (new Date(json['publishedAt'])),
        'permit': PermitListItemFromJSON(json['permit']),
        'displayInfo': DisplayInfoFromJSON(json['displayInfo']),
        'id': json['id'],
        'managementInfo': TicketTemplateManagementInfoFromJSON(json['managementInfo']),
    };
}
export function TicketTemplateListItemToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'expirationInDays': value.expirationInDays,
        'publishedAt': value.publishedAt === undefined ? undefined : (value.publishedAt.toISOString()),
        'permit': PermitListItemToJSON(value.permit),
        'displayInfo': DisplayInfoToJSON(value.displayInfo),
        'id': value.id,
        'managementInfo': TicketTemplateManagementInfoToJSON(value.managementInfo),
    };
}
