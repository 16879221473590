export const num2name = {
    0: "TUTOR_ROLE_UNSPECIFIED",
    1: "ADMIN",
    2: "TUTOR",
};
export const name2num = {
    TUTOR_ROLE_UNSPECIFIED: 0,
    ADMIN: 1,
    TUTOR: 2,
};
