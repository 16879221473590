// @ts-nocheck
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../runtime/json/scalar";
import { WireType, } from "../../../runtime/wire/index";
import { default as serialize, } from "../../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../../runtime/wire/scalar";
import { encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "../../google/protobuf/StringValue";
import { default as deserialize, } from "../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        userId: "0",
        blocked: false,
        blockedReason: undefined,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.userId !== undefined)
        result.userId = tsValueToJsonValueFns.int64(value.userId);
    if (value.blocked !== undefined)
        result.blocked = tsValueToJsonValueFns.bool(value.blocked);
    if (value.blockedReason !== undefined)
        result.blockedReason = value.blockedReason;
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.userId !== undefined)
        result.userId = jsonValueToTsValueFns.int64(value.userId);
    if (value.blocked !== undefined)
        result.blocked = jsonValueToTsValueFns.bool(value.blocked);
    if (value.blockedReason !== undefined)
        result.blockedReason = value.blockedReason;
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.userId !== undefined) {
        const tsValue = value.userId;
        result.push([1, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.blocked !== undefined) {
        const tsValue = value.blocked;
        result.push([2, tsValueToWireValueFns.bool(tsValue)]);
    }
    if (value.blockedReason !== undefined) {
        const tsValue = value.blockedReason;
        result.push([3, ((tsValue) => ({ type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }))({ value: tsValue })]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    var _a;
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.userId = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.bool(wireValue);
        if (value === undefined)
            break field;
        result.blocked = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = (_a = (wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined)) === null || _a === void 0 ? void 0 : _a.value;
        if (value === undefined)
            break field;
        result.blockedReason = value;
    }
    return result;
}
