// @ts-nocheck
import { name2num, num2name, } from "../../../inside/Country";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../../runtime/json/scalar";
import { WireType, } from "../../../../runtime/wire/index";
import { default as serialize, } from "../../../../runtime/wire/serialize";
import { default as Long, } from "../../../../runtime/Long";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../../../runtime/wire/scalar";
import { default as deserialize, } from "../../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        country: "UNSPECIFIED_COUNTRY",
        active: false,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.country !== undefined)
        result.country = tsValueToJsonValueFns.enum(value.country);
    if (value.active !== undefined)
        result.active = tsValueToJsonValueFns.bool(value.active);
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.country !== undefined)
        result.country = jsonValueToTsValueFns.enum(value.country);
    if (value.active !== undefined)
        result.active = jsonValueToTsValueFns.bool(value.active);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.country !== undefined) {
        const tsValue = value.country;
        result.push([1, { type: WireType.Varint, value: new Long(name2num[tsValue]) }]);
    }
    if (value.active !== undefined) {
        const tsValue = value.active;
        result.push([2, tsValueToWireValueFns.bool(tsValue)]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.country = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.bool(wireValue);
        if (value === undefined)
            break field;
        result.active = value;
    }
    return result;
}
