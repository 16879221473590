import { TicketListItemFromJSON, TicketListItemToJSON, } from './TicketListItem';
/**
 * Check if a given object implements the ListTicketsResponse interface.
 */
export function instanceOfListTicketsResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "total" in value;
    isInstance = isInstance && "tickets" in value;
    return isInstance;
}
export function ListTicketsResponseFromJSON(json) {
    return ListTicketsResponseFromJSONTyped(json, false);
}
export function ListTicketsResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'total': json['total'],
        'tickets': (json['tickets'].map(TicketListItemFromJSON)),
    };
}
export function ListTicketsResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'total': value.total,
        'tickets': (value.tickets.map(TicketListItemToJSON)),
    };
}
