import {atom} from 'jotai';
import type {LearningDomainListItem} from '@santa-web/gen/open-api/admin/models/LearningDomainListItem';

export const ADMIN_RIIID_LEARNING_DOMAIN_STORAGE_KEY = 'admin-riiid-learning-domain' as const;

export const RIIID_LEARNING_DOMAIN_HEADER_KEY = 'riiid-learning-domain' as const;

const originalRiiidLearningDomainAtom = atom<LearningDomainListItem | null>(null);

export const riiidLearningDomainAtom = atom(
  get => {
    return get(originalRiiidLearningDomainAtom);
  },
  (_, set, newRiiidLearningDomain: LearningDomainListItem) => {
    localStorage.setItem(ADMIN_RIIID_LEARNING_DOMAIN_STORAGE_KEY, String(newRiiidLearningDomain.id));
    set(originalRiiidLearningDomainAtom, newRiiidLearningDomain);
  }
);
