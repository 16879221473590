/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
/**
 * Check if a given object implements the UpdateOfferGroupDetailImage interface.
 */
export function instanceOfUpdateOfferGroupDetailImage(value) {
    let isInstance = true;
    isInstance = isInstance && "imageAssetId" in value;
    return isInstance;
}
export function UpdateOfferGroupDetailImageFromJSON(json) {
    return UpdateOfferGroupDetailImageFromJSONTyped(json, false);
}
export function UpdateOfferGroupDetailImageFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'imageAssetId': json['imageAssetId'],
        'id': !exists(json, 'id') ? undefined : json['id'],
    };
}
export function UpdateOfferGroupDetailImageToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'imageAssetId': value.imageAssetId,
        'id': value.id,
    };
}
