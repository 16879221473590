// @ts-nocheck
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../../runtime/json/scalar";
import { default as serialize, } from "../../../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../../../runtime/wire/scalar";
import { default as deserialize, } from "../../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        targetUserCount: 0,
        issuedCouponCount: 0,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.targetUserCount !== undefined)
        result.targetUserCount = tsValueToJsonValueFns.int32(value.targetUserCount);
    if (value.issuedCouponCount !== undefined)
        result.issuedCouponCount = tsValueToJsonValueFns.int32(value.issuedCouponCount);
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.targetUserCount !== undefined)
        result.targetUserCount = jsonValueToTsValueFns.int32(value.targetUserCount);
    if (value.issuedCouponCount !== undefined)
        result.issuedCouponCount = jsonValueToTsValueFns.int32(value.issuedCouponCount);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.targetUserCount !== undefined) {
        const tsValue = value.targetUserCount;
        result.push([1, tsValueToWireValueFns.int32(tsValue)]);
    }
    if (value.issuedCouponCount !== undefined) {
        const tsValue = value.issuedCouponCount;
        result.push([2, tsValueToWireValueFns.int32(tsValue)]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int32(wireValue);
        if (value === undefined)
            break field;
        result.targetUserCount = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int32(wireValue);
        if (value === undefined)
            break field;
        result.issuedCouponCount = value;
    }
    return result;
}
