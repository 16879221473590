/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { ManagementInfoFromJSON, ManagementInfoToJSON, } from './ManagementInfo';
import { OfferFromJSON, OfferToJSON, } from './Offer';
import { OfferGroupDetailImageFromJSON, OfferGroupDetailImageToJSON, } from './OfferGroupDetailImage';
import { OfferGroupDisplayConfigFromJSON, OfferGroupDisplayConfigToJSON, } from './OfferGroupDisplayConfig';
import { OfferGroupSalesInfoFromJSON, OfferGroupSalesInfoToJSON, } from './OfferGroupSalesInfo';
import { OfferTypeFromJSON, OfferTypeToJSON, } from './OfferType';
/**
 * Check if a given object implements the OfferGroup interface.
 */
export function instanceOfOfferGroup(value) {
    let isInstance = true;
    isInstance = isInstance && "offers" in value;
    isInstance = isInstance && "country" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "offerGroupDetailImages" in value;
    isInstance = isInstance && "salesConfig" in value;
    isInstance = isInstance && "displayInfo" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "managementInfo" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "learningDomainId" in value;
    isInstance = isInstance && "updatedAt" in value;
    return isInstance;
}
export function OfferGroupFromJSON(json) {
    return OfferGroupFromJSONTyped(json, false);
}
export function OfferGroupFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'offers': (json['offers'].map(OfferFromJSON)),
        'country': json['country'],
        'createdAt': (new Date(json['createdAt'])),
        'offerGroupDetailImages': (json['offerGroupDetailImages'].map(OfferGroupDetailImageFromJSON)),
        'publishedAt': !exists(json, 'publishedAt') ? undefined : (new Date(json['publishedAt'])),
        'salesConfig': OfferGroupSalesInfoFromJSON(json['salesConfig']),
        'displayInfo': OfferGroupDisplayConfigFromJSON(json['displayInfo']),
        'id': json['id'],
        'managementInfo': ManagementInfoFromJSON(json['managementInfo']),
        'type': OfferTypeFromJSON(json['type']),
        'learningDomainId': json['learningDomainId'],
        'updatedAt': (new Date(json['updatedAt'])),
    };
}
export function OfferGroupToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'offers': (value.offers.map(OfferToJSON)),
        'country': value.country,
        'createdAt': (value.createdAt.toISOString()),
        'offerGroupDetailImages': (value.offerGroupDetailImages.map(OfferGroupDetailImageToJSON)),
        'publishedAt': value.publishedAt === undefined ? undefined : (value.publishedAt.toISOString()),
        'salesConfig': OfferGroupSalesInfoToJSON(value.salesConfig),
        'displayInfo': OfferGroupDisplayConfigToJSON(value.displayInfo),
        'id': value.id,
        'managementInfo': ManagementInfoToJSON(value.managementInfo),
        'type': OfferTypeToJSON(value.type),
        'learningDomainId': value.learningDomainId,
        'updatedAt': (value.updatedAt.toISOString()),
    };
}
