/* tslint:disable */
/* eslint-disable */
import * as runtime from '../runtime';
import { StripeSubscriptionOrderFromJSON, } from '../models';
/**
 *
 */
export class StripeSubscriptionOrderAdminV1Api extends runtime.BaseAPI {
    /**
     * id에 해당하는 구매(주문)를 가져옵니다.
     */
    async getStripeSubscriptionOrderRaw(requestParameters, initOverrides) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling getStripeSubscriptionOrder.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/admin/commerce/v1/stripe/subscription-order/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => StripeSubscriptionOrderFromJSON(jsonValue));
    }
    /**
     * id에 해당하는 구매(주문)를 가져옵니다.
     */
    async getStripeSubscriptionOrder(requestParameters, initOverrides) {
        const response = await this.getStripeSubscriptionOrderRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
