import { StripeProductBoardFromJSON, StripeProductBoardToJSON, } from './StripeProductBoard';
/**
 * Check if a given object implements the ListStripeProductBoardsResponse interface.
 */
export function instanceOfListStripeProductBoardsResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "total" in value;
    isInstance = isInstance && "productBoards" in value;
    return isInstance;
}
export function ListStripeProductBoardsResponseFromJSON(json) {
    return ListStripeProductBoardsResponseFromJSONTyped(json, false);
}
export function ListStripeProductBoardsResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'total': json['total'],
        'productBoards': (json['productBoards'].map(StripeProductBoardFromJSON)),
    };
}
export function ListStripeProductBoardsResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'total': value.total,
        'productBoards': (value.productBoards.map(StripeProductBoardToJSON)),
    };
}
