/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { DisplayInfoFromJSON, DisplayInfoToJSON, } from './DisplayInfo';
import { PermitFromJSON, PermitToJSON, } from './Permit';
import { TicketTemplateManagementInfoFromJSON, TicketTemplateManagementInfoToJSON, } from './TicketTemplateManagementInfo';
/**
 * Check if a given object implements the TicketTemplate interface.
 */
export function instanceOfTicketTemplate(value) {
    let isInstance = true;
    isInstance = isInstance && "country" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "expirationInDays" in value;
    isInstance = isInstance && "permit" in value;
    isInstance = isInstance && "displayInfo" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "managementInfo" in value;
    isInstance = isInstance && "learningDomainId" in value;
    isInstance = isInstance && "updatedAt" in value;
    return isInstance;
}
export function TicketTemplateFromJSON(json) {
    return TicketTemplateFromJSONTyped(json, false);
}
export function TicketTemplateFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'country': json['country'],
        'createdAt': (new Date(json['createdAt'])),
        'expirationInDays': json['expirationInDays'],
        'publishedAt': !exists(json, 'publishedAt') ? undefined : (new Date(json['publishedAt'])),
        'permit': PermitFromJSON(json['permit']),
        'displayInfo': DisplayInfoFromJSON(json['displayInfo']),
        'id': json['id'],
        'managementInfo': TicketTemplateManagementInfoFromJSON(json['managementInfo']),
        'learningDomainId': json['learningDomainId'],
        'updatedAt': (new Date(json['updatedAt'])),
    };
}
export function TicketTemplateToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'country': value.country,
        'createdAt': (value.createdAt.toISOString()),
        'expirationInDays': value.expirationInDays,
        'publishedAt': value.publishedAt === undefined ? undefined : (value.publishedAt.toISOString()),
        'permit': PermitToJSON(value.permit),
        'displayInfo': DisplayInfoToJSON(value.displayInfo),
        'id': value.id,
        'managementInfo': TicketTemplateManagementInfoToJSON(value.managementInfo),
        'learningDomainId': value.learningDomainId,
        'updatedAt': (value.updatedAt.toISOString()),
    };
}
