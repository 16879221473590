/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
/**
 * Check if a given object implements the AgreementInfo interface.
 */
export function instanceOfAgreementInfo(value) {
    let isInstance = true;
    isInstance = isInstance && "pushAgreed" in value;
    isInstance = isInstance && "marketingAgreed" in value;
    return isInstance;
}
export function AgreementInfoFromJSON(json) {
    return AgreementInfoFromJSONTyped(json, false);
}
export function AgreementInfoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'age14At': !exists(json, 'age14At') ? undefined : (new Date(json['age14At'])),
        'pushAgreed': json['pushAgreed'],
        'serviceTermsAt': !exists(json, 'serviceTermsAt') ? undefined : (new Date(json['serviceTermsAt'])),
        'marketingAt': !exists(json, 'marketingAt') ? undefined : (new Date(json['marketingAt'])),
        'pushAt': !exists(json, 'pushAt') ? undefined : (new Date(json['pushAt'])),
        'privacyAt': !exists(json, 'privacyAt') ? undefined : (new Date(json['privacyAt'])),
        'marketingAgreed': json['marketingAgreed'],
    };
}
export function AgreementInfoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'age14At': value.age14At === undefined ? undefined : (value.age14At.toISOString()),
        'pushAgreed': value.pushAgreed,
        'serviceTermsAt': value.serviceTermsAt === undefined ? undefined : (value.serviceTermsAt.toISOString()),
        'marketingAt': value.marketingAt === undefined ? undefined : (value.marketingAt.toISOString()),
        'pushAt': value.pushAt === undefined ? undefined : (value.pushAt.toISOString()),
        'privacyAt': value.privacyAt === undefined ? undefined : (value.privacyAt.toISOString()),
        'marketingAgreed': value.marketingAgreed,
    };
}
