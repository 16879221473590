import { PermitListItemFromJSON, PermitListItemToJSON, } from './PermitListItem';
/**
 * Check if a given object implements the ListPermitResponse interface.
 */
export function instanceOfListPermitResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "total" in value;
    isInstance = isInstance && "permits" in value;
    return isInstance;
}
export function ListPermitResponseFromJSON(json) {
    return ListPermitResponseFromJSONTyped(json, false);
}
export function ListPermitResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'total': json['total'],
        'permits': (json['permits'].map(PermitListItemFromJSON)),
    };
}
export function ListPermitResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'total': value.total,
        'permits': (value.permits.map(PermitListItemToJSON)),
    };
}
