export const num2name = {
    0: "ERROR_UNSPECIFIED",
    1: "ERROR_SELF_LEARNING_HIERARCHY_NOT_FOUND",
    2: "ERROR_ALREADY_DEPRECATED",
};
export const name2num = {
    ERROR_UNSPECIFIED: 0,
    ERROR_SELF_LEARNING_HIERARCHY_NOT_FOUND: 1,
    ERROR_ALREADY_DEPRECATED: 2,
};
