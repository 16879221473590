// @ts-nocheck
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "./(SystemConfig)/StringList";
import { encodeJson as encodeJson_2, decodeJson as decodeJson_2, encodeBinary as encodeBinary_2, decodeBinary as decodeBinary_2, } from "./(SystemConfig)/LongList";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../runtime/json/scalar";
import { WireType, } from "../../runtime/wire/index";
import { default as serialize, } from "../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../runtime/wire/scalar";
import { default as deserialize, } from "../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        type: "",
        config: undefined,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    var _a;
    const result = {};
    if (value.type !== undefined)
        result.type = tsValueToJsonValueFns.string(value.type);
    switch ((_a = value.config) === null || _a === void 0 ? void 0 : _a.field) {
        case "long": {
            result.long = tsValueToJsonValueFns.int64(value.config.value);
            break;
        }
        case "stringList": {
            result.stringList = encodeJson_1(value.config.value);
            break;
        }
        case "bool": {
            result.bool = tsValueToJsonValueFns.bool(value.config.value);
            break;
        }
        case "string": {
            result.string = tsValueToJsonValueFns.string(value.config.value);
            break;
        }
        case "longList": {
            result.longList = encodeJson_2(value.config.value);
            break;
        }
        case "json": {
            result.json = tsValueToJsonValueFns.string(value.config.value);
            break;
        }
    }
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.type !== undefined)
        result.type = jsonValueToTsValueFns.string(value.type);
    if (value.long !== undefined)
        result.config = { field: "long", value: jsonValueToTsValueFns.int64(value.long) };
    if (value.stringList !== undefined)
        result.config = { field: "stringList", value: decodeJson_1(value.stringList) };
    if (value.bool !== undefined)
        result.config = { field: "bool", value: jsonValueToTsValueFns.bool(value.bool) };
    if (value.string !== undefined)
        result.config = { field: "string", value: jsonValueToTsValueFns.string(value.string) };
    if (value.longList !== undefined)
        result.config = { field: "longList", value: decodeJson_2(value.longList) };
    if (value.json !== undefined)
        result.config = { field: "json", value: jsonValueToTsValueFns.string(value.json) };
    return result;
}
export function encodeBinary(value) {
    var _a;
    const result = [];
    if (value.type !== undefined) {
        const tsValue = value.type;
        result.push([1, tsValueToWireValueFns.string(tsValue)]);
    }
    switch ((_a = value.config) === null || _a === void 0 ? void 0 : _a.field) {
        case "long": {
            const tsValue = value.config.value;
            result.push([2, tsValueToWireValueFns.int64(tsValue)]);
            break;
        }
        case "stringList": {
            const tsValue = value.config.value;
            result.push([3, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
            break;
        }
        case "bool": {
            const tsValue = value.config.value;
            result.push([4, tsValueToWireValueFns.bool(tsValue)]);
            break;
        }
        case "string": {
            const tsValue = value.config.value;
            result.push([5, tsValueToWireValueFns.string(tsValue)]);
            break;
        }
        case "longList": {
            const tsValue = value.config.value;
            result.push([6, { type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }]);
            break;
        }
        case "json": {
            const tsValue = value.config.value;
            result.push([7, tsValueToWireValueFns.string(tsValue)]);
            break;
        }
    }
    return serialize(result);
}
const oneofFieldNumbersMap = {
    config: new Set([2, 3, 4, 5, 6, 7]),
};
const oneofFieldNamesMap = {
    config: new Map([
        [2, "long"],
        [3, "stringList"],
        [4, "bool"],
        [5, "string"],
        [6, "longList"],
        [7, "json"],
    ]),
};
export function decodeBinary(binary) {
    var _a;
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    const wireFieldNumbers = Array.from(wireFields.keys()).reverse();
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.type = value;
    }
    oneof: {
        const oneofFieldNumbers = oneofFieldNumbersMap.config;
        const oneofFieldNames = oneofFieldNamesMap.config;
        const fieldNumber = wireFieldNumbers.find(v => oneofFieldNumbers.has(v));
        if (fieldNumber == null)
            break oneof;
        const wireValue = wireFields.get(fieldNumber);
        const wireValueToTsValueMap = {
            [2](wireValue) { return wireValueToTsValueFns.int64(wireValue); },
            [3](wireValue) { return wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined; },
            [4](wireValue) { return wireValueToTsValueFns.bool(wireValue); },
            [5](wireValue) { return wireValueToTsValueFns.string(wireValue); },
            [6](wireValue) { return wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined; },
            [7](wireValue) { return wireValueToTsValueFns.string(wireValue); },
        };
        const value = (_a = wireValueToTsValueMap[fieldNumber]) === null || _a === void 0 ? void 0 : _a.call(wireValueToTsValueMap, wireValue);
        if (value === undefined)
            break oneof;
        result.config = { field: oneofFieldNames.get(fieldNumber), value: value };
    }
    return result;
}
