import { LanguageFromJSON, LanguageToJSON, } from './Language';
/**
 * Check if a given object implements the LearningDomainInfo interface.
 */
export function instanceOfLearningDomainInfo(value) {
    let isInstance = true;
    isInstance = isInstance && "displayName" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "language" in value;
    isInstance = isInstance && "id" in value;
    return isInstance;
}
export function LearningDomainInfoFromJSON(json) {
    return LearningDomainInfoFromJSONTyped(json, false);
}
export function LearningDomainInfoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'displayName': json['displayName'],
        'description': json['description'],
        'language': LanguageFromJSON(json['language']),
        'id': json['id'],
    };
}
export function LearningDomainInfoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'displayName': value.displayName,
        'description': value.description,
        'language': LanguageToJSON(value.language),
        'id': value.id,
    };
}
