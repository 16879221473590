/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { DisplayInfoFromJSON, DisplayInfoToJSON, } from './DisplayInfo';
import { PermitListItemFromJSON, PermitListItemToJSON, } from './PermitListItem';
/**
 * Check if a given object implements the TicketListItem interface.
 */
export function instanceOfTicketListItem(value) {
    let isInstance = true;
    isInstance = isInstance && "expireAtExclusive" in value;
    isInstance = isInstance && "ticketTemplateId" in value;
    isInstance = isInstance && "isUsable" in value;
    isInstance = isInstance && "permit" in value;
    isInstance = isInstance && "displayInfo" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "isExpired" in value;
    isInstance = isInstance && "isUsed" in value;
    isInstance = isInstance && "isRefundable" in value;
    return isInstance;
}
export function TicketListItemFromJSON(json) {
    return TicketListItemFromJSONTyped(json, false);
}
export function TicketListItemFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'expireAtExclusive': (new Date(json['expireAtExclusive'])),
        'ticketTemplateId': json['ticketTemplateId'],
        'isUsable': json['isUsable'],
        'permit': PermitListItemFromJSON(json['permit']),
        'displayInfo': DisplayInfoFromJSON(json['displayInfo']),
        'usedAt': !exists(json, 'usedAt') ? undefined : (new Date(json['usedAt'])),
        'id': json['id'],
        'isExpired': json['isExpired'],
        'isUsed': json['isUsed'],
        'isRefundable': json['isRefundable'],
    };
}
export function TicketListItemToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'expireAtExclusive': (value.expireAtExclusive.toISOString()),
        'ticketTemplateId': value.ticketTemplateId,
        'isUsable': value.isUsable,
        'permit': PermitListItemToJSON(value.permit),
        'displayInfo': DisplayInfoToJSON(value.displayInfo),
        'usedAt': value.usedAt === undefined ? undefined : (value.usedAt.toISOString()),
        'id': value.id,
        'isExpired': value.isExpired,
        'isUsed': value.isUsed,
        'isRefundable': value.isRefundable,
    };
}
