import {useRouter} from 'next/router';
import React, {useMemo} from 'react';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SettingsIcon from '@mui/icons-material/Settings';
import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Toolbar,
  Typography,
  Collapse,
  Grid,
  Select,
  MenuItem,
  SelectChangeEvent,
  IconButton,
} from '@mui/material';

import {useAtom} from 'jotai';
import {LearningDomainKey, LearningDomainListItem} from '@santa-web/gen/open-api/admin';
import {riiidCountryAtom} from '#app/atoms/country';
import RouteLink from '#app/components/RouteLink';
import {isoCountrySelections, IsoCountryCodes, availableCountriesForLearningDomains} from '#app/constants/isoCountries';
import {NAVIGATION_ITEMS, SingleNavigationItem, NavigationItem} from '#app/constants/navigation';
import {useListLearningDomains} from '#app/hooks/useListLearningDomains';
import useRiiidLearningDomain from '#app/hooks/useRiiidLearningDomain';

export const DRAWER_WIDTH = 240;

const Navigation: React.FC = () => {
  const {data: learningDomains} = useListLearningDomains();
  const {riiidLearningDomain, setRiiidLearningDomain} = useRiiidLearningDomain();
  const [riiidCountry, setRiiidCountry] = useAtom(riiidCountryAtom);
  const [openedListIndexes, setOpenedListIndexes] = React.useState<number[]>([]);
  const availableCountries = availableCountriesForLearningDomains[riiidLearningDomain.key];

  const isListExpanded = (index: number) => openedListIndexes.includes(index);

  const handleToggleList = (index: number) => {
    if (isListExpanded(index)) {
      setOpenedListIndexes(prev => prev.filter(listIndex => listIndex !== index));
    } else {
      setOpenedListIndexes(prev => [...prev, index]);
    }
  };

  const handleChangeLearningDomainId = React.useCallback(
    (e: SelectChangeEvent<number>) => {
      const {value} = e.target;
      const selectedLearningDomainId = Number(value);
      if (selectedLearningDomainId && Number.isSafeInteger(selectedLearningDomainId)) {
        const learningDomain = learningDomains?.find(({id}) => id === selectedLearningDomainId);
        learningDomain && setRiiidLearningDomain(learningDomain);
      }
    },
    [learningDomains, setRiiidLearningDomain]
  );

  const handleCountryChange = React.useCallback(
    (e: SelectChangeEvent<IsoCountryCodes>) => {
      const value = e.target.value as IsoCountryCodes;
      setRiiidCountry(value);
    },
    [setRiiidCountry]
  );

  const navigationItems = useMemo(() => {
    return NAVIGATION_ITEMS.map(nav => {
      if (nav.type === 'group') {
        const children = nav.children.filter(item => canExposeItem({item, riiidLearningDomain, riiidCountry}));

        if (children.length <= 0) return null;

        return {
          ...nav,
          children,
        };
      }

      return nav;
    }).filter(
      (item): item is NavigationItem =>
        !!item && (item.type === 'group' || canExposeItem({item, riiidLearningDomain, riiidCountry}))
    );
  }, [riiidCountry, riiidLearningDomain]);

  return (
    <Drawer
      variant="permanent"
      anchor="left"
      css={{
        flexShrink: 0,
        width: DRAWER_WIDTH,
        '.MuiDrawer-paper': {
          width: DRAWER_WIDTH,
        },
      }}>
      <Toolbar variant="dense">
        <RouteLink href="/" color="inherit">
          <Typography color="textSecondary" variant="h6" css={{cursor: 'pointer', paddingTop: '10px'}}>
            Santa Admin
          </Typography>
        </RouteLink>
      </Toolbar>
      <Toolbar variant="dense">
        <Grid container xs={12} alignItems="center">
          <Grid item xs={10}>
            <Select fullWidth value={riiidLearningDomain.id} onChange={handleChangeLearningDomainId}>
              {learningDomains?.map(({id, key}) => (
                <MenuItem key={key} value={id}>
                  {key}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={2}>
            <RouteLink href="/domain">
              <IconButton>
                <SettingsIcon />
              </IconButton>
            </RouteLink>
          </Grid>
        </Grid>
      </Toolbar>
      {riiidLearningDomain.key !== 'TOEIC' && (
        <Toolbar variant="dense">
          <Grid container>
            <Select<IsoCountryCodes> fullWidth value={riiidCountry} onChange={handleCountryChange}>
              {availableCountries.map(isoCountryCode => (
                <MenuItem key={isoCountryCode} value={isoCountryCode}>
                  {isoCountrySelections[isoCountryCode]}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Toolbar>
      )}
      <Divider />
      <List>
        {navigationItems.map((item, i) => {
          const isExpanded = isListExpanded(i);
          return (
            <React.Fragment key={i}>
              {item.type !== 'group' ? (
                <NavigationItem item={item} />
              ) : (
                <List
                  subheader={
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="space-between"
                      css={{cursor: 'pointer', paddingRight: '1em'}}
                      onClick={() => handleToggleList(i)}>
                      <Grid item>
                        <ListSubheader>{item.text}</ListSubheader>
                      </Grid>
                      <Grid item>
                        {isExpanded ? <ExpandLessIcon color="action" /> : <ExpandMoreIcon color="action" />}
                      </Grid>
                    </Grid>
                  }>
                  <Collapse in={isExpanded}>
                    {item.children.map((childItem, i) => (
                      <NavigationItem item={childItem} key={i} />
                    ))}
                  </Collapse>
                </List>
              )}
            </React.Fragment>
          );
        })}
      </List>
    </Drawer>
  );
};

const NavigationItem: React.FC<{item: SingleNavigationItem}> = ({item: {icon, text, to}}) => {
  const router = useRouter();
  return (
    <RouteLink href={to} color="textPrimary">
      <ListItem button selected={router.pathname.startsWith(to)}>
        {icon && <ListItemIcon>{icon}</ListItemIcon>}
        <ListItemText>{text}</ListItemText>
      </ListItem>
    </RouteLink>
  );
};

export function canExposeItem({
  item,
  riiidLearningDomain,
  riiidCountry,
}: {
  item: SingleNavigationItem;
  riiidLearningDomain: LearningDomainListItem;
  riiidCountry: IsoCountryCodes;
}) {
  function filterByLearningDomains(learningDomains?: LearningDomainKey[]) {
    return learningDomains == null || learningDomains.includes(riiidLearningDomain.key);
  }

  function filterByCountries(countries?: IsoCountryCodes[]) {
    return countries == null || countries.includes(riiidCountry);
  }

  return (
    item.exposeConditions.length === 0 ||
    item.exposeConditions.some(
      ({learningDomains, countries}) => filterByLearningDomains(learningDomains) && filterByCountries(countries)
    )
  );
}

export default Navigation;
