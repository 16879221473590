import { LearningDomainInfoFromJSON, LearningDomainInfoToJSON, } from './LearningDomainInfo';
import { LearningDomainKeyFromJSON, LearningDomainKeyToJSON, } from './LearningDomainKey';
import { LearningDomainStatusFromJSON, LearningDomainStatusToJSON, } from './LearningDomainStatus';
/**
 * Check if a given object implements the LearningDomainDetail interface.
 */
export function instanceOfLearningDomainDetail(value) {
    let isInstance = true;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "imageSetId" in value;
    isInstance = isInstance && "learningDomainStatuses" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "learningDomainInfos" in value;
    isInstance = isInstance && "key" in value;
    isInstance = isInstance && "updatedAt" in value;
    return isInstance;
}
export function LearningDomainDetailFromJSON(json) {
    return LearningDomainDetailFromJSONTyped(json, false);
}
export function LearningDomainDetailFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'createdAt': (new Date(json['createdAt'])),
        'imageSetId': json['imageSetId'],
        'learningDomainStatuses': (json['learningDomainStatuses'].map(LearningDomainStatusFromJSON)),
        'id': json['id'],
        'learningDomainInfos': (json['learningDomainInfos'].map(LearningDomainInfoFromJSON)),
        'key': LearningDomainKeyFromJSON(json['key']),
        'updatedAt': (new Date(json['updatedAt'])),
    };
}
export function LearningDomainDetailToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'createdAt': (value.createdAt.toISOString()),
        'imageSetId': value.imageSetId,
        'learningDomainStatuses': (value.learningDomainStatuses.map(LearningDomainStatusToJSON)),
        'id': value.id,
        'learningDomainInfos': (value.learningDomainInfos.map(LearningDomainInfoToJSON)),
        'key': LearningDomainKeyToJSON(value.key),
        'updatedAt': (value.updatedAt.toISOString()),
    };
}
