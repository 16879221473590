import { UserDetailFromJSON, UserDetailToJSON, } from './UserDetail';
/**
 * Check if a given object implements the WithdrawUserResponse interface.
 */
export function instanceOfWithdrawUserResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "user" in value;
    return isInstance;
}
export function WithdrawUserResponseFromJSON(json) {
    return WithdrawUserResponseFromJSONTyped(json, false);
}
export function WithdrawUserResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'user': UserDetailFromJSON(json['user']),
    };
}
export function WithdrawUserResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'user': UserDetailToJSON(value.user),
    };
}
