/* tslint:disable */
/* eslint-disable */
/**
 * - **PG**: PG
 * - **IN_APP**: 인앱
 * @export
 */
export const PaymentType = {
    PG: 'PG',
    IN_APP: 'IN_APP'
};
export function PaymentTypeFromJSON(json) {
    return PaymentTypeFromJSONTyped(json, false);
}
export function PaymentTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function PaymentTypeToJSON(value) {
    return value;
}
