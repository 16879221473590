import { TicketTemplateListItemFromJSON, TicketTemplateListItemToJSON, } from './TicketTemplateListItem';
/**
 * Check if a given object implements the OnboardingTicketTemplateListItem interface.
 */
export function instanceOfOnboardingTicketTemplateListItem(value) {
    let isInstance = true;
    isInstance = isInstance && "ticketTemplate" in value;
    isInstance = isInstance && "quantity" in value;
    return isInstance;
}
export function OnboardingTicketTemplateListItemFromJSON(json) {
    return OnboardingTicketTemplateListItemFromJSONTyped(json, false);
}
export function OnboardingTicketTemplateListItemFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'ticketTemplate': TicketTemplateListItemFromJSON(json['ticketTemplate']),
        'quantity': json['quantity'],
    };
}
export function OnboardingTicketTemplateListItemToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'ticketTemplate': TicketTemplateListItemToJSON(value.ticketTemplate),
        'quantity': value.quantity,
    };
}
