import {ErrorBody} from '@santa-web/gen/open-api/admin';

export class SantaFetchError extends Error {
  constructor(e: Error) {
    super(e.message);
    this.stack = e.stack;
    this.name = 'SantaFetchError';
  }
}

export interface SantaResponseErrorConstructorParams {
  santaErrorCode?: string;
  santaErrorMessage?: string;
  message: string;
  response: Response;
}

export class SantaResponseError extends Error {
  public santaErrorCode?: string;
  public santaErrorMessage?: string;
  public response: Response;

  constructor({santaErrorCode, santaErrorMessage, message, response}: SantaResponseErrorConstructorParams) {
    super(message);
    this.response = response;
    this.santaErrorCode = santaErrorCode;
    this.santaErrorMessage = santaErrorMessage;
    this.name = 'SantaResponseError';
  }

  static async fromResponse(response: Response) {
    try {
      const errorBody = await response.json();
      if (!errorBody.code || !errorBody.message) {
        throw new Error();
      }
      const {code, message} = errorBody as ErrorBody;
      return new SantaResponseError({
        santaErrorCode: code,
        santaErrorMessage: message,
        message,
        response,
      });
    } catch (innerError) {
      return new SantaResponseError({
        message: `${response.status} ${response.statusText}`,
        response,
      });
    }
  }
}
