// @ts-nocheck
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "../../../inside/payment/PaymentProvider";
import { WireType, } from "../../../../runtime/wire/index";
import { default as serialize, } from "../../../../runtime/wire/serialize";
import { default as deserialize, } from "../../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        paymentProvider: [],
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    result.paymentProvider = value.paymentProvider.map(value => encodeJson_1(value));
    return result;
}
export function decodeJson(value) {
    var _a, _b;
    const result = getDefaultValue();
    result.paymentProvider = (_b = (_a = value.paymentProvider) === null || _a === void 0 ? void 0 : _a.map((value) => decodeJson_1(value))) !== null && _b !== void 0 ? _b : [];
    return result;
}
export function encodeBinary(value) {
    const result = [];
    for (const tsValue of value.paymentProvider) {
        result.push([1, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 1).map(([, wireValue]) => wireValue);
        const value = wireValues.map((wireValue) => wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined).filter(x => x !== undefined);
        if (!value.length)
            break collection;
        result.paymentProvider = value;
    }
    return result;
}
