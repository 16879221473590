import { StripeBillingConfigFromJSON, StripeBillingConfigToJSON, } from './StripeBillingConfig';
import { StripeSubscriptionOrderStatusFromJSON, StripeSubscriptionOrderStatusToJSON, } from './StripeSubscriptionOrderStatus';
/**
 * Check if a given object implements the UserStripeSubscriptionOrderListItem interface.
 */
export function instanceOfUserStripeSubscriptionOrderListItem(value) {
    let isInstance = true;
    isInstance = isInstance && "productId" in value;
    isInstance = isInstance && "checkoutSessionId" in value;
    isInstance = isInstance && "billingConfig" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "productName" in value;
    isInstance = isInstance && "status" in value;
    return isInstance;
}
export function UserStripeSubscriptionOrderListItemFromJSON(json) {
    return UserStripeSubscriptionOrderListItemFromJSONTyped(json, false);
}
export function UserStripeSubscriptionOrderListItemFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'productId': json['productId'],
        'checkoutSessionId': json['checkoutSessionId'],
        'billingConfig': StripeBillingConfigFromJSON(json['billingConfig']),
        'id': json['id'],
        'productName': json['productName'],
        'status': StripeSubscriptionOrderStatusFromJSON(json['status']),
    };
}
export function UserStripeSubscriptionOrderListItemToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'productId': value.productId,
        'checkoutSessionId': value.checkoutSessionId,
        'billingConfig': StripeBillingConfigToJSON(value.billingConfig),
        'id': value.id,
        'productName': value.productName,
        'status': StripeSubscriptionOrderStatusToJSON(value.status),
    };
}
