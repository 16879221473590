import { PassTemplateListItemFromJSON, PassTemplateListItemToJSON, } from './PassTemplateListItem';
/**
 * Check if a given object implements the ListPassTemplateResponse interface.
 */
export function instanceOfListPassTemplateResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "total" in value;
    isInstance = isInstance && "passTemplates" in value;
    return isInstance;
}
export function ListPassTemplateResponseFromJSON(json) {
    return ListPassTemplateResponseFromJSONTyped(json, false);
}
export function ListPassTemplateResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'total': json['total'],
        'passTemplates': (json['passTemplates'].map(PassTemplateListItemFromJSON)),
    };
}
export function ListPassTemplateResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'total': value.total,
        'passTemplates': (value.passTemplates.map(PassTemplateListItemToJSON)),
    };
}
