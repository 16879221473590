export const num2name = {
    0: "ERROR_UNSPECIFIED",
    1: "ERROR_SELF_LEARNING_HIERARCHY_NOT_FOUND",
    2: "ERROR_ALREADY_SET_AS_READY",
    3: "ERROR_INVALID_TEMPLATE",
};
export const name2num = {
    ERROR_UNSPECIFIED: 0,
    ERROR_SELF_LEARNING_HIERARCHY_NOT_FOUND: 1,
    ERROR_ALREADY_SET_AS_READY: 2,
    ERROR_INVALID_TEMPLATE: 3,
};
