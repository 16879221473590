/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { DiscountConfigFromJSON, DiscountConfigToJSON, } from './DiscountConfig';
import { PeriodFromJSON, PeriodToJSON, } from './Period';
import { UserSegmentationFromJSON, UserSegmentationToJSON, } from './UserSegmentation';
/**
 * Check if a given object implements the SalesConfig interface.
 */
export function instanceOfSalesConfig(value) {
    let isInstance = true;
    isInstance = isInstance && "userSegmentations" in value;
    isInstance = isInstance && "salesPeriod" in value;
    return isInstance;
}
export function SalesConfigFromJSON(json) {
    return SalesConfigFromJSONTyped(json, false);
}
export function SalesConfigFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'userSegmentations': (json['userSegmentations'].map(UserSegmentationFromJSON)),
        'discountConfig': !exists(json, 'discountConfig') ? undefined : DiscountConfigFromJSON(json['discountConfig']),
        'salesPeriod': PeriodFromJSON(json['salesPeriod']),
    };
}
export function SalesConfigToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'userSegmentations': (value.userSegmentations.map(UserSegmentationToJSON)),
        'discountConfig': DiscountConfigToJSON(value.discountConfig),
        'salesPeriod': PeriodToJSON(value.salesPeriod),
    };
}
