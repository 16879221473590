import { TicketTemplateFromJSON, TicketTemplateToJSON, } from './TicketTemplate';
/**
 * Check if a given object implements the GetTicketTemplateResponse interface.
 */
export function instanceOfGetTicketTemplateResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "ticketTemplate" in value;
    return isInstance;
}
export function GetTicketTemplateResponseFromJSON(json) {
    return GetTicketTemplateResponseFromJSONTyped(json, false);
}
export function GetTicketTemplateResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'ticketTemplate': TicketTemplateFromJSON(json['ticketTemplate']),
    };
}
export function GetTicketTemplateResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'ticketTemplate': TicketTemplateToJSON(value.ticketTemplate),
    };
}
