// @ts-nocheck
import { encodeJson as encodeJson_2, decodeJson as decodeJson_2, encodeBinary as encodeBinary_2, decodeBinary as decodeBinary_2, } from "./(UpdateStudentRequest)/Unlink";
import { encodeJson as encodeJson_3, decodeJson as decodeJson_3, encodeBinary as encodeBinary_3, decodeBinary as decodeBinary_3, } from "./(UpdateStudentRequest)/Link";
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "../../b2b/(B2bStudent)/PrivacyInfo";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../../runtime/json/scalar";
import { WireType, } from "../../../../runtime/wire/index";
import { default as serialize, } from "../../../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../../../runtime/wire/scalar";
import { default as deserialize, } from "../../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        studentId: "0",
        privacyInfo: undefined,
        linkConfig: undefined,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    var _a;
    const result = {};
    if (value.studentId !== undefined)
        result.studentId = tsValueToJsonValueFns.int64(value.studentId);
    if (value.privacyInfo !== undefined)
        result.privacyInfo = encodeJson_1(value.privacyInfo);
    switch ((_a = value.linkConfig) === null || _a === void 0 ? void 0 : _a.field) {
        case "unlink": {
            result.unlink = encodeJson_2(value.linkConfig.value);
            break;
        }
        case "link": {
            result.link = encodeJson_3(value.linkConfig.value);
            break;
        }
    }
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.studentId !== undefined)
        result.studentId = jsonValueToTsValueFns.int64(value.studentId);
    if (value.privacyInfo !== undefined)
        result.privacyInfo = decodeJson_1(value.privacyInfo);
    if (value.unlink !== undefined)
        result.linkConfig = { field: "unlink", value: decodeJson_2(value.unlink) };
    if (value.link !== undefined)
        result.linkConfig = { field: "link", value: decodeJson_3(value.link) };
    return result;
}
export function encodeBinary(value) {
    var _a;
    const result = [];
    if (value.studentId !== undefined) {
        const tsValue = value.studentId;
        result.push([1, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.privacyInfo !== undefined) {
        const tsValue = value.privacyInfo;
        result.push([2, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    switch ((_a = value.linkConfig) === null || _a === void 0 ? void 0 : _a.field) {
        case "unlink": {
            const tsValue = value.linkConfig.value;
            result.push([3, { type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }]);
            break;
        }
        case "link": {
            const tsValue = value.linkConfig.value;
            result.push([4, { type: WireType.LengthDelimited, value: encodeBinary_3(tsValue) }]);
            break;
        }
    }
    return serialize(result);
}
const oneofFieldNumbersMap = {
    linkConfig: new Set([3, 4]),
};
const oneofFieldNamesMap = {
    linkConfig: new Map([
        [3, "unlink"],
        [4, "link"],
    ]),
};
export function decodeBinary(binary) {
    var _a;
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    const wireFieldNumbers = Array.from(wireFields.keys()).reverse();
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.studentId = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.privacyInfo = value;
    }
    oneof: {
        const oneofFieldNumbers = oneofFieldNumbersMap.linkConfig;
        const oneofFieldNames = oneofFieldNamesMap.linkConfig;
        const fieldNumber = wireFieldNumbers.find(v => oneofFieldNumbers.has(v));
        if (fieldNumber == null)
            break oneof;
        const wireValue = wireFields.get(fieldNumber);
        const wireValueToTsValueMap = {
            [3](wireValue) { return wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined; },
            [4](wireValue) { return wireValue.type === WireType.LengthDelimited ? decodeBinary_3(wireValue.value) : undefined; },
        };
        const value = (_a = wireValueToTsValueMap[fieldNumber]) === null || _a === void 0 ? void 0 : _a.call(wireValueToTsValueMap, wireValue);
        if (value === undefined)
            break oneof;
        result.linkConfig = { field: oneofFieldNames.get(fieldNumber), value: value };
    }
    return result;
}
